@import '../utils/utils.scss';

.feedback-main {
    padding: 40px 30px !important;
    background-color: #F9F9F9;
    height: calc(100vh - 58px);

    .feedbackAssessment {
        border-radius: 20px;
        background-color: #fff;
        padding: 30px 20px 20px 40px;
        .peer-feed-btn{
            padding: 0.25rem 1rem;
            border-radius: 5px;
            background-color: var(--primary);
            color: var(--secondary);
            width: 10rem;
            cursor: pointer;
        }
        .MuiStack-root {
            margin-left: 0;
            margin-right: 0;
        }
        .userImg {
            height: 75px;
            width: 75px;
            border-radius: 50%;
        }

        .bor-b {
            border-bottom: 1px solid #00000026;
            padding-bottom: 20px;
        }

        .title {
            font-size: 2rem;
            font-weight: 600;
            line-height: 48px;
            letter-spacing: 0em;
            color: var(--primary);
            margin: 0;
        }

        .id {
            @include smallText;
        }

        .viewBtn {
            @include subHeadingDark;
            font-weight: 600;
            padding: 13px 15px;
            border-color: var(--primary);
            background: #fff;
            border-radius: 10px;
        }

        .trendingRating {
            .title {
                font-family: Poppins;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0em;
                margin: 0;
                color: #000;
            }

            .rateOuter {
                padding-top: 25px;
                padding-bottom: 0;
                @media(max-width:500px){
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 10px;
                }
                @media(min-width:500px){
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-gap: 10px;
                }
            }

            .rate {
                border-radius: 10px;
                background: #F9F9F9;
                position: relative;
                padding: 15px 12px;
                text-align: center;
                width: 100%;
                cursor: pointer;

                .rateTitle {
                    @include smallText;
                    font-family: Poppins;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0em;
                    margin: 0;
                }

                .checked {
                    opacity: 0;
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    width: 12px;
                }
                
            }

            .rate.active {
                background-color: var(--primary);    
                .rateTitle {
                    color: var(--secondary);
                }
            }

            .contentDiv {
                .contentHead {
                    padding-right: 20px;
                    padding-bottom: 20px;
                    padding-top: 20px;

                    .title {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 30px;
                        letter-spacing: 0em;
                        color: #000000;
                        margin: 0;

                        .red {
                            color: #DB3535;
                        }
                    }

                }

                .values {
                    padding-bottom: 30px;
                    grid-template-columns: repeat(6, minmax(0, 1fr));

                    .valueTitle {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 30px;
                        letter-spacing: 0em;
                        color: var(--tertiary);
                        margin: 0;
                        text-align: center;
                        padding: 20px 20px;
                        border-radius: 10px;
                        position: relative;

                        .checked {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            opacity: 0;
                        }
                    }

                    .valueTitle.active {
                        background-color: var(--primary); 
                        .rateTitle {
                            color: var(--secondary);
                        }
                    }

                    .value1 {
                        background: #FCF8E8;
                    }

                    .value2 {
                        background: #FFE6E6;
                    }

                    .value3 {
                        background: #F6FBF4;
                    }

                    .value4 {
                        background: #EAF6F6;
                    }

                    .value5 {
                        background: #DAEAF1;
                    }

                    .value6 {
                        background: #F9F9F9;
                    }

                    .textarea {
                        width: 100%;
                        background: #F9F9F9;
                        border: none;
                        border-radius: 10px;
                        height: 65px;
                        padding: 10px 20px;
                        outline: none;
                    }

                    .btn {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0em;
                        border-radius: 10px;
                        padding: 10px 50px;
                    }

                    .save {
                        border: 1px solid #000;
                        background: #fff;
                    }

                    .next {
                        background: #000;
                        color: #fff;
                    }

                    .MuiStack-root.css-13xfuc4-MuiStack-root {
                        margin: 1rem 0rem;
                    }

                }
            }


        }

        .accDetail-questions{
            @include subHeadingDark;
            display: flex;
            justify-content: space-between;
            .icon-btns{
                min-width: 14rem;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
               p{
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--primary);
                font-size: 0.87rem;
                cursor: pointer;
               
               }
               .peer-btn{
            border: 1px solid var(--primary);
            padding: 2px 1rem;
            border-radius: 0.5rem;
            &:hover{
                color: skyblue;
                border: 1px solid skyblue;
            }

            }
               .i-btn{
                p{
                background-color: var(--primary);
                color: #ffffff;
                border-radius: 1rem;
                width: 1.5rem;
                height: 1.5rem;
                text-align: center;
                font-weight: 600;
                &:hover{
                    background-color: skyblue;
                }
               }}
            }
        }
        .rateOuter {
            padding-top: 25px;
            padding-bottom: 0;
            @media(max-width:500px){
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
            }
            @media(min-width:500px){
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-gap: 10px;
            }
        }
        .rate {
            border-radius: 10px;
            background: #F9F9F9;
            position: relative;
            padding: 15px 12px;
            text-align: center;
            width: 100%;
            height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            cursor: pointer;
            
            .rateTitle {
                @include smallText;
                font-family: Poppins;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                margin: 0;
            }
            .checked {
                opacity: 0;
                position: absolute;
                right: 10px;
                top: 5px;
                width: 12px;
            }
        }
        .rate.active {
            background-color: var(--primary);      
            .rateTitle {
                color: var(--secondary);
            }
        }

        .rate.deactive {
            .rateTitle{
                color: #cfcfcf;
                background-color: #f5f5f5;
                cursor: no-drop;
            }
        }
        .dropdown-heading{
            @include normalText;
            display: flex;
            justify-content: space-between;
            .i-btn{
                p{
                background-color: var(--primary);
                color: #ffffff;
                border-radius: 1rem;
                width: 1.5rem;
                height: 1.5rem;
                text-align: center;
                font-weight: 600;
                cursor: pointer;
                &:hover{
                    background-color: skyblue;
                }
               }}

        }
        .accDetal-xFeedback{
            .q-title{
                @include normalText;
                margin-top: 0.7rem;
                margin-left: 0.5rem;
            }
            .q-text{
                color: var(--tertiary);
                font-size: 0.82rem;
                padding: 0.7rem;
                height: 5rem;
                background-color: #f9f9f9;
                border-radius: 7px;
                margin-top: 0.7rem;
                .readmore-text{
                    font-size: 0.82rem;
                    margin-left: 0.5rem;
                    color: blue;
                    cursor: pointer;
                }
            }
            
        }
        .q-title-values{
            @include normalText;
            margin-top: 1rem;
            margin-left: 0.5rem;
        }
        .peer-title{
            font-size: 1rem;
            margin-top: 0.7rem;
            color: gray;
        }

        .accDetal-peerFeedback{
            .p-title{
                font-size: 1rem;
                margin-top: 0.7rem;
                color: gray;
            }
            .p-text{
                font-size: 1rem;
                padding: 0.7rem;
                border-radius: 7px;
                display: list-item;          /* This has to be "list-item"                                               */
                list-style-type: square;     /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
                list-style-position: inside; /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-position */
            }
        }

        
    }
    .rightArrow {
        cursor: pointer;
    }
    .main-btn {
        .MuiStack-root {
            margin-left: 0;
            margin-right: 0;
        }
    
        .valuesBtn {
            line-height: 24px;
            width: 163px;
            height: 50px;
            
            border-radius: 10px;
        }
    
        .draft {
            @include subHeadingDark;
            background: #fff;
            border-color: var(--primary);

            display: flex;
            justify-content: center;
            align-items: center;
        }
        .save-draft {
            @include subHeadingDark;
            background: #fff;
            border: 1px solid var(--primary);
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;

            &:hover{
                color: #006387;
                border: 1px solid #006387;
            }
        }
    
        .next {
            @include subHeadingLight;
            border: none;
            color: #fff;
            background-color: var(--primary);
            &:hover{
                background-color: #006387;
            }
        }
    }
    .acc-wrapper{
        .acc-heading{
            margin-top: 1rem;
            font-size: 1.25rem;
            font-weight: 600;
        }
    }
    .no-feedback-user{
        width: 100%;
        text-align: center;
        padding: 5rem 0px;
        font-size: 2rem;
        color: var(--primary);
        font-weight: 500;
    }
}

.user-profile-page{
    .feedback{
        .heading {            
            .membCount {
                @include heading;
                font-weight: 600;
                line-height: 30px;
            }
            .member {
                @include smallText;
                line-height: 18px;
            }
        }
       
        .userData {
            border: 1px solid #00000038;
            border-radius: 10px;
            padding: 18px;
            margin-bottom: 15px;
           
            .userImg {
                height: 75px;
                width: 75px;
                border-radius: 50%;
            }
            .name {
                @include subHeadingDark;
                font-weight: 600;
                line-height: 24px;
            }
            .member {
                @include smallText;
                font-weight: 400;
                line-height: 18px;
            }
            button.btn {
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0em;
                border-radius: 10px;
                width: 130px;
            }
            button.btn.green {
                background: #69C88E;
            }
            button.btn.red {
                background: #DB3535;
            }

        }
    }
   
}

