.login-main{
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #ffffff;
  .left {
    padding: 20px 30px;
    .loginBg {
      width: 100%;
      height: 100vh;
      max-height: calc(100vh - 40px);
    }
  }
  .row {
    margin: 0;
  }
  .right {
    .loginBlock {
      display: flex;
      align-items: center;
      height: 100vh;
      width: 100%;
      .loginBlockInner {
        width: 100%;
        padding-right: 20px;
        .logo-img{
          width: 100%;
          margin-bottom: 2rem;
        }
        .loginHeadingOuter {
          border-bottom: 1px solid var(--primary);
          margin-bottom: 30px;
          position: relative;
          &::after {
            content: '';
            height: 3px;
            width: 169px;
            background: var(--primary);
            position: absolute;
            bottom: 0px;
          }
          .loginHeading {
            width: fit-content;
            margin: 0;
            font-family: Poppins;
            font-size: 32px;
            font-weight: 400;
            line-height: 48px;
            letter-spacing: 0.015em;
            color: var(--primary);
            padding-bottom: 10px;
          }
        }
        .form-check.checkbox {
          .form-check-label {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
        .or-text{
          text-align: center;
          font-size: 1.5rem;
          color: var(--primary);
          margin-top: 0.7rem;
        }
      }
    }
  }

}

.loginBlock {

    .input {
      background-color: #F9F9F9;
      width: 100%;
      padding-left: 12px;
      border: none;
      height: 65px;
      border-radius: 10px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--tertiary);
      outline: none;
    }
    .eyeIcon{
      border: none;
      position: absolute;
      background-color: #f9f9f9;
      top: 0;
      font-size: 1rem;
      right: 0.5rem;
      cursor: pointer;
      width: 2rem;
      height: 90%;
      margin-top: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg{
        color: var(--primary);
      }
    }
    button.login {
      background: var(--primary);
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 30px;
      color: #ffffff;
      width: 100%;
      padding: 10px 0;
    }
    .link {
      color: var(--tertiary);
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      text-decoration: none;
    }
    .loginBtnOuter {
      button {
        padding: 12px 22px;
        background: var(--primary);
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        height: 65px;
        text-transform: capitalize;
        p {
          margin: 0;
        }
      }
    }
  
    .container{
      .orOuter{
        margin: auto 1rem;
        margin-top: 1.2rem;
        hr{
          border-color: var(--primary);
        }
        .orInner{
          margin: 0px auto;
          margin-top: -0.75rem;
          width: 2.5rem;
          text-align: center;
          background-color: #ffffff;
          color: var(--primary);
          font-weight: 600;
           
        }
      }
    }
  }
  
  .headerLogin {
    background: var(--secondary);

    .container{
        .img-wrap{
            display: flex;
            flex-wrap: wrap;
            .img-wrap-1{
                width: 100%;
            }
        }
    }
  
    .headerLoginInner {

        .img-text{
            padding: 1rem 0px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
  
      .right {
        display: flex;
        align-items: center;
      }
      .logoImg {
        width: 100%;
        height: 20rem;
        margin: 1rem auto;
        padding: 1.5rem 0;
      }
      .left {
        .text {
          font-weight: 600;
          font-size: 1.5rem;
          color: var(--primary);
          padding-left: 25px;
          .textInner {
            font-weight: 400;
          }
        }
        .desc {
          font-weight: 300;
          font-size: 0.75rem;
          line-height: 20px;
          color: var(--primary);
          padding-left: 25px;
        }
      }
    }
   
  }

  .input-wrapper{
    flex-wrap: wrap;
    padding-bottom: 0.5rem;
    .name-ip{
        .helping-text{
            padding-top: 0.5rem;
            color: gray;
            font-size: 0.9rem;
        }
        .error-text{
            margin-top: 0.5rem;
            color: red;
            font-size: 0.9rem;
        }
    }
    
  }
  .password-wrap{
    flex-wrap: wrap;
    padding-top: 0.5rem;
    .password-ip{
        .password-outer{
            display: flex;
            position: relative;            
        }
        .error-text{
          margin-top: 0.5rem;
          color: red;
          font-size: 0.9rem;
      }
    }
}
.forgot-pass{
    max-width: 20rem;
    text-align: right;
}
