.common-emp-profile{
    .user-profile-sidedrawer-img{
        padding: 0.5rem;
        display: flex;
        justify-content: center;    
        .userImg {
          width: 75px;
          height: 75px;
          border-radius: 50%;
      }
    }    

    .user-info{
      p{
        font-size: 1rem;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
        span{
            font-weight: 500;
            text-transform: capitalize;
        }
      }
    }

    background: #fff;
    border-radius: 20px;
    margin: 0 1rem;
    margin-bottom: 30px;
    .accordian-title {
        @include heading;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
    }
    .accordian-data {
        ul{
            li{
                @include normalText
            }
        }
        .d-flex {
            border-bottom: 1px solid rgb(223, 223, 223);
            padding: 0.25rem !important;
        }
        .title {
            font-size: 0.9rem;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--tertiary);
            margin: 0;
        }
        .subTitle {
            margin: 0;
            font-size: 0.9rem;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            color: var(--primary);
        }
        .emp-profile-ratings{
            margin-top: 1rem;
                border: none;
                width: 100%;
                text-align: left;
                font-size: 0.9rem;
                thead{
                    background-color: #f0f0f0;
                }
                tr{
                    border-bottom: 1px solid rgb(223, 223, 223);
                }
                th{
                    text-align: left;
                    padding: 0.25rem 0px;
                }
                td{
                    text-align: left;
                    padding: 0.25rem 0px;
                }
        }
    }
    .tablee{
      
        .subTitle {
            width: 40%;
        }
        .title{
          width: 40%;
        }
        .middle{
            display: flex;
            justify-content: center;
            width: 30%;
        }
        .right{
            display: flex;
            justify-content: right;
            width: 30%;
        }
    }
}
