@import "../../utils/utils.scss";

.emp-goals-page {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1rem 2rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .year-dropDown-div {
        width: 100%;
        text-align: end;

        .year-dropDown {
            width: 110px;
            background-color: var(--secondary);
            border-radius: 10px;
            border: none;
            gap: 10px;
        }

        .year-dropDown .MuiSelect-select {
            color: var(--primary);
            font-size: 16px;
            border: none;
        }

        .year-dropDown .MuiMenuItem-root {
            color: var(--primary);
            font-size: 16px;
        }

        .year-dropDown .MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }


    .outer-chart-layout {
        width: 100%;
        display: grid;
        grid-template-columns: 49.5% 1% 49.5%;
        background-color: var(--white);
        border-radius: 20px;
        margin-top: 15px;

        .column-layout {
            padding: 24px;
        }

        .verticalBorder {
            width: 1px;
            background-color: var(--grayLight);
            margin-top: 65px;
            margin-bottom: 30px;
        }

        .goalMilestoneSummary {
            color: var(--primary);
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0px;
        }

        .chart-column {
            display: flex;
            padding-top: 10px;
        }

        .d-block-w100 {
            display: block;
            width: 100%;
            align-content: center;
            padding-left: 24px;
        }

        .mt-18 {
            margin-top: 18px;
        }

        .sub-outer-chart {
            width: 100%;
            display: grid;
            grid-template-columns: 50% 50%;

            .sub-outer-column {}

            .sub-col-value {
                color: var(--primary);
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0px;
            }

            .d-flex {
                display: flex;
            }

            .status-card {
                width: 19px;
                height: 11px;
                border-radius: 4px;
                margin-top: 5px;
                margin-right: 5px;
            }

            .status-green {
                background-color: #11CE87;
            }

            .status-red {
                background-color: #DB3535;
            }

            .status-yellow {
                background-color: #FAC94D;
            }

            .status-blue {
                background-color: #2C8FFC;
            }

            .sub-col-percent-value {
                color: var(--primary);
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 0px;
            }

            .sub-col-status {
                color: var(--grayDark);
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 0px;
                margin-left: 23px;
            }
        }
    }

    .goals-tab-btn-div {
        width: 100%;
        display: grid;
        grid-template-columns: 84% 14%;
        grid-gap: 1rem;
        margin-top: 30px;

        .goals-tab {
            display: flex;
            align-items: flex-end;

            .tab-d-flex {
                display: flex;
            }

            .all-selected-count-border {
                width: fit-content;
                background-color: var(--white);
                border: 1px solid var(--secondary);
                border-radius: 50px;
                display: flex;
                padding: 5px;
                margin-top: 10px;

                .all-select-btn {
                    background-color: transparent;
                    padding: 6px 13px;
                    border-radius: 50px;
                    gap: 10px;
                    color: var(--black);
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: capitalize;
                }

                .active-btn {
                    background-color: var(--primary);
                    padding: 6px 13px;
                    border-radius: 50px;
                    gap: 10px;
                    color: var(--white);
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: capitalize;
                }
            }

            .tab {
                color: var(--grayDark);
                font-size: 16px;
                font-weight: 400;
                border: none;
                margin-right: 1rem;
                padding: 0.5rem 1rem;
                border-radius: 48px;
                cursor: pointer;

                span {
                    color: var(--white);
                    font-size: 10px;
                    font-weight: 500;
                    padding: 0.30rem;
                    border-radius: 1rem;
                    background-color: var(--primary);
                    margin-left: 0.5rem;
                }
            }

            .tab.active {
                color: var(--primary);
                font-size: 16px;
                font-weight: 400;
                background-color: var(--secondary);
            }

            .qu-icon-div {
                display: flex;
                justify-content: flex-end;
                flex: 1;
            }
        }

        .submit-Goals-div {
            text-align: right;
            align-content: end;

            .submit-Goals-btn {
                width: 8.4rem;
                background-color: var(--primary);
                border-radius: 10px;
                padding: 8px 20px;
                cursor: pointer;
                text-transform: capitalize;
                color: var(--white);
                font-size: 14px;
                font-weight: 500;
            }
        }
    }


    .outer-edit-comments-layout {
        width: 100%;
        display: grid;
        grid-template-columns: 68% 30%;
        grid-gap: 1rem;
        margin-top: 16px;

        .edit-card-layout {

            .button-outer-view-top{
                display: flex;
                justify-content: flex-end;
                .reject-btn{
                    background-color: #DB3535;
                    border: 1px solid #DB3535;
                    color: #ffffff;
                    padding: 5px 20px;
                    border-radius: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    margin-right: 1rem;
                }
                .approve-btn{
                    background-color: var(--primary);
                    border: 1px solid var(--primary);
                    color: #ffffff;
                    padding: 5px 20px;
                    border-radius: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    margin-right: 1rem;
                }
                .disabled-reject-btn{
                    background-color: gray;
                    border: 1px solid gray;
                }
                .disabled-approve-btn{
                    background-color: gray;
                    border: 1px solid gray;
                }
            }

            .accordian-heading {
                color: var(--black);
                font-size: 16px;
                font-weight: 400;
                // margin-left: 5px;
            }

            .accordian-end-date {
                color: #72A4B8;
                font-size: 12px;
                font-weight: 400;
                // margin-left: 42px;
            }

            .d-flex {
                display: flex !important;
            }

            .slider-div {
                margin-top: 5px;
                margin-left: 10px;
            }

            .slider-status {
                padding: 3px 8px 3px 8px;
                border-radius: 8px;
                border: 1px;
                opacity: 0px;
                color: var(--grayDark);
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                margin-left: 10px;
            }

            .status-gray-color {
                background-color: var(--grayLight);
            }

            .status-green-color {
                background-color: #BCE7D3;
            }

            .accordian-slider-persent {
                color: var(--primaryLight);
                font-size: 10px;
                font-weight: 500;
            }

            .slider {
                height: 6px;
                width: 158px;
                background-color: var(--secondary);
                border-radius: 6px;
            }

            .replicate-btn {
                padding: 5px 20px;
                border-color: var(--primary);
                border-radius: 8px;
                color: var(--primary);
                font-size: 14px;
                font-weight: 500;
                margin-right: 15px;
                text-transform: capitalize;
                cursor: pointer;
            }

            .edit-btn {
                height: 2.7rem;
                background-color: var(--primary);
                padding: 5px 20px;
                border-color: var(--primary);
                border-radius: 8px;
                color: var(--white);
                font-size: 14px;
                font-weight: 500;
                margin-right: 15px;
                text-transform: capitalize;
                cursor: pointer;
            }
            .replica-btn {
                height: 2.7rem;
                background-color: #ffffff;
                padding: 5px 20px;
                border: 1px solid var(--primary);
                border-radius: 8px;
                color: var(--primary);
                font-size: 14px;
                font-weight: 500;
                margin-right: 15px;
                text-transform: capitalize;
                cursor: pointer;
            }

            .horizontal-border {
                border: 1px solid #F4F4F4;
                margin-left: 15px;
                margin-right: 40px;
            }

            .details-div {
                margin-left: 15px;
                margin-top: 20px;
            }

            .details-title {
                color: var(--primary);
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 0px;
            }

            .details-description {
                color: var(--grayDark);
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 0px;
                margin-right: 20px;
            }

            .mt-20 {
                margin-top: 20px;
            }

            .sub-container {
                width: 100%;
                display: grid;
                grid-template-columns: 49.5% 49.5%;
                grid-gap: 1rem;
                margin-top: 20px;

                .sub-item-col {
                    .border-div {
                        padding: 6px 10px;
                        border-radius: 7px;
                        border: 1px solid #BDD7EA;
                        margin-top: 2px;
                        margin-right: 8px;
                    }

                    .sub-container-description {
                        color: var(--primary);
                        font-size: 14px;
                        font-weight: 400;
                        margin-bottom: 0px;
                    }

                    .red-description {
                        color: #DB3535;
                    }
                }
            }

            .sub-status-div {
                width: 100%;
                display: grid;
                grid-template-columns: 14% 14% 69% 2%;
                align-items: center;
                margin-top: 32px;
            }

            .sub-status-title {
                color: #272727;
                font-size: 16px;
                font-weight: 600;
                margin-right: 5px;
            }

            .completed-status {
                color: #00AA5A;
                background-color: #E6F4EE;
                border: 1px solid #BCE7D3;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                padding: 3px 8px;
                border-radius: 8px;
                margin-right: 5px;
                margin-bottom: 0px;
                text-align: center;
            }

            .delayed-status {
                color: #DB3535;
                background-color: #FFF0F0;
                border: 1px solid #F8DBDB;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                padding: 3px 8px;
                border-radius: 8px;
                margin-right: 5px;
                margin-bottom: 0px;
                text-align: center;
            }

            .inProgress-status {
                color: #E5A30E;
                background-color: #FDF5E4;
                border: 1px solid #EAD9B4;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                padding: 3px 8px;
                border-radius: 8px;
                margin-right: 5px;
                margin-bottom: 0px;
                text-align: center;
            }

            .status-horizontal-border {
                width: 100%;
                height: 1px;
                background-color: #F4F4F4;
            }

            .status-col-div {
                width: 100%;
                display: grid;
                grid-template-columns: 2% 98%;

                .status-col {

                    .status-vertical-border {
                        width: 1px;
                        height: 80%;
                        background-color: var(--grayLight);
                        margin-top: 20px;
                    }
                }
            }
        }

     
    }



    .status-history-main {
        background-color: var(--white);
        border-radius: 14px;
        padding: 20px;
        margin-top: 20px;

        .header-div {
            display: flex !important;
            justify-content: space-between;

            .header-title {
                color: var(--primary);
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }

        .item-header {
            display: flex;
            align-items: center;
            margin-top: 15px;

            .item-title {
                color: var(--primary);
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 0px;
            }

            .toFromStatus {
                padding: 3px 8px 3px 8px;
                border-radius: 8px;
                border: 1px;
                opacity: 0px;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                margin-left: 10px;
                margin-right: 10px;
                margin-bottom: 0px;
            }

            .statusGrayBgBorder {
                border: 1px solid var(--grayLight);
                background-color: #E9E9E9;
                color: var(--grayDark);
            }

            .statusGreenBgBorder {
                border: 1px solid #BCE7D3;
                background-color: #E6F4EE;
                color: #00AA5A;
            }

            .statusOrangeBgBorder {
                border: 1px solid #EAD9B4;
                background-color: #FDF5E4;
                color: #E5A30E;
            }

            .status-date {
                color: var(--grayDark);
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 0px;
                flex: 1;
                text-align: end;
            }
        }

        .status-description {
            color: var(--tertiary);
            font-size: 14px;
            font-weight: 400;
            margin-top: 10px;
            margin-bottom: 0px;
        }

        .item-border {
            height: 1px;
            background-color: var(--grayDark);
            margin-top: 12px;
            margin-bottom: 12px;
        }
    }
    .not-found{
        color: var(--primary);
        text-align: center;
        margin-top: 1rem;
        font-size: 1.25rem;
        font-weight: 500;
    }
}
