body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Poppins !important;
  /* font-family: 'Poppins', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiStack-root .MuiAccordionSummary-content .accorTitle {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  color: var(--tertiary);
}
.MuiStack-root .Mui-expanded .MuiAccordionSummary-content .accorTitle {
  color: var(--primary);
}

.borNone {
  border: none !important;
}
.MuiStack-root .MuiAccordionSummary-content svg {
  font-size: 1.5rem !important;
}
.MuiStack-root .MuiAccordionSummary-content .accorTitle .upArrowAccrodian {
  position: absolute;
  right: 15px;
  top: 21px;
  opacity: 0;
}
.MuiStack-root .Mui-expanded .MuiAccordionSummary-content .accorTitle .upArrowAccrodian {
  opacity: 1;
}
.MuiStack-root p {
  margin: 0;
}

.accordian-data .dataDesc {
  background-color: #F9F9F9;
  border-radius: 10px;
  padding: 20px;
}
.accordian-data .dataDesc p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  margin: 0;
  color: var(--tertiary);
}
.accordian-data .comment .heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  margin: 0;
  color: var(--primary);
  padding: 15px 0px;
}
.accordian-data .comment .user{
  width: 31px;
  height: fit-content;
}
.accordian-data .d-flex {
  border-bottom: 1px solid #000;
  padding: 10px 0;
}
.accordian-data .comment .d-flex {
  border: none;
  padding: 0;
}
.accordian-data .comment .commentInner {
  padding-bottom: 30px;
}
.accordian-data .comment .commentData .d-flex {
  border: none;
  padding: 0;
}
.accordian-data .comment .commentData .userName {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  margin: 0;
  color: var(--tertiary);
}
.accordian-data .comment .commentData .userName .red {
  color: #DB3535;
}
.accordian-data .comment .commentData .date {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  margin: 0;
  color: #000;
}
.accordian-data .comment .commentData .desc {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  margin: 0;
  color: var(--tertiary);
}
.accordian-data .comment .textAdd {
  position: relative;
}
.accordian-data .comment .textAdd textarea {
  background-color: #F9F9F9;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: var(--tertiary);
  margin: 0;
  border: none;
  outline: none;
  width: 100%;
  height: 65px;
  padding: 10px 50px 10px 10px;
}
.accordian-data .comment .send {
  position: absolute;
  right: 10px;
  top: 10px;
}
.cursor-pointer {
  cursor: pointer;
}



