@import "../utils/utils.scss";

.dashboard-main{
    width: 100%;
    height: calc(100vh - 58px);   
    padding: 1rem 2rem;
    background-color: var(--graybg);
    overflow-y: auto;
    .search-wrapper{
        display: flex;
        justify-content: space-between;
        .r-f-tabs{
            display: flex;
            align-items: center;
            .tab{
                background-color: #ffffff;
                @include subHeadingDark;
                margin-right: 1rem;
                padding: 0.5rem 1rem;
                border: 1px solid var(--primary);
                border-radius: 48px;
                cursor: pointer;
                span{
                    padding: 0.25rem;
                    font-size: 0.65rem;
                    border-radius: 1rem;
                    background-color: var(--primary);
                    color: #ffffff;
                    margin-left: 0.5rem;
                }
            }
            .tab.active{
                border: 1px solid var(--primary);
                background-color: var(--secondary);
            }
        }
    }
    .outer-layout{    
        width: 100%; 
        display: grid;
        grid-template-columns: 70% 1fr;
        grid-gap: 1rem;
        .first-column{
            .first-row{
                background-color: #ffffff;
                margin-bottom: 1rem;
                padding: 1rem;
                border-radius: 10px;
                .header-section{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .heading{
                        @include heading;
                    }
                    .drop-block{
                        display: flex;
                        align-items: center;
                        width: 8.4rem;
                        border: 1px solid gray;
                        border-radius: 10px;
                        img{
                            width: 0.86rem;
                            margin-left: 0.5rem;
                        }
                        
                    }
                }
                .first-task-row{
                    margin-top: 1rem;
                    height: 110px;
                    display: grid;
                    grid-template-columns: 40% 1fr 1fr 1fr;
                    grid-gap: 1rem; 
                    .block-1{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: var(--primary);
                        border-radius: 10px;
                        img{
                            width: 1.6rem;
                        }
                        .number{
                            font-size: 4rem;
                            color: #ffffff;
                            margin: 0px 1rem;
                        }
                        .text{
                            font-size: 0.75rem;
                            color: var(--graybg);
                            width: 4rem;
                        }
                    }
                    .block{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background-color: var(--graybg);
                        border-radius: 10px;
                        text-align: center;
                        .number{
                            font-size: 2rem;
                            color: var(--primary);
                        }
                        .text{
                            @include smallText;
                        }
                    }
                }
                .second-task-row{
                    margin-top: 1rem;
                    height: 110px;
                    display: grid;
                    grid-template-columns: 40% 1fr 1fr 1fr;
                    grid-gap: 1rem; 
                    .block-1{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: var(--secondary);
                        border-radius: 10px;
                        img{
                            width: 1.6rem;
                        }
                        .number{
                            font-size: 4rem;
                            color: var(--primary);
                            margin: 0px 1rem;
                        }
                        .text{
                            font-size: 0.75rem;
                            color: var(--primary);
                            width: 4rem;
                        }
                    }
                    .block{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background-color: var(--graybg);
                        border-radius: 10px;
                        text-align: center;
                        .number{
                            font-size: 2rem;
                            color: var(--primary);
                        }
                        .text{
                            @include smallText;
                        }
                        &:last-child{
                            .text{
                                color: red;
                            }
                        }
                    }
                }
                
            }
            .second-row{
                border-radius: 10px;
                background-color: #ffffff;
                padding: 1rem;
                .header-section{
                  .heading{
                    @include heading;
                  }
                }
                  .main-b{
                    .org-update-row{
                        display: flex;
                        margin-top: 1rem;
                        img{
                            width: 6rem;
                            height: 5rem;
                            border-radius: 5px;
                        }
                        .update-info{
                            margin-left: 1rem;
                        .head{
                            @include subHeadingDark;
                            margin-bottom: 0.5rem
                        }
                        .description{
                            @include smallText;
                        }
                    }
                    
                  }
                  
                }
                
            }
        }
        
        .second-column{
            padding: 1rem;
            background-color: #ffffff;
            border-radius: 10px;
            .upcoming-events{
                .heading{
                    @include heading;
                    margin-bottom: 1rem;
                }
                .card-u{
                    padding: 1rem;
                    margin-top: 1rem;
                    background-color: rgba(223,246,255,0.1);
                    border-radius: 10px;
                    color: var(--primary);
                    .arrival{
                        padding: 0.25rem 1rem;
                        background-color: #ffffff;
                        border-radius: 10px;
                        font-size: 0.75rem;
                        width: fit-content;
                        margin-bottom: 1rem;
                        img{
                            width: 0.7rem;
                            margin-right: 0.5rem;
                        }
                    }
                    .card-head{
                        @include subHeadingDark;
                        margin-bottom: 0.7rem;
                    }
                    .description{
                        @include smallText;
                    }
                }
            }
        }
    } 
    .outer-layout-reports{
        .first-row{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 1rem;
            .row-block{
                background-color: #ffffff;
                border-radius: 10px;
                padding: 1rem;
            }
            .apprecial{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .top{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2rem;
                    .heading{
                        .text{
                           @include subHeadingDark; 
                           font-weight: 600;
                        }
                        .subtext{
                            @include smallText;
                            font-size: 0.7rem;
                        }
                    }
                    .dropdn{
                        display: flex;
                        align-items: center;
                        width: 8.4rem;
                        height: 2rem;
                        border: 1px solid gray;
                        border-radius: 10px;
                        img{
                            width: 0.86rem;
                            margin-left: 0.5rem;
                        }
                    }
                }
                .middle{
                    display: flex;
                    justify-content: center;
                }
                .bottom{
                    margin-top: 2rem;
                    button{
                        width: 100%;
                        background-color: var(--secondary);
                        border: 1px solid var(--primary);
                        border-radius: 5px;
                        font-size: 0.7rem;
                        padding: 0.5rem;
                        color: var(--primary);
                        img{
                           width: 0.75rem;
                           margin-left: 1rem;
                        }
                        &:hover{
                            font-size: 0.77rem;
                            padding: 0.45rem;
                        }
                        &:active{
                            background-color: #f0feff;
                        }
                    }
                }
            }
            .team{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .top{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2rem;
                    .heading{
                        .text{
                           @include subHeadingDark; 
                           font-weight: 600;
                        }
                        .subtext{
                            @include smallText;
                            font-size: 0.7rem;
                        }
                    }
                    .dropdn{
                        display: flex;
                        align-items: center;
                        width: 8.4rem;
                        height: 2rem;
                        border: 1px solid gray;
                        border-radius: 10px;
                        img{
                            width: 0.86rem;
                            margin-left: 0.5rem;
                        }
                    }
                }
                .middle{
                    .avatars {
                        margin-bottom: 1.5rem;
                        display: flex;
                        list-style-type: none;
                        padding:0px;
                        flex-direction: row;
                          &__item{
                              background-color: var(--secondary);
                            border: 2px solid #ffffff;
                            border-radius: 100%;
                            color: var(--primary);
                            display: block;
                            font-family: sans-serif;
                            font-size: 12px;
                            font-weight: 100;
                            height: 2.5rem;
                              width: 2.5rem;
                            text-align: center;
                              transition: margin 0.1s ease-in-out;
                              overflow: hidden;
                              margin-left: -16px;
                          &:first-child {
                            z-index: 1;
                            margin-left: 0px;
                          }
                          &:nth-child(2) {
                            z-index: 2;
                          }
                          &:nth-child(3) {
                            z-index: 3;
                          }
                          &:nth-child(4) {
                            z-index: 4;
                          }
                              &:nth-child(5) {
                            z-index: 5;                            
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          }
                          img{width:100%}
                          }
                          &:hover {
                          .avatars__item {
                            margin-right:10px;
                          }
                          }
                      }
                      .req-submitted{                        
                        margin-bottom: 1.5rem;
                        .title{
                            font-size: 0.75rem;
                            color: var(--primary);
                            margin-bottom: 0.6rem;
                        }
                        .progress{
                            width: 100%;
                            height: 0.4rem;
                            background-color: var(--graybg);
                            border-radius: 10px;
                            .act-pro{
                                background-color: var(--primary);
                                border-radius: 10px;
                            }
                        }
                      }
                      .req-inprogress{          
                        .title{
                            font-size: 0.75rem;
                            color: var(--primary);
                            margin-bottom: 0.6rem;
                        }
                        .progress{
                            width: 100%;
                            height: 0.4rem;
                            background-color: var(--graybg);
                            border-radius: 10px;
                            .act-pro{
                                background-color: var(--secondary);
                                border-radius: 10px;
                            }
                        }
                      }
                }
                .bottom{
                    margin-top: 2rem;
                    button{
                        width: 100%;
                        background-color: #ffffff;
                        border: 1px solid var(--primary);
                        border-radius: 5px;
                        font-size: 0.7rem;
                        padding: 0.5rem;
                        color: var(--primary);
                        img{
                           width: 0.75rem;
                           margin-left: 1rem;
                        }
                        &:hover{
                            font-size: 0.77rem;
                            padding: 0.45rem;
                        }
                        &:active{
                            background-color: #f0feff;
                        }
                    }
                }
            }
            .comments{
                .top{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2rem;
                    .heading{
                        .text{
                           @include subHeadingDark;  
                           font-weight: 600;
                        }
                        .subtext{
                            @include smallText;
                            font-size: 0.7rem;
                        }
                    }
                }
                .emp-comments{
                    margin-bottom: 1rem;
                    .empDetaila{
                        margin-bottom: 0.5rem;
                        display: flex;
                        .empPic{
                            .userImg{
                                width: 45px;
                                height: 45px;
                                border-radius: 50%;                                
                            }
                        }
                        .userInfo{
                            margin-left: 1rem;
                            .name {
                                @include subHeadingDark;
                                font-size: 0.9rem;
                                span{
                                    color: var(--tertiary);
                                    font-size: 0.84rem;
                                }
                            }
                            .designation{
                                @include smallText;
                            }
                        }
                    }
                    .desc{
                        @include smallText;
                        font-size: 0.7rem;
                    }
                }
            }
        }
        .second-row{
            margin-top: 1rem;
            background-color: #ffffff;
            border-radius: 10px;
            padding: 1rem;
            .top-row{
                display: flex;
                justify-content: space-between;
                .left{
                .heading{
                    .text{
                       @include subHeadingDark;  
                       font-weight: 600;
                    }
                    .subtext{
                        @include smallText;
                        font-size: 0.7rem;
                    }
                }
            }
            .right{
                display: flex;
                justify-content: flex-end;
                .dropdn{
                    margin-right: 1rem;
                    display: flex;
                    align-items: center;
                    width: 8.4rem;
                    height: 2rem;
                    border: 1px solid gray;
                    border-radius: 10px;
                    img{
                        width: 0.86rem;
                        margin-left: 0.5rem;
                    }
                }
                button{
                    width: 100%;
                    height: 2rem;
                    background-color: #ffffff;
                    border: 1px solid var(--primary);
                    border-radius: 5px;
                    font-size: 0.7rem;
                    padding: 0px 2rem;
                    color: var(--primary);
                    img{
                       width: 0.75rem;
                       margin-left: 1rem;
                    }
                   
                    &:active{
                        background-color: #f0feff;
                    }
                }
            }
            }
            .bottom-row{
                margin-top: 1rem;
                .tablee{
                    background-color: #ffffff;
                    .feedback-emp-list-table{
                        width: 100%;
                        background-color: #ffffff;
                        th, td{
                            padding: 0.5rem;
                        }
                        tr{
                            border-bottom: 1px solid #e2e2e2;
                        }
                        th {
                            font-size: 0.9rem;
                            color: var(--secondary);
                            font-weight: 500;
                            background-color: var(--primary);
                            
                        }
                        .table-head-emp-list{
                            height: 4rem;
                        }
                        .mid-cols{
                            width: 25%;
                            text-align: center;
                        }
                        .last-col{
                            width: 20%;
                            text-align: end;
                        }
                        .last-col.head{
                            padding-right: 1.8rem;
                        }
                        td{
                            .empDetaila{
                                display: flex;
                                .empPic{
                                    .userImg{
                                        width: 45px;
                                        height: 45px;
                                        border-radius: 50%;                                
                                    }
                                }
                                .userInfo{
                                    margin-left: 1rem;
                                    .name {
                                        @include subHeadingDark;
                                        font-size: 0.9rem
                                    }
                                    .designation{
                                        @include smallText;
                                    }
                                }
                                
                            }
                            .btns{
                                font-size: 0.75rem;
                                text-align: center;
                                
                            }
                            .btnss{
                                border: none;
                                background-color: #ffffff;
                                .send-btn{
                                    font-size: 0.75rem;
                                    background-color: var(--secondary);
                                    color: var(--primary);
                                    border: none;
                                    padding: 5px 10px;
                                    border-radius: 10px;
                                }
                            }
                            .btns.green {
                                padding: 2px 5px;
                                font-size: var(--smallTextFontSize);
                                color: #69C88E;
                                font-weight: 500;
                            }
                            .btns.grey {
                                color: var(--tertiary);
                                padding: 2px 5px;
                                font-size: var(--smallTextFontSize);
                                font-weight: 500;
                            }
                            .btns.yellow {
                                padding: 2px 5px;
                                font-size: var(--smallTextFontSize);
                                color: #fdaa34;
                                font-weight: 500;
                            }
                            .action{
                                button{
                                    font-family: 'poppins';
                                    padding: 0.25rem;
                                    svg{
                                        color: var(--primary);
                                        font-size: 1.25rem;
                                    }
                                }
                                
                            }
                        }
                    }
                } 
            }
            
        }
    }
    .final-report-heading{
        @include heading;
        font-weight: 500;
    }
}


@media only screen and (max-width: 900px) {
    .dashboard-main{
        width: 100%;
        height: calc(100vh - 58px);   
        padding: 20px;
        background-color: var(--graybg);
        overflow-y: auto;

        .outer-layout{    
            grid-template-columns: 100%;
        }
    }
}