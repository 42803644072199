.assessment-show-in-sidebar{
    p{
        margin: 0;
        padding: 0;
    }
    color: var(--primary);
    .main-heading{
        font-size: 1.15rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    .section-main{
        margin-bottom: 1rem;
        .section-label{
            font-size: 0.9rem;
            font-weight: 500;
            margin-top: 2rem;
            margin-bottom: 1rem;
        }  
        .question-label{
            font-size: 0.77rem;
            font-weight: 500;
            margin-bottom: 1rem;
        }
        .feedback-text{
            font-size: 0.73rem;
            font-weight: 400;
            margin-bottom: 1rem;
            .emp-response{
                // width: 75%;
                display: flex;
                flex-direction: column;
                // font-size: 0.75rem;
                color: var(--primary);
                .text-responses{                        
                    // background-color: var(--graybg);
                    color: gray;
                    padding: 0.5rem;
                    border-radius: 10px;
                }
                .ratings{
                    padding: 0.25rem 0.5rem;
                    border: 1px solid #cdcdcd;
                    border-radius: 10px;
                    margin-top: 0.5rem;
                    width: fit-content;
                }
                .selections{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .selected-value{
                        margin-top: 0.5rem;
                        margin-right: 0.25rem;
                        padding: 0.25rem 0.5rem;
                        background-color: var(--secondary);
                        border-radius: 10px;
                    }
                }
            }
        }
    }
    
    .read-more-less {
        background: none;
        border: none;
        color: rgb(0, 128, 255);
        cursor: pointer;
        padding: 0;
        margin-left: 5px;
      }
}

.self-assesment-inmanager-feedback{
    padding: 0.7rem;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    margin-top: 1rem;
    box-shadow: 0px 0px 12px 4px #cdcdcd;
    .assessment-show-in-sidebar{
        p{
            margin: 0;
            padding: 0;
        }
        color: var(--primary);
        .main-heading{
            font-size: 1rem;
            margin-bottom: 1rem;
        }
        .section-main{
            margin-bottom: 1rem;
            .section-label{
                font-size: 0.9rem;
                margin-bottom: 1rem;
                font-weight: 400;
                color: gray;
            }  
            .question-label{
                font-size: 0.84rem;
                margin-bottom: 1rem;
                font-weight: 400;
                color: rgb(158, 158, 158);
            }
            .feedback-text{
                font-size: 0.75rem;
                margin-bottom: 1rem;
                font-weight: 400;
                .emp-response{
                    // width: 75%;
                    display: flex;
                    flex-direction: column;
                    // font-size: 0.75rem;
                    color: var(--primary);
                    .text-responses{                        
                        background-color: var(--graybg);
                        padding: 0.5rem;
                        border-radius: 10px;
                        color: rgb(158, 158, 158);
                    }
                    .ratings{
                        padding: 0.25rem 0.5rem;
                        border: 1px solid #cdcdcd;
                        border-radius: 10px;
                        margin-top: 0.5rem;
                        width: fit-content;
                        color: rgb(158, 158, 158);
                    }
                    .selections{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        .selected-value{
                            margin-top: 0.5rem;
                            margin-right: 0.25rem;
                            padding: 0.25rem 0.5rem;
                            background-color: var(--secondary);
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
          
    }
}