.emp-cohort{
    .search-wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        margin: auto;
        .helper{
            font-size: 0.75rem;
            position: absolute;
            top: 143px;
        }
        .filter-tab{
            @include subHeadingDark;
            display: flex;
            margin-left: 0.5rem;
            border: 1px solid var(--primary);
            border-radius: 10px;
            padding: 1.05rem 1.25rem;
            width: 9rem;
            cursor: pointer;
            img{
                margin-right: 0.5rem;
            }
            svg{
                margin: auto;
                margin-left: 0.5rem;
            }
        }
        .filter-tab.active{
            background-color: var(--secondary);
        }
    }
    .filters-block{
        border: 1px solid var(--tertiary);
        border-radius: 10px;
        margin: 1rem 0px;
        padding: 1rem;
        animation: openCard 500ms ease-out;
        .filters{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            animation: openCard1 500ms ease-out;
            
        }
    }
    .tabs-outer{
        display: flex;
        justify-content: flex-end;
        margin: 1rem 0px;
         .tabs{
        @include heading;
        font-size: 0.81rem;
        display: flex;
        flex-direction: row;
        background-color: var(--graybg);
        border-radius: 2rem;
        width: max-content;
        border: 1px solid var(--secondary);
        .tab{
            cursor: pointer;
            padding: 0.25rem 2rem;
            .selected-number{
                margin-left: 0.5rem;
                background-color: #ffffff;
                color: var(--primary);
                padding: 1px 5px;
                border-radius: 12px;
                border: 1px solid var(--primary);
            }
        }
        .tab.active{
            background-color: var(--primary);
            color: var(--graybg);
            font-weight: 500;
            border-radius: 2rem;
        }
    }
    }
    .empty-selected{
        @include normalText;
        text-align: center;
    }
    .tablee{
        overflow-x: auto;
        background-color: #ffffff;
        margin: 1rem 0px; 
        padding: 1rem;
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        .feedback-emp-list-table{
            width: 100%;
            background-color: #ffffff;
            th, td{
                padding: 0.5rem;
                text-align: left;
            }
            tr{
                border-bottom: 1px solid #e2e2e2;
            }
            th {
                font-size: 0.9rem;
                color: var(--primary);
                font-weight: 500;
                background-color: #ffffff;                
            }          
            
            td{
                .empDetaila{
                    display: flex;
                    cursor: pointer;
                    .empPic{
                        .userImg{
                            width: 45px;
                            height: 45px;
                            border-radius: 50%;                                
                        }
                    }
                    .userInfo{
                        margin-left: 1rem;
                        .name {
                            @include subHeadingDark;
                            font-size: 0.9rem
                        }
                        .designation{
                            @include smallText;
                        }
                    }
                        svg{
                            color: var(--primary);
                            margin: auto 0.5rem;
                            font-size: 1.25rem;
                        }
                    
                }
                .btns{
                    @include subHeadingDark;
                    font-size: 0.9rem;
                    text-align: right;
                }               
            }
            td.levels{
                @include subHeadingDark;
                font-size: 0.9rem;
                text-align: center;
            }
            .mid-col-levels{
                text-align: center;
            }
            .emp-name{
                cursor: pointer;
                svg{
                    color: var(--primary);
                    margin: auto 0.5rem;
                    font-size: 1.25rem;
                }
            }
            .mid-col{
                text-align: left;
            }
            .last-col{
                text-align: right;
            }
            .selected{                
                opacity: 0;
                animation: fadeInOut 500ms infinite;
            }
        }
        .no-result{
            @include heading;
            text-align: center;
            width: 100%;
            padding-top: 2rem;
        }
    }  
}
@keyframes openCard {
    0% {
      height: 0;
    }
    100% {
      height: 16rem;
    }
  }
@keyframes openCard1 {
    0% {
      height: 0;
    }
    100% {
      height: 10rem;
    }
  }
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }