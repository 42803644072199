.custom-date-time-picker{
    position: relative;
    .heading{
        position: absolute;
        z-index: 15;
        left: 1rem;
        font-size: 0.81rem;
        color: gray;
    }
    .react-datepicker-popper{
        z-index: 10;
    }
    .react-datepicker-wrapper{
        width: 100%;
        input{
            width: 100%;
            height: 3.3rem;
            border: none;
            background-color: var(--graybg);
            padding-left: 1rem;
            padding-top: 0.5rem;
            color: gray;
            font-family: "poppins";
            font-size: 0.9rem;
        }
    }    
}
.custom-date-time-picker.error{
    .react-datepicker-wrapper{
        input{           
            border: 1px solid red;
        }
    }    
}