@import "../utils/utils.scss";

.validated-grid {
    display: flex;
    align-items: center;
    margin-bottom: 15px !important;                 
}
.validation-icon{
    margin-right: 8px;
}

.promotions {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    
    .title-grid {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    .title-grid1{
        text-align: right;
    }

    .page-title {
        color: #00425A;
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        font-family: 'poppins';
        align-self: center;
    }

    .my-team-btn {
        width: 180px;
        background-color: #BAD7E9;
        padding: 12px 15px;
        border-radius: 10px;
        border: none;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .slider-icon {
        color: #00425A;
        font-size: 20px;
        cursor: pointer;
        margin-right: 10px;
    }

    .down-arrow {
        color: #00425A;
        font-size: 20px;
        cursor: pointer;
        margin-left: 10px;
    }

    .top-btn-List {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
    }

    .active-btn {
        background-color: #BAD7E9;
        padding: 8px 16px;
        border-radius: 20px;
        border: none;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .inActive-btn {
        background-color: transparent;
        padding: 8px 16px;
        border-radius: 20px;
        border: none;
        color: #979797;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .c-pointer {
        cursor: pointer;
    }

    .table-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding-top: 12px;
        padding-bottom: 20px;
        margin-bottom: 20px;

        .nominate-promo-table {
            background-color: #ffffff;
            .asasas {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: #979797;
            }
            .emp-list-table {
                width: 100%;
                background-color: #ffffff;

                th,
                td {
                    padding: 0.5rem;
                }

                tr {
                    border-bottom: 1px solid #e2e2e2;
                }
                tr:last-child {
                    border-bottom: 0px solid #e2e2e2;
                }

                th {
                    font-size: 0.9rem;
                    color: var(--primary);
                    font-weight: 500;
                    background-color: #ffffff;

                }

                .table-head-emp-list {
                    height: 4rem;
                }

                .w-130 {
                    width: 130px !important;
                }
                .w-200 {
                    width: 200px !important;
                }

                .ta-end {
                    text-align: end;
                }

                .pl-20 {
                    padding-left: 20px !important;
                }
               

                .pr-20 {
                    padding-right: 20px !important;
                }

                .mr-5 {
                    margin-right: 5px !important;
                }

                .emp-name-checkbox {
                    color: #00425A;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: 'poppins';
                }

                .emp-icon {
                    height: 20px;
                    width: 20px;
                    margin-top: 2px;
                    margin-left: 5px;
                }

                .emp-table-value {
                    color: #979797;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: 'poppins';
                }
                .emp-table-value-data {
                    color: #979797;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: 'poppins';
                    // display: flex;
                    align-items: center;
                }
                .emp-table-value-blue-data {
                    color: #00425A;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: 'poppins';
                    display: flex;
                    align-items: center;
                }

                .edit-view-btn {
                    width: max-content;
                    background-color: #BAD7E9;
                    padding: 8px 18px;
                    border-radius: 10px;
                    border: none;
                    color: #00425A;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: 'poppins';
                    text-transform: capitalize;
                }
                .action-btn {
                    width: 120px;
                    background-color: #BAD7E9;
                    padding: 12px 15px;
                    border-radius: 10px;
                    border: none;
                    color: #00425A;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: 'poppins';
                    text-transform: capitalize;
                }
             
                .feedback-btn {
                    background-color: transparent;
                    padding: 12px 0px;
                    border-radius: 10px;
                    border: none;
                    color: #00AA5A;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    font-family: "poppins";
                    text-transform: capitalize;
                }
                .declined-btn {
                    background-color: transparent;
                    padding: 12px 0px;
                    border-radius: 10px;
                    border: none;
                    color: #DB3535;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    font-family: "poppins";
                    text-transform: capitalize;
                }
                .edit-small-btn {
                    // width: 240px;
                    background-color: transparent;
                    border: 1px solid #979797 !important;
                    padding: 5px 15px;
                    border-radius: 10px;
                    border: none;
                    color: #00425A;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: 'poppins';
                    text-transform: capitalize;
                    height: 32px;
                }
                .circleText {
                    background-color: #00425A;
                    width: max-content;
                    padding: 0px;
                    width: 26px;
                    text-align: center;
                    border-radius: 20px;
                    height: 26px;
                    font-size: 14px;
                    color: #fff;
                    line-height: 27px;
                    font-weight: 400;
                    margin: 0px 7px;
                }

            }
        }

        .save-submit-btn-div {
            display: flex;
            justify-content: end;
            margin-top: 20px;
            padding-right: 24px;

            .save-draft {
                border: 1px solid #004259;
                border-radius: 10px;
                padding: 13px 20px;
                background-color: #FFFFFF;
                color: #004259;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
                text-transform: capitalize;
            }

            .submit-list {
                background-color: #004259;
                border-radius: 10px;
                padding: 13px 20px;
                color: #FFFFFF;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
                text-transform: capitalize;
                margin-left: 15px;
            }
        }
    }

}