.calendar {
    max-width: 400px;
    margin: auto;
    text-align: center;
    .calendar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .arrows{
            background-color: #ffffff;
            border: none;
            cursor: pointer;
        }
        .head{
            font-size: 1rem;
            color: var(--primary);
        }
      }
      
      .calendar-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }
      
      .days-of-week {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        margin-bottom: 10px;
        color: var(--tertiary);
        font-size: 0.75rem;
      }
      
      .week {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
      }
      
      .day {
        background-color: #ffffff;
        border: none;
        padding: 10px;
        color: var(--primary);
        cursor: pointer;
      }

      .day.today{
        background-color: var(--secondary);
        border-radius: 5px;
      }
      
      .empty {
        visibility: hidden;
      }
  }
  
  