@import "../utils/utils.scss";

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  ul {
    list-style: none;
    padding: 0;
    display: flex;
  }

  li {
    margin: 0 5px;
  }

  

  button {
    padding: 5px 10px;
    margin: 0px 3px;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid var(--primary);
    border-radius: 4px;
    color: var(--primary);

    &:hover {
      background-color: #e6f4f9;
      color: var(--primary);
    }

  }
  button.active {
    background-color: var(--secondary);
    color: var(--primary);
    border: 1px solid var(--secondary);
  }
  button.arrow {
    border: none;
    font-size: 1.25rem;
  }
}
