.view-goals-request{
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1rem 2rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .tabs-block {
        display: flex;
        justify-content: space-between;
        .tab-d-flex {
            display: flex;
            .tab {
                color: var(--grayDark);
                background-color: var(--graybg);
                font-size: 16px;
                font-weight: 400;
                border: none;
                margin-right: 1rem;
                padding: 0.5rem 1rem;
                border-radius: 48px;
                cursor: pointer;
    
                span {
                    color: var(--white);
                    font-size: 10px;
                    font-weight: 500;
                    padding: 0.30rem;
                    border-radius: 1rem;
                    background-color: var(--primary);
                    margin-left: 0.5rem;
                }
            }
    
            .tab.active {
                color: var(--primary);
                font-size: 16px;
                font-weight: 400;
                background-color: var(--secondary);
            }
        }
       

        .year-dropDown-div {
            // width: 100%;
            // text-align: end;
    
            .year-dropDown {
                width: 110px;
                background-color: var(--secondary);
                border-radius: 10px;
                border: none;
                gap: 10px;
            }
    
            .year-dropDown .MuiSelect-select {
                color: var(--primary);
                font-size: 16px;
                border: none;
            }
    
            .year-dropDown .MuiMenuItem-root {
                color: var(--primary);
                font-size: 16px;
            }
    
            .year-dropDown .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }
        .btn-div{
            display: flex;
            align-items: center;
            .excel-download{
                width: 9rem;
                background-color: #ffffff;
                color: var(--primary);
                border: 1px solid var(--primary);
                border-radius: 0.5rem;
                padding: 0.25rem 0.5rem;
                margin-right: 1rem;
            }
            .approve-goals{
                width: 9rem;
                background-color: var(--primary);
                color: #ffffff;
                border-radius: 0.5rem;
                padding: 0.25rem 0.5rem;
            }
        }
    }




    .outer-chart-layout {
        width: 100%;
        display: grid;
        grid-template-columns: 49.5% 1% 49.5%;
        background-color: var(--white);
        border-radius: 20px;
        margin-top: 15px;
        margin-bottom: 15px;

        .column-layout {
            padding: 24px;
        }

        .verticalBorder {
            width: 1px;
            background-color: var(--grayLight);
            margin-top: 65px;
            margin-bottom: 30px;
        }

        .goalMilestoneSummary {
            color: var(--primary);
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0px;
        }

        .chart-column {
            display: flex;
            padding-top: 10px;
        }

        .d-block-w100 {
            display: block;
            width: 100%;
            align-content: center;
            padding-left: 24px;
        }

        .mt-18 {
            margin-top: 18px;
        }

        .sub-outer-chart {
            width: 100%;
            display: grid;
            grid-template-columns: 50% 50%;

            .sub-outer-column {}

            .sub-col-value {
                color: var(--primary);
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0px;
            }

            .d-flex {
                display: flex;
            }

            .status-card {
                width: 19px;
                height: 11px;
                border-radius: 4px;
                margin-top: 5px;
                margin-right: 5px;
            }

            .status-green {
                background-color: #11CE87;
            }

            .status-red {
                background-color: #DB3535;
            }

            .status-yellow {
                background-color: #FAC94D;
            }

            .status-blue {
                background-color: #2C8FFC;
            }

            .sub-col-percent-value {
                color: var(--primary);
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 0px;
            }

            .sub-col-status {
                color: var(--grayDark);
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 0px;
                margin-left: 23px;
            }
        }
    }

    .goals-tab-btn-div {
        width: 100%;
        display: grid;
        grid-template-columns: 68% 30%;
        grid-gap: 1rem;
        // margin-top: 10px;

        .goals-tab {
            display: flex;
            align-items: flex-end;

            

            .all-selected-count-border {
                width: fit-content;
                background-color: var(--white);
                border: 1px solid var(--secondary);
                border-radius: 50px;
                display: flex;
                padding: 5px;
                margin-top: 10px;

                .all-select-btn {
                    background-color: transparent;
                    padding: 6px 13px;
                    border-radius: 50px;
                    gap: 10px;
                    color: var(--black);
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: capitalize;
                }

                .active-btn {
                    background-color: var(--primary);
                    padding: 6px 13px;
                    border-radius: 50px;
                    gap: 10px;
                    color: var(--white);
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: capitalize;
                }
            }

     

            .qu-icon-div {
                display: flex;
                justify-content: flex-end;
                flex: 1;
            }
        }

        .submit-Goals-div {
            text-align: right;
            align-content: end;

            .submit-Goals-btn {
                background-color: var(--primary);
                border-radius: 10px;
                padding: 8px 20px;
                cursor: pointer;
                text-transform: capitalize;
                color: var(--white);
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .table-container-sample {
        position: relative;
        width: 100%;
        height: 32rem;
        overflow-x: auto;
        margin-top: 1rem;
        table {
            border-collapse: collapse;
            width: 100%;
            table-layout: fixed;
            border: none;
            thead{
                position: sticky;
                top: 0;
                z-index: 2;
            }
            th, td {
                background-color: #ffffff;
                padding: 8px;
                text-align: left;
                width:10rem;
                border: none;
                word-break: break-word;
            }
            tr{
                border-bottom: 1px solid rgb(206, 206, 206);
            }
            th {
                .col-head-outer{
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    .table-heading{
                        margin-left: 0.7rem;
                        color: #00425A;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                    }
                }
            }
            th.sticky, td.sticky {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                background-color: #f4fdff;
                z-index: 1;
                width: 7rem;
            }
            th.sticky2, td.sticky2 {
                position: -webkit-sticky;
                position: sticky;
                left: 7rem;
                background-color: #f4fdff;
                z-index: 1;
                width: 9rem;
            }
            th.sticky-right, td.sticky-right {
                position: -webkit-sticky;
                position: sticky;
                right: 0;
                background-color: #f4fdff;
                z-index: 1;
                width: 6rem;
            }
            th.sticky + th.sticky, td.sticky + td.sticky {
                left: 50px; /* Adjust width of first sticky column */
            }
            td{
                color: #979797;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                line-height: normal;
            }
            th.gray-block, td.gray-block{
                background-color: #f2f2f2;
            }
            td.table-value-emp-name {
                    color: #00425A;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    .all-flags{
                        display: flex;
                        justify-content: flex-start;
                    }
                    
                }
            td.table-value.mid-align {
                    text-align: center;
                }
            td.table-value-btns {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                // padding: 1rem;
                    .edit-btn {
                        width: 2.7rem;
                        font-size: 0.75rem;
                        color: var(--primary);
                        background-color: #ffffff;
                        border: 1px solid gray;
                        border-radius: 5px;
                        margin-bottom: 0.5rem;
                    }
                    .view-btn {
                        width: 2.7rem;
                        font-size: 0.75rem;
                        color: var(--primary);
                        background-color: #ffffff;
                        border: 1px solid gray;
                        border-radius: 5px;
                    }
                }
            
        }
       
    }
    .table-bg {
        border-radius: 20px;
        background-color: #fff;
        padding-top: 20px;
        margin-top: 15px;
        margin-bottom: 15px;

        .no-data{
            color: var(--primary);
            margin-top: 1rem;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 500;
        }

        .table-top-layout {
            display: flex;
            justify-content: space-between;
            margin: 0px 1rem;

            .table-top-column {
                display: flex;
            justify-content: space-between;

            .search-bar-div{
                position: relative;
                .search-bar {
                    background-color: #F9F9F9;
                    border-radius: 10px;                
                    }
                    .close-icon{
                        color: gray !important;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                    }
                }

                .table-filter-dropdown {

                    .filter-tab {
                        @include subHeadingDark;
                        background-color: #BAD7E9;
                        position: relative;
                        margin-left: 1rem;
                        border: 1px solid var(--primary);
                        border-radius: 10px;
                        padding: 1.05rem 1.25rem;
                        width: 7.9rem;
                        cursor: pointer;

                        img {
                            margin-right: 0.7rem;
                        }

                        .red-dot {
                            width: 12px;
                            height: 12px;
                            background-color: red;
                            border-radius: 6px;
                            position: absolute;
                            top: 5px;
                            right: 5px;
                        }
                    }
                }

                .check-box {
                    color: #00425A;
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: 10px;
                    margin-left: 10px;
                }

                .w-100 {
                    width: 100%;
                }

                .mt-12 {
                    margin-top: 12px;
                }

                .mr-inherit {
                    margin-right: inherit;
                }
            }

            .expand-all {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-right: inherit;
            }
        }

    }
    .table-pagination-wrapper {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;

        .d-flex {
            display: flex;
        }

        .per-page-item {
            color: #979797;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            align-content: center;
            margin: 0px 10px;
        }

        .pagination-active {
            width: 32px;
            height: 32px;
            border-radius: 5px;
            background-color: #BAD7E9;
            text-align: center;
            align-content: center;
            padding-top: 3px;
        }

        .pagination-deactivet {
            width: 32px;
            height: 32px;
            border-radius: 5px;
            border: 0.5px solid #979797;
            text-align: center;
            align-content: center;
            padding-top: 3px;
        }

        .ac-center-mr-12 {
            align-content: center;
            margin-right: 12px;
        }

        .ac-center {
            align-content: center;
        }
    }
}