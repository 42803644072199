@import "../../../utils/utils.scss";
.emp-info-main{
    .empDetailass{                    
        display: flex;
        .empPic{
            .userImg{
                width: 45px;
                height: 45px;
                border-radius: 50%;                                
            }
        }
        .userInfo{
            margin-left: 1rem;
            .name {
                @include subHeadingDark;
                font-size: 0.9rem
            }
            .designation{
                @include smallText;
            }
        }                    
    }  
    .main-content{
        margin-top: 2rem;
        .emp-info-tabs{
          display: flex;
          .tab{
            border: none;
            background-color: transparent;
            padding: 0.25rem 1rem;
            font-size: 1rem;
            color: var(--tertiary);
            cursor: pointer;
          }
          .tab.active{
            font-size: 1rem;
            color: var(--primary);
            background-color: var(--secondary);
            border-radius: 1rem;
          }
        }
        .tab-content{
            margin-top: 2rem;
            .employee-details{
                margin: 1rem 0px;
                padding: 0.7rem 1.5rem;
                background-color: var(--graybg);
                border-radius: 10px;
                .heading{
                    @include subHeadingDark;
                    font-weight: 600;
                }
                .row-of-content{
                    width: 100%;
                    margin: 0.5rem 0px;
                    display: flex;
                    .title{
                        width: 60%;
                        @include normalText;
                    }
                    .value{
                        width: 40%;
                        @include normalText;
                        color: var(--primary);
                        font-weight: 500;
                    }
                }
            }
        }
    }
}