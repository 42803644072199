@import "../../utils/utils.scss";

.scheduling-session-page {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1rem 2rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .scheduling-session-container {
        background-color: var(--white);
        border-radius: 20px;
        padding: 24px;

        .heading-label {
            color: #004259;
            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
        }

        .w-100 {
            width: 100%;
        }

        .role-container {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 10px;
        }

        .role-border-div {
            border: 1px solid #BDD7EA;
            padding: 6px 10px;
            border-radius: 7px;
            width: fit-content;
        }

        .d-flex {
            display: flex;
        }

        .ai-center {
            align-items: center;
        }

        .dropdown-roles-container {
            display: grid;
            grid-template-columns: 4% 96%;
        }

        .mt-20 {
            margin-top: 20px;
        }

        .role-type {
            color: #00425A;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
        }

        .role-close-Icon {
            height: 12px;
            width: 12px;
            cursor: pointer;
            margin-left: 15px;
        }

        .field-row {
            margin-top: 24px;
            display: flex;
            justify-content: space-between;

            .text-field,
            .select-field,
            .date-field,
            .number-field {
                width: 100%;
            }

            .field-label {
                // font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.52px;
                text-align: left;
                height: 15px;
                gap: 0px;
                opacity: 70%;
            }

            .row-half {
                width: 49%;
            }
        }
    }

    .submit-cancel-div {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;

        .cancel-btn {
            background: #fff;
            border: 1px solid #004259;
            border-radius: 8px;
            padding: 10px 20px;
            color: #004259;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
        }

        .submit-btn {
            background: #004259;
            border: none;
            border-radius: 8px;
            padding: 10px 20px;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
        }
    }
}