@import '../utils/utils.scss';

.sidefilter {
  z-index: 10;
  background: #ffffff;
  overflow-y: scroll;
  padding: 2rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 29%;
  animation: slideIn 500ms linear;

  .close-drawer-icon {
    float: right;
    cursor: pointer;
  }

  border-left: 2px solid gray;
  box-shadow: 0px 0px 208px 10px;

  .drawer-children-main {
    margin: 1rem 0px;
    @include smallText;

    div {
      .scrl-div {
        position: absolute;
        width: 91%;
        height: 100%;
        padding-bottom: 5rem;
      }
    }
  }
  .filter-wrapper{
        margin-top: 2rem;
        .filters-heading{
          @include subHeadingDark;
          font-weight: 500;
          margin-bottom: 1rem;
        }
  }
 
}

.d-sidefilter {
  background: #ffffff;
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 29%;
  animation: slideOut 500ms linear;
}

@keyframes slideIn {
  0% {
    transform: translateX(400px);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translateX(0px);
    animation-timing-function: ease-in;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0px);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translateX(100%);
    animation-timing-function: ease-in;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* width */
.sidefilter::-webkit-scrollbar {
  width: 0.32rem;
}

/* Track */
.sidefilter::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sidefilter::-webkit-scrollbar-thumb {
  background: #c7c7c7;
}

/* Handle on hover */
.sidefilter::-webkit-scrollbar-thumb:hover {
  background: #a9a9a9;
}