///////////////////////
/// 
/// 

.table-container-sample {
    width: 100%;
    overflow-x: auto;
    margin-top: 1rem;
    table {
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;
        border: none;
        thead{
            position: sticky;
            top: 0;
            z-index: 2;
        }
        th, td {
            background-color: #ffffff;
            padding: 8px;
            text-align: left;
            width:10rem;
            border: none;
            word-break: break-word;

        }
        .large-content-cell {
            width: 13rem !important;
        }

        tr{
            border-bottom: 1px solid rgb(206, 206, 206);
        }
        th {
            .col-head-outer{
                display: flex;
                align-items: center;
                justify-content: left;
                .table-heading{
                    margin-left: 0.7rem;
                }
            }
        }
        th.sticky, td.sticky {
            position: -webkit-sticky;
            position: sticky;
            left: 0;
            z-index: 1;
            width: 22rem; // new with updated
            background-color: #f9fdff;
        }
        th.sticky2, td.sticky2 {
            position: -webkit-sticky;
            position: sticky;
            left: 7rem;
            z-index: 1;
            width: 9rem;
            background-color: #f9fdff;
        }
        th.sticky-right, td.sticky-right {
            position: -webkit-sticky;
            position: sticky;
            right: 0;
            z-index: 1;
            width: 5rem;
            background-color: #f9fdff;
        }
        th.sticky + th.sticky, td.sticky + td.sticky {
            left: 50px; /* Adjust width of first sticky column */
        }
      
        th.gray-block, td.gray-block{
            background-color: #F9F9F9;
        }
       
        td.table-value-btns {
            padding: 1rem;
              
            }
        
    }
   
}

.filters {
    display: flex;
    border: 1px solid #ddd;
  }
  
  .filter-nav {
    width: 252px;
    border-right: 1px solid #ddd;
    padding: 10px;
  }
  
  .filter-nav-item {
    padding: 10px;
    cursor: pointer;
        font-family: Inter;
        font-size: var(--textFontSize);
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #667085;

  }
  
  .filter-nav-item.active {
    background-color: #F9F5FF;
    color: #6941C6;
  }

  .checkbox-item-container-one-card {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding: 20px;
  }

  .checkbox-item-container-two-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
  }
  
  .filter-options {
    padding: 10px;
    width: 548px;

    .filter-item-title {
        font-family: Inter;
        font-size: var(--textFontSize);
        font-weight: 500;
        line-height: 20px;
        text-align: left;

    }
  }
  
  .filter-group {
    display: flex;
    flex-direction: column;
  }
  
  .filter-option {
    margin-bottom: 5px;

    display: flex;
    align-items: center;
    gap: 1rem;

    color: #344054;

    input {
        border: 1px solid #D0D5DD;

        width: 16px;
        height: 16px;
        gap: 0px;
        border-radius: 4px 0px 0px 0px;
        border: 1px 0px 0px 0px;

    }
  }
  
  .filter-actions {
    gap: 12px;
    display: flex;
    justify-content: flex-end;

    
  }
  
  .filter-actions button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .filter-actions button:first-child {
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;

    font-family: Inter;
    font-size: var(--textFontSize);
    font-weight: 500;
    line-height: 20px;
    text-align: left;

  }
  
  .filter-actions button:last-child {
    background-color: #7F56D9;
    color: white;
  }
  
  

/// ///////////////

.setting-dropdown-main-container {
    z-index: 99;
    pointer-events: auto;
    position: absolute;
    top: 228px;
    right: 48px;

    background: #FFFFFF;
    box-shadow: 0px 10px 15px -3px #0000001A;

    border-radius: 16px;

    .filter-header {
        font-family: Inter;
        font-size: var(--headerFonrSize);
        font-weight: 600;
        line-height: 28px;
        text-align: left;

    }

    .close-dilog svg {
        width: 24px;
        height: 24px;
    }

    .top-header {
        margin: auto 12px;
        }
    }

.emp-profile-main {
    background: #FBF8FF;

    .nav-tabs-outer{ //includedExtra
        display: flex;
        justify-content: flex-end;
         .tabs{
        font-size: 0.81rem;
        display: flex;
        flex-direction: row;
        background-color: var(--graybg);
        width: max-content;

        padding: 4px;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid #F2F4F7;

        margin: 0 1rem;
        .tab{
            cursor: pointer;
            padding: 0.25rem 2rem;
            .selected-number{
                margin-left: 0.5rem;
                background-color: #ffffff;
                color: var(--primary);
                padding: 1px 5px;
                border-radius: 12px;
                border: 1px solid var(--primary);
            }
            
            
        }

        .active {
            background-color: #F9F5FF;
            padding: 8px 12px 8px 12px;
            border-radius: 6px;
        }
        .inactive {
            padding: 8px 12px 8px 12px;
            border-radius: 6px;
        }

        p {
            font-size: var(--subHeaderFontSize);
            font-weight: 500;
            line-height: 24px;
            color: var(--bredcumb-subTitle-color);
            margin: 0;
            cursor: pointer;
        }
    }
    }

    .tabs-outer{ //includedExtra
        display: flex;
        justify-content: flex-end;
         .tabs{
        font-size: 0.81rem;
        display: flex;
        flex-direction: row;
        background-color: var(--graybg);
        width: max-content;
        .tab{
            cursor: pointer;
            padding: 0.25rem 2rem;
            .selected-number{
                margin-left: 0.5rem;
                background-color: #ffffff;
                color: var(--primary);
                padding: 1px 5px;
                border-radius: 12px;
                border: 1px solid var(--primary);
            }
            
            
        }
        .tab.active{
            background-color: var(--primary);
            color: var(--graybg);
            font-weight: 500;
            border-radius: 2rem;
        }
    }
    }




    padding: 0 24px;
    padding-bottom: 46px;

    .header-title {
        font-size: var(--pageHeaderFontSize);
        font-weight: 500;
        line-height: 40px;
        color: var(--black);
        padding: 24px 0;
        margin: 0;
    }
   
    .breadcumb {
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 16px 0px;

        .mainPage {
            font-size: var(--subHeaderFontSize);
            font-weight: 500;
            line-height: 24px;
            color: var(--bredcumb-title-color);
            margin: 0;

            cursor: pointer;
        }

        .subPage {
            font-size: var(--subHeaderFontSize);
            font-weight: 500;
            line-height: 24px;
            color: var(--bredcumb-subTitle-color);
            margin: 0;
            cursor: pointer;
        }
    }

    .profile {
        display: flex;
        gap: 24px;
        flex-direction: column;

        .searchBar {
            background: #FFFFFF;
            padding: 12px 24px;
            border-radius: 12px;

            // if tabs present in searchbar
            .nav-tabs-outer{ //includedExtra
                display: flex;
                justify-content: flex-end;
                 .tabs{
                font-size: 0.81rem;
                display: flex;
                flex-direction: row;
                background-color: var(--graybg);
                width: max-content;
                padding: 4px;
                gap: 8px;
                border-radius: 8px;
                border: 1px solid #F2F4F7;

                margin: 0 1rem;
                .tab{
                    cursor: pointer;
                    padding: 0.25rem 2rem;
                    .selected-number{
                        margin-left: 0.5rem;
                        background-color: #ffffff;
                        color: var(--primary);
                        padding: 1px 5px;
                        border-radius: 12px;
                        border: 1px solid var(--primary);
                    }
                    
                    
                }
        
                .active {
                    background-color: #F9F5FF;
                    padding: 8px 12px 8px 12px;
                    border-radius: 6px;
                }
                .inactive {
                    padding: 8px 12px 8px 12px;
                    border-radius: 6px;
                }
                p {
                    font-size: var(--subHeaderFontSize);
                    font-weight: 500;
                    line-height: 24px;
                    color: var(--bredcumb-subTitle-color);
                    margin: 0;
                    cursor: pointer;
                }
            }
            }

            .filter-card{
                padding: 4px 4px 4px 10px;
                gap: 3px;
                border-radius: 4px 0px 0px 0px;

                background-color: #F4EBFF;
                min-inline-size: max-content;
                cursor: pointer;

                .title {
                    font-family: Inter;
                    font-size: var(--textFontSize);
                    font-weight: 500;
                    line-height: 20px;
                    text-align: center;
                    color: #7F56D9

                }

                .delete {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    cursor: pointer;
                }

                .filter-count {
                    width: auto;
                    height: 19px;
                    padding: 1px 3px 1px 3px;
                    gap: 10px;
                    border-radius: 16px;
                    background: #7F56D9; 
                    
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    color: #FFFFFF;

                    font-family: Inter;
                    font-size: var(--smallTextFontSize);
                    font-weight: 600;
                    line-height: 18px;
                    text-align: center;


                }

            }

            .reset-filters {
                font-family: Inter;
                font-size: var(--textFontSize);
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                color: #6941C6;

                align-items: center;
                display: flex;

                cursor: pointer;
            }

            .search-title {
                white-space:nowrap;
                font-size: var(--headerFonrSize);
                font-weight: 600;
                line-height: 28px;
                color: var(--title-color);
                margin: 0;
            }

            .right-container-search { //includedExtra
               
                display: flex;
                justify-content: flex-end;
                height: fit-content;
                
                .MuiAutocomplete-fullWidth { //overwrite for search bar
                    width: 320px;
                }

                .search-filters {
                    display: flex;
                    width: 130px;
                    justify-content: space-around;

                    border: 1px solid #D0D5DD;
                    padding: 4px;

                    border-radius: 4px;

                    align-items: center;
                    cursor: pointer;

                    margin-left: 16px; // replace with gap

                    .filters-icon {
                        width: 16px;
                        height: 16px;

                        color: #344054;
                    }

                    .filters-title {
                        font-family: Inter;
                        font-size: var(--textFontSize);
                        font-weight: 500;
                        line-height: 20px;
                        text-align: left;
                        
                        color: #344054;
                        
                    }

                    .filters-count{
                        font-family: Inter;
                        font-size: var(--smallTextFontSize);
                        font-weight: 500;
                        line-height: 18px;
                        text-align: center;

                        color: #344054;
                        background: #F4EBFF;

                        padding: 0px 4px 0px 4px;
                        gap: 0px;
                        border-radius: 3px;


                    }
                }
            }

            .search {
                position: relative;

                .searchInput {
                    border: 1px solid var(--primary-400);
                    border-radius: 4px;
                    color: var(--title-color);
                    padding: 8px 12px;
                    width: 320px;
                    outline: none;
                }

                .searchIcon {
                    position: absolute;
                    top: 10px;
                    right: 12px;
                }
            }
        }

        .profile-table {
            table {
                width: 100%;
                border-spacing: 0;
                border: 1px solid var(--card-color);
                border-radius: 12px;
                background: var(--bg);

                thead {
                    tr {
                        th {
                            font-size: var(--smallTextFontSize);
                            font-weight: 500;
                            line-height: 18px;
                            text-align: left;
                            color: var(--TextSecondary);
                            padding: 13px 24px;
                            border-bottom: 1px solid var(--card-color);
                            background: var(--about-bg);

                            &:nth-of-type(1) {
                                border-top-left-radius: 12px;
                            }

                            &:nth-last-child(1) {
                                border-top-right-radius: 12px;
                            }

                            .filter {
                                display: flex;
                                align-items: center;
                                gap: 4px;

                                .label {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                tbody {
                    background: #FFFFFF;

                    tr {
                        td {
                            padding: 7px 24px;
                            font-size: var(--textFontSize);
                            font-weight: 500;
                            color: var(--TextSecondary);

                            .details {
                                display: flex;
                                gap: 12px;
                                align-items: center;

                                .hidden {
                                    opacity: 0;
                                }

                                .userImage {
                                    width: 40px;
                                    height: 40px;
                                }

                                .detailsInner {
                                    .title {
                                        font-size: var(--textFontSize);
                                        font-weight: 500;
                                        line-height: 20px;
                                        color: var(--title-color);
                                        margin: 0;

                                        text-align: left;

                                    }

                                    .empId {
                                        font-size: var(--smallTextFontSize);
                                        font-weight: 500;
                                        line-height: 18px;
                                        color: var(--subTitle-color);
                                        margin: 0;
                                    }

                                    .empDesignation {
                                        color: var(--subTitle-color);
                                        margin: 0;

                                        font-family: Inter;
                                        font-size: var(--smallTextFontSize);
                                        font-weight: 400;
                                        line-height: 18px;
                                        text-align: left;

                                    }
                                }

                            }
                        }
                        .table-action-button{
                            font-family: Inter;
                            font-size: var(--textFontSize);
                            font-weight: 400;
                            line-height: 20px;
                            text-align: left;
                            color: #7F56D9;

                        }
                    }
                }
            }
        }

        .profile-details {
            display: flex;
            gap: 16px;
            align-items: flex-start;
            background: #FFFFFF;
            padding: 24px;
            border-radius: 12px;

            .userImage {
                width: 84px;
                height: 84px;
            }

            .details {
                width: 100%; //added 2-july
                display: flex;
                flex-direction: column;
                gap: 16px;

                .detailsOne {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    .detailsInner {
                        .userName {
                            font-size: var(--profileSubCardHeaderFontSize);
                            font-weight: 600;
                            line-height: 28px;
                            color: var(--title-color);
                            margin: 0;
                        }

                        .dept {
                            font-size: var(--subHeaderFontSize);
                            font-weight: 500;
                            line-height: 24px;
                            color: var(--black);
                            margin: 0;
                        }

                        .empId {
                            font-size: var(--smallTextFontSize);
                            font-weight: 500;
                            line-height: 18px;
                            color: var(--subTitle-color);
                            margin: 0;
                        }

                        .cardOuter {
                            display: flex;
                            gap: 10px;

                            .card {
                                border: 1px solid var(--card-color);
                                background: var(--primary-25);
                                border-radius: 20px;
                                padding: 5px 10px;
                                font-size: var(--smallTextFontSize);
                                font-weight: 500;
                                line-height: 18px;
                                color: var(--TextPrimary);
                            }
                        }
                    }

                    .contact {
                        background: var(--primary-25);
                        border-radius: 12px;
                        padding: 16px;
                        gap: 12px;
                        display: flex;
                        flex-direction: column;

                        .mailOuter {
                            display: flex;
                            gap: 8px;
                            align-items: center;

                            .mail {
                                font-size: var(--textFontSize);
                                font-weight: 500;
                                line-height: 16.94px;
                                letter-spacing: -0.02em;
                                color: var(--primary-600);
                                margin: 0;
                            }
                        }

                        .mobNoOuter {
                            display: flex;
                            gap: 8px;
                            align-items: center;

                            .mobNo {
                                font-size: var(--textFontSize);
                                font-weight: 500;
                                line-height: 16.94px;
                                letter-spacing: -0.02em;
                                color: var(--primary-600);
                                margin: 0;
                            }
                        }
                    }
                }

                .detailsTwo {
                    display: flex;
                    justify-content: space-between;

                    .detailsTwoInner {
                        width: 100%;
                        display: flex;
                        gap: 8px;
                        flex-direction: column;
                        border-left: 1px solid #D9DCE4;
                        padding-left: 20px;

                        &:nth-of-type(1) {
                            border: none;
                        }

                        .title {
                            font-size: var(--textFontSize);
                            font-weight: 500;
                            line-height: 16.94px;
                            letter-spacing: -0.03em;
                            color: var(--subTitle-color);
                            margin: 0;
                        }

                        .subTitle {
                            font-size: var(--textFontSize);
                            font-weight: 500;
                            line-height: 16.94px;
                            letter-spacing: -0.02em;
                            color: var(--black);
                            margin: 0;
                        }
                    }
                }

                .about {
                    padding: 20px;
                    display: flex;
                    gap: 14px;
                    align-items: flex-start;
                    background: var(--about-bg);
                    border-radius: 12px;

                    .aboutInner {
                        display: flex;
                        gap: 4px;
                        flex-direction: column;

                        .title {
                            font-size: var(--profileSubCardHeaderFontSize);
                            font-weight: 500;
                            line-height: 28px;
                            color: var(--black);
                            margin: 0;
                        }

                        .desc {
                            font-size: var(--textFontSize);
                            font-weight: 500;
                            line-height: 20px;
                            color: var(--black);
                            margin: 0;
                        }
                    }
                }
            }
        }

        .tabs {
            background: #FFFFFF;
            padding: 24px;
            border-radius: 12px;

            .tabs-block {
                display: flex;
                justify-content: flex-start;
                padding-top: 1rem;
                border-bottom: 1px solid var(--card-color);
                margin-bottom: 1rem;
                gap: 16px;

                .tab {
                    font-weight: 600;
                    font-size: var(--textFontSize);
                    line-height: 20px;
                    color: var(--subTitle-color);
                    padding: 12px;
                    cursor: pointer;
                }

                .active {
                    color: var(--bredcumb-subTitle-color);
                    border-bottom: 2px solid var(--bredcumb-subTitle-color);
                    background-color: #F9F5FF;
                }
            }
        }

        .tabs2 {
            background: #FFFFFF;

            .tabs-block {
                display: flex;
                justify-content: flex-start;
                border-bottom: 1px solid var(--card-color);
                margin-bottom: 20px;
                background: #F9FAFB;
                border: 1px solid var(--card-color);
                border-radius: 8px;
                padding: 4px;
                gap: 8px;

                .tab {
                    font-weight: 600;
                    font-size: var(--textFontSize);
                    line-height: 20px;
                    color: var(--subTitle-color);
                    padding: 8px 12px;
                    cursor: pointer;
                }

                .active {
                    color: var(--bredcumb-subTitle-color);
                    border-radius: 6px;
                    background: #fff;
                    box-shadow: 0px 1px 2px 0px var(--tab-box-shadow);
                    box-shadow: 0px 1px 3px 0px var(--tab-box-shadow);
                    border: none;
                }
            }
        }

        .performanceHistory {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .title {
                font-size: var(--profileSubCardHeaderFontSize);
                font-weight: 600;
                line-height: 28px;
                color: var(--black);
                margin: 0;
            }

            .ratingOuter {
                display: flex;
                flex-direction: column;
                gap: 24px;

                .rating {
                    display: flex;
                    gap: 16px;
                    align-items: flex-start;

                    .ratingInner {
                        display: flex;
                        gap: 20px;
                        flex-direction: column;
                        width: 100%;

                        .ratingTitle {
                            font-size: var(--headerFonrSize);
                            font-weight: 600;
                            line-height: 28px;
                            color: var(--black);
                            margin: 0;

                            .name {
                                padding-left: 10px;
                            }
                        }

                        table {
                            width: 100%;
                            border-spacing: 0;
                            border: 1px solid var(--card-color);
                            border-radius: 12px;
                            background: var(--bg);
                            
                            thead {
                                tr {
                                    th {
                                        font-size: var(--textFontSize);
                                        font-weight: 600;
                                        line-height: 18px;
                                        color: var(--TextSecondary);
                                        padding: 13px 24px;
                                        border-bottom: 1px solid var(--card-color);
                                        border-left: 1px solid var(--card-color);
                                        background: var(--about-bg);
                                        text-align: center;

                                        &:nth-of-type(1) {
                                            border-top-left-radius: 12px;
                                            border-left: none;
                                        }

                                        &:nth-last-child(1) {
                                            border-top-right-radius: 12px;
                                        }

                                        .filter {
                                            display: flex;
                                            align-items: center;
                                            gap: 4px;

                                            .label {
                                                margin: 0;
                                            }
                                        }
                                    }

                                    th.right {
                                        text-align: right;
                                    }
                                }
                            }

                            tbody {
                                background: #FFFFFF;

                                tr {
                                    &:nth-last-child(1) {
                                        td {
                                            border-bottom: none;

                                            &:nth-of-type(1) {
                                                border-bottom-left-radius: 12px;
                                            }

                                            &:nth-last-child(1) {
                                                border-bottom-right-radius: 12px;
                                            }
                                        }
                                    }

                                    td {
                                        padding: 18px 24px;
                                        font-size: var(--textFontSize);
                                        font-weight: 500;
                                        color: var(--TextSecondary);
                                        text-align: center;
                                        border-left: 1px solid var(--card-color);
                                        border-bottom: 1px solid var(--card-color);

                                        &:nth-of-type(1) {
                                            border-left: none;
                                        }

                                        .details {
                                            display: flex;
                                            gap: 12px;
                                            align-items: center;
                                            cursor: pointer;

                                            .userImage {
                                                width: 40px;
                                                height: 40px;
                                            }

                                            .detailsInner {
                                                .title {
                                                    font-size: var(--textFontSize);
                                                    font-weight: 500;
                                                    line-height: 20px;
                                                    color: var(--title-color);
                                                    margin: 0;
                                                }

                                                .empId {
                                                    font-size: var(--smallTextFontSize);
                                                    font-weight: 500;
                                                    line-height: 18px;
                                                    color: var(--subTitle-color);
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }

                                    td.bg {
                                        background: var(--about-bg);
                                    }

                                    td.right {
                                        text-align: right;
                                    }

                                    td.left {
                                        text-align: left;
                                        .dataText {
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            line-clamp: 4;
                                            -webkit-line-clamp: 4;
                                            display: -webkit-box;
                                            -webkit-box-orient: vertical;
                                        }
                                    }
                                }
                            }
                        }
                        .performanceCard {
                            display: grid;
                            grid-template-columns: repeat(4, minmax(0, 1fr));
                            gap: 16px;

                            .card {
                                background: var(--primary-25);
                                border: 1px solid var(--card-color);
                                padding: 12px 24px;
                                display: flex;
                                flex-direction: column;
                                gap: 8px;
                                border-radius: 12px;

                                .top {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    .year {
                                        font-size: var(--headerFonrSize);
                                        font-weight: 600;
                                        line-height: 40px;
                                        color: var(--title-color);
                                        margin: 0;
                                    }
                                }

                                .fileName {
                                    font-size: var(--subHeaderFontSize);
                                    font-weight: 400;
                                    line-height: 24px;
                                    color: var(--TextSecondary);
                                    margin: 0;
                                }
                            }
                        }
                        
                        .formOuter {
                            display: flex;
                            justify-content: flex-end;
                            .form {
                                display: flex;
                                gap: 6px;
                                flex-direction: column;
                                max-width: 320px;
                                label {
                                    font-size: var(--textFontSize);
                                    font-weight: 500;
                                    line-height: 20px;
                                    color: var(--TextPrimary);
                                    margin: 0;
                                }
    
                                .selectOption {
                                    position: relative;
                                    width: 320px;
    
                                    select {
                                        border: 1px solid var(--select-option);
                                        box-shadow: 0px 1px 2px 0px var(--select-box-shadow);
                                        padding: 6px 14px;
                                        border-radius: 4px;
                                        width: 100%;
                                        appearance: none;
                                        -webkit-appearance: none;
                                    }
    
                                    .selectDD {
                                        position: absolute;
                                        right: 6px;
                                        top: 6px;
                                    }
                                }
                            }
                        }

                        .movementJourney {
                            background: var(--movement-bg);
                            padding: 24px 0;
                            border-radius: 12px;
                            width: 100%;

                            .movementJourneyInner {
                                padding: 12px 30px;
                                background: var(--movement-bg-inner);
                                text-align: center;
                                border-radius: 12px;
                                width: 50%;
                                margin: 0 auto;

                                .movementJourney {
                                    width: 496px;
                                    background: transparent;
                                }
                            }
                        }

                    }
                }

                .dividerLine {
                    border: 1px solid #0000000F;
                    margin: 0;
                }
            }
        }

        .experience {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .title {
                font-size: var(--profileSubCardHeaderFontSize);
                font-weight: 600;
                line-height: 28px;
                color: var(--black);
                margin: 0;
            }

            .ratingOuter {
                display: flex;
                flex-direction: column;
                gap: 24px;
                position: relative;

                &::before {
                    content: '';
                    border-left: 1px solid #0000000F;
                    position: absolute;
                    left: 12px;
                    height: -webkit-fill-available;
                }

                .rating {
                    display: flex;
                    gap: 16px;
                    align-items: flex-start;

                    .openCloseImg {
                        z-index: 1;
                        background: #fff;
                    }

                    .roundImg {
                        margin: 8px;
                    }

                    .ratingInner {
                        display: flex;
                        gap: 20px;
                        flex-direction: column;
                        width: 100%;

                        .detailsTwo {
                            display: flex;
                            justify-content: space-between;

                            .detailsTwoInner {
                                width: 100%;
                                display: flex;
                                gap: 8px;
                                flex-direction: column;

                                &:nth-of-type(1) {
                                    border: none;
                                }

                                .title {
                                    font-size: var(--textFontSize);
                                    font-weight: 500;
                                    line-height: 16.94px;
                                    letter-spacing: -0.03em;
                                    color: var(--subTitle-color);
                                    margin: 0;
                                }

                                .subTitle {
                                    font-size: var(--textFontSize);
                                    font-weight: 500;
                                    line-height: 16.94px;
                                    letter-spacing: -0.02em;
                                    color: var(--black);
                                    margin: 0;
                                }
                            }
                        }

                        .ratingTitle {
                            font-size: var(--headerFonrSize);
                            font-weight: 600;
                            line-height: 28px;
                            color: var(--black);
                            margin: 0;

                            .name {
                                padding-left: 10px;
                            }

                            .subName {
                                font-size: var(--textFontSize);
                                font-weight: 500;
                                line-height: 20px;
                                color: var(--subTitle-color);
                            }
                        }

                    }

                    .subRating {
                        display: flex;
                        gap: 16px;
                        flex-direction: column;
                        width: 100%;

                        .ratingSubTitle {
                            font-size: var(--headerFonrSize);
                            font-weight: 500;
                            line-height: 28px;
                            color: var(--black);
                            margin: 0;
                        }

                        .listOuter {
                            padding-left: 25px;

                            .listTitle {
                                font-size: var(--textFontSize);
                                font-weight: 500;
                                line-height: 20px;
                                color: var(--black);
                                margin: 0;
                            }

                            .list {
                                font-size: var(--textFontSize);
                                font-weight: 400;
                                line-height: 20px;
                                color: var(--subTitle-color);
                                padding-inline-start: 25px;
                                margin: 0;
                            }
                        }

                        .projectDetails {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            padding-left: 25px;

                            .goal {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .goalTitle {
                                    font-size: var(--textFontSize);
                                    font-weight: 400;
                                    line-height: 20px;
                                    color: var(--subTitle-color);
                                    margin: 0;
                                }

                                .goalDesc {
                                    font-size: var(--textFontSize);
                                    font-weight: 500;
                                    line-height: 20px;
                                    color: var(--black);
                                    margin: 0;
                                }
                            }

                            .desc {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .descTitle {
                                    font-size: var(--textFontSize);
                                    font-weight: 400;
                                    line-height: 20px;
                                    color: var(--subTitle-color);
                                    margin: 0;
                                }

                                .content {
                                    font-size: var(--textFontSize);
                                    font-weight: 400;
                                    line-height: 20px;
                                    color: var(--black);
                                    margin: 0;
                                }
                            }

                            .due {
                                display: flex;
                                gap: 150px;

                                .dueDate {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 8px;

                                    .title {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--subTitle-color);
                                        margin: 0;
                                    }

                                    .date {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--black);
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .dividerLine {
                    border: 1px solid #0000000F;
                    margin: 0;
                }
            }

            .dividerLine {
                border: 1px solid rgba(0, 0, 0, 0.0588235294);
                margin: 0;
            }
        }

        .educationCertifications {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .title {
                font-size: var(--profileSubCardHeaderFontSize);
                font-weight: 600;
                line-height: 28px;
                color: var(--black);
                margin: 0;
            }

            .ratingOuter {
                display: flex;
                flex-direction: column;
                gap: 24px;
                position: relative;

                &::before {
                    content: '';
                    border-left: 1px solid #0000000F;
                    position: absolute;
                    left: 12px;
                    height: -webkit-fill-available;
                }

                .rating {
                    display: flex;
                    gap: 16px;
                    align-items: flex-start;

                    .openCloseImg {
                        z-index: 1;
                        background: #fff;
                    }

                    .roundImg {
                        margin: 8px;
                        z-index: 1;
                    }

                    .ratingInner {
                        display: flex;
                        gap: 8px;
                        flex-direction: column;
                        width: 100%;

                        .ratingTitle {
                            font-size: var(--headerFonrSize);
                            font-weight: 600;
                            line-height: 28px;
                            color: var(--black);
                            margin: 0;

                            .name {
                                padding-left: 10px;
                            }

                            .subName {
                                font-size: var(--textFontSize);
                                font-weight: 500;
                                line-height: 20px;
                                color: var(--subTitle-color);
                            }
                        }

                        .educationDetails {
                            display: flex;
                            gap: 6px;
                            flex-direction: column;
                            font-size: var(--textFontSize);
                            font-weight: 500;
                            line-height: 20px;
                            padding-left: 25px;

                            .title {
                                font-size: var(--textFontSize);
                                color: var(--subTitle-color);
                                margin: 0;

                                .subTitle {
                                    font-size: var(--textFontSize);
                                    color: var(--black);
                                    margin: 0;
                                }

                                .dot {
                                    background: var(--subTitle-color);
                                    width: 4px;
                                    height: 4px;
                                    display: inline-flex;
                                    align-items: center;
                                    margin: 0 14px;
                                }
                            }

                            .subDetails {
                                font-size: var(--textFontSize);
                                color: var(--black);
                                margin: 0;
                            }

                            .dateRange {
                                font-size: var(--textFontSize);
                                color: var(--subTitle-color);
                                margin: 0;
                                font-weight: 400;
                            }

                            .link {
                                display: flex;
                                gap: 6px;
                                align-items: center;

                                .linkText {
                                    font-size: var(--textFontSize);
                                    font-weight: 500;
                                    line-height: 20px;
                                    color: var(--primary-600);
                                    margin: 0;
                                }
                            }
                        }

                    }

                    .subRating {
                        display: flex;
                        gap: 16px;
                        flex-direction: column;
                        width: 100%;

                        .ratingSubTitle {
                            font-size: var(--headerFonrSize);
                            font-weight: 500;
                            line-height: 28px;
                            color: var(--black);
                            margin: 0;
                        }

                        .listOuter {
                            padding-left: 25px;

                            .listTitle {
                                font-size: var(--textFontSize);
                                font-weight: 500;
                                line-height: 20px;
                                color: var(--black);
                                margin: 0;
                            }

                            .list {
                                font-size: var(--textFontSize);
                                font-weight: 400;
                                line-height: 20px;
                                color: var(--subTitle-color);
                                padding-inline-start: 25px;
                                margin: 0;
                            }
                        }

                        .projectDetails {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            padding-left: 25px;

                            .goal {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .goalTitle {
                                    font-size: var(--textFontSize);
                                    font-weight: 400;
                                    line-height: 20px;
                                    color: var(--subTitle-color);
                                    margin: 0;
                                }

                                .goalDesc {
                                    font-size: var(--textFontSize);
                                    font-weight: 500;
                                    line-height: 20px;
                                    color: var(--black);
                                    margin: 0;
                                }
                            }

                            .desc {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .descTitle {
                                    font-size: var(--textFontSize);
                                    font-weight: 400;
                                    line-height: 20px;
                                    color: var(--subTitle-color);
                                    margin: 0;
                                }

                                .content {
                                    font-size: var(--textFontSize);
                                    font-weight: 400;
                                    line-height: 20px;
                                    color: var(--black);
                                    margin: 0;
                                }
                            }

                            .due {
                                display: flex;
                                gap: 150px;

                                .dueDate {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 8px;

                                    .title {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--subTitle-color);
                                        margin: 0;
                                    }

                                    .date {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--black);
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .dividerLine {
                    border: 1px solid #0000000F;
                    margin: 0;
                }
            }

            .dividerLine {
                border: 1px solid rgba(0, 0, 0, 0.0588235294);
                margin: 0;
            }

        }

        .interestAspiration {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .title {
                font-size: var(--profileSubCardHeaderFontSize);
                font-weight: 600;
                line-height: 28px;
                color: var(--black);
                margin: 0;
            }

            .ratingOuter {
                display: flex;
                flex-direction: column;
                gap: 24px;
                position: relative;

                &::before {
                    content: '';
                    border-left: 1px solid #0000000F;
                    position: absolute;
                    left: 12px;
                    height: -webkit-fill-available;
                }

                .rating {
                    display: flex;
                    gap: 16px;
                    align-items: flex-start;

                    .openCloseImg {
                        z-index: 1;
                        background: #fff;
                    }

                    .roundImg {
                        margin: 8px;
                    }

                    .ratingInner {
                        display: flex;
                        gap: 8px;
                        flex-direction: column;
                        width: 100%;

                        .ratingTitleOuter {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .ratingTitle {
                                font-size: var(--headerFonrSize);
                                font-weight: 600;
                                line-height: 28px;
                                color: var(--black);
                                margin: 0;

                                .name {
                                    padding-left: 10px;
                                }

                                .subName {
                                    font-size: var(--textFontSize);
                                    font-weight: 500;
                                    line-height: 20px;
                                    color: var(--subTitle-color);
                                }
                            }

                            .dateOuter {
                                font-size: var(--textFontSize);
                                font-weight: 400;
                                line-height: 16.94px;
                                letter-spacing: -0.03em;
                                margin: 0;
                                color: var(--subTitle-color);

                                .date {
                                    color: var(--black);
                                }
                            }
                        }

                        .educationDetails {
                            display: flex;
                            gap: 6px;
                            flex-direction: column;
                            font-size: var(--textFontSize);
                            font-weight: 500;
                            line-height: 20px;
                            padding-left: 25px;

                            .title {
                                font-size: var(--textFontSize);
                                color: var(--subTitle-color);
                                margin: 0;

                                .subTitle {
                                    font-size: var(--textFontSize);
                                    color: var(--black);
                                    margin: 0;
                                }

                                .dot {
                                    background: var(--subTitle-color);
                                    width: 4px;
                                    height: 4px;
                                    display: inline-flex;
                                    align-items: center;
                                    margin: 0 14px;
                                }
                            }

                            .subDetails {
                                font-size: var(--textFontSize);
                                color: var(--black);
                                margin: 0;
                            }

                            .dateRange {
                                font-size: var(--textFontSize);
                                color: var(--subTitle-color);
                                margin: 0;
                                font-weight: 400;
                            }

                            .link {
                                display: flex;
                                gap: 6px;
                                align-items: center;

                                .linkText {
                                    font-size: var(--textFontSize);
                                    font-weight: 500;
                                    line-height: 20px;
                                    color: var(--primary-600);
                                    margin: 0;
                                }
                            }
                        }

                    }

                    .subRating {
                        display: flex;
                        gap: 16px;
                        flex-direction: column;
                        width: 100%;

                        .ratingSubTitle {
                            font-size: var(--headerFonrSize);
                            font-weight: 500;
                            line-height: 28px;
                            color: var(--black);
                            margin: 0;
                        }

                        .listOuter {
                            padding-left: 25px;

                            .listTitle {
                                font-size: var(--textFontSize);
                                font-weight: 500;
                                line-height: 20px;
                                color: var(--black);
                                margin: 0;
                            }

                            .list {
                                font-size: var(--textFontSize);
                                font-weight: 400;
                                line-height: 20px;
                                color: var(--subTitle-color);
                                padding-inline-start: 25px;
                                margin: 0;
                            }
                        }

                        .projectDetails {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            padding-left: 25px;

                            .goal {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .goalTitle {
                                    font-size: var(--textFontSize);
                                    font-weight: 400;
                                    line-height: 20px;
                                    color: var(--subTitle-color);
                                    margin: 0;
                                }

                                .goalDesc {
                                    font-size: var(--textFontSize);
                                    font-weight: 500;
                                    line-height: 20px;
                                    color: var(--black);
                                    margin: 0;
                                }
                            }

                            .desc {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .descTitle {
                                    font-size: var(--textFontSize);
                                    font-weight: 400;
                                    line-height: 20px;
                                    color: var(--subTitle-color);
                                    margin: 0;
                                }

                                .content {
                                    font-size: var(--textFontSize);
                                    font-weight: 400;
                                    line-height: 20px;
                                    color: var(--black);
                                    margin: 0;
                                }
                            }

                            .due {
                                display: flex;
                                gap: 150px;

                                .dueDate {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 8px;

                                    .title {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--subTitle-color);
                                        margin: 0;
                                    }

                                    .date {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--black);
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .dividerLine {
                    border: 1px solid #0000000F;
                    margin: 0;
                }
            }

            .dividerLine {
                border: 1px solid rgba(0, 0, 0, 0.0588235294);
                margin: 0;
            }
        }

        .LearningDevelopment {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .title {
                font-size: var(--profileSubCardHeaderFontSize);
                font-weight: 600;
                line-height: 28px;
                color: var(--black);
                margin: 0;
            }

            .course {
                .ratingOuter {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    position: relative;

                    .rating {
                        display: flex;
                        gap: 16px;
                        align-items: flex-start;

                        .openCloseImg {
                            z-index: 1;
                            background: #fff;
                        }

                        .roundImg {
                            margin: 8px;
                        }

                        .ratingInner {
                            display: flex;
                            gap: 20px;
                            flex-direction: column;
                            width: 100%;

                            .detailsTwo {
                                display: flex;
                                justify-content: space-between;

                                .detailsTwoInner {
                                    width: 100%;
                                    display: flex;
                                    gap: 8px;
                                    flex-direction: column;

                                    &:nth-of-type(1) {
                                        border: none;
                                    }

                                    .title {
                                        font-size: var(--textFontSize);
                                        font-weight: 500;
                                        line-height: 16.94px;
                                        letter-spacing: -0.03em;
                                        color: var(--subTitle-color);
                                        margin: 0;
                                    }

                                    .subTitle {
                                        font-size: var(--textFontSize);
                                        font-weight: 500;
                                        line-height: 16.94px;
                                        letter-spacing: -0.02em;
                                        color: var(--black);
                                        margin: 0;
                                    }
                                }
                            }

                            .ratingTitle {
                                font-size: var(--headerFonrSize);
                                font-weight: 600;
                                line-height: 28px;
                                color: var(--black);
                                margin: 0;

                                .name {
                                    padding-left: 10px;
                                }

                                .subName {
                                    font-size: var(--textFontSize);
                                    font-weight: 500;
                                    line-height: 20px;
                                    color: var(--subTitle-color);
                                }
                            }

                            .projectDetails {
                                display: flex;
                                flex-direction: column;
                                gap: 20px;
                                padding-left: 25px;

                                .goal {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 8px;

                                    .goalTitle {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--subTitle-color);
                                        margin: 0;
                                    }

                                    .goalDesc {
                                        font-size: var(--textFontSize);
                                        font-weight: 500;
                                        line-height: 20px;
                                        color: var(--black);
                                        margin: 0;
                                    }

                                    .skillList {
                                        display: flex;
                                        gap: 8px;

                                        .skillName {
                                            font-size: var(--smallTextFontSize);
                                            font-weight: 500;
                                            line-height: 18px;
                                            color: var(--TextPrimary);
                                            padding: 4px 8px;
                                            border: 1px solid var(--select-option);
                                            border-radius: 4px;
                                            margin: 0;
                                        }
                                    }
                                }

                                .desc {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 8px;

                                    .descTitle {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--subTitle-color);
                                        margin: 0;
                                    }

                                    .content {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--black);
                                        margin: 0;
                                    }
                                }

                                .due {
                                    display: flex;
                                    gap: 20px;

                                    .dueDate {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 8px;
                                        width: 50%;

                                        .title {
                                            font-size: var(--textFontSize);
                                            font-weight: 400;
                                            line-height: 20px;
                                            color: var(--subTitle-color);
                                            margin: 0;
                                        }

                                        .date {
                                            font-size: var(--textFontSize);
                                            font-weight: 400;
                                            line-height: 20px;
                                            color: var(--black);
                                            margin: 0;
                                        }
                                    }
                                }
                            }

                        }
                    }

                    .dividerLine {
                        border: 1px solid #0000000F;
                        margin: 0;
                    }
                }
            }

            .projectsMentorship {
                .ratingOuter {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    position: relative;

                    .rating {
                        display: flex;
                        gap: 16px;
                        align-items: flex-start;

                        .openCloseImg {
                            z-index: 1;
                            background: #fff;
                        }

                        .roundImg {
                            margin: 8px;
                        }

                        .ratingInner {
                            display: flex;
                            gap: 20px;
                            flex-direction: column;
                            width: 100%;

                            .ratingTitle {
                                font-size: var(--headerFonrSize);
                                font-weight: 600;
                                line-height: 28px;
                                color: var(--black);
                                margin: 0;

                                .name {
                                    padding-left: 10px;
                                }

                                .subName {
                                    font-size: var(--textFontSize);
                                    font-weight: 500;
                                    line-height: 20px;
                                    color: var(--subTitle-color);
                                }
                            }

                            .projectDetails {
                                display: flex;
                                flex-direction: column;
                                gap: 20px;
                                padding-left: 25px;

                                .mentorDetails {
                                    display: flex;
                                    gap: 14px;
                                    flex-direction: column;

                                    .label {
                                        font-size: var(--textFontSize);
                                        font-weight: 600;
                                        line-height: 20px;
                                        margin: 0;
                                        color: var(--black);
                                    }

                                    .mentorDetailsInner {
                                        display: flex;
                                        gap: 24px;
                                        flex-direction: column;

                                        .detailsTwo {
                                            display: flex;
                                            justify-content: flex-start;

                                            .detailsTwoInner {
                                                width: 25%;
                                                display: flex;
                                                gap: 8px;
                                                flex-direction: column;

                                                &:nth-of-type(1) {
                                                    border: none;
                                                }

                                                .title {
                                                    font-size: var(--textFontSize);
                                                    font-weight: 500;
                                                    line-height: 16.94px;
                                                    letter-spacing: -0.03em;
                                                    color: var(--subTitle-color);
                                                    margin: 0;
                                                }

                                                .subTitle {
                                                    font-size: var(--textFontSize);
                                                    font-weight: 500;
                                                    line-height: 16.94px;
                                                    letter-spacing: -0.02em;
                                                    color: var(--black);
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }
                                }

                                .goal {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 8px;

                                    .goalTitle {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--subTitle-color);
                                        margin: 0;
                                    }

                                    .goalDesc {
                                        font-size: var(--textFontSize);
                                        font-weight: 500;
                                        line-height: 20px;
                                        color: var(--black);
                                        margin: 0;
                                    }

                                    .skillList {
                                        display: flex;
                                        gap: 8px;

                                        .skillName {
                                            font-size: var(--smallTextFontSize);
                                            font-weight: 500;
                                            line-height: 18px;
                                            color: var(--TextPrimary);
                                            padding: 4px 8px;
                                            border: 1px solid var(--select-option);
                                            border-radius: 4px;
                                            margin: 0;
                                        }
                                    }
                                }

                                .desc {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 8px;

                                    .descTitle {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--subTitle-color);
                                        margin: 0;
                                    }

                                    .content {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--black);
                                        margin: 0;
                                    }
                                }

                                .due {
                                    display: flex;
                                    gap: 20px;

                                    .dueDate {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 8px;
                                        width: 50%;

                                        .title {
                                            font-size: var(--textFontSize);
                                            font-weight: 400;
                                            line-height: 20px;
                                            color: var(--subTitle-color);
                                            margin: 0;
                                        }

                                        .date {
                                            font-size: var(--textFontSize);
                                            font-weight: 400;
                                            line-height: 20px;
                                            color: var(--black);
                                            margin: 0;
                                        }
                                    }
                                }

                                .documentsAttached {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 8px;

                                    .documentsLabel {
                                        font-size: var(--textFontSize);
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(--subTitle-color);
                                        margin: 0;
                                    }

                                    .performanceCard {
                                        display: grid;
                                        grid-template-columns: repeat(3, minmax(0, 1fr));
                                        gap: 8px;

                                        .card {
                                            background: var(--primary-25);
                                            border: 1px solid var(--card-color);
                                            padding: 12px 24px;
                                            display: flex;
                                            flex-direction: column;
                                            gap: 8px;
                                            border-radius: 12px;

                                            .top {
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: center;

                                                .fileName {
                                                    font-size: var(--subHeaderFontSize);
                                                    font-weight: 400;
                                                    line-height: 24px;
                                                    color: var(--TextSecondary);
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }

                    .dividerLine {
                        border: 1px solid #0000000F;
                        margin: 0;
                    }
                }
            }

            .dividerLine {
                border: 1px solid rgba(0, 0, 0, 0.0588235294);
                margin: 0;
            }
        }
    }
}
