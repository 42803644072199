@import "../utils/utils.scss";

.compensation {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1rem 2rem;
    background-color: var(--graybg);
    overflow-y: auto;
    .no-data{
        text-align: center;
        padding: 5rem;
        font-size: 1.5rem;
        font-weight: 500;
    }
    .tabs-outer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tabs {
            @include heading;
            font-size: 1.1rem;
            display: flex;
            flex-direction: row;
            background-color: #ffffff;
            border-radius: 2rem;
            width: max-content;

            .tab {
                border: none;
                background-color: #ffffff;
                cursor: pointer;
                padding: 0.5rem 2rem;
                border-radius: 2rem;
            }

            .tab.active {
                background-color: var(--primary);
                color: var(--graybg);
                font-weight: 500;
                border-radius: 2rem;
            }
        }

        .report-btn-div {
            .bottom {
                display: flex;
                justify-content: flex-end;

                .MuiFormControl-root {
                    width: 15rem;
                    background-color: #ffffff;
                    border-radius: 5px;
                    font-size: 0.7rem;
                    padding: 0.5rem;
                    color: var(--primary);
                }
            }
        }

    }

    .top-sction-wrapper {
        display: flex;
        justify-content: flex-end;

        .budget-dashboard {
            color: #00425A;
            font-family: Poppins;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            align-content: center;
        }

        .top-dropdown .MuiFormControl-root {
            width: 165px;
            background-color: #BAD7E9;
            border-radius: 10px;
            color: #00425A;
            font-size: 16px;
            font-weight: 500;
        }

        .my-team-section .edit-btn {
            background-color: #00425A;
            width: 94px;
            height: 45px;
            border-radius: 10px;
            border: none;
            color: #F9F9F9;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
        }
    }

    .chart-bg {
        border-radius: 20px;
        background-color: #fff;
        padding: 20px;
        margin-top: 15px;
        margin-bottom: 15px;

        .outer-layout {
            width: 100%;
            display: grid;
            grid-template-columns: 33.3% 33.4% 33.3%;

            .grid-column {
                text-align: center;

                .progress-title {
                    color: #00425A;
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.3799999952316284px;
                    text-align: left;
                    margin-left: 1rem;
                }

                .image-container {
                    position: relative;
                    display: inline-block;
                    margin-top: 20px;
                }

                .image-container img {
                    display: block;
                    width: 150px;
                    height: 150px;
                }

                .overlay-div {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    padding: 10px 20px;
                }

                .price-text {
                    color: #00425A;
                    font-size: 16px;
                    font-weight: 500;
                }

                .total-amt {
                    color: #979797;
                    font-family: Poppins;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 15px;
                    text-align: center;
                }

                .progress-bottom-div {
                    display: flex;
                    justify-content: space-between;
                    padding-left: 40px;
                    padding-right: 40px;
                    margin-top: 10px;
                }

                .d-flex {
                    display: flex;
                }

                .progress-point {
                    width: 19px;
                    height: 11px;
                    border-radius: 4px;
                    margin-top: 4px;
                }

                .bg-blue {
                    background-color: #2D99FE;
                }

                .bg-light-red {
                    background-color: #D87D4B;
                }

                .bg-light-green {
                    background-color: #2DDAC1;
                }

                .bg-lavender {
                    background-color: #CD77DB;
                }

                .progress-count {
                    color: #00425A;
                    font-size: 14px;
                    margin-left: 5px;
                }

                .progress-persend {
                    color: #979797;
                    font-size: 14px;
                    margin-left: 5px;
                }

                .available-txt {
                    color: #979797;
                    font-size: 12px;
                }

                .vertical-border {
                    width: 1px;
                    height: 150px;
                    background-color: #D9D9D9;
                    margin-top: 45px;
                }
            }
            .grid-column.middle {
                border-right: 1px solid rgb(221, 221, 221);
                border-left: 1px solid rgb(221, 221, 221);
            }
        }
    }

    .table-bg {
        border-radius: 20px;
        background-color: #fff;
        padding-top: 20px;
        margin-top: 15px;
        margin-bottom: 15px;

        .table-top-layout {
            width: 100%;
            display: grid;
            grid-template-columns: 30% 15% 11% 15% 15% 14%;
            padding-left: 20px;
            padding-right: 20px;

            .table-top-column {
                margin-right: 10px;

                .search-bar {
                    background-color: #F9F9F9;
                    border-radius: 10px;
                }

                .table-filter-dropdown {

                    .filter-tab {
                        @include subHeadingDark;
                        background-color: #BAD7E9;
                        position: relative;
                        margin-left: 0.5rem;
                        border: 1px solid var(--primary);
                        border-radius: 10px;
                        padding: 1.05rem 1.25rem;
                        width: 7.9rem;
                        cursor: pointer;

                        img {
                            margin-right: 0.7rem;
                        }

                        .red-dot {
                            width: 12px;
                            height: 12px;
                            background-color: red;
                            border-radius: 6px;
                            position: absolute;
                            top: 5px;
                            right: 5px;
                        }
                    }
                }

                .check-box {
                    color: #00425A;
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: 10px;
                    margin-left: 10px;
                }

                .w-100 {
                    width: 100%;
                }

                .mt-12 {
                    margin-top: 12px;
                }

                .mr-inherit {
                    margin-right: inherit;
                }
            }

            .expand-all {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-right: inherit;
            }
        }

    }

    .table-pagination-wrapper {
        display: flex;
        justify-content: space-between;

        .d-flex {
            display: flex;
        }

        .per-page-item {
            color: #979797;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            align-content: center;
            margin-right: 10px;
        }

        .pagination-active {
            width: 32px;
            height: 32px;
            border-radius: 5px;
            background-color: #BAD7E9;
            text-align: center;
            align-content: center;
            padding-top: 3px;
        }

        .pagination-deactivet {
            width: 32px;
            height: 32px;
            border-radius: 5px;
            border: 0.5px solid #979797;
            text-align: center;
            align-content: center;
            padding-top: 3px;
        }

        .ac-center-mr-12 {
            align-content: center;
            margin-right: 12px;
        }

        .ac-center {
            align-content: center;
        }
    }
    .table-container-sample {
        width: 100%;
        height: 32rem;
        overflow-x: auto;
        margin-top: 1rem;
        table {
            border-collapse: collapse;
            width: 100%;
            table-layout: fixed;
            border: none;
            thead{
                position: sticky;
                top: 0;
                z-index: 2;
            }
            th, td {
                background-color: #ffffff;
                padding: 8px;
                text-align: left;
                width:10rem;
                border: none;
                word-break: break-word;
            }
            tr{
                border-bottom: 1px solid rgb(206, 206, 206);
            }
            th {
                .col-head-outer{
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    .table-heading{
                        margin-left: 0.7rem;
                        color: #00425A;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                    }
                }
            }
            th.sticky, td.sticky {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                background-color: #f4fdff;
                z-index: 1;
                width: 7rem;
            }
            th.sticky2, td.sticky2 {
                position: -webkit-sticky;
                position: sticky;
                left: 7rem;
                background-color: #f4fdff;
                z-index: 1;
                width: 9rem;
            }
            th.sticky-right, td.sticky-right {
                position: -webkit-sticky;
                position: sticky;
                right: 0;
                background-color: #f4fdff;
                z-index: 1;
                width: 6rem;
            }
            th.sticky + th.sticky, td.sticky + td.sticky {
                left: 50px; /* Adjust width of first sticky column */
            }
            td{
                color: #979797;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                line-height: normal;
            }
            th.gray-block, td.gray-block{
                background-color: #f2f2f2;
            }
            td.table-value-emp-name {
                    color: #00425A;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    .all-flags{
                        display: flex;
                        justify-content: flex-start;
                    }
                    
                }
            td.table-value.mid-align {
                    text-align: center;
                }
            td.table-value-btns {
                padding: 1rem;
                    .edit-btn {
                        font-size: 0.75rem;
                        color: var(--primary);
                        background-color: #ffffff;
                        border: 1px solid gray;
                        border-radius: 5px;
                    }
    
                    .save-btn {
                        padding: 0px 0.75rem;
                        font-size: 0.75rem;
                        color: #ffffff;
                        background-color: var(--primary);
                        border-radius: 5px;
                    }
    
                    .save-btn.gray-bg {
                        padding: 0px 0.75rem;
                        font-size: 0.75rem;
                        color: #ffffff;
                        background-color: gray;
                        border-radius: 5px;
                    }
                }
            
        }
       
    }
    .approve-btn-outer{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .apr-btn{
            width: 10rem;
            margin: 1rem 0px;
            font-family: 'Poppins';
            padding: 0.5rem 1rem;
            border: none;
            background-color: rgb(17, 193, 91);
            border-radius: 10px;
            &:hover{
                background-color: rgb(30, 196, 121) ;
            }
            &:active{
                background-color: rgb(17, 193, 91);
            }
            
        }
        .apr-btn-disabled{
            width: 10rem;
            margin: 1rem 0px;
            font-family: 'Poppins';
            padding: 0.5rem 1rem;
            border: none;
            background-color: rgb(174, 173, 173);
            border-radius: 10px;
        }
    }
}