@import "../../utils/utils.scss";

.rating-calibration-report-view {
    @include heading;

    .tabs-outer {
        display: flex;
        justify-content: space-between;
        padding: 0px 1rem;

        .down-report{
            button{
                width: 100%;
                height: 2rem;
                background-color: #ffffff;
                border: 1px solid var(--primary);
                border-radius: 5px;
                font-size: 0.7rem;
                padding: 0px 2rem;
                color: var(--primary);
                img{
                   width: 0.75rem;
                   margin-left: 1rem;
                }
                &:hover{
                    font-size: 0.77rem;
                    padding: 0px 1.7rem;
                }
                &:active{
                    background-color: #f0feff;
                }
            }
        }

        .tabs {
            @include heading;
            font-size: 0.81rem;
            display: flex;
            flex-direction: row;
            background-color: var(--graybg);
            border-radius: 2rem;
            width: max-content;
            border: 1px solid var(--secondary);

            .tab {
                border-radius: 2rem;
                border: none;
                cursor: pointer;
                padding: 0.25rem 2rem;
            }

            .tab.active {
                background-color: var(--primary);
                color: var(--graybg);
                font-weight: 500;
                border-radius: 2rem;
            }
        }
    }
    .tabs-outer-1 {
        display: flex;
        padding: 0px 1rem;

        .tabs {
            @include heading;
            font-size: 0.81rem;
            display: flex;
            flex-direction: row;
            background-color: var(--graybg);
            border-radius: 2rem;
            width: max-content;
            border: 1px solid var(--secondary);

            .tab {
                border-radius: 2rem;
                border: none;
                cursor: pointer;
                padding: 0.25rem 2rem;
            }

            .tab.active {
                background-color: var(--primary);
                color: var(--graybg);
                font-weight: 500;
                border-radius: 2rem;
            }
        }
        .icon-tabs {
            @include heading;
            display: flex;
            flex-direction: row;
            background-color: var(--graybg);
            width: max-content;

            .tab {
                background-color: var(--secondary);
                cursor: pointer;
                padding: 0.5rem ;
                display: flex;
                align-items: center;
                svg{
                    font-size: 1.25rem;
                    margin: 0px 0.84rem;
                }
            }
            .tab-1{
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .tab-2{
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            .tab.active {
                background-color: var(--primary);
                color: var(--graybg);
                font-weight: 500;
            }
        }
    }

    .color-legends-block{
        @include normalText;
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        .default{
            span{
                width: 2.5rem;
                height: auto;
                background-color: #ffe200;
            }
        }
        .Changed{
            span{
                margin-left: 1rem;
                width: 2.5rem;
                height: auto;
                background-color: green;
            }
        }
    }

    .readonly-tabs {
        float: right;
        @include heading;
        font-size: 0.95rem;
        display: flex;
        flex-direction: row;
        background-color: #ffffff;
        border-radius: 2rem;
        width: max-content;
        border: 1px solid var(--primary);

        .tab {
            cursor: pointer;
            padding: 0.25rem 2rem;
        }

        .tab.active {
            background-color: var(--primary);
            color: var(--graybg);
            font-weight: 500;
            border-radius: 2rem;
        }
    }

    .main-btn {
        .MuiStack-root {
            margin-left: 0;
            margin-right: 0;
        }

        .valuesBtn {
            line-height: 24px;
            width: 163px;
            height: 50px;

            border-radius: 10px;
        }

        .draft {
            @include subHeadingDark;
            background: #fff;
            border-color: var(--primary);

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .save-draft {
            @include subHeadingDark;
            background: #fff;
            border: 1px solid var(--primary);
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                color: #006387;
                border: 1px solid #006387;
            }
        }

        .next {
            @include subHeadingLight;
            border: none;
            color: #fff;
            background-color: var(--primary);

            &:hover {
                background-color: #006387;
            }
        }
    }

    .non-rated-emps {
        margin: 2rem 0px;
        background-color: #ffffff;
        padding: 1rem;

        .title {
            @include heading;
        }

        .tablee {
            background-color: #ffffff;

            .feedback-emp-list-table {
                width: 100%;
                background-color: #ffffff;
                th,
                td {
                    padding: 0.5rem;
                }

                tr {
                    border-bottom: 1px solid #e2e2e2;
                }

                th {
                    font-size: 0.9rem;
                    color: var(--primary);
                    font-weight: 500;
                    background-color: #ffffff;

                }

                .table-head-emp-list {
                    height: 4rem;
                }

                .mid-cols {
                    width: 33%;
                    text-align: left;
                }

                .last-col {
                    width: 9rem;
                    text-align: start;

                }

                .last-col.head {
                    padding-right: 1.8rem;
                }

                td {
                    .empDetaila {
                        display: flex;

                        .empPic {
                            .userImg {
                                width: 45px;
                                height: 45px;
                                border-radius: 50%;
                            }
                        }

                        .userInfo {
                            margin-left: 1rem;

                            .name {
                                @include subHeadingDark;
                                font-size: 0.9rem
                            }

                            .designation {
                                @include smallText;
                            }
                        }

                    }

                    .btns {
                        font-size: 0.75rem;
                        text-align: left;

                    }

                    .btnss {
                        .send-btn {
                            font-size: 0.75rem;
                            background-color: var(--secondary);
                            color: var(--primary);
                            border: none;
                            padding: 5px 10px;
                            border-radius: 10px;
                        }
                    }

                    .feedback-table-status {
                        @include smallText;
                    }

                    .btns.green {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: #69C88E;
                        font-weight: 500;
                    }

                    .btns.grey {
                        color: var(--tertiary);
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        font-weight: 500;
                    }

                    .btns.yellow {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: #fdaa34;
                        font-weight: 500;
                    }

                    .action {
                        button {
                            font-family: 'poppins';
                            padding: 0.25rem;

                            svg {
                                color: var(--primary);
                                font-size: 1.25rem;
                            }
                        }

                    }

                    .status-contain {
                        display: flex;
                        align-items: center;

                        .checkIcon {
                            width: 1.25rem;
                            margin-right: 0.5rem;
                        }
                    }
                }
            }
        }
    }
    .fleader-approve-btn-outer{
        display: flex;
        justify-content: flex-end;
        .fleader-approve-btn{
            background-color: #69C88E;
            border: none;
            color: #ffffff;
            padding: 0.5rem 1rem;
            border-radius: 10px;
        }
    }
}

.rating-calibration-report-view .view-report-row {
    margin: 1rem 0rem;
    height: 90px;
    display: inline-flex;
    width: 100%;
    grid-gap: 0.75rem;
}

.rating-calibration-report-view .blockBlue {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #BAD7E9;
    border-radius: 10px;
    text-align: center;
    width: 20%;
}

.rating-calibration-report-view .block {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    width: 20%;
}

.rating-calibration-report-view .number {
    font-size: 1.1rem;
    color: var(--primary);
    font-weight: 500;

}

.rating-calibration-report-view .text {
    color: var(--tertiary);
    margin: 0px;
    padding: 0px;
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
}

.rating-calibration-report-view .outer-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;

    .loader-outer {
        display: flex;
        justify-content: center;
    }

    .second-div {
        display: flex;
        align-items: center;
        margin: 1rem;

        .filter-tab {
            @include subHeadingDark;
            position: relative;
            margin-left: 0.5rem;
            border: 1px solid var(--primary);
            border-radius: 10px;
            padding: 1.05rem 1.25rem;
            width: 10rem;
            cursor: pointer;

            img {
                margin-right: 0.5rem;
            }

            .red-dot {
                width: 12px;
                height: 12px;
                background-color: red;
                border-radius: 6px;
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }
    }

    .filters-block {
        border: 1px solid var(--tertiary);
        border-radius: 10px;
        margin: 1rem 0px;
        padding: 1rem;
        animation: openCard 500ms ease-out;

        .filters {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            animation: openCard1 500ms ease-out;

        }
    }
}

.rating-calibration-report-view .w-80 {
    width: 80%;
    color: #00425A;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.rating-calibration-report-view .w-20 {
    width: 20%;
    text-align: right;
}

.rating-calibration-report-view .w-100 {
    width: 100%;
    text-align: right;
}

.rating-calibration-report-view .tableDiv {
    display: table;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1%;
    position: relative;
}

.rating-calibration-report-view .table-heading .col {
    background: #BAD7E9;
    border-radius: 8px;
}

.rating-calibration-report-view .table-heading {
    font-weight: 500;
    display: table-row;
    text-align: center;
    line-height: 39px;
    font-size: 14px;
    color: var(--primary);
    position: sticky;
    top: -12px;
    z-index: 1;
}

.rating-calibration-report-view .table-row-full {
    display: table-row;
    text-align: center;
    font-size: 14px;
    line-height: 39px;
    font-weight: 500;

    .col {
        display: table-cell;
        border: 2px solid #fff;
        background-color: #F9F9F9;

        .inner-col {
            position: absolute;
            top: 0px;
            right: 0;
            left: 0;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            max-height: 12rem;
            overflow-y: auto;
        }

        .pre-post {
            display: flex;

            .col-pre {
                background-color: #F9F9F9;
                border-right: 1px solid #ffffff;
            }

            .col-post {
                background-color: #F9F9F9;
                border-left: 1px solid #ffffff;
            }
        }
    }
}

.rating-calibration-report-view .col {
    display: table-cell;
    border: 2px solid #fff;

    .pre-post {
        display: flex;

        .col-pre {
            background-color: #F1F0F6;
            border-right: 1px solid #ffffff;
        }

        .col-post {
            background-color: #FCEEED;
            border-left: 1px solid #ffffff;
        }
    }
}

.rating-calibration-report-view .tblData {
    background-color: #f9f9f9;
    border-radius: 8px;
}

.rating-calibration-report-view .tblData-dnd {
    position: relative;
    height: 12rem;
    border-radius: 8px;

    .dragable-emp {
        @include subHeadingDark;
        font-size: 0.83rem;
        padding: 0px 0.25rem;
        cursor: pointer;
        background-color: #f9f9f9;
        margin: 0.25rem;
        margin-bottom: 4px !important;
        border-radius: 0.25rem;
        border: 1px solid var(--secondary);
    }

    .dragable-emp.green-border {
        border-left: 5px solid green;
    }

    .dragable-emp.yellow-border {
        border-left: 5px solid #ffe200;
    }

    .reason-input {
        width: 10rem;
        padding: 0.5rem;
        margin: 0px 0.5rem;
        background-color: #ffffff;
        position: absolute;
        top: 0rem;
        left: 0;
        z-index: 1;
        border-radius: 10px;
        box-shadow: 0px 0px 6px 1px #a6a6a6;

        .heading {
            @include normalText;
            svg{
                position: absolute;
                top: 1rem;
                right: 5px;
                cursor: pointer;
                font-size: 1rem;
                background-color: #ffffff;
                &:hover{
                    background-color: rgb(239, 239, 239);
                }
            }
        }

        button {
            margin-top: 0.5rem;
            width: 100%;
            font-size: 1rem;
            background-color: var(--primary);
            color: #ffffff;
        }
    }
    svg{
        position: absolute;
        bottom: 5px;
        right: 5px;
        cursor: pointer;
        font-size: 1.2rem;
        background-color: #ffffff;
        &:hover{
            background-color: rgb(239, 239, 239);
        }
    }
}

.rating-calibration-report-view .tblData-dnd.active {
    padding: 0px 0.5rem;
    width: 20%;
    background-color: var(--secondary);
    transition: 0.25s;
}

.rating-calibration-report-view .tblData-dnd.expanded {
    height: 14rem;
    position: fixed;
    top: 58px;
    width: calc(100% - 360px);
    left: 334px;
    z-index: 1;
    border: 1px solid var(--primary);
    .expand-heading{
        background-color: #ffffff;
        @include subHeadingDark;
        padding: 0.25rem;
        text-decoration: underline;
    }
    .inner-col{
        background-color: var(--graybg);
        margin-top: 2.25rem;
        padding: 0px 1rem;
    }
    
}

.rating-calibration-report-view .bgn {
    background-color: #fff !important;
    text-align: left;
    width: auto;
}

.rating-calibration-report-view .next {
    font-size: var(--subHeaderFontSize);
    margin: 0px;
    padding: 0px;
    border: none;
    color: #fff;
    background: var(--primary);
}

.rating-calibration-report-view .valuesBtn {
    line-height: 24px;
    width: 163px;
    height: 50px;
    border-radius: 10px;
}

/* width */
.inner-col::-webkit-scrollbar {
    width: 0.32rem;
}

/* Track */
.inner-col::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.inner-col::-webkit-scrollbar-thumb {
    background: var(--secondary);
}

/* Handle on hover */
.inner-col::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
}