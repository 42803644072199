.rating-table {
  .table-header {
    @include heading;
    background-color: var(--primary);
    color: #ffffff;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    margin: 0;
    margin-top: 1rem;
    word-break: break-all;
    padding: 1rem;

  }
}