.tooltip-main{
    position: relative;
    .msg{
        z-index: 11;
        width: 25rem;
        left: 1rem;
        top: -1rem;
        position: absolute;
        padding: 0.25rem;
        border: 1px solid #000000;
        border-radius: 10px;
        background-color: gray;
        color: #ffffff;
        text-align: center;
        opacity: 0; /* Start as invisible */
        animation: fadeIntooltip 500ms forwards; /* Apply animation over 2 seconds */
    }
    .child-name{
        padding: 0.25rem;
        cursor: pointer;
        &:hover{
            background-color: rgb(220, 217, 217);
            border-radius: 4px;
        }
    }
}

@keyframes fadeIntooltip {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
