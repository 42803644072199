@import "../utils/utils.scss";

.compensation-chart {
    width: 100%;
    height: calc(100vh - 45px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;
    
    .chart-card {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px;
        
        .chart-title {
            color: #00425A;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            font-family: 'poppins';
            margin-bottom: 20px;
        }
    }

}