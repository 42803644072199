.sidebar-main-outer {
    display: flex;
    justify-content: space-between;

    .for-collapse {
        border: none;
        padding: 0;
        cursor: pointer;
        background-color: #025573;
        right: 0;
        top: 47%;
        position: absolute;
        height: 4rem;
        width: 1rem;
        display: flex;
        align-items: center;

        .collapsible-nav-bar {
            justify-content: flex-end;
            display: flex;

            .collapsible-nav-size {}

            svg {
                width: 1rem;
                height: 1rem;
                cursor: pointer;
                color: var(--secondary);
                transform: rotate(0deg);
                transition: transform .1s ease-in-out;
            }

            .close {
                position: absolute;
                right: 24px;
                top: 17px;
                width: 32px;
                height: 32px;
                opacity: 0.3;
            }

            .close:hover {
                opacity: 1;
            }

            .close:before,
            .close:after {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 25px;
                width: 2px;
                background-color: var(--secondary);
            }

            .close:before {
                transform: rotate(45deg);
            }

            .close:after {
                transform: rotate(-45deg);
            }

        }
    }
}

.sidebarMain {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    gap: 30px;
    padding: 0px 1rem;
    width: 100%;

    .headMiddle {
        margin-top: 3rem;
    }
}

.sidebar {
    transition: width 0.2s ease-in;
    background: var(--primary);
    height: 100vh;
    width: 324px;
    position: fixed;
    overflow: auto;

    section {
        margin: 0 auto;
        width: 100%;
    }

    .header {
        .sitelogo {
            margin-bottom: 1rem;

            img {
                width: 100%;
                height: 3.6rem;
                margin-left: 13px;
            }
        }

        .user-container {
            display: flex;
            justify-content: center;
            margin-bottom: 43px;

            .user-container-margin {
                display: flex;
                width: 100%;

                // flex-direction: column;
                // margin-left: 1rem;
                .icon-container {
                    .icon-size {
                        width: 45px;
                        height: 45px;

                        img {
                            width: 100%;
                            height: inherit;
                        }

                        .user {
                            border-radius: 50%;
                            width: 100%;
                            height: inherit;
                        }
                    }
                }

                .title-container {
                    margin-left: 0.5rem;

                    .align-title {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .upper-title {
                            color: #ffffff;
                            font-size: 1rem;
                            font-weight: 600;
                            word-break: break-all;
                        }

                        .lower-title {
                            color: var(--secondary);
                            font-size: 0.81rem;
                        }
                    }
                }
            }
        }
    }

    .middle {
        .routes {
            .route-item {
                font-size: 1rem;
                color: var(--secondary);
                cursor: pointer;

                svg {
                    width: 19px;
                    height: 19px;
                    color: #ffffff;
                    margin-right: 0.5rem;
                }

                .category {
                    padding: 0.5rem;
                    // padding-left: 1rem;
                    border-radius: 1.5rem;
                    display: flex;
                    align-items: center;

                    svg {
                        width: 0.9rem;
                        margin-left: 0.25rem;
                    }
                }

                .category.active {
                    background-color: #025573;
                }

                .sub-cat {
                    border-left: 1px solid var(--secondary);
                    padding: 0.375rem 0px;
                    padding-left: 0.75rem;
                    margin-left: 1.7rem;
                    color: #7493a6;
                }

                .sub-cat.active {
                    color: #ffffff;
                    padding-bottom: 0.25rem;
                    width: fit-content;
                }

            }

            .active-nav-route-button {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                .nav-button-radious {
                    transition: background-color 0.5s ease;
                    border-radius: 24px;
                    width: 100%;
                    background-color: var(--secondary);

                    align-items: center;
                    display: flex;
                    height: 36px;
                    cursor: pointer;
                    margin-top: 7px;

                    .icon-name-container {
                        display: flex;
                        margin-left: 10px;
                        margin-right: 10px;
                        align-items: center;

                        .icon-container {
                            svg {
                                width: 19px;
                                height: 19px;

                            }

                            path {
                                fill: var(--primary);
                            }
                        }

                        .route-name-container {
                            margin-left: 15px;
                        }
                    }
                }

                .subcategory-main-container {
                    margin-left: 43px;

                    .active-sub-item {
                        .itme-title {
                            color: var(--secondary);
                            cursor: pointer;
                            margin: 0.5rem 0px;
                        }
                    }

                    .inactive-sub-item {
                        .itme-title {
                            color: rgb(214, 214, 214);
                            cursor: pointer;
                            margin: 0.5rem 0px;
                        }
                    }
                }
            }

            .inactive-nav-route-button {
                display: flex;
                align-items: center;
                flex-direction: column;

                .nav-button-radious {
                    transition: background-color 0.5s ease;
                    border-radius: 24px;
                    width: 100%;
                    background: var(--primary);

                    align-items: center;
                    display: flex;
                    height: 36px;
                    cursor: pointer;
                    margin-top: 7px;

                    .icon-name-container {
                        display: flex;
                        margin-left: 10px;
                        margin-right: 10px;
                        align-items: center;

                        .icon-container {
                            svg {
                                width: 19px;
                                height: 19px;

                            }

                            path {
                                fill: var(--secondary);
                            }
                        }

                        .route-name-container {
                            margin-left: 15px;
                            color: var(--secondary);
                        }
                    }
                }

                .nav-button-radious:hover {
                    background-color: rgb(226, 228, 215);

                    .route-name-container {
                        color: var(--primary);
                    }

                    .icon-container {
                        path {
                            fill: var(--primary);
                        }
                    }

                    transition: background-color 0.5s ease;
                }

                .subcategory-main-container {
                    .active-sub-item {
                        .itme-title {
                            color: var(--secondary);
                            cursor: pointer;
                        }
                    }

                    .inactive-sub-item {
                        .itme-title {
                            color: var(--tertiary);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .footer {
        width: 100%;
        bottom: 0;
        padding: 0 1rem;

        .logout-size {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            margin-bottom: 2rem;

            .title {
                color: var(--secondary);
            }

            .logout-icon {
                background-color: var(--primary);
                border: none;
                cursor: pointer;

                svg {
                    width: 22px;
                }
            }
        }
    }

}

.sidebar-Collide {
    transition: width 0.2s ease-out;
    background: var(--primary);
    height: 100vh;
    width: 100px;
    position: fixed;
    overflow: auto;

    section {
        margin: 0 auto;
        width: 100%;
    }

    .header {
        .sitelogo {
            margin-bottom: 1rem;

            img {
                width: 100%;
                margin-left: 13px;
            }
        }

        .collapsible-nav-bar {
            margin: 28px 23px;
            justify-content: center;
            display: flex;

            svg {
                width: 1rem;
                height: 1rem;
                cursor: pointer;
                color: var(--secondary);
                transform: rotate(180deg);
                transition: transform .1s ease-in-out;
            }

        }

        .user-container {
            display: flex;
            justify-content: center;
            margin-bottom: 43px;

            .user-container-margin {
                display: flex;
                width: 100%;
                justify-content: flex-start;

                .icon-container {
                    .icon-size {
                        width: 70px;
                        height: 70px;

                        .user {
                            width: 100%;
                            height: inherit;
                            border-radius: 50%;
                        }
                    }
                }

                .title-container {
                    display: none;

                    .align-title {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .upper-title {
                            color: var(--secondary);
                            word-break: break-all;
                        }

                        .lower-title {
                            color: var(--secondary);
                        }
                    }
                }
            }
        }
    }

    .middle {
        .routes {

            .route-item {
                .category {
                    cursor: pointer;

                    span {
                        svg {
                            margin: 0.75rem auto;
                        }

                        &:nth-child(1) {
                            display: flex;
                            justify-content: center;
                        }

                        &:nth-child(2) {
                            display: none;
                        }
                    }
                }

                .category.active {
                    background-color: #025573;
                    border-radius: 2rem;

                    span {
                        svg {
                            margin: 0.75rem auto;
                        }
                    }
                }
            }

            .active-nav-route-button {
                display: flex;
                justify-content: center;

                .nav-button-radious {
                    justify-content: center;
                    transition: background-color 0.5s ease;
                    border-radius: 24px;
                    width: 100%;
                    background-color: var(--secondary);

                    align-items: center;
                    display: flex;
                    height: 36px;
                    cursor: pointer;
                    margin-top: 7px;

                    .icon-name-container {
                        display: flex;

                        .icon-container {
                            svg {
                                width: 19px;
                                height: 19px;

                            }

                            path {
                                fill: var(--primary);
                            }
                        }

                        .route-name-container {
                            display: none;
                            margin-left: 15px;
                        }
                    }
                }

                .nav-button-radious:hover {
                    background-color: rgb(226, 228, 215);
                    transition: background-color 0.5s ease;
                }
            }

            .inactive-nav-route-button {
                display: flex;
                justify-content: center;

                .nav-button-radious {
                    justify-content: center;
                    transition: background-color 0.5s ease;
                    border-radius: 24px;
                    width: 100%;
                    background: var(--primary);

                    align-items: center;
                    display: flex;
                    height: 36px;
                    cursor: pointer;
                    margin-top: 7px;

                    .icon-name-container {
                        display: flex;
                        margin-left: 0;

                        .icon-container {
                            svg {
                                width: 19px;
                                height: 19px;

                            }

                            path {
                                fill: var(--secondary);
                            }
                        }

                        .route-name-container {
                            display: none;
                            margin-left: 15px;
                            color: var(--secondary);
                        }
                    }
                }

                .nav-button-radious:hover {
                    background-color: rgb(226, 228, 215);

                    .route-name-container {
                        color: var(--primary);
                    }

                    .icon-container {
                        path {
                            fill: var(--primary);
                        }

                    }

                    transition: background-color 0.5s ease;
                }
            }
        }
    }

    .footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;

        .logout-size {
            display: flex;
            justify-content: center;
            width: 80%;
            margin: 0 auto;
            margin-bottom: 34px;

            .title {
                display: none;
                color: var(--secondary);
            }

            .logout-icon {
                background-color: var(--primary);
                border: none;
                cursor: pointer;

                svg {
                    width: 22px;
                }
            }
        }
    }

}

.sidebar-login-page {
    display: none;
}

.main {
    margin-left: 324px;
    transition: margin-left 0.2s ease-in;
}

.main-Collide {
    transition: margin-left 0.2s ease-out;
    margin-left: 100px;
}

.main-login-page {
    margin-left: 0;
}

/* width */
.sidebar::-webkit-scrollbar {
    width: 0.41rem;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
    background: #919191;
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
    background: #737373;
}

/* width */
.routes::-webkit-scrollbar {
    width: 0.41rem;
}

/* Track */
.routes::-webkit-scrollbar-track {
    background: var(--primary);
}

/* Handle */
.routes::-webkit-scrollbar-thumb {
    background: #2c5d74;
}

/* Handle on hover */
.routes::-webkit-scrollbar-thumb:hover {
    background: #737373;
}

@media screen and (max-width: 900px) {
    .sidebar {
        // display: none;
        z-index: 1000;
    }

    .main {
        margin-left: 0;
        /* Ensures main content fills the space */
    }
    .header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .back-arrow{
        //     width: 100%;
        // display: flex;
        // justify-content: flex-end;
        svg{
            font-size: 1.75rem;
            cursor: pointer;
            color: var(--secondary);
        }
    }}
}
.sidebar-collapse-icon{
    position: fixed;
    top: 0.5rem;
    left: 1rem;
    z-index: 1000;
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--primary);
    cursor: pointer;
}