@mixin heading($margin: 0px, $padding: 0px) {
    font-size: var(--headerFonrSize);
    color: var(--primary);
    margin: $margin;
    padding: $padding;
  }

@mixin subHeadingDark($margin: 0px, $padding: 0px) {
    font-size: var(--subHeaderFontSize);
    color: var(--primary);
    margin: $margin;
    padding: $padding;
  }

@mixin subHeadingLight($margin: 0px, $padding: 0px) {
    font-size: var(--subHeaderFontSize);
    color: var(--tertiary);
    margin: $margin;
    padding: $padding;
  }

@mixin normalText($margin: 0px, $padding: 0px) {
    font-size: var(--textFontSize);
    color: var(--tertiary);
    margin: $margin;
    padding: $padding;
  }

@mixin smallText($margin: 0px, $padding: 0px) {
    font-size: var(--smallTextFontSize);
    color: var(--tertiary);
    margin: $margin;
    padding: $padding;
  }

@mixin goalLabel() { // @include goalLabel; 
  font-size: var(--goal-label-font-size);
  color: var(--goal-label-color);
  font-weight: var(--goal-label-font-weight);
  font-family: var(--goal-label-font-family);
  line-height: var(--goal-label-line-height);
  opacity: var(--goal-label-opacity);
}

@mixin goalLabelView() { // @include goalLabel; 
  font-size: var(--goal-label-view-font-size);
  color: var(--goal-label-view-color);
  font-weight: var(--goal-label-view-font-weight);
  font-family: var(--goal-label-view-font-family);
  line-height: var(--goal-label-line-height);
  opacity: var(--goal-label-view-opacity);
}

@mixin goalInputView() { // @include goalLabel; 
  color: var(--goal-input-view-color);
  font-family: var(--goal-input-view-font-family);
  font-size: var(--goal-input-view-font-size);
  font-weight: var(--goal-input-view-font-weight);
  // line-height: var(--goal-input-line-height);
  // opacity: var(--goal-input-view-opacity);
}
  
.center-at-box {
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
}