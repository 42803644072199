@import '../utils/utils.scss';

.sidedrawer {
  z-index: 10;
  background: #ffffff;
  overflow-y: scroll;
  padding: 2rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 60%;
  animation: slideIn 500ms linear;

  .close-drawer-icon {
    float: right;
    cursor: pointer;
    &:hover{
      padding: 0px 2px;
      border-radius: 5px;
      background-color: #f0f0f0;
    }
  }

  border-left: 2px solid gray;
  box-shadow: 0px 0px 208px 10px;

  .drawer-children-main {
    margin: 1rem 0px;
    @include smallText;

    div {
      .scrl-div {
        position: absolute;
        width: 91%;
        height: 100%;
        padding-bottom: 5rem;

        .heading {
          @include subHeadingDark;
          font-size: 0.9rem;
          margin-top: 0.5rem;
          margin-bottom: 0.15rem;
        }

        .big-heading {
          @include subHeadingDark;
          font-size: 0.9rem;
          margin-top: 0.5rem;
          margin-bottom: 0.15rem;
        }

        .situatin {
          background-color: #d9ead3;
        }

        .behavior {
          background-color: #d9d2e9;
        }

        .impact {
          background-color: #f4cccc;
        }

        .color-info {
          display: flex;
          justify-content: space-evenly;

          p {
            display: flex;
            align-items: center;
            margin-bottom: 0;
          }

          .color-block {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 1rem;
          }

          .situatin {
            background-color: #d9ead3;
          }

          .behavior {
            background-color: #d9d2e9;
          }

          .impact {
            background-color: #f4cccc;
          }
        }
      }
    }

    .sidedrawer-peer-info {
      .title {
        @include heading;
        font-weight: 600;
        margin-bottom: 1.5rem;

        span {
          color: var(--tertiary);
        }
      }

      .description {
        @include subHeadingDark;
        margin-bottom: 1.5rem;
      }

      .peer-card {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 1rem;
        margin-bottom: 1rem;

        .emp-details {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.75rem;

          .empDetaila {
            display: flex;

            .empPic {
              .userImg {
                width: 30px;
                height: 30px;
                border-radius: 50%;
              }
            }

            .userInfo {
              margin-left: 1rem;

              .name {
                @include subHeadingDark;
                // font-size: 0.9rem
              }

              .designation {
                @include smallText;
              }
            }

          }

          .time {
            @include smallText;
          }
        }

        .emp-feedback {
          @include smallText;
          font-size: 0.87rem;
        }
      }
    }
    .peer-info-load-more-btn{
      @include subHeadingDark;
      float: right;
      padding: 0.75rem 2rem;
      border: 1px solid var(--primary);
      border-radius: 10px;
      background-color: #ffffff;
      &:hover{
        background-color: var(--graybg);
      }
      &:active{
        background-color: #ffffff;
      }
    }
  }

  .drawer-table {
    border: 1px solid gray;

    th {
      background-color: #dcdcdc;
      color: #000000;
      font-weight: 500;
    }

    tr,
    th,
    td {
      border: 1px solid gray;
      padding: 0.25rem;
    }
  }

  .send-feedback-req-drawer-main{
    .empDetaila{
      display: flex;
      .empPic{
          .userImg{
              width: 45px;
              height: 45px;
              border-radius: 50%;                                
          }
      }
      .userInfo{
          margin-left: 1rem;
          .name {
              @include subHeadingDark;
              font-size: 0.9rem
          }
          .designation{
              @include smallText;
          }
      }
      
  }
    .sun-editor{
      margin-top: 2rem;
    }
    .date-outer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
    }
  }

  .view-feedback-request{
    .empDetaila {
      display: flex;

      .empPic {
        .userImg {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }

      .userInfo {
        margin-left: 1rem;

        .name {
          @include subHeadingDark;
          // font-size: 0.9rem
        }

        .designation {
          @include smallText;
        }
      }

    }
    .msg{
      margin: 1rem 0px;
      padding: 1rem;
      background-color: var(--graybg);
      border-radius: 10px;
    }
  
  }
  .send-btn-div{
    display: flex;
    justify-content: flex-end;
  }
.selfAssesmentAllinone {
 .feedback-main {
    padding: 0px !important;
    background-color: #ffffff;
    .feedbackAssessment {
      padding: 0px;
    }
  } 
}
  
 
}

.d-sidedrawer {
  background: #ffffff;
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 50%;
  animation: slideOut 500ms linear;
}

.calibration-side-drawer{
  .sidedrawer{
    min-width: 55rem;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(400px);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translateX(0px);
    animation-timing-function: ease-in;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0px);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translateX(100%);
    animation-timing-function: ease-in;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* width */
.sidedrawer::-webkit-scrollbar {
  width: 0.32rem;
}

/* Track */
.sidedrawer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sidedrawer::-webkit-scrollbar-thumb {
  background: #c7c7c7;
}

/* Handle on hover */
.sidedrawer::-webkit-scrollbar-thumb:hover {
  background: #a9a9a9;
}