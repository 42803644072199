@import "../utils/utils.scss";

.my-community-team {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .heading-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .manage-rewards {
            color: #00425A;
            font-size: 24px;
            line-height: 24px;
            font-weight: 600;
            font-family: 'poppins';
        }

        .back-btn {
            height: 43px;
            border: 1px solid #979797;
            background-color: #FFFFFF;
            padding: 13px 20px;
            border-radius: 8px;
            color: #00425A;
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
            font-family: 'poppins';
            text-transform: capitalize;
            cursor: pointer;
        }
    }

    .tab-btn-List {
        display: flex;
        gap: 10px;
        margin-bottom: 5px;
        overflow-x: auto;
        white-space: nowrap;
        padding-bottom: 10px;
        scrollbar-width: none;
    }

    .active-btn {
        background-color: #BAD7E9;
        padding: 8px 16px;
        border-radius: 40px;
        border: none;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .inActive-btn {
        background-color: #FFFFFF;
        padding: 8px 16px;
        border-radius: 40px;
        border: none;
        color: #979797;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }


    .table-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding-top: 12px;
        padding-bottom: 20px;
        margin-bottom: 20px;

        .nominate-promo-table {
            overflow-x: auto;
            background-color: #ffffff;

            .table-head-emp-list th:first-child,
            .emp-list-table tr td:first-child {
                position: sticky;
                left: 0;
                background-color: white;
                z-index: 2;
            }

            .emp-list-table {
                width: 100%;
                background-color: #ffffff;

                th,
                td {
                    padding: 0.5rem;
                }

                tr {
                    border-bottom: 1px solid #e2e2e2;
                }

                th {
                    font-size: 0.9rem;
                    color: var(--primary);
                    font-weight: 500;
                    background-color: #ffffff;

                }

                .table-head-emp-list {
                    height: 3rem;
                }

                .w-155 {
                    width: 155px !important;
                }

                .w-230 {
                    width: 230px !important;
                }

                .ta-end {
                    text-align: end;
                }

                .pl-20 {
                    padding-left: 20px !important;
                }

                .pr-20 {
                    padding-right: 20px !important;
                }

                .mr-5 {
                    margin-right: 5px !important;
                }

                .emp-table-value {
                    color: #7E7E7E;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: 'poppins';
                }

                .view-full {
                    color: #004259;
                }

                .user-msg-grid {
                    width: fit-content;
                    background-color: #EDFAFF;
                    border-radius: 8px;
                    padding: 6px 10px;
                    display: flex;
                    align-items: center;
                    margin-top: 8px;
                    margin-bottom: 8px;

                    .user-msg-icon {
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        border: 1px solid #D4E9F6;
                    }

                    .user-msg {
                        color: #004259;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        font-family: 'poppins';
                        margin-left: 10px;
                    }
                }

                .btn-div {
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;

                    .reject-btn {
                        height: 30px;
                        border: 1px solid #DB3535;
                        padding: 6px 20px;
                        border-radius: 8px;
                        background-color: #FFFFFF;
                        color: #DB3535;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        font-family: 'poppins';
                        text-transform: capitalize;
                    }

                    .accept-btn {
                        height: 30px;
                        background-color: #004259;
                        padding: 6px 20px;
                        border-radius: 8px;
                        color: #FFFFFF;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        font-family: 'poppins';
                        text-transform: capitalize;
                    }

                    .see-reason-btn {
                        width: 90px;
                        height: 30px;
                        background-color: transparent;
                        padding: 6px 5px;
                        border-radius: 8px;
                        color: #004259;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        font-family: 'poppins';
                        text-transform: capitalize;
                    }

                    .rejected-btn {
                        height: 30px;
                        background-color: #DB3535;
                        padding: 6px 14px;
                        border-radius: 8px;
                        color: #FFFFFF;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        font-family: 'poppins';
                        text-transform: capitalize;
                    }

                    .accepted-btn {
                        height: 30px;
                        background-color: #00AA5A;
                        padding: 6px 14px;
                        border-radius: 8px;
                        color: #FFFFFF;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        font-family: 'poppins';
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    .d-flex {
        display: flex;
    }

    .jc-end {
        justify-content: flex-end;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .ml-10 {
        margin-left: 10px;
    }

    .left-right-arrow {
        height: 20px;
        width: 12px;
        align-self: center;
    }

    .pagination-border {
        height: 32px;
        width: 32px;
        border: 0.5px solid #979797;
        border-radius: 5px;
        margin-left: 10px;
        padding-top: 3px;
        text-align: center;
        align-content: center;

        .pagination-count {
            color: #004259;
            font-size: 16px;
            font-weight: 400;
        }
    }
}

@media only screen and (max-width: 900px) {
    .my-community-team {
        width: 100%;
        height: calc(100vh - 58px);
        padding: 1.5rem;
        background-color: var(--graybg);
        overflow-y: auto;

        .table-card {
            background-color: #ffffff;
            border-radius: 20px;
            padding-top: 12px;
            padding-bottom: 20px;
            margin-bottom: 20px;

            .nominate-promo-table {
                background-color: #ffffff;
                overflow-x: scroll;
                white-space: nowrap;
            }
        }

    }
}