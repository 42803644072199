@import '../utils/utils.scss';

.siteHeader{
    top: 0;
    width: calc(100% - 324px);
    @media screen and (max-width: 900px) {
        width: 100%;
    }
    position: fixed;
    z-index: 9;
    height: 3.6rem;
    background: #ffffff;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .sitelogo{
        margin-bottom: 1rem;
        img{
            width: 10rem;
            height: 3rem;
            margin-top: 11px;
            margin-right: 1rem;
            margin-left: 1rem;
        }
    }

    .laft-side-block{
        display: flex;
        align-items: center;
        svg{
            color: var(--primary);
            font-size: 1.5rem;
            margin-left: 1rem;
            cursor: pointer;
        }
        .header-title{
            @include heading;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-left: 1rem;
        }
    }

    .year-text{
        @include subHeadingDark;
        display: flex;
        align-items: center;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .iconsBlock{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 25px;
        img{
            width: 1.36rem;
            margin: auto 0.5rem;
            cursor: pointer;
        }
      
        .icon-container {
            margin: auto 0.5rem;
            .icon-size {
                width: 30px;
                overflow: hidden;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .icon-size-avatar {
                overflow: hidden;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .sb-avatar {
                width: auto !important;
                height: auto !important;
                .sb-avatar__text{
                    width: 1.7rem !important;
                    height: 1.7rem !important;
                }
            }
            img {
                width: 100%;
                height: inherit;
            }          
            svg {
                width: 19px;
                
            }
            path {
                fill: var(--primary);
            }
        }
    }
    .setting-dropdown-main-container {
        z-index: 99;
        pointer-events: auto;
        color: var(--secondary);
        position: absolute;
        top: 64px;
        right: 17px;
        background: var(--primary);
        border-radius: 20px;
        padding: 1em;
        .menu-list{
            .menu{
                white-space: nowrap;
                cursor: pointer;
            }
            .logout-icon{
                white-space: nowrap;
                cursor: pointer;
            }
        }

        .active-nav-route-button{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            .nav-button-radious{
                padding-right: 3rem;
                transition: background-color 0.5s ease;
                border-radius: 24px;
                width: 90%;
                background-color: var(--secondary);

                align-items: center;
                display: flex;
                height: 36px;
                cursor: pointer;
                margin-top: 7px;
                .icon-name-container{
                    display: flex;
                    margin-left: 20px;
                    align-items: center;
                    .icon-container {
                        svg {
                            width: 19px;
                            
                        }
                        path {
                            fill: var(--primary);
                        }
                    }
                    .route-name-container {
                        margin-left: 15px;
                        color: var(--primary);
                        text-wrap: nowrap;
                    }
                }
            }
        }

        .inactive-nav-route-button{
            display: flex;
            align-items: center;
            flex-direction: column;
            .nav-button-radious{
                padding-right: 3rem; // extra
                transition: background-color 0.5s ease;
                border-radius: 24px;
                width: 90%;
                background: var(--primary);

                align-items: center;
                display: flex;
                height: 36px;
                cursor: pointer;
                margin-top: 7px;
                .icon-name-container{
                    display: flex;
                    margin-left: 20px;
                    align-items: center;
                    .icon-container {
                        svg {
                            width: 19px;
                           
                        }
                        path {
                            fill: var(--secondary);
                        }
                    }
                    .route-name-container {
                        margin-left: 15px;
                        color: var(--secondary);
                        text-wrap: nowrap; // extra
                    }
                }
            }
            .nav-button-radious:hover{
                background-color: rgb(226, 228, 215);
                .route-name-container{
                    color: var(--primary);
                }                
                .icon-container {
                    path {
                        fill: var(--primary);
                    }
                }
                transition: background-color 0.5s ease;
            }
        }
    }
}

.form-row-popup-pass-change{
    width: 32rem;
    .popup-pass-change-text1{
        @include subHeadingDark;
        font-size: 0.9rem;
        text-align: center;
        font-weight: 500;
        margin-bottom: 0.7rem;
    }
    .popup-pass-change-text{
        @include subHeadingDark;
        font-size: 0.9rem;
        padding: 0px 1rem;
    }
    .user-change-password-page{
        .main-btn {
            .MuiStack-root {
                margin-left: 0;
                margin-right: 0;
            }
        
            .valuesBtn {
                line-height: 24px;
                height: 2.5rem;
                width: 100%;
            }
    
        
            .next {
                @include subHeadingLight;
                border: none;
                color: #fff;
                background: var(--primary);
            }
        }
    
        .accorDetails.personalInfo {
            background: #fff;
            border-radius: 20px;
            position: relative;
            .eyeIcon{
                position: absolute;
                right: 0.75rem;
                top: 32%;
                cursor: pointer;
                padding: 0px 0.25rem;
                border-radius: 1rem;
                &:hover{
                    background-color: var(--secondary);
                }
            }
            .accordian-title {
                color: #401C73;
                font-size: 16px;
                font-weight: 600;
                line-height: 36px;
                letter-spacing: 0em;
            }
        }
    
        .accordian-data {
            .d-flex {
                border-bottom: 1px solid #000;
                padding: 10px 0;
            }
            .title {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #7E7E7E;
                margin: 0;
            }
            .subTitle {
                margin: 0;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0em;
                color: #401C73;
            }
        }
    }
}