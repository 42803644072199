@import "../utils/utils.scss";

.my-career {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .user-profile-grid {
        display: flex;
        align-content: center;
        gap: 10px;

        .user-profile {
            height: 48px;
            width: 48px;
            border-radius: 50%;
            border: 1px solid #D9D9D9;
        }

        .user-name {
            color: #00425A;
            font-size: 18px;
            line-height: 28px;
            font-weight: 700;
            font-family: 'poppins';
        }

        .user-post {
            color: #979797;
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            font-family: 'poppins';
        }
    }


    .junior-to-senior-level-card {
        background-color: #ffffff;
        border-radius: 16px;
        padding: 20px;
        padding-left: 0px !important;
        margin-bottom: 20px;

        .junior-to-senior-level-grid {
            display: grid;
            grid-template-columns: 97% 3%;

            .top-bottom-arrow {
                width: 100%;
                height: 8px;
                object-fit: cover;
            }

            .right-top-arrow {
                width: 10px;
                height: 87%;
                object-fit: cover;
                margin-top: 45px;
                margin-left: 10px;
            }

            .top-txt {
                color: #00425A;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
                text-align: center;
            }
            .top-txt-vertical {
                color: #00425A;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
                text-align: center;
                writing-mode: vertical-rl; /* Or 'vertical-lr' */
                // text-orientation: upright; /* Keeps characters upright */
                transform: rotate(180deg);      /* Optional: Keep it upright */
            }

            .inner-junior-to-senior-level {
                background-color: var(--graybg);
                padding: 20px 10px;

                .card-level-grid {
                    display: grid;
                    grid-template-columns: 10% 10% 12% 12% 12% 12% 12% 12%;
                    gap: 8px;

                    .dashed-border-bg {
                        background-color: #BAD7E9;
                        border: 1px dashed #00425A;
                        padding: 4px 8px;
                        border-radius: 8px;
                    }

                    .solid-border-bg {
                        background-color: #FFFFFF;
                        border: 1px solid #BAD7E9;
                        padding: 4px 8px;
                        border-radius: 8px;
                    }

                    .card-txt {
                        color: #00425A;
                        font-size: 10px;
                        line-height: 16px;
                        font-weight: 400;
                        font-family: 'poppins';
                        height: 100%;
                    }

                    .arrow-ml-75 {
                        margin-left: 75px;
                    }

                    .ta-center {
                        text-align: center;
                    }
                }
            }

            .footer-level-grid {
                display: grid;
                grid-template-columns: 10% 10% 12% 12% 12% 12% 12% 12%;
                gap: 8px;
                padding: 0px 10px;

                .footer-txt {
                    color: #979797;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    font-family: 'poppins';
                }
            }
        }
    }
}