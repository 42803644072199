@import "../utils/utils.scss";

.emp-goals-form-page {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1rem 2rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .goals-form-page-container {
        background-color: var(--white);
        border-radius: 20px;

        .form-page-container-margin {
            margin: 35px auto;
            width: 95%;
            padding: 30px 0;
        }

        .valuesBtn {
            line-height: 24px;
            width: 163px;
            height: 50px;
            
            border-radius: 10px;
        }

        .save-draft {
            @include subHeadingLight;
            background: #fff;
            border-color: var(--primary);

            display: flex;
            justify-content: center;
            align-items: center;

            color: var(--primary);
            font-weight: 500;

            // font-family: Inter;
            // font-size: 14px;
            // font-weight: 500;
            // line-height: 16.94px;
            // text-align: left;

        }
    
        .next {
            @include subHeadingLight;
            border: none;
            color: #fff;
            background: var(--primary);
        }

        .form-goal-head{

            .goal-head{

                .head-title{
                    // font-family: Inter;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 21.78px;
                    text-align: left;

                    color: #272727;
                    
                }

                .head-delete{
                    // font-family: Inter;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    text-align: left;

                    color: #DB3535;

                    display: flex;
                    align-items: center;
                    margin-right: 0.2rem;
                    
                }
            }
        }

        @mixin field-row() {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;

            .text-field, .select-field, .date-field, .number-field {
                width: 100%;
            }

            .select-field {

                .selected-menu {
                    display: flex;
                    flex-wrap: wrap;

                    .menu-item-container {
                        display: flex;

                        border-radius: 6px;
                        padding: 4.5px 9px;
                        border: 1px solid;
                        border-color: rgba(0, 0, 0, 0.23);

                        margin-top: 0.7rem;
                        margin-right: 0.7rem;

                        margin-top: 0.7rem;
                        margin-right: 0.7rem;
                        align-items: center;


                        .title{
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                            text-align: left;

                        }

                        .delete{
                            margin-left: 0.5rem;
                        }
                    }
                }
            }

            .field-label{
                // font-family: Inter;
                // font-size: 12px;
                // font-weight: 400;
                // line-height: 14.52px;
                // text-align: left;
                // opacity: 70%;
                @include goalLabel;

                // width: 1221px;
                height: 15px;
                gap: 0px;


            }

            .row-half{
                width: 49%; 
            }
        }

        .form-goal-contant{
            margin-bottom: 2rem;

            .goal-contant{


                .field-row{
                    @include field-row()
                }
            }
        }
        
            .form-container-group {
                margin-top: 2rem;
            }

            .container-group-header {

                display: flex;
                justify-content: space-between;
                align-items: center;
                
                .group-header-title{
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.3799999952316284px;
                    text-align: left;

                    white-space: nowrap;
                    
                }
                .group-header-status{
                    border: 1px solid #F8DBDB;
                    border-radius: 8px;
                    margin-left: 18px;
                    padding: 2px 9px;
                    background: #FFF0F0;
                    text-align: center;
                    font-size: 13px;
                    // color: #00AA5A;
                }

                .group-header-divider-line{
                    border-top: 1px solid #D9D9D9;
                    width: -webkit-fill-available;
                    height: 0px;

                    margin: 0 12px;
                }

                .group-header-delete{

                    

                    display: flex;
                    align-items: center;
                    margin-right: 0.2rem;

                    white-space: nowrap;

                    .head-delete{
                        display: flex;
                        align-items: center;

                        .delete-icon{
                            color: #DB3535;
                        }
                        .title{
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16.94px;
                            text-align: left;
        
                            color: #DB3535;
                            text-transform: capitalize;
                        }
                    }
                }
            }


            .container-group-field{
                margin-top: 17px;
                display: flex;

                .group-field-line-left{
                    border-left: 1px solid #D9D9D9;
                    margin-top: 7px;
                }

                .group-field-margin{
                    margin-left: 20px;
                    width: 100%;

                    .form-group-contant{
                        .group-contant{

                            .field-row{
                                @include field-row()
                            }
                        }
                    }
                }
            }
            .container-group-add-more-container{
                margin-top: 1rem;
                border-bottom: 1px solid #D9D9D9;
                padding-bottom: 1rem;

                .title{
                    // font-family: Inter;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.36px;
                    text-align: left;

                    color: #004259;

                    text-transform: capitalize;

                }
            }
    }


    .form-goal-head {
        width: 100%;
        // display: grid;
        // grid-template-columns: 49.5% 1% 49.5%;
        // background-color: var(--white);
        // border-radius: 20px;
        margin-top: 15px;

        .goal-head {
            display: flex;
            justify-content: space-between;
        }

       
    }

    .field-error-message{
        p {
            font-size: 0.9rem;
            color: darkred;
            margin-left: 0.6em;
        }
    }

    .cha-left-indicator {
        // font-size: 0.9rem;
        // color: darkred;

        @include goalLabel;
    }
}


////////////// view mood ///////////////
.emp-goals-form-page-view {
    width: 100%;
    height: calc(100vh - 58px);
    // padding: 1rem 2rem; // comment for view mode
    // background-color: var(--graybg); // comment for view mode
    overflow-y: auto;

    .goals-form-page-container {
        background-color: var(--white);
        border-radius: 20px;

        .form-page-container-margin {
            margin: 35px auto;
            width: 95%;
            padding: 30px 0;
        }

        .valuesBtn {
            line-height: 24px;
            width: 163px;
            height: 50px;
            
            border-radius: 10px;
        }

        .save-draft {
            @include subHeadingLight;
            background: #fff;
            border-color: var(--primary);

            display: flex;
            justify-content: center;
            align-items: center;

            color: var(--primary);
            font-weight: 500;

            // font-family: Inter;
            // font-size: 14px;
            // font-weight: 500;
            // line-height: 16.94px;
            // text-align: left;

        }
    
        .next {
            @include subHeadingLight;
            border: none;
            color: #fff;
            background: var(--primary);
        }

        .form-goal-head{

            .goal-head{

                .head-title{
                    // font-family: Inter;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 21.78px;
                    text-align: left;

                    color: #272727;
                    
                }

                .head-delete{
                    // font-family: Inter;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    text-align: left;

                    color: #DB3535;

                    display: flex;
                    align-items: center;
                    margin-right: 0.2rem;
                    
                }
            }
        }

        @mixin field-row() {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;

            .text-field, .select-field, .date-field, .number-field {
                width: 100%;
            }

            .select-field {

                .selected-menu {
                    display: flex;
                    flex-wrap: wrap;

                    .menu-item-container {
                        display: flex;

                        border-radius: 6px;
                        padding: 4.5px 9px;
                        border: 1px solid;
                        border-color: rgba(0, 0, 0, 0.23);

                        margin-top: 0.7rem;
                        margin-right: 0.7rem;

                        margin-top: 0.7rem;
                        margin-right: 0.7rem;
                        align-items: center;


                        .title{
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                            text-align: left;

                        }

                        .delete{
                            margin-left: 0.5rem;
                        }
                    }
                }
            }

            .field-label{
                // font-family: Inter;
                // font-size: 12px;
                // font-weight: 400;
                // line-height: 14.52px;
                // text-align: left;
                // opacity: 70%;
                @include goalLabelView;

                // width: 1221px;
                height: 15px;
                gap: 0px;


            }

            .row-half{
                width: 49%; 
            }
        }

        .form-goal-contant{
            margin-bottom: 2rem;

            .goal-contant{


                .field-row{
                    @include field-row()
                }
            }
        }
        
            .form-container-group {
                margin-top: 2rem;
            }

            .container-group-header {

                display: flex;
                justify-content: space-between;
                align-items: center;
                
                .group-header-title{
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.3799999952316284px;
                    text-align: left;

                    white-space: nowrap;
                    
                }

                .group-header-status{
                    border: 1px solid #F8DBDB;
                    border-radius: 8px;
                    margin-left: 18px;
                    padding: 2px 9px;
                    background: #FFF0F0;
                    text-align: center;
                    font-size: 13px;
                    // color: #00AA5A;
                }

                .group-header-divider-line{
                    border-top: 1px solid #D9D9D9;
                    width: -webkit-fill-available;
                    height: 0px;

                    margin: 0 12px;
                }

                .group-header-delete{

                    

                    display: flex;
                    align-items: center;
                    margin-right: 0.2rem;

                    white-space: nowrap;

                    .head-delete{
                        display: flex;
                        align-items: center;

                        .delete-icon{
                            color: #DB3535;
                        }
                        .title{
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16.94px;
                            text-align: left;
        
                            color: #DB3535;
                            text-transform: capitalize;
                        }
                    }
                }
            }


            .container-group-field{
                margin-top: 17px;
                display: flex;

                .group-field-line-left{
                    border-left: 1px solid #D9D9D9;
                    margin-top: 7px;
                }

                .group-field-margin{
                    margin-left: 20px;
                    width: 100%;

                    .form-group-contant{
                        .group-contant{

                            .field-row{
                                @include field-row()
                            }
                        }
                    }
                }
            }
            .container-group-add-more-container{
                margin-top: 1rem;
                border-bottom: 1px solid #D9D9D9;
                padding-bottom: 1rem;

                .title{
                    // font-family: Inter;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.36px;
                    text-align: left;

                    color: #004259;

                    text-transform: capitalize;

                }
            }
    }


    .form-goal-head {
        width: 100%;
        // display: grid;
        // grid-template-columns: 49.5% 1% 49.5%;
        // background-color: var(--white);
        // border-radius: 20px;
        margin-top: 15px;

        .goal-head {
            display: flex;
            justify-content: space-between;
        }

       
    }

    .field-error-message{
        p {
            font-size: 0.9rem;
            color: darkred;
            margin-left: 0.6em;
        }
    }

    .number-field {
        pointer-events: none !important;

        .MuiInputBase-root{
            padding: 0;
        }

        fieldset{
            border: none;
            margin: 0;
            padding: 0;
        }

        input {
            padding: 0;
            // color: #979797;
            @include goalInputView;
            pointer-events: none !important;
        }

        textarea{
            // color: #979797;
            @include goalInputView;
            pointer-events: none !important;
        }
    }

    .text-field{
        pointer-events: none !important;

        .MuiInputBase-root{
            padding: 0;
        }

        fieldset{
            border: none;
            margin: 0;
            padding: 0;
        }

        input {
            padding: 0;
            // color: #979797;
            @include goalInputView;
            pointer-events: none !important;
        }

        textarea{
            // color: #979797;
            @include goalInputView;
            pointer-events: none !important;
        }


    }

    .date-field {
        pointer-events: none !important;
        fieldset {
            border: none;
            pointer-events: none !important;

        }

        input{
            padding-left: 0 !important;
            // color: #979797;
            @include goalInputView;
            pointer-events: none !important;
        }

        svg {
            display: none;
        }

        button{
            display: none;
            pointer-events: none !important;
        }
    }

    .select-field{
        pointer-events: none !important;
        fieldset {
            border: none;
            pointer-events: none !important;

        }

        input{
            padding-left: 0 !important;
            // color: #979797;
            @include goalInputView;
            pointer-events: none !important;

        }

        svg {
            display: none;
        }

        button{
            display: none;
            pointer-events: none !important;
        }

        .MuiSelect-select{
            padding: 0 !important;
        }

        .MuiInputBase-root{
            // color: #979797;
            @include goalInputView
        }
    }

    .field-label {

        // font-family: Poppins !important;
        // font-size: 14px !important;
        // font-weight: 400 !important;
        // color: #00425A !important;
        // opacity: 100% !important;

        @include goalLabelView;
    }

}


.upload-document {

    label {
        background: none;
        border: none;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.36px;
        text-align: left;
        color: #004259;
    }

    .left-position {
        width: 100%;
        background: rgba(217, 217, 217, 0.2); /* Background with 20% opacity */
        border-radius: 8px;
        padding: 14px 18px;
    }

    .round-box {
        background: white;
        border-radius: 50%;
    }

    .icon-container {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        color: #004259;
        margin-left: 0.5rem;
        /* Full opacity */
        cursor: pointer;
    }

    .delete {

        .left-gap {
            width: 25px;
        }

        .title {
            margin-left: 0.5rem;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            color: #DB3535;
            /* Full opacity */

            cursor: pointer;
        }
    }

    .doc-distibute{
        .doc-row{
            margin: 11px 0px;
        }
    }
}
