@import "../utils/utils.scss";

.my-community {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .tab-btn-List {
        display: flex;
        gap: 10px;
        margin-bottom: 5px;
        overflow-x: auto;
        white-space: nowrap;
        padding-bottom: 10px;
        scrollbar-width: none;
    }

    // .tab-btn-List::-webkit-scrollbar {
    //     display: none;
    // }

    .date-btn {
        display: none;
    }

    .active-btn {
        background-color: #BAD7E9;
        padding: 8px 16px;
        border-radius: 40px;
        border: none;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .inActive-btn {
        background-color: #FFFFFF;
        padding: 8px 16px;
        border-radius: 40px;
        border: none;
        color: #979797;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .my-engagement-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px 24px;
        margin-bottom: 20px;

        .user-profile {
            height: 48px;
            width: 48px;
            border-radius: 50%;
            border: 1px solid #D9D9D9;
        }

        .user-name {
            color: #004259;
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
            font-family: 'poppins';
            margin-left: 15px;
        }

        .user-post {
            color: #7E7E7E;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            font-family: 'poppins';
            margin-left: 15px;
        }

        .user-date {
            color: #979797;
            font-size: 14px;
            line-height: 27px;
            font-weight: 400;
            font-family: 'poppins';
            text-align: end;
        }

        .horizontal-border {
            border: 1px solid #D9D9D9;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .banner-title {
            color: #272727;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'poppins';
        }

        .banner-user {
            color: #004259;
        }

        .banner-img {
            width: 100%;
            height: 240px;
            background-color: #E4F6FF;
            border-radius: 14px;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .link-count {
            color: #979797;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            font-family: 'poppins';
        }

        .c-pointer {
            cursor: pointer;
        }

        .like-share-icon {
            width: 18px;
            height: 18px;
        }

        .like-share-lbl {
            color: #979797;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            font-family: 'poppins';
            margin-left: 5px;
        }

        .blue-drak {
            color: #025573;
        }

        .comment-grid {
            align-items: center;
            margin-top: 10px;
        }
    }

    .kudos-rewards-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 20px;

        .kudos-rewards-container {
            overflow-x: auto;
            scrollbar-width: none;
            /* Firefox */
        }

        .kudos-rewards-container::-webkit-scrollbar {
            height: 6px;
            display: none;
            /* Chrome, Safari, Edge, and Opera */
        }

        .kudos-rewards-container::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 10px;
        }

        .kudos-rewards-container::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
        }

        .kudos-rewards-scroll {
            flex-wrap: nowrap;
            gap: 16px;

            .kudos-rewards-item-div {
                cursor: pointer;
            }
        }

        .kudos-rewards-item {
            height: 142px;
            width: 148px;
            min-width: 145px;
            // background-color: #E9FBF2;
            box-shadow: 0px 0px 10px 0px #0000001A;
            border-radius: 14px;
            text-align: center;
            padding: 10px;
            padding-top: 15px;

            .kudos-rewards-icon {
                height: 76px;
                width: 76px;
                border-radius: 50%;
                border: 1px solid #D4E9F6;
            }

            .kudos-rewards-title {
                color: #272727;
                font-size: 12px;
                line-height: 18px;
                font-weight: 500;
                font-family: 'poppins';
                margin-top: 2px;
            }
        }
    }

    .manage-rewards-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px 24px;
        margin-bottom: 20px;

        .heading-view-all {
            display: flex;
            justify-content: space-between;

            .manage-rewards {
                color: #004259;
                font-size: 18px;
                line-height: 27px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .view-all-btn {
                border: none;
                background-color: #ffffff;
                color: var(--primary);
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                font-family: 'poppins';
                cursor: pointer;
            }
        }

        .manage-rewards-container {
            display: grid;
            grid-template-columns: 6% 94%;
            margin-top: 15px;
        }

        .rewards-mt-5 {
            margin-top: 5px !important;
        }

        .dot-circle {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: #BDD7EA;
        }

        .user-name {
            color: #004259;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            font-family: 'poppins';
        }

        .nominated-for {
            color: #979797;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            font-family: 'poppins';
            margin-left: 5px !important;
            margin-right: 5px !important;
        }


        .rewards-description {
            color: #7E7E7E;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            font-family: 'poppins';
            margin-bottom: 10px;
        }

        .reject-accept-btn-grid {
            display: flex;
            gap: 10px;
            margin-bottom: 15px;

            .reject-btn {
                height: 30px;
                border: 1px solid #DB3535;
                padding: 6px 20px;
                border-radius: 8px;
                background-color: #FFFFFF;
                color: #DB3535;
                font-size: 12px;
                line-height: 18px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .accept-btn {
                height: 30px;
                background-color: #004259;
                padding: 6px 20px;
                border-radius: 8px;
                color: #FFFFFF;
                font-size: 12px;
                line-height: 18px;
                font-weight: 500;
                font-family: 'poppins';
            }
        }

        .view-full {
            color: #004259;
            cursor: pointer;
        }

        .user-msg-grid {
            width: fit-content;
            background-color: #EDFAFF;
            border-radius: 8px;
            padding: 6px 10px;
            display: flex;
            align-items: center;
            margin-top: 8px;
            margin-bottom: 8px;

            .user-msg-icon {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                border: 1px solid #D4E9F6;
            }

            .user-msg {
                color: #004259;
                font-size: 12px;
                line-height: 18px;
                font-weight: 500;
                font-family: 'poppins';
                margin-left: 10px;
            }
        }

        .item-bottom-border {
            width: 100%;
            height: 1px;
            background-color: #D9D9D9;
        }
    }

    .calender-events-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px 24px;
        margin-bottom: 20px;

        .w-100 {
            width: 100% !important;
        }

        .calendar-events {
            color: #004259;
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
            font-family: 'poppins';
        }

        .date-container {
            overflow-x: auto;
            margin-top: 20px;
            scrollbar-width: none;
            /* Firefox */
        }

        .date-container::-webkit-scrollbar {
            height: 6px;
            display: none;
            /* Chrome, Safari, Edge, and Opera */
        }

        .date-container::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 10px;
        }

        .date-container::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
        }

        .date-scroll {
            flex-wrap: nowrap;
            gap: 10px;
        }

        .date-item {
            border: 1px solid #D9D9D9;
            padding: 12px 13px;
            border-radius: 8px;

            .date-title {
                color: #00425A;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                font-family: 'poppins';
                text-align: center;
            }

            .day-title {
                color: #7E7E7E;
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                font-family: 'poppins';
                text-align: center;
                margin-top: 5px;
            }
        }

        .events-label {
            color: #00425A;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            font-family: 'poppins';
            margin-top: 24px;
            margin-bottom: 18px;
        }

        .events-container {
            display: grid;
            grid-template-columns: 7% 93%;
            margin-bottom: 20px;
        }

        .event-mt-5 {
            margin-top: 5px;
        }

        .event-mt-10 {
            margin-top: 10px;
        }

        .dot-circle {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: #BDD7EA;
        }

        .vertical-border {
            width: 1px;
            height: 100%;
            background-color: #000000;
            opacity: 10%;
            margin-top: 5px;
            margin-left: 5px;
        }

        .date-month {
            color: #979797;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            font-family: 'poppins';
        }

        .birthday-grid {
            display: flex;
            flex-wrap: wrap;
        }

        .birthday-anniversary {
            cursor: pointer;
            color: #004259;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'poppins';
            margin-right: 10px;
        }

        .d-flex {
            display: flex !important;
        }

        .event-user-profile {
            height: 26px;
            width: 26px;
            border: 1px solid #D9D9D9;
            border-radius: 50%;
            margin-right: 10px;
        }
    }

    .my-accomplishment-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px 24px;
        margin-bottom: 20px;

        .my-accomplishments {
            color: #004259;
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
            font-family: 'poppins';
        }

        .awards {
            color: #212529;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            font-family: 'poppins';
            margin-left: 10px;
        }

        .my-accom-container {
            overflow-x: auto;
            margin-top: 20px;
            scrollbar-width: none;
            /* Firefox */
        }

        .my-accom-container::-webkit-scrollbar {
            height: 6px;
            display: none;
            /* Chrome, Safari, Edge, and Opera */
        }

        .my-accom-container::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 10px;
        }

        .my-accom-container::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
        }

        .my-accom-scroll {
            flex-wrap: nowrap;
            gap: 10px;
        }

        .my-accom-item {
            height: 142px;
            width: 148px;
            min-width: 145px;
            background-color: #D8E9F3;
            box-shadow: 0px 0px 10px 0px #0000001A;
            background: linear-gradient(206.87deg, rgba(186, 215, 233, 0.2) 0%, rgba(45, 199, 255, 0.2) 105.71%);
            border: 1px solid #D8E9F3;
            border-radius: 14px;
            text-align: center;
            padding: 10px;
            padding-top: 15px;

            .my-accom-icon {
                height: 56px;
                width: 56px;
                border-radius: 50%;
                border: 1px solid #D4E9F6;
            }

            .my-accom-border {
                width: 26px;
                height: 1px;
                border: 1px solid #00425A;
                margin: 5px auto;
            }

            .my-accom-title {
                color: #272727;
                font-size: 12px;
                line-height: 18px;
                font-weight: 500;
                font-family: 'poppins';
                margin-top: 2px;
            }
        }

        .my-accom-slider-icon {
            height: 8px;
            width: 67px;
            margin: 15px auto;
            margin-bottom: 0px;
            display: flex;
            align-items: center;
        }
    }

    .survey-puzzle-container {
        display: grid;
        grid-template-columns: 48% 48%;
        gap: 18px;

        .survey-item {
            background-color: #F4EFC3;
            border-radius: 20px;
            padding: 15px;
            display: flex;
        }

        .puzzle-item {
            background-color: #CFF6E4;
            border-radius: 20px;
            padding: 15px;
            display: flex;
        }

        .survey-puzzle-icon {
            height: 62px;
            width: 62px;
            background-color: #FFFFFF;
            border-radius: 50%;
            border: 1px solid #D4E9F6;
            margin-right: 10px;
        }

        .right-arrow-icon {
            height: 16px;
            width: 16px;
            margin-left: 5px;
        }

        .title {
            color: #00425A;
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
            font-family: 'poppins';
        }

        .description {
            color: #7E7E7E;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            font-family: 'poppins';
        }
    }
    .back-arrow{
        svg{
            margin-top: 1rem;
            margin-left: 1.5rem;
            font-size: 1.25rem;
            color: var(--primary);
            cursor: pointer;
        }
    }
}

//----- Modal Popup -----//
.kudos-for-modal {
    .model-title {
        color: #00425A;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        font-family: 'poppins';
        margin-bottom: 15px;

        .light-text {
            color: #979797;
        }
    }

    .reason-for-nomination {
        color: #00425A;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        font-family: 'poppins';
        margin-top: 20px;
        margin-bottom: 5px;
    }

    .mt-16 {
        margin-top: 16px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .nominated-for {
        color: #979797;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 15px;
    }

    .image-section {
        background-color: #fff;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 20px;

        .main-b .org-update-row {
            display: flex;
            margin-top: 0rem;
        }

        .main-b .org-update-row .loginBg {
            width: 76px;
            height: 76px;
            border: 4px solid #D4E9F6;
            border-radius: 50%;
        }

        .main-b .org-update-row .update-info {
            margin-left: 1rem;
        }

        .main-b .org-update-row .update-info .head {
            font-size: 16px;
            color: #004259;
            font-weight: 500;
            line-height: 24px;
            font-family: 'poppins';
            margin: 0px;
            padding: 0px;
        }

        .main-b .org-update-row .update-info .description {
            font-size: 16px;
            color: #7E7E7E;
            font-weight: 400;
            line-height: 24px;
            font-family: 'poppins';
            margin: 0px;
            padding: 0px;
        }

        .up-arrow {
            width: 20px;
            height: 20px;
            margin: 10px 0rem;
        }
    }

    .upload-doc-button {
        background-color: transparent;
        padding: 10px;
        width: 100%;
        border-style: dashed;
        border-radius: 14px;
        border-color: #979797;
        height: 176px;
        margin-bottom: 20px;
        background-color: #FFFFFF;
    }

    .whiteBoxbluetext {
        background-color: #fff;
        padding: 12px;
        border-radius: 8px;
        color: #004259;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .uploadmessage {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: #C1C1C1;
        font-family: 'poppins';
    }

    .edit-button {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #004259;
        font-family: 'poppins';
        cursor: pointer;
        margin-right: 5px;
    }

    .edit-button span {
        color: rgba(126, 126, 126, 1);
    }

    .search-bar {
        display: flex;
        align-items: center;
        border: 1px solid #00425A;
        border-radius: 10px;
        padding: 5px;
        width: 100%;
        background-color: #F7FBFD;
        position: relative;
    }

    path {
        color: #979797;
    }

    label {
        font-size: 12px;
        display: inline-block;
        line-height: 18px;
        font-weight: 400;
        color: #272727;
        font-family: 'poppins';
        margin-bottom: 5px;
    }

    .search-input {
        border: none;
        outline: none;
        padding: 8px 42px;
        flex-grow: 1;
        background-color: transparent;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #004259;
    }

    .search-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 5px;
        position: absolute;
        left: 12px;
    }

    .d-flex {
        display: flex !important;
    }

    .ai-center {
        align-items: center;
        border: 1px solid #BDD7EA;
        width: fit-content;
        padding: 3px 14px;
        margin-top: 10px;
        border-radius: 8px;
    }

    .ai-center p {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #00425A;
        font-family: 'poppins';
        margin-right: 12px;
    }

    .search-btn .search-icon {
        font-size: 20px;
        color: #979797;
    }

    .attchmentLine {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #00425A;
        float: right;
        margin: 10px 0px 0px;
        width: 100%;
        text-align: right;
    }

    .upload-send-txt {
        color: #00425A;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        margin: 10px 0px 0px;
        margin-bottom: 10px;
        width: 100%;
    }

    .greeting-card {
        .greeting-heading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .greeting-text {
                color: #00425A;
                font-size: 20px;
                line-height: 24px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .right-mt-20 {
                margin-top: 20px;
            }
        }

        .greeting-tab-btn-List {
            display: flex;
            gap: 10px;
            margin-bottom: 20px;

            // overflow-x: auto;
            // white-space: nowrap;
            // scrollbar-width: thin;
            // -ms-overflow-style: none;
        }

        .active-btn {
            background-color: #BAD7E9;
            padding: 8px 16px;
            border-radius: 40px;
            border: none;
            color: #00425A;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'poppins';
            text-decoration: none;
            cursor: pointer;
            white-space: nowrap;
        }

        .inActive-btn {
            background-color: #FFFFFF;
            padding: 8px 16px;
            border-radius: 40px;
            border: none;
            color: #979797;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'poppins';
            text-decoration: none;
            cursor: pointer;
            white-space: nowrap;
        }

        .greeting-container {
            overflow-x: auto;
            // scrollbar-width: none;
            margin-bottom: 10px;
            /* Firefox */
        }

        .greeting-container::-webkit-scrollbar {
            height: 6px;
            // display: none;
            /* Chrome, Safari, Edge, and Opera */
        }

        .greeting-container::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 10px;
        }

        .greeting-container::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
        }

        .greeting-scroll {
            flex-wrap: nowrap;
            gap: 10px;
            margin-bottom: 10px;
        }

        .greeting-item {
            height: 211px;
            width: 190px;
            min-width: 190px;

            .greeting-image {
                height: 211px;
                width: 190px;
                border-radius: 8px;
                border: 1px solid #D4E9F6;
            }
        }

    }

    .botttombtn {
        text-align: center;
        margin: 30px;
        margin-bottom: 15px;
    }

    .cancel-btn {
        width: 100px;
        height: 47px;
        text-transform: capitalize;
        cursor: pointer;
        background-color: transparent;
        border: 1px solid #004259;
        color: #004259;
        font-size: 14px;
        font-weight: 500;
        font-family: 'poppins';
        line-height: 24px;
        border-radius: 8px;
    }

    .update-btn {
        width: 100px;
        height: 47px;
        text-transform: capitalize;
        background-color: #004259;
        border: 1px solid #004259;
        cursor: pointer;
        margin-left: 10px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 500;
        font-family: 'poppins';
        line-height: 24px;
        border-radius: 8px;
    }
}

.certificate-modal {

    .model-title {
        color: #00425A;
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        font-family: "poppins";
        align-content: center;
    }

    .model-close-grid {
        display: flex;
        justify-content: flex-end;

        .role-close-icon {
            height: 20px;
            width: 20px;
            cursor: pointer;
        }
    }

    .model-title-grid {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 15px;

        .download-btn {
            background-color: #00425A;
            border-radius: 10px;
            padding: 8px 16px;
            color: #F9F9F9;
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            font-family: "poppins";
        }
    }


    .certificate-icon {
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 900px) {
    .my-community {
        width: 100%;
        height: calc(100vh - 58px);
        padding: 16px;
        background-color: var(--graybg);
        overflow-y: auto;

        .active-btn {
            padding: 6px 12px;
            font-size: 12px;
        }

        .inActive-btn {
            padding: 6px 12px;
            font-size: 12px;
        }

        .ac-center {
            align-content: center;
        }

        .pt-0 {
            padding-top: 0px;
        }

        .date-btn {
            position: fixed;
            top: 75px;
            right: 0px;
            z-index: 1000;
            background-color: #00425A;
            border: none;
            padding: 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            cursor: pointer;
            display: block;

            .date-icon {
                width: 24px;
                height: 24px;
            }
        }

        .my-engagement-card {
            background-color: #ffffff;
            border-radius: 15px;
            padding: 10px 12px;
            margin-bottom: 20px;

            .user-profile {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                border: 1px solid #D9D9D9;
            }

            .user-name {
                font-size: 14px;
                line-height: 21px;
                margin-left: 0px;
            }

            .user-post {
                font-size: 12px;
                line-height: 18px;
                margin-left: 0px;
            }

            .user-date {
                font-size: 12px;
                line-height: 16px;
                margin-top: 4px;
            }

            .banner-title {
                font-size: 14px;
                line-height: 18px;
            }

            .banner-img {
                width: 100%;
                height: auto;
            }
        }

        .survey-puzzle-container {

            .survey-puzzle-icon {
                height: 40px;
                width: 40px;
                background-color: #FFFFFF;
                border-radius: 50%;
                border: 1px solid #D4E9F6;
                margin-right: 10px;
            }

            .title {
                color: #00425A;
                font-size: 15px;
                line-height: 20px;
                font-weight: 500;
                font-family: "poppins";
            }

            .description {
                color: #7E7E7E;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                font-family: "poppins";
            }
        }
    }

    .kudos-for-modal {
        .model-title {
            font-size: 18px;
        }

        .image-section {
            padding: 15px;

            .main-b .org-update-row .loginBg {
                width: 45px;
                height: 45px;
                border: 2px solid #D4E9F6;
                border-radius: 50%;
            }

            .main-b .org-update-row .update-info .head {
                font-size: 14px;
                line-height: 18px;
            }

            .main-b .org-update-row .update-info .description {
                font-size: 12px;
                line-height: 15px;
            }

            .up-arrow {
                width: 18px;
                height: 15px;
                margin: 5px 0rem;
            }

            .main-b .org-update-row .update-info {
                margin-left: 0.5rem;
            }
        }

        .greeting-card {
            .greeting-heading {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;

                .greeting-text {
                    color: #00425A;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 500;
                    font-family: 'poppins';
                }

                .right-mt-20 {
                    margin-top: 20px;
                }
            }

            .greeting-tab-btn-List {
                display: flex;
                gap: 10px;
                margin-bottom: 20px;
                overflow-x: auto;
                white-space: nowrap;
                scrollbar-width: thin;
                -ms-overflow-style: none;
            }

            .active-btn {
                background-color: #BAD7E9;
                padding: 8px 16px;
                border-radius: 40px;
                border: none;
                color: #00425A;
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                font-family: 'poppins';
                text-decoration: none;
                cursor: pointer;
                white-space: nowrap;
            }

            .inActive-btn {
                background-color: #FFFFFF;
                padding: 8px 16px;
                border-radius: 40px;
                border: none;
                color: #979797;
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                font-family: 'poppins';
                text-decoration: none;
                cursor: pointer;
                white-space: nowrap;
            }

            .greeting-container {
                overflow-x: auto;
                // scrollbar-width: none;
                margin-bottom: 10px;
                /* Firefox */
            }

            .greeting-container::-webkit-scrollbar {
                height: 6px;
                // display: none;
                /* Chrome, Safari, Edge, and Opera */
            }

            .greeting-container::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.3);
                border-radius: 10px;
            }

            .greeting-container::-webkit-scrollbar-track {
                background-color: rgba(0, 0, 0, 0.1);
            }

            .greeting-scroll {
                flex-wrap: nowrap;
                gap: 10px;
                margin-bottom: 10px;
            }

            .greeting-item {
                height: 142px;
                width: 128px;
                min-width: 128px;

                .greeting-image {
                    height: 142px;
                    width: 128px;
                    border-radius: 8px;
                    border: 1px solid #D4E9F6;
                }
            }

        }

        .upload-send-txt {
            color: #00425A;
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            margin: 0px;
            margin-bottom: 10px;
            width: 100%;
        }

        .jc-center {
            justify-content: center !important;
        }

        .botttombtn {
            text-align: center;
            margin: 30px;
            margin-bottom: 0px;
            display: flex;
        }
    }

    .certificate-modal {

        .model-title {
            font-size: 18px;
        }

        .model-close-grid {
            display: flex;
            justify-content: space-between;

            .role-close-icon {
                height: 20px;
                width: 20px;
                cursor: pointer;
            }
        }

        .jc-center-mb {
            justify-content: center !important;
            margin-bottom: 0px !important;
        }

        .model-title-grid {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            margin-bottom: 15px;

            .download-btn {
                background-color: #00425A;
                border-radius: 10px;
                padding: 8px 16px;
                color: #F9F9F9;
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                font-family: "poppins";
            }
        }


        .certificate-icon {
            width: 100%;
            height: auto;
            margin-top: 10px !important;
        }
    }
}