@import '../utils/utils.scss';

.feedback-request-one{
    height: calc(100vh - 58px);
    padding: 0px 2rem;
    background-color: var(--graybg);
   
    .search-wrapper{
        display: flex;
        justify-content: space-between;
        .r-f-tabs{
            display: flex;
            align-items: center;
            .tab{
                border: none;
                @include subHeadingDark;
                margin-right: 1rem;
                padding: 0.5rem 1rem;
                border-radius: 48px;
                cursor: pointer;
                span{
                    padding: 0.25rem;
                    font-size: 0.65rem;
                    border-radius: 1rem;
                    background-color: var(--primary);
                    color: #ffffff;
                    margin-left: 0.5rem;
                }
            }
            .tab.active{
                background-color: var(--secondary);
            }
        }
        .second-div{
            display: flex;
            align-items: center;
            .filter-tab{
                @include subHeadingDark;
                position: relative;
                margin-left: 0.5rem;
                border: 1px solid var(--primary);
                border-radius: 10px;
                padding: 1.05rem 1.25rem;
                width: 7rem;
                cursor: pointer;
                img{
                    margin-right: 0.5rem;
                }
                .red-dot{
                    width: 12px;
                    height: 12px;
                    background-color: red;
                    border-radius: 6px;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            }
        }
    }
    .feedback-one{
        background: #fff;
        border-radius: 20px;
        padding: 0.5rem;
        .tablee{
            background-color: #ffffff;
            .feedback-emp-list-table{
                width: 100%;
                background-color: #ffffff;
                th, td{
                    padding: 0.5rem;
                }
                tr{
                    border-bottom: 1px solid #e2e2e2;
                }
                th {
                    font-size: 0.9rem;
                    color: var(--primary);
                    font-weight: 500;
                    background-color: #ffffff;
                    
                }
                .table-head-emp-list-send{
                    height: 4rem;
                    .mid-cols{
                        text-align: left;
                    }
                }
                .table-head-emp-list-sent{
                    height: 4rem;
                    .mid-cols{
                        width: 20%;
                        text-align: left;
                    }
                }
                
                
                td{
                    .empDetaila{
                        display: flex;
                        .empPic{
                            .userImg{
                                width: 45px;
                                height: 45px;
                                border-radius: 50%;                                
                            }
                        }
                        .userInfo{
                            margin-left: 1rem;
                            .name {
                                @include subHeadingDark;
                                font-size: 0.9rem
                            }
                            .designation{
                                @include smallText;
                            }
                        }
                        
                    }
                    .btns{
                        font-size: 0.75rem;
                        text-align: left;
                        .send-btn{
                            background-color: var(--secondary);
                            color: var(--primary);
                            border: none;
                            padding: 0.5rem 1rem;
                            border-radius: 5px;
                        }
                        .checkIcon{
                            width: 1.25rem;
                            margin-right: 0.5rem;
                        }
                    }
                    .btns.green {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: #69C88E;
                        font-weight: 500;
                    }
                    .btns.grey {
                        color: var(--tertiary);
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        font-weight: 500;
                    }
                    .btns.yellow {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: #fdaa34;
                        font-weight: 500;
                    }
                    .action{
                        button{
                            font-family: 'poppins';
                            padding: 0.25rem;
                            svg{
                                color: var(--primary);
                                font-size: 1.25rem;
                            }
                        }
                        
                    }
                }
            }
        }    
    }
   .search-popup-req-feed{
    position: absolute;
    z-index: 111;
    width: 100%;  
    padding: 0.7rem;
    box-shadow: 0px 0px 6px 1px #e1e1e1;
    background-color: #ffffff;
    max-height: 20rem;
    overflow-y: scroll;
    .search-emp-outer{
        display: flex;
        justify-content: space-between;
        margin: 0.5rem 0px;
        .empDetaila{
            display: flex;
            .empPic{
                .userImg{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;                                
                }
            }
            .userInfo{
                margin-left: 1rem;
                .name {
                    @include subHeadingDark;
                    font-size: 0.81rem
                }
                .designation{
                    @include smallText;
                }
            }            
        }
        .search-sednd-btn{
            padding: 0.25rem 0.5rem;
            background-color: var(--secondary);
            color: var(--primary);
            border-radius: 10px;
            border: none;
            font-size: 0.81rem;
        }
    }
    
   }
   .no-result{
    @include subHeadingDark;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
   }
   .view-all-search{
    @include subHeadingDark;
    font-size: 0.9rem;
    cursor: pointer;
    text-align: center;
   }
}
/* width */
.search-popup-req-feed::-webkit-scrollbar {
    width: 0.32rem;
  }
  
  /* Track */
  .search-popup-req-feed::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .search-popup-req-feed::-webkit-scrollbar-thumb {
    background: #c7c7c7;
  }
  
  /* Handle on hover */
  .search-popup-req-feed::-webkit-scrollbar-thumb:hover {
    background: #a9a9a9;
  }