.compenssation-history{
    .tabs-outer{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        .r-f-tabs{
            display: flex;
            align-items: center;
            .tab{
                border: none;
                @include subHeadingDark;
                margin-right: 1rem;
                padding: 0.5rem 1rem;
                border-radius: 48px;
                cursor: pointer;
            
            }
            .tab.active{
                background-color: var(--secondary);
            }
        }
    .i-btn{
        width: 1.25rem;
        height: 1.25rem;
        text-align: center;
        font-size: 0.9rem;
        border: 1px solid var(--primary);
        border-radius: 1rem;
        margin-left: 0.5rem;
        cursor: pointer;
    }
    }
    

    .letter-block{
        background-color: #ffffff;
        padding: 1rem;
        border-radius: 10px;
        font-family: 'Poppins' !important;
        color: var(--primary) !important;
        div, p, span, li, a{
            font-family: 'Poppins' !important;
            color: var(--primary) !important;
        }
        div, p{
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
            color: var(--primary) !important;
        }
        ul, ol{
            list-style: revert !important;
            margin-left: 1rem !important;
            color: var(--primary) !important;
        }
        table {
            margin: 1rem 0px;
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            thead{
                background-color: var(--secondary);
            }
            tbody{
                tr{
                    border-top: 1px solid rgba(0, 0, 0, 0.1);
                    td{
                        div{
                            span{
                                color: rgba(151, 151, 151, 1) !important;
                            }
                        }                    
                    }
                }
               
                tr:first-child{
                    background-color: var(--primary);
                    color: #ffffff !important;
                    td{
                        color: #ffffff !important;
                        div{
                            color: #ffffff !important;
                            span{
                                color: #ffffff !important;
                            }
                        }       
                        p{
                            color: #ffffff !important;
                        }             
                    }
    
                      td:first-child {
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                      }
                      
                      td:last-child {
                        border-bottom-right-radius: 8px;
                        border-top-right-radius: 8px;
                      }
                }
    
                tr:last-child{
                    border-top: 1px solid rgb(110, 110, 110);
                    border-bottom: 1px solid rgb(110, 110, 110);
                }
                
            }
          }
          
          td, th {
            padding: 0.25rem;
          }
    
    }   
  .btn-outer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .download-letter-btn{
        margin-top: 0.5rem;
        background-color: var(--primary);
        color: #ffffff;
        padding: 0.6rem 1rem;
        border: none;
        border-radius: 0.6rem;
        font-weight: 300;

        &:hover{
            font-weight: 500;
        }
        &:active{
            font-weight: 300;
        }
        
    }
  }
  
}

.compensation-history-in-profile{
    .tablee{
        background-color: #ffffff;
        .compensation-history-table{
            width: 100%;
            background-color: #ffffff;
            th, td{
                padding: 0.5rem;
            }
            tr{
                border-bottom: 1px solid #e2e2e2;
            }
            th {
                font-size: 1rem;
                color: #272727;
                font-weight: 500;
                background-color: #ffffff;
                
            }
            th.action-column{
                text-align: center;
            }
            td{
                font-size: 1rem;
                color: var(--grayDark);
            }
            td.action-column{
                text-align: center;
                .btns{
                    .view-letter{
                        margin: auto;
                        border: none;
                        background-color: var(--secondary);
                        color: var(--primary);
                        border-radius: 0.6rem;
                        font-size: 1rem;
                        padding: 0.5rem 1.25rem;
                    }
                }
            }
   }
    }  
    .compensation-history-table-design-2{
        .table-header {
            @include heading;
            background-color: var(--primary);
            color: #ffffff;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            margin: 0;
            margin-top: 1rem;
            word-break: break-all;
            padding: 1rem;
        
          }
          td.action-column{
                text-align: center;
                .btns{
                    .view-letter{
                        margin: auto;
                        border: none;
                        background-color: var(--secondary);
                        color: var(--primary);
                        border-radius: 0.6rem;
                        font-size: 1rem;
                        padding: 0.5rem 1.25rem;
                    }
                }
            }
}
}

.comp-history-pdf-view{

    .btn-outer{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .download-btn{
            margin: 1rem 0px;
            border: none;
            background-color: var(--primary);
            color: #ffffff;
            width: 7.5rem;
            height: 2rem;
            border-radius: 0.29rem;
            &:hover{
                font-size: 0.81rem;
            }
            span{
                margin: 0px 2rem;
            }
        }
    }
    
}