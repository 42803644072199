@import '../utils/utils.scss';

.feedback-index-one{
    height: calc(100vh - 58px);
    .tabs-outer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tabs{
            @include heading;
            font-size: 1.1rem;
            display: flex;
            flex-direction: row;
            background-color: #ffffff;
            border-radius: 2rem;
            width: max-content;
            .tab{
                border: none;
                background-color: #ffffff;
                border-radius: 2rem;
                cursor: pointer;
                padding: 0.5rem 2rem;
            }
            .tab.active{
                background-color: var(--primary);
                color: var(--graybg);
                font-weight: 500;
                border-radius: 2rem;
            }
        }
        .report-btn-div{
            .bottom{
                display: flex;
                justify-content: flex-end;
                .MuiFormControl-root{
                    width: 15rem;
                    background-color: #ffffff;
                    border-radius: 5px;
                    font-size: 0.7rem;
                    padding: 0.5rem;
                    color: var(--primary);
                }
            }
        }
        
    }
    .no-result{
        @include heading;
        text-align: center;
        width: 100%;
        padding-top: 2rem;
    }
    .team-info{
        display: flex;
        .all-counts{
            width: 40%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            padding-right: 1rem;
            border-right: 1px solid #cdcdcd;
            .head{
                @include heading;
                border: 1px solid #cdcdcd;
                border-radius: 10px;
                padding: 1rem;
                font-weight: 500;
            }
        }
        .total-team{
            background-color: var(--graybg);
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
                .title{
                    @include normalText;
                    font-weight: 500;
                }
                .team-count{
                    font-size: 1.75rem;
                    color: var(--primary);
                    .vertical-line{
                        margin: 0px 1rem;
                    }
                    .percent-sign{
                        font-size: 1.25rem;
                    }
                    .small-text{
                        font-size: 1.25rem;
                    }
                }
        }
    }
    .team-rating-table{
        width: 60%;
        padding-left: 1rem;
        .outer-layout {
            width: 100%;
            display: grid;
            grid-template-columns:100% 1fr;
            grid-gap: 1rem;
            span {
                padding: 0.25rem;
                font-size: 0.65rem;
                border-radius: 1rem;
                background-color: var(--primary);
                color: #ffffff;
                margin-left: 0.5rem;
            }
            .w-80{
                width: 80%;
                color: #00425A;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }
            .w-20{
                width:20%;
                text-align: right;
            }
            .w-100{
                width:100%;
                text-align: right;
            }
            .tableDiv{
                display: table;
                width: 100%;
                border-collapse: collapse;
                margin-top: 2%;
                margin-bottom: 1%;
            }
            .table-heading .col {
                background: #BAD7E9;
                border-radius: 8px;
            }
            .table-heading{
                font-weight: 500;
                display: table-row;   
                text-align: center;
                line-height: 39px;
                font-size: 14px;
                color: var(--primary);  
             }
             .table-row-full{  
                display: table-row;
                text-align: center;
                font-size: 14px;
                line-height: 39px;
                font-weight: 500;
                .col{
                    display:table-cell;
                    border: 2px solid #fff;
                    background-color: #F9F9F9;
                .pre-post{
                    display: flex;
                    justify-content: center;
                    .col-pre{
                       background-color: #F9F9F9;
                       border-right: 1px solid #ffffff;
                    }
                    .col-post{
                       background-color: #F9F9F9;
                       border-left: 1px solid #ffffff;
                    }
                    .vertical-line{
                        margin: 0px 1rem;
                        color: var(--tertiary);
                    }
                }
                }
             }
             .col{ 
                display:table-cell;
                 border: 2px solid #fff;
                .pre-post{
                    display: flex;
                    .col-pre{
                       background-color: #F1F0F6;
                       border-right: 1px solid #ffffff;
                    }
                    .col-post{
                       background-color: #FCEEED;
                       border-left: 1px solid #ffffff;
                    }
                }
             }
             .tblData{
                background-color: #f9f9f9;
                border-radius: 8px;
             }
             .tblData-dnd{
                height: 12rem;
                border-radius: 8px;
                .dragable-emp{
                    @include subHeadingDark;
                    cursor: pointer;
                    background-color: #f9f9f9;
                    margin: 0.5rem;
                    border-radius: 0.25rem;
                    border: 1px solid var(--secondary);
                }
             }
             .tblData-dnd.active{
                padding: 0px 0.5rem;
                width: 20%;
                background-color: var(--secondary);
                transition: 0.25s;
             }
             .bgn{
                background-color: #fff !important;
                text-align: left;
                width:auto;
             }
             .next {
                font-size: var(--subHeaderFontSize);
                margin: 0px;
                padding: 0px;
                border: none;
                color: #fff;
                background: var(--primary);
            }
            .valuesBtn {
                line-height: 24px;
                width: 163px;
                height: 50px;
                border-radius: 10px;
            }
        }
    }
    .feedback-one{
        background: #fff;
        border-radius: 20px;
        padding: 30px 30px;
        .tablee-myteam{
            background-color: #ffffff;
            .feedback-emp-list-table{
                width: 100%;
                background-color: #ffffff;
                th, td{
                    padding: 0.5rem;
                    text-align: left;
                }
                tr{
                    border-bottom: 1px solid #e2e2e2;
                }
                th {
                    font-size: 0.9rem;
                    color: var(--secondary);
                    font-weight: 500;
                    background-color: var(--primary);
                    
                }
                .table-head-emp-list{
                    height: 4rem;
                    border-radius: 10px;
                }
                .mid-cols{
                    width: 18%;
                    text-align: left;
                }
                td{
                    .empDetaila{
                        display: flex;
                        .empPic{
                            .userImg{
                                width: 45px;
                                height: 45px;
                                border-radius: 50%;                                
                            }
                        }
                        .userInfo{
                            margin-left: 1rem;
                            .name {
                                @include subHeadingDark;
                                font-size: 0.9rem
                            }
                            .designation{
                                @include smallText;
                            }
                        }
                        
                    }
                    .btns{
                        width: 6.3rem;
                        display: block;
                        font-size: 0.75rem;
                        cursor: auto;
                        text-align: left;

                        .inner-calib{
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            .check-team{
                                margin-left: 0.25rem;
                                width: 1rem;
                                height: 1rem;
                            }
                        }
                        .check-team{
                            margin-left: 0.25rem;
                            width: 1rem;
                            height: 1rem;
                        }
                       
                        .view-feedback-btn{
                            cursor: pointer;
                            color: var(--tertiary);
                            margin: 0px auto;
                            display: flex;
                            align-items: center;
                            padding: 0.25rem;
                            border-radius: 10px;
                            &:hover{
                                background-color: var(--secondary);
                            }
                           
                        }
                        
                    }
                    .btns.promo{
                        display: flex;
                        justify-content: center;
                    }
                    .btns.red {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: red;
                        font-weight: 500;
                    }
                    .btns.green {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: #69C88E;
                        font-weight: 500;
                    }
                    .btns.grey {
                        color: var(--tertiary);
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        font-weight: 500;
                    }
                    .btns.yellow {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: #fdaa34;
                        font-weight: 500;
                    }
                    .action{
                        button{
                            font-family: 'poppins';
                            padding: 0.25rem;
                            svg{
                                color: var(--primary);
                                font-size: 1.25rem;
                            }
                        }
                        
                    }
                }
            }
        }    
        .tablee{
            background-color: #ffffff;
            .feedback-emp-list-table{
                width: 100%;
                background-color: #ffffff;
                th, td{
                    padding: 0.5rem;
                }
                tr{
                    border-bottom: 1px solid #e2e2e2;
                }
                th {
                    font-size: 0.9rem;
                    color: var(--secondary);
                    font-weight: 500;
                    background-color: var(--primary);
                }
                .table-head-emp-list{
                    height: 4rem;
                    border-radius: 10px;
                }
                .mid-cols{
                    width: 18%;
                    text-align: center;
                }
                td{
                    .empDetaila{
                        display: flex;
                        .empPic{
                            .userImg{
                                width: 45px;
                                height: 45px;
                                border-radius: 50%;                                
                            }
                        }
                        .userInfo{
                            margin-left: 1rem;
                            .name {
                                @include subHeadingDark;
                                font-size: 0.9rem
                            }
                            .designation{
                                @include smallText;
                            }
                        }
                        
                    }
                    .btns{
                        width: 6.3rem;
                        display: block;
                        margin: 0 auto;
                        font-size: 0.75rem;
                        cursor: auto;
                        text-align: center;

                        .inner-calib{
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            .check-team{
                                margin-left: 0.25rem;
                                width: 1rem;
                                height: 1rem;
                            }
                        }
                        .check-team{
                            margin-left: 0.25rem;
                            width: 1rem;
                            height: 1rem;
                        }
                       
                        .view-feedback-btn{
                            cursor: pointer;
                            color: var(--tertiary);
                            margin: 0px auto;
                            display: flex;
                            align-items: center;
                            padding: 0.25rem;
                            border-radius: 10px;
                            &:hover{
                                background-color: var(--secondary);
                            }
                           
                        }
                    }
                    .btns.red {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: red;
                        font-weight: 500;
                    }
                    .btns.green {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: #69C88E;
                        font-weight: 500;
                    }
                    .btns.grey {
                        color: var(--tertiary);
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        font-weight: 500;
                    }
                    .btns.yellow {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: #fdaa34;
                        font-weight: 500;
                    }
                    .action{
                        button{
                            font-family: 'poppins';
                            padding: 0.25rem;
                            svg{
                                color: var(--primary);
                                font-size: 1.25rem;
                            }
                        }
                        
                    }
                }
            }
        }    
        .color-legends-block{
            @include normalText;
            display: flex;
            justify-content: flex-end;
            margin: 1rem 0px;
            .default{
                img{
                    width: 1.5rem;
                    height: auto;
                }
            }
            
        }
    }
    
   .search-wrapper{
    display: flex;
    justify-content: space-between;
   }
}

.my-team-drawer-feedback{
    .empDetaila{
        display: flex;
        .empPic{
            .userImg{
                width: 45px;
                height: 45px;
                border-radius: 50%;                                
            }
        }
        .userInfo{
            margin-left: 1rem;
            .name {
                @include subHeadingDark;
                font-size: 0.9rem
            }
            .designation{
                @include smallText;
            }
        }
        
    }
    .feedback-status-main{
        .block{
            margin: 1rem 0px;
            .title{
                @include heading;
                .count{
                    color: #979797;
                }
            }
            .req-contents{
                .peer-req{
                    margin: 0.5rem 0px;
                    padding: 0.5rem;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    display: flex;
                    justify-content: space-between;
                    .empDetaila{
                        display: flex;
                        .empPic{
                            display: flex;
                            align-items: center;
                            .userImg{
                                width: 45px;
                                height: 45px;
                                border-radius: 50%;                                
                            }
                        }
                        .userInfo{
                            margin-left: 1rem;
                            .name {
                                @include subHeadingDark;
                                font-size: 0.9rem
                            }
                            .designation{
                                @include smallText;
                            }
                        }
                        
                    }
                }
            }
        }      
        
    }
    .initiate-request{
        .search-title{
            @include heading;
            margin-top: 1rem;
        }
        .selected-to-send{
            border: 1px solid var(--primary);
            border-radius: 10px;
            padding: 0.5rem;
            margin: 1rem 0px;
        }
        .send-re-btn{
            padding: 0.5rem 1rem;
            font-size: 1rem;
            color: #ffffff;
            background-color: var(--primary);
            border: none;
            border-radius: 10px;
            &:hover{
                background-color: #005a7a;
            }
            &:active{
                background-color: var(--primary);
            }
        }
        .search-popup-req-feed{
        position: absolute;
        z-index: 111;
        width: 100%;  
        padding: 0.7rem;
        box-shadow: 0px 0px 6px 1px #e1e1e1;
        background-color: #ffffff;
        max-height: 20rem;
        overflow-y: scroll;
        .search-emp-outer{
            display: flex;
            justify-content: space-between;
            margin: 0.5rem 0px;
            .empDetaila{
                display: flex;
                .empPic{
                    display: flex;
                    align-items: center;
                    .userImg{
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;                                
                    }
                }
                .userInfo{
                    margin-left: 1rem;
                    .name {
                        @include subHeadingDark;
                        font-size: 0.81rem
                    }
                    .designation{
                        @include smallText;
                    }
                }            
            }
            .search-sednd-btn{
                padding: 0.25rem 0.5rem;
                background-color: var(--secondary);
                color: var(--primary);
                border-radius: 10px;
                border: none;
                font-size: 0.81rem;
            }
        }
        
       }
    }
    .no-result{
        @include subHeadingDark;
        text-align: center;
        margin-top: 2rem;
       }
       .view-all-search{
        @include subHeadingDark;
        font-size: 0.9rem;
        cursor: pointer;
        text-align: center;
       }
}

.emp-skel{
    margin: 1rem 0px;
    padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .empDetaila{
        display: flex;
        .empPic{
            display: flex;
            align-items: center;
            .userImg{
                width: 45px;
                height: 45px;
                border-radius: 50%;                                
            }
        }
        .userInfo{
            margin-left: 1rem;
            .name {
                @include subHeadingDark;
                font-size: 0.81rem
            }
            .designation{
                @include smallText;
            }
        }            
    }
}

.old-feedback-show-manager-in-sidebar{
    .sidedrawer{
        width: 60rem;
        .drawer-children-main{
            .feedback-view-main{
                background-color: #ffffff;
                padding: 0px;
            }
        }
    }
}