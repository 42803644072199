@import '../../utils/utils.scss';

.promo-page-rating-calibration{
    background-color: var(--graybg);
    padding: 1rem;
    .page-title{
        @include heading;
    }
    .rating-calibration-report-view .view-report-row {
        margin: 1rem 0rem;
        height: 90px;
        display: inline-flex;
        width: 100%;
        grid-gap: 0.75rem;
    }
    
    .rating-calibration-report-view .blockBlue {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #BAD7E9;
        border-radius: 10px;
        text-align: center;
        width: 20%;
    }
    
    .rating-calibration-report-view .block {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 10px;
        text-align: center;
        width: 20%;
    }
    
    .rating-calibration-report-view .number {
        font-size: 1.1rem;
        color: var(--primary);
        font-weight: 500;
    
    }
    
    .rating-calibration-report-view .text {
        color: var(--tertiary);
        margin: 0px;
        padding: 0px;
        font-size: 10px;
        line-height: 15px;
        font-weight: 400;
    }

    .second-div{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .approve-all-btn{
            @include subHeadingDark;
            font-weight: 400;
            border: none;
            border-radius: 10px;
            background-color: #69C88E;
            color: #ffffff;
            margin-left: 0.5rem;
            padding: 1.05rem 1.25rem;
            width: 7rem;
        }
        .approve-next-btn{
            @include subHeadingDark;
            font-weight: 400;
            border: none;
            border-radius: 10px;
            background-color: var(--primary);
            color: #ffffff;
            margin-left: 0.5rem;
            padding: 1.05rem 1.25rem;
            width: 7rem;
        }
    }

    .search-wrapper{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0px;
        .r-f-tabs{
            display: flex;
            align-items: center;
            .tab{
                border: none;
                width: fit-content;
                text-align: center;
                @include subHeadingDark;
                margin-right: 1rem;
                padding: 0.5rem 2rem;
                padding-left: 1rem;
                border-radius: 48px;
                cursor: pointer;
                position: relative;
                span{
                    padding: 0.25rem;
                    font-size: 0.65rem;
                    border-radius: 1rem;
                    background-color: var(--primary);
                    color: #ffffff;
                    position: absolute;
                    right: 5px;
                    width: 21px;
                    text-align: center;
                }
            }
            .tab.active{
                background-color: var(--secondary);
            }
        }
        .second-div{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .approve-all-btn{
                @include subHeadingDark;
                font-weight: 400;
                border: none;
                border-radius: 10px;
                background-color: #69C88E;
                color: #ffffff;
                margin-left: 0.5rem;
                padding: 1.05rem 1.25rem;
                width: 7rem;
            }
            .approve-next-btn{
                @include subHeadingDark;
                font-weight: 400;
                border: none;
                border-radius: 10px;
                background-color: var(--primary);
                color: #ffffff;
                margin-left: 0.5rem;
                padding: 1.05rem 1.25rem;
                width: 7rem;
            }
        }
    }

    

    .tablee{
        background-color: #ffffff;
        margin: 1rem 0px;
        border-radius: 10px;
        .feedback-emp-list-table{
            width: 100%;
            background-color: #ffffff;
            th, td{
                padding: 0.5rem;
            }
            tr{
                border-bottom: 1px solid #e2e2e2;
            }
            th {
                font-size: 0.9rem;
                color: var(--primary);
                font-weight: 500;
                background-color: #ffffff;
                
            }
            th.e-name{
                min-width: 7rem;
                cursor: pointer;
                svg{
                    font-size: 1.25rem;
                    margin-right: 0.5rem;
                }
            }
            .table-head-emp-list{
                height: 4rem;
            }
            .mid-cols{
                text-align: left;
            }
            .last-col{
                width: 9rem;
                text-align: start;
                
            }
            .mid-cols-small{
                max-width: 8rem;
            }
            .mid-cols-medium{
                width: 7rem;
            }
            .last-col-large{
                width: 9rem;
            }
            .last-col.head{
                padding-right: 1.8rem;
            }
            td{
               @include normalText;
               padding: 0.5rem;
               .title{
                display: flex;
                align-items: center;
                cursor: pointer;
                svg{
                    font-size: 1.25rem;
                    margin-right: 0.5rem;
                }
                .emp-i{
                    margin-left: 0.5rem;
                    .name{
                        color: var(--primary);
                    }
                    .emp-id{
                        @include smallText;
                    }
                   }
               }
               
               .revised{
                color: #69C88E;
               }
               .not-revised{
                color: red;
               }
               .empDetaila{
                .empPic{
                    display: none;
                }
               }
               .the-reason{
                max-height: 5rem;
                overflow-y: scroll;
               }
               .view-details-btn{
                border: 1px solid var(--primary);
                background-color: #ffffff;
                color: var(--primary);
                cursor: pointer;
                padding: 0rem 0.5rem;
                border-radius: 5px;
               }
            }
        }
        .no-data{
            @include heading;
            padding: 1rem;
            text-align: center;
        }
    }
}
/* width */
.the-reason::-webkit-scrollbar {
    width: 0.32rem;
  }
  
  /* Track */
  .the-reason::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .the-reason::-webkit-scrollbar-thumb {
    background: #c7c7c7;
  }
  
  /* Handle on hover */
  .the-reason::-webkit-scrollbar-thumb:hover {
    background: #a9a9a9;
  }