.add-session-main{
    height: calc(100vh - 58px);
    .accordian-heading{
        padding: 5px 1rem;
    }

    .tablee{
        background-color: #ffffff;
        .feedback-emp-list-table{
            width: 100%;
            background-color: #ffffff;
            th, td{
                padding: 0.5rem;
            }
            tr{
                border-bottom: 1px solid #e2e2e2;
            }
            th {
                font-size: 0.9rem;
                color: var(--secondary);
                font-weight: 500;
                background-color: var(--primary);                
            }
            .table-head-emp-list{
                height: 4rem;
            }
            .mid-cols{
                width: 25%;
                text-align: center;
            }
            
            td{
                .empDetaila{
                    display: flex;
                    .empPic{
                        .userImg{
                            width: 45px;
                            height: 45px;
                            border-radius: 50%;                                
                        }
                    }
                    .userInfo{
                        margin-left: 1rem;
                        .name {
                            @include subHeadingDark;
                            font-size: 0.9rem
                        }
                        .designation{
                            @include smallText;
                        }
                    }
                    
                }
                .btns{
                    font-size: 0.75rem;
                    text-align: center;
                    .send-btn{
                        background-color: var(--secondary);
                        color: var(--primary);
                        border: none;
                        padding: 5px 10px;
                        border-radius: 10px;
                    }
                }
                .btns.green {
                    padding: 2px 5px;
                    font-size: var(--smallTextFontSize);
                    color: #69C88E;
                    font-weight: 500;
                }
                .btns.grey {
                    color: var(--tertiary);
                    padding: 2px 5px;
                    font-size: var(--smallTextFontSize);
                    font-weight: 500;
                }
                .btns.yellow {
                    padding: 2px 5px;
                    font-size: var(--smallTextFontSize);
                    color: #fdaa34;
                    font-weight: 500;
                }
                .action{
                    button{
                        font-family: 'poppins';
                        padding: 0.25rem;
                        svg{
                            color: var(--primary);
                            font-size: 1.25rem;
                        }
                    }
                    
                }
            }
        }
    }    

    .schedule-form-outer-block{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem; 
        .schedule-ip-wrap{
            padding-left: 0.5rem;
            display: flex;
            align-items: center;
            background-color: var(--graybg);
        }
        .date-show{
            padding: 0.82rem 0.5rem;
            background-color: var(--graybg);
        }
    }

    .add-sesion-btn-outer{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .draft-btn{
            @include subHeadingLight;
            border: 1px solid var(--primary);
            background-color: #ffffff;
            color: var(--primary);
            padding: 0.75rem 1.5rem;
            border-radius: 10px;
            margin-right: 1rem;
            &:hover{
                background-color: #edfaff;
            }
            &:active{
                background-color: #ffffff;
            }
        }
        .add-btn{
            @include subHeadingLight;
            border: 1px solid var(--primary);
            background-color: var(--primary);
            color: #F9F9F9;
            padding: 0.75rem 1.5rem;
            border-radius: 10px;
            &:hover{
                background-color: #005f81;
            }
            &:active{
                background-color: var(--primary);
            }
        }
    }

    .search-popup-req-feed{
        position: absolute;
        z-index: 111;
        width: 23.5rem;  
        padding: 0.7rem;
        box-shadow: 0px 0px 6px 1px #e1e1e1;
        background-color: #ffffff;
        max-height: 20rem;
        overflow-y: scroll;
        .search-emp-outer{
            display: flex;
            justify-content: space-between;
            margin: 0.5rem 0px;
            .empDetaila{
                display: flex;
                .empPic{
                    .userImg{
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;                                
                    }
                }
                .userInfo{
                    margin-left: 1rem;
                    .name {
                        @include subHeadingDark;
                        font-size: 0.81rem
                    }
                    .designation{
                        @include smallText;
                    }
                }            
            }
            .search-add-emp-btn{
                padding: 0.25rem 0.5rem;
                background-color: var(--secondary);
                color: var(--primary);
                border-radius: 10px;
                border: none;
                font-size: 0.81rem;
            }
            .search-added-emp-btn{
               @include smallText;
            }
        }
        
       }
       .no-result{
        @include subHeadingDark;
        text-align: center;
       }
    /* width */
   
   
}