// leftSectionGrid css
.headtBtnTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.planebtnActive {
    background-color: #00425A !important;
    padding: 10px 18px !important;
    font-size: 12px !important;
    margin: 0 10px 0px 0px;
    font-weight: 400 !important;
    line-height: 21px !important;
    color: #fff !important;
    border: 1px solid #BAD7E9 !important;
    gap: 10px !important;
    border-radius: 10px !important;
}

.form-development-goal .field-row .row-half {
    width: 49%;
}

.customModel .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 420px;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #00425A;
    padding: 20px 10px;
    border-radius: 20px;
}

.customModel .css-knqc4i-MuiDialogActions-root {
    justify-content: center;
}

.customModel .btn {
    text-transform: capitalize;
}

.redbtnActive {
    background-color: #DB3535 !important;
    padding: 10px 18px !important;
    font-size: 12px !important;
    margin: 0 10px 0px 0px;
    font-weight: 400 !important;
    line-height: 21px !important;
    color: #fff !important;
    border: 1px solid #DB3535 !important;
    gap: 10px !important;
    border-radius: 10px !important;
}

.form-development-goal .field-label {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #7E7E7E;
}

.form-development-goal .field-row .text-field {
    width: 100%;
}

.form-development-goal .field-row .select-field .selected-menu {
    display: flex;
}

.form-development-goal .field-row .select-field .selected-menu .menu-item-container {
    display: flex;
    border-radius: 6px;
    padding: 4.5px 9px;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.23);
    margin-top: 0.7rem;
    margin-right: 0.7rem;
    margin-top: 0.7rem;
    margin-right: 0.7rem;
    align-items: center;
}

.formLastBtn .save-draft {
    font-size: var(--subHeaderFontSize);
    color: var(--tertiary);
    margin: 0px;
    padding: 0px;
    background: #fff;
    border-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);
    font-weight: 500;
}

.formCard {
    border: 1px solid #97979733;
    padding: 10px 20px 30px;
    border-radius: 20px;
    margin-top: 25px;
}

.development-bottom-border {
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.head-delete a {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #DB3535;
    display: flex;
    align-items: center;
    margin-right: 0.2rem;
    text-decoration: none;
}

.formLastBtn .next {
    font-size: var(--subHeaderFontSize);
    color: var(--tertiary);
    margin: 0px;
    padding: 0px;
    border: none;
    color: #fff;
    background: var(--primary);
}

.formLastBtn .valuesBtn {
    line-height: 24px;
    width: 163px;
    height: 50px;
    border-radius: 10px;
}

.form-development-goal .field-row {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.form-development-goal .field-row-checbox {
    margin-top: 15px;
    display: flex;
    justify-content: left;
}

.form-development-goal .field-row-checbox div {
    margin-right: 20px;
}

.input-group-custom .btn {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2;
    border: 0px;
    border-left: 1px solid #979797;
    margin: 7px 12px;
    line-height: 12px;
    border-radius: 0px;
    height: 48px;
}

.input-group-custom .btn img {
    margin-top: 0px;
    margin-left: 14px;
}

.input-group-custom .form-control {
    background-color: #F9F9F9;
    border: 0px !important;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    height: 60px;
    color: #979797;
    border-radius: 10px;
}

.input-group-custom {
    position: relative;
}

.planebtn {
    background-color: #fff !important;
    padding: 10px 18px !important;
    font-size: 12px !important;
    margin: 0 10px 0px 0px;
    font-weight: 400 !important;
    line-height: 21px !important;
    color: #00425A !important;
    border: 1px solid #BAD7E9 !important;
    gap: 10px !important;
    border-radius: 10px !important;
}

.devGoalBtn {
    background-color: #00425A !important;
    padding: 18px 32px !important;
    font-size: 16px !important;
    margin: 7px 2px 24px;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: #FFFFFF !important;
    border: 1px solid #00425A !important;
    gap: 10px !important;
    border-radius: 10px !important;
}

.iconAlignment {
    display: flex;
    align-items: center;
}

.iconAlignment .py0 {
    padding-left: 0px;
}

.infoTitle {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #00425A;
}

.iconAlignment .description {
    height: 60px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #979797;
}

.iconAlignment .descriptionlong {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #979797;
}

.iconList {
    float: right;
    display: flex;
    align-items: center;
}

.icon-cursor-pointer {
    cursor: pointer;
}

.accordiandata {
    border-top: 1px solid #d8d8d8;
    margin: 14px 0px;
}

.iconList img {
    margin-left: 20px;
    cursor: pointer;
}

.iconList .pencile-edit {
    height: 15px;
    width: 15px;
}

.iconList .down-arrow {
    height: 10px;
    width: 16px;
}

.cardBlueTitle {
    color: #00425A;
    margin-bottom: 5px !important;
    padding: 0px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
}

.sub-head-title {
    color: #00425A;
    margin-bottom: 5px !important;
    padding: 0px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
}

// sidebar css
.blueCircleText {
    width: 34px;
    background-color: rgba(186, 215, 233, 1);
    /* text-align: center; */
    height: 30px;
    display: inline;
    align-items: center;
    vertical-align: middle;
    margin: 0 auto;
    padding: 7px;
    border-radius: 30px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: rgba(0, 66, 90, 1);
}

.sidebarDesc {
    color: rgba(33, 37, 41, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.sidebarDate {
    color: rgba(151, 151, 151, 1) !important;
    font-size: 12px;
    font-weight: 400 !important;
    line-height: 18px !important;
}

.bottom-border1 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0px 0px 8px;
}

.wrap:last-child .vcenter {
    border-bottom: 0px solid rgba(0, 0, 0, 0.1);
}

.d-flex {
    display: flex;
}

@media only screen and (max-width: 900px) {
    .infoTitle {
        font-size: 16px;
    }

    .devGoalBtn {
        background-color: #00425A !important;
        padding: 8px 16px !important;
        font-size: 12px !important;
        margin: 7px 2px 24px;
        font-weight: 400 !important;
        line-height: 18px !important;
        color: #FFFFFF !important;
        border: 1px solid #00425A !important;
        gap: 10px !important;
        border-radius: 8px !important;
    }

    .sub-head-title {
        color: #00425A;
        margin-top: 10px !important;
        margin-bottom: 0px !important;
        padding: 0px !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 14px !important;
    }

    .iconAlignment {
        .description {
            height: auto;
            font-size: 12px;
        }

        .descriptionlong {
            font-size: 12px;
        }
    }

    .form-development-goal {
        .field-row {
            margin-top: 0px;
            display: block;

            .row-half {
                width: 100%;
            }
        }

        .field-label {
            margin-top: 10px;
        }

        .field-row-checbox {
            margin-top: 5px;
        }
    }



    .formLastBtn {
        .valuesBtn {
            width: 85px;
            height: 40px;
            border-radius: 10px;
            font-size: 14px;
            line-height: 18px;
        }
    }
}