@import "../utils/utils.scss";

.candidate {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .title-grid {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        .page-title {
            color: #00425A;
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            font-family: 'poppins';
            align-self: center;
        }

        .edit-btn {
            width: fit-content;
            background-color: #00425A;
            padding: 8px 20px;
            border-radius: 8px;
            border: 0.5px solid #00425A;
            color: #FFFFFF;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            font-family: 'poppins';
            text-transform: capitalize;
        }
    }

    .user-form-card {
        background-color: #ffffff;
        border-radius: 20px;
        border: 1px solid #E6E6E6;

        .user-card {
            background-color: #F4F9FB;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            padding: 20px;
            display: grid;
            grid-template-columns: 14% 1% 17% 11% 20% 16% 19%;
            gap: 5px;

            .ta-center {
                text-align: center;
            }

            .profile-name {
                color: #00425A;
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
                font-family: 'poppins';

                img {
                    margin-left: 4px;
                    height: 8.73px;
                    width: 16px;
                }
            }

            .profile-field-label {
                color: #979797;
                font-size: 10px;
                line-height: 15px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .profile-emp-lable {
                color: #979797;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .profile-emp-no {
                color: #00425A;
                font-size: 12px;
                line-height: 15px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .profile-field-value {
                color: #00425A;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .vertical-border {
                width: 1px;
                height: '100%';
                background-color: #E6E6E6;
            }

            .mt-15 {
                margin-top: 15px;
            }

            .profile-btn {
                width: 140px;
                padding: 5px 10px;
                background-color: #BAD7E9;
                border-radius: 5px;
                text-transform: capitalize;
            }

            .profile-btn-txt {
                color: #000000;
                font-size: 10px;
                line-height: 12px;
                font-weight: 400;
                font-family: 'poppins';
                text-transform: capitalize;
                text-align: left;
                margin-left: 5px;
            }
        }

        .edit-user-card {
            background-color: #F4F9FB;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            padding: 20px;
            display: grid;
            grid-template-columns: 15% 1% 21% 21% 21% 21%;
            gap: 5px;

            .ta-center {
                text-align: center;
            }

            .profile-name {
                color: #00425A;
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
                font-family: 'poppins';

                img {
                    margin-left: 4px;
                    height: 8.73px;
                    width: 16px;
                }
            }

            .profile-field-label {
                color: #979797;
                font-size: 10px;
                line-height: 15px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .profile-emp-lable {
                color: #979797;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .profile-emp-no {
                color: #00425A;
                font-size: 12px;
                line-height: 15px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .profile-field-value {
                color: #00425A;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .vertical-border {
                width: 1px;
                height: '100%';
                background-color: #E6E6E6;
            }

            .mt-15 {
                margin-top: 15px;
            }

            .profile-btn {
                width: 140px;
                padding: 5px 10px;
                background-color: #BAD7E9;
                border-radius: 5px;
                text-transform: capitalize;
            }

            .profile-btn-txt {
                color: #000000;
                font-size: 10px;
                line-height: 12px;
                font-weight: 400;
                font-family: 'poppins';
                text-transform: capitalize;
                text-align: left;
                margin-left: 5px;
            }
        }

        .form-card {
            background-color: #FFFFFF;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            padding: 20px;

            .form-title {
                color: #00425A;
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .form-description {
                color: #979797;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                font-family: 'poppins';
                margin-top: 5px;
            }

            .form-sub-title {
                color: #00425A;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                font-family: 'poppins';
                margin-top: 5px;
            }

            .form-checkbox-title {
                color: #00425A;
                font-size: 12px;
                line-height: 14px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .horizontal-border {
                height: 1px;
                width: 100%;
                background-color: #E6E6E6;
                margin-top: 20px;
            }

            .mt-40 {
                margin-top: 40px !important;
            }

            .list-container {
                display: grid;
                grid-template-columns: 25% 75%;
                gap: 5px;
                margin-top: 20px;

                .list-left-title {
                    color: #00425A;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500;
                    font-family: 'poppins';
                }

                .right-list-container {
                    display: grid;
                    grid-template-columns: 13% 24% 22% 21% 20%;
                    gap: 5px;

                    .list-right-title {
                        color: #979797;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 400;
                        font-family: 'poppins';
                    }

                    .list-right-value {
                        color: #00425A;
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: 400;
                        font-family: 'poppins';
                        margin-top: 8px;
                    }
                }

                .list-bottom-img {
                    width: 300px;
                    margin-top: 5px;
                    margin-left: -5px;
                }
            }
        }
    }

    .historical-section-title {
        color: #00425A;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        font-family: 'poppins';
        margin-top: 20px;
    }

    .historical-section-card {
        background-color: #ffffff;
        border-radius: 20px;
        border: 1px solid #E6E6E6;
        margin-top: 5px;

        .historical-section-list {
            display: grid;
            grid-template-columns: 10% 20% 12% 14% 10% 18% 14%;
            gap: 5px;
            height: 50px;
            padding-left: 20px;
            padding-right: 20px;
            align-items: center;

            .historical-section-head {
                color: #00425A;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .historical-section-value {
                color: #979797;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                font-family: 'poppins';
            }
        }

        .historical-border {
            height: 1px;
            width: 100%;
            background-color: #E6E6E6;
        }
    }


    .future-compensation-card {
        background-color: #ffffff;
        border-radius: 20px;
        border: 1px solid #E6E6E6;
        margin-top: 10px;
        padding: 20px;

        .future-compensation-title {
            color: #00425A;
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            font-family: 'poppins';
        }

        .ta-center {
            text-align: center;
        }

        .chart-bottom-container {
            display: grid;
            grid-template-columns:  12% 14% 18% 20% 25% 11%;
            gap: 5px;
            margin-top: 10px;

            .chart-btm-grid {
                display: flex;
            }
        }

        .mt-10 {
            margin-top: 10px !important;
        }

        .chart-txt-box {
            height: 12px;
            width: 20px;
            border-radius: 5px;
        }

        .light-pink-bg {
            background-color: #cd77db;
        }

        .blue-bg {
            background-color: #2d99fe;
        }

        .green-bg {
            background-color: #129182;
        }

        .dark-red-bg {
            background-color: #d87d4b;
        }

        .chart-txt {
            color: #000000;
            font-size: 10px;
            line-height: 14px;
            font-weight: 500;
            font-family: 'poppins';
            margin-left: 5px;
        }
    }

    .cancel-save-grid {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        
        .cancel-btn {
            border: 1px solid #004259;
            border-radius: 10px;
            padding: 13px 20px;
            background-color: var(--graybg);
            color: #004259;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            font-family: 'poppins';
            text-transform: capitalize;
        }

        .save-btn {
            background-color: #004259;
            border-radius: 10px;
            padding: 13px 20px;
            color: #FFFFFF;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            font-family: 'poppins';
            text-transform: capitalize;
            margin-left: 15px;
        }
    }
}