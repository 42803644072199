@import '../utils/utils.scss';

.rating-collab{
    height: calc(100vh - 58px);
    padding: 0px 2rem;
    background-color: var(--graybg);
    .no-result{
        @include heading;
        text-align: center;
        width: 100%;
        padding-top: 2rem;
    }
    .search-wrapper{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0px;
        .r-f-tabs{
            display: flex;
            align-items: center;
            .tab{
                border: none;
                @include subHeadingDark;
                margin-right: 1rem;
                padding: 0.5rem 1rem;
                border-radius: 48px;
                cursor: pointer;
                span{
                    padding: 0.25rem;
                    font-size: 0.65rem;
                    border-radius: 1rem;
                    background-color: var(--primary);
                    color: #ffffff;
                    margin-left: 0.5rem;
                }
            }
            .tab.active{
                background-color: var(--secondary);
            }
            
        }
        .second-div{
            display: flex;
            align-items: center;
        @include subHeadingDark;
        .add-session{
            padding: 1.1rem 1.5rem;
            background-color: var(--secondary);
            margin-right: 1rem;
            border: 1px solid var(--secondary);
            border-radius: 10px;
            &:hover{
                background-color: #BAD7E9;
            }
            &:active{
                background-color: var(--secondary);
            }
            img{
                width: 1.1rem;
                margin-right: 0.4rem;
            }
        }
        .i-btn{
            font-size: 0.75rem;
            span{
                padding: 0rem 0.37rem;
                border: 1px solid var(--primary);
                border-radius: 20px;
                cursor: pointer;
            }
        }
            .filter-tab{
                @include subHeadingDark;
                position: relative;
                margin-left: 0.5rem;
                border: 1px solid var(--primary);
                border-radius: 10px;
                padding: 1.05rem 1.25rem;
                width: 7rem;
                cursor: pointer;
                img{
                    margin-right: 0.5rem;
                }
                .red-dot{
                    width: 12px;
                    height: 12px;
                    background-color: red;
                    border-radius: 6px;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            }
        }

    }
    .session-tables{
        background: #fff;
        border-radius: 20px;
        padding: 0.5rem;
        .tablee{
            background-color: #ffffff;
            .feedback-emp-list-table{
                width: 100%;
                background-color: #ffffff;
                th, td{
                    padding: 0.5rem;
                    text-align: left;
                }
                tr{
                    border-bottom: 1px solid #e2e2e2;
                }
                th {
                    font-size: 0.9rem;
                    color: var(--primary);
                    font-weight: 500;
                    background-color: #ffffff;
                }
                .table-head-emp-list{
                    height: 4rem;
                }
                .first-col{
                    width: 16%;
                }
                .mid-cols{
                    width: 19%;
                    text-align: left;
                }
                
                td{
                    .empDetaila{
                        display: flex;
                        .userInfo{
                            .name {
                                @include subHeadingDark;
                                font-size: 0.9rem
                            }
                            .designation{
                                @include smallText;
                                a{
                                    text-decoration: none;
                                }
                            }
                        }
                        
                    }
                    .btns{
                        font-size: 0.75rem;
                        text-align: left;
                        .send-btn{
                            background-color: var(--secondary);
                            color: var(--primary);
                            border: none;
                            padding: 5px 10px;
                            border-radius: 10px;
                            min-width: 4.3rem;
                            svg{
                                margin-right: 0.25rem;
                            }
                        }
                    }
                    .btns.green {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: #69C88E;
                        font-weight: 500;
                    }
                    .btns.grey {
                        color: var(--tertiary);
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        font-weight: 500;
                    }
                    .btns.yellow {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: #fdaa34;
                        font-weight: 500;
                    }
                    .action{
                        display: flex;
                        justify-content: center;
                        button{
                            font-family: 'poppins';
                            padding: 0.25rem;
                            svg{
                                color: var(--primary);
                                font-size: 1.25rem;
                            }
                        }
                        
                    }
                }
            }
        }    
    }
   
}