@import './dashboard/dashboard.scss';
@import './profile/profile.scss';
@import './main/main.scss';
@import './login/login.scss';
@import './sidebar/sidebar.scss';
@import './feedback/feedback.scss';
@import './dialog/dialog.scss';
@import './header/header.scss';
@import './selfAssesment//selfAssesment.scss';
@import './sidedrawer/sidedrawer.scss';
@import './commonEmpProfile/commonEmpProfile.scss';
@import './feedbackInitial/feedbackInitial.scss';
@import './utils/utils.scss';
@import './selfAssesmentAllinone/selfAssesmentAllinone.scss';
@import './changePassword/changePassword.scss';
@import './viewFeedback/viewFeedback.scss';
@import './pagination/pagination.scss';
@import './requestFeedback/requestFeedback.scss';
@import './givePeerFeedback/givePeerFeedback.scss';
@import './ratingCollab//ratingCollab.scss';
@import './ratingCollab/addSession/addSession.scss';
@import './ratingCollab/addSession/hostSession/hostSesssion.scss';
@import './ratingCollab/addSession/cohort/cohort.scss';
@import './ratingCollab/addSession/empInfo/empInfo.scss';
@import './ratingCollab/viewReport/viewReport.scss';
@import './ratingCollab/chngedReport/chngedReport.scss';
@import './calender/calender.scss';
@import './dateTimePicker/dateTimePicker.scss';
@import './sideFilters/sideFilters.scss';
@import './approveSuggest/approveSuggest.scss';
@import './searchComp/searchComp.scss';
@import './ratingTable/ratingTable.scss';
@import './infoContent/infoContent.scss';
@import './notificationDrawer/notificationDrawer.scss';
@import './functionalLeader/functionalLeader.scss';
@import './accessDenied/accessDenied.scss';
@import './ratingCollab/promoApprove/promoApprove.scss';
@import './compensation/compensation.scss';
@import './compensationAnalytics/compensationAnalytics.scss';
@import './tooltip/tooltip.scss';
@import './EmployeeProfile//empProfile.scss';
@import './performanceRating/performanceRating.scss';
@import './compTeam/compTeam.scss';
@import './compHistory/compHistory.scss';
@import './goals/employee/empGoalsView.scss';
@import './goals/manager/viewGoalRequest.scss';
@import './goals/goalsForm.scss';
@import './managerFeedback/employeeAssesmentStatic.scss';
@import './managerFeedback/peerFeedbackStatic.scss';
@import './eep/eep.scss';
@import './idp/idp.scss';
@import './successionPlan/successionPlan.scss';
@import './hrSuccessionPlan/hrSuccessionPlan.scss';
@import './hrSuccessionPlan/schedulingSession/schedulingSession.scss';
@import './hrSuccessionPlan/HrViewSuccessionPlan/hrViewSuccessionPlan.scss';
@import './reportPerformance/reportPerformance.scss';
@import './EmployeeEngagement/employeeEngagement.scss';
@import './comments/comments.scss';
@import './myTeamPage/myTeamPage.scss';
@import './MyCommunity/myCommunity.scss';
@import './MyCommunity/myCommunityTeam.scss';
@import './Promotions/promotions.scss';
@import './Promotions/ViewSharedDocuments.scss';
@import './Promotions/promotionsForm.scss';
@import './ManagerFeedbacksMember/managerFeedbacksMember.scss';
@import './Candidate/candidate.scss';
@import './myTeamCompensation/myTeamCompensation.scss';
@import './HrPromotions/hrPromotions.scss';
@import './HrPromotions/approverViewForm.scss';
@import './HrPromotions/hrViewSession.scss';
@import './HrPromotions/hrSchedulingSession.scss';
@import './HrPromotions/panelistTeamList.scss';
@import './CompensationChart/compensationChart.scss';
@import './AboutMe/myCareer.scss';
@import './Leave/myLeave.scss';
@import './Leave/myTeamLeave.scss';
@import './Leave/teamLeaveHistory.scss';

:root {
    --primary: #00425A;
    --primaryLight: #025573;
    --secondary: #BAD7E9;
    --tertiary: #7E7E7E;
    --graybg: #F9F9F9;
    --grayDark: #979797;
    --grayLight: #D9D9D9;
    
    --warning:red;
    --selectedTileColor: #e7f6ff;
    --footerBackground: #FAF3E3;

    --headerFonrSize: 1.15rem;
    --subHeaderFontSize: 1rem;
    --textFontSize: 0.9rem;
    --smallTextFontSize: 0.75rem;
  
    // For Emp Profile page
    --TextPrimary: #344054;
    --TextSecondary: #475467;
    --TextActive : #6941C6;
    --nav-title: #6F7590;
    --nav-title-name: #3E4359;
  
    --warning:red;
    --selectedTileColor: #e7f6ff;
    --footerBackground: #FAF3E3;
    
    --black: #000;
    --white: #fff;
    --bg: #FBF8FF;
  
    --bredcumb-title-color: #475467;
    --bredcumb-subTitle-color: #6941C6; 
  
    --title-color: #101828;
    --subTitle-color: #667085;
  
    --primary-400: #B692F6;
    --primary-600: #7F56D9;
    --primary-25: #FCFAFF;
    --about-bg: #F9FAFB;
  
    --card-color: #EAECF0;
    --select-option: #D0D5DD;
    --select-box-shadow: #1018280D;
    --movement-bg: #FCFCFD;
    --movement-bg-inner: #F2F4F7;
  
    --tab-box-shadow: #1018280F;

    // add / edit goal
    --goal-label-color: #272727;
    // --goal-label-color: yellow;
    --goal-label-font-size: 0.75rem;
    --goal-label-font-weight: 400;
    --goal-label-font-family: Inter;
    --goal-label-line-height: 14.52px;
    --goal-label-opacity: 70%;

    // view goal
    --goal-label-view-color: #00425A !important;
    --goal-label-view-font-size: 0.875rem !important;
    --goal-label-view-font-weight: 400 !important;
    --goal-label-view-font-family: Poppins !important;
    --goal-label-view-opacity: 100% !important;

    --goal-input-view-color: #979797 !important;
    --goal-input-view-font-size: 1rem !important;
    --goal-input-view-font-weight: 400 !important;
    --goal-input-view-font-family: Poppins !important;
    --goal-input-view-opacity: 100% !important;
    
  }

 
  
.accordian-heading{
   @include heading;  
  }

  .download-btn-for-performance-history{
    border-radius: 1rem;
    background-color: var(--primary);
    width: 1.8rem;
    display: flex;
    justify-content: center;
    svg{
      color: #ffffff;
      width: 1rem;
      &:hover{
        width: 1.1rem;
      }
      &:active{
        width: 1rem;
      }
    }
 }

  .acc-head-btns{
    display: flex;
    .i-btn{
      p{
        background-color: var(--primary);
        color: #ffffff;
        border-radius: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        font-weight: 600;
        margin-right: 1rem;
        font-size: 1rem;
        &:hover{
            background-color: skyblue;
        }
       }
    }
    
  }
  .acc-head-btns1{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.75rem;
    .i-btn{
      p{
        background-color: var(--primary);
        color: #ffffff;
        border-radius: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        font-weight: 600;
        // margin-right: 1rem;
        font-size: 1rem;
        &:hover{
            background-color: skyblue;
        }
       }
    }
    
  }

 

.MuiTooltip-popper{
.MuiTooltip-tooltip{
    font-family: 'Poppins';
    font-size: 0.75rem;
    letter-spacing: 1px;
}
}


/* HTML: <div class="loader"></div> */
.auto-save-loader  {
  width: fit-content;
  // font-weight: bold;
  font-family: poppins;
  font-size: 1rem;
  color: var(--primary);
  clip-path: inset(0 100% 0 0);
  animation: l5 2s steps(11) infinite;
}
// .auto-save-loader:before {
//   content:"Loading..."
// }
@keyframes l5 {to{clip-path: inset(0 -1ch 0 0)}}

.popover-btns {
  padding: 0.32rem 0.75rem;
  width: 100%;
  border: none;
  background-color: #ffffff;
  &:hover{
      background-color: var(--secondary);
  }
 }
.popover-btns.red {
  color: red;
 }
 .page-i-btn{
  font-size: 14px;
  font-weight: 500;
  margin-left: 1rem;
  padding: 0px 8px;
  border: 1px solid var(--primary);
  border-radius: 16px;
  cursor: pointer;
 }

 @font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter_24pt-Regular.ttf");
}

.jodit-status-bar__item.jodit-status-bar__item-right{
  a{
    display: none;
  }
}