.initiate-search-div{
    display: flex;
    align-items: center;
    .filter-tab{
        @include subHeadingDark;
        margin-left: 0.5rem;
        border: 1px solid var(--primary);
        border-radius: 10px;
        padding: 1.05rem 1.25rem;
        width: 7rem;
        cursor: pointer;
        img{
            margin-right: 0.5rem;
        }
    }
    .search-popup-req-feed{
        position: absolute;
        z-index: 111;
        width: 100%;  
        padding: 0.7rem;
        box-shadow: 0px 0px 6px 1px #e1e1e1;
        background-color: #ffffff;
        max-height: 20rem;
        overflow-y: scroll;
        .search-emp-outer{
            display: flex;
            justify-content: space-between;
            margin: 0.5rem 0px;
            cursor: pointer;
            .empDetaila{
                display: flex;
                .empPic{
                    .userImg{
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;                                
                    }
                }
                .userInfo{
                    margin-left: 1rem;
                    .name {
                        @include subHeadingDark;
                        font-size: 0.81rem
                    }
                    .designation{
                        @include smallText;
                    }
                }            
            }
            .search-sednd-btn{
                padding: 0.25rem 0.5rem;
                background-color: var(--secondary);
                color: var(--primary);
                border-radius: 10px;
                border: none;
                font-size: 0.81rem;
            }
        }
        
       }
       .no-result{
        @include subHeadingDark;
        text-align: center;
        margin-top: 2rem;
       }
       .view-all-search{
        @include subHeadingDark;
        font-size: 0.9rem;
        cursor: pointer;
        text-align: center;
       }
}