.comp-my-team{
    height: calc(100vh - 58px);
    padding: 0px 2rem;
    background-color: var(--graybg);
    .upper-header{
        .search-wrapper{
    display: flex;
    justify-content: space-between;
    .r-f-tabs{
        display: flex;
        align-items: center;
        .tab{
            border: none;
            @include subHeadingDark;
            margin-right: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 48px;
            cursor: pointer;
            span{
                padding: 0.25rem;
                font-size: 0.65rem;
                border-radius: 1rem;
                background-color: var(--primary);
                color: #ffffff;
                margin-left: 0.5rem;
            }
        }
        .tab.active{
            background-color: var(--secondary);
        }
    }
    .second-div{
        display: flex;
        align-items: center;
        button{
            background-color: var(--primary);
            color: #ffffff;
            border: none;
            border-radius: 0.5rem;
            margin-left: 0.7rem;
            height: 3.7rem;
            padding: 0px 2rem;
            font-family: "poppins";
            font-weight: 500;
            &:hover{
                color: var(--secondary);
            }
            &:active{
                color: #ffffff;
            }
        }
        .disable{
            background-color: gray;
        }
    }
}
    }
    .tablee{
        background-color: #ffffff;
        .feedback-emp-list-table{
            width: 100%;
            background-color: #ffffff;
            
            tr{
                border-bottom: 1px solid #e2e2e2;
            }
            th {
                padding: 1.5rem;
                font-size: 0.9rem;
                color: var(--primary);
                font-weight: 500;
                background-color: #ffffff;
                width: 25%;
                
            }
            th.team-member-name {
                padding-left: 0.5rem !important;
            }
            .mid-cols{
                text-align: center;
                .btn{
                    background-color: var(--secondary);
                    color: var(--primary);
                    border: none;
                    border-radius: 0.6rem;
                    font-family: poppins;
                    font-size: 0.9rem;
                    font-weight: 500;
                    padding: 0.5rem 1rem;
                    &:hover{
                        font-weight: 600;
                    }
                    &:active{
                        font-weight: 500;
                    }
                }
                .btn.disable{
                    background-color: gray;
                    color: #ffffff;
                    font-weight: 400;
                }
            }
         
            td{
                padding: 0.5rem;
                .empDetaila{
                    display: flex;
                    .empPic{
                        .userImg{
                            width: 45px;
                            height: 45px;
                            border-radius: 50%;                                
                        }
                    }
                    .userInfo{
                        margin-left: 1rem;
                        .name {
                            @include subHeadingDark;
                            font-size: 0.9rem
                        }
                        .designation{
                            @include smallText;
                        }
                    }
                    
                }
               
            }
        }
    } 
    .tabs-outer {
        display: flex;
        justify-content: space-between;
        padding: 0px 1rem;
        margin-bottom: 1rem;

        .down-report{
            button{
                width: 100%;
                height: 2rem;
                background-color: #ffffff;
                border: 1px solid var(--primary);
                border-radius: 5px;
                font-size: 0.7rem;
                padding: 0px 2rem;
                color: var(--primary);
                img{
                   width: 0.75rem;
                   margin-left: 1rem;
                }
                &:hover{
                    font-size: 0.77rem;
                    padding: 0px 1.7rem;
                }
                &:active{
                    background-color: #f0feff;
                }
            }
        }

        .tabs {
            @include heading;
            font-size: 0.81rem;
            display: flex;
            flex-direction: row;
            background-color: #ffffff;
            border-radius: 2rem;
            width: max-content;
            border: 1px solid var(--secondary);

            .tab {
                cursor: pointer;
                padding: 0.25rem 2rem;
                span{
                    background-color: #ffffff;
                    color: var(--primary);
                    padding: 0px 0.45rem;
                    border-radius: 1rem;
                    margin-left: 0.5rem;
                    border: 1px solid var(--primary);
                }
            }

            .tab.active {
                background-color: var(--primary);
                color: var(--graybg);
                font-weight: 500;
                border-radius: 2rem;
            }
        }
    }
}


