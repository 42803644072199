.eeppage .accordian-data .d-flex {
    border-bottom: 1px solid rgb(223, 223, 223);
    padding: 10px 0 0px;
}

.eeppage .accordian-data .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--tertiary);
    margin: 0px !important;
    width: 30%;
}

.padding-left {
    margin-left: 10px;
}

.eeppage .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(151, 151, 151, 1);
}

.eeppage .accordian-data .subTitle {
    width: 55%;
}

.eeppage .header-section .heading {
    font-size: var(--headerFonrSize);
    color: var(--primary);
    margin: 0px;
    padding: 0px;
    font-weight: 500;
    line-height: 27px;
}

.profile-pic {
    color: transparent;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
}

.profile-pic input {
    display: none;
}

.profile-pic img {
    // position: absolute;
    object-fit: cover;
    // width: 133px;
    // height: 139px;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, .35);
    border-radius: 100px;
    z-index: 0;
    left: 18%;
}

.profile-d-flex {
    display: flex;
}

.profile-div {
    position: relative;
    left: 10px;
}

.bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
}

.user-profile {
    width: 140px;
    height: 140px;
}

.aboutheading {
    color: var(--primary) !important;
    margin-bottom: 5px !important;
    padding: 0px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 27px !important;
}

.aboutheading span {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(33, 37, 41, 1);
}

.competencies-heading {
    color: #212529 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    margin-top: 10px !important;
}

.competencies-sub-heading {
    color: #7E7E7E !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
}

.competencies-mb-12 {
    margin-bottom: 12px !important;
}

.content-section {
    flex-grow: 1;
}

.bottom-section {
    text-align: left;
    // padding-top: 16px;
}

.profile-pic .imgCircle {
    // position: absolute;
    object-fit: cover;
    // width: 133px;
    // height: 139px;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, .35);
    border-radius: 100px;
    z-index: 0;
    left: 18%;
    position: absolute;
    top: 100%;

}

.imageRightText .userTitle {
    color: #00425A;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 0px !important;
}

.imageRightText .postionTitle {
    color: #979797;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.imageRightText .postionTitle img {
    margin-right: 10px;
}

.imageRightText .userTitle span {
    color: #00425A;
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
}

.imageRightText .px-3:first-child {
    padding-left: 0px !important;
}

.imageRightText {
    position: absolute;
    bottom: 0%;
    left: 180px;
}

.profile-pic .-label {
    cursor: pointer;
    height: 165px;
    width: 165px;
}

.profile-pic:hover .-label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .8);
    z-index: 10000;
    color: #fafafa;
    transition: background-color 0.2s ease-in-out;
    border-radius: 100px;
    margin-bottom: 0;
}

.profile-pic span {
    display: inline-flex;
    padding: 0.2em;
    height: 2em;
    right: 2%;
    bottom: 12%;
}

.img-resposive {
    width: 100%;
    height: auto;
}

.btnList {
    margin-bottom: 10px;
}

.profilebtn {
    background-color: #fff !important;
    padding: 10px !important;
    font-size: 0.9rem !important;
    margin: 0 2px;
    font-weight: 500 !important;
    line-height: 21px !important;
    color: #00425A !important;
    border: 1px solid #BAD7E9 !important;
    gap: 10px !important;
    border-radius: 10px !important;
}

.profilebtnActive {
    background-color: #00425A !important;
    padding: 10px !important;
    font-size: 0.9rem !important;
    margin: 0 2px;
    font-weight: 500 !important;
    line-height: 21px !important;
    color: #fff !important;
    border: 1px solid #BAD7E9 !important;
    gap: 10px !important;
    border-radius: 10px !important;
}

.contactInfoBtn {
    background-color: #BAD7E9 !important;
    padding: 5px 10px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    color: #00425A !important;
    border: 0px !important;
    gap: 10px !important;
    border-radius: 19px !important;
}

.topbannerGrid {
    position: relative;
    margin-bottom: 40px;
}

.topbannerGrid .cardBody {
    position: absolute;
    bottom: -10%;
    margin-bottom: 16px;
    width: 100%;
    text-align: left;
    padding: 0px 20px;
}

.topbannerGrid .p-relative {
    position: relative;
}

.topbannerGrid .pencileIcon {
    position: absolute;
    top: 25px;
    right: 10px;
    width: 20px;
    height: 20px;
}

// Sidebar css
.sidebarheading {
    color: var(--primary) !important;
    font-size: 18px !important;
    line-height: 27px !important;
    font-weight: 500 !important;
    margin: 0px !important;
    padding: 0px !important;
    margin-bottom: 0.5rem !important;
    position: relative;
    display: flex;
    align-items: center;
}

.goalIcon {
    float: right;
    position: absolute;
    right: 0px;
    top: -10px;
}

.completeProfileCardSlider {
    background-color: #ffffff;
    // padding: 1rem;
    border-radius: 20px;
    margin-bottom: 30px;
}

.dashboard-main .outer-layout .second-column1 {
    padding: 0rem;
    // background-color: #ffffff;
    border-radius: 10px;
    height: auto;
}

.mobile-display {
    display: none;
}

.desktop-display {
    display: block;
}

.eeppage .mobile-company-list {
    display: none;
}

.eeppage .desktop-company-list {
    display: flex;
}

.completeProfileCard {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 20px;
    margin-bottom: 30px;
}

.sidevarAspiration {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #7E7E7E;
    margin-bottom: 0rem !important;
}

.sidevarAspirationSub {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #212529;
}

.bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 17px 0px;
}

$borderWidth: 5px;
$animationTime: .5s;
$border-color-default: #efefef;
$border-color-fill: #ffb43e;
$size: 160px;
$howManySteps: 100; //this needs to be even. 

.progressBarGrid {
    display: flex;
    align-items: center;
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 10px;
}

.alertIcon {
    float: right;
    position: absolute;
    right: 16px;
}

.progress1 {
    width: 60px;
    height: 60px;
    line-height: $size;
    background: none;
    //   margin: 0 auto;
    margin-right: 12px;
    box-shadow: none;
    position: relative;

    &:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: $borderWidth solid rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 0;
        left: 0;
    }

    >span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    .progress-left {
        left: 0;
    }

    .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: $borderWidth;
        border-style: solid;
        position: absolute;
        top: 0;
        border-color: #04EB84;

    }

    .progress-left .progress-bar {
        left: 100%;
        border-top-right-radius: ($size/2);
        ;
        border-bottom-right-radius: ($size/2);
        ;
        border-left: 0;
        -webkit-transform-origin: center left;
        transform-origin: center left;
        //animation: loading-2 1.5s linear forwards 1.8s;
    }

    .progress-right {
        right: 0;

        .progress-bar {
            left: -100%;
            border-top-left-radius: ($size/2);
            ;
            border-bottom-left-radius: ($size/2);
            ;
            border-right: 0;
            -webkit-transform-origin: center right;
            transform-origin: center right;
            //animation: loading-1 1.8s linear forwards;
        }
    }

    .progress-value {
        display: flex;
        border-radius: 50%;
        font-size: 1rem;
        text-align: center;
        line-height: 20px;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-weight: 300;

        div {
            //margin-top: 10px;
        }

        span {
            font-size: 12px;
            text-transform: uppercase;
        }

        .percentage-txt {
            color: #00425A;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            margin-left: 6px;
            margin-top: 4px;
        }
    }
}

/* This for loop creates the necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
@for $i from 1 through $howManySteps {
    $stepName: (
        $i*(100 / $howManySteps)
    );

//animation only the left side if below 50%
@if $i <=($howManySteps/2) {
    .progress1[data-percentage="#{$stepName}"] {
        .progress-right .progress-bar {
            animation: loading-#{$i} $animationTime linear forwards;
        }

        .progress-left .progress-bar {
            animation: 0;
        }
    }
}

//animation only the right side if above 50%
@if $i >($howManySteps/2) {
    .progress1[data-percentage="#{$stepName}"] {
        .progress-right .progress-bar {
            animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
        }

        .progress-left .progress-bar {
            animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
        }
    }
}
}

//animation
@for $i from 1 through ($howManySteps/2) {
    $degrees: (
        180/($howManySteps/2)
    );
$degrees: (
    $degrees*$i
);

@keyframes loading-#{$i} {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate($degrees);
        transform: rotate(#{$degrees}deg);
    }
}
}


// second page css
.wrap {
    display: flex;
    background: white;
    padding: 1rem 0rem 0rem 0rem;
    // border-radius: 0.5rem;
    // box-shadow: 7px 7px 30px -5px rgba(0,0,0,0.1);
    margin-bottom: 0rem;

}

.wrap:last-child .vcenter {
    border-bottom: 0px solid rgba(0, 0, 0, 0.1);
}

.dateTime {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: rgba(126, 126, 126, 1);
    margin-bottom: 0.5rem;
}

.dateTime span {
    padding: 0px 5px;
}

.dateTime a {
    color: rgba(33, 37, 41, 1);
    text-decoration: none;
}

.ico-wrap {
    margin: 7px 0px 7px 0px;
}

.mbr-iconfont {
    font-size: 4.5rem !important;
    color: #313131;
    margin: 1rem;
    padding-right: 0rem;
}

.vcenter {
    margin: 0px 0px 0px 15px;
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mbr-section-title3 {
    text-align: left;
}

.wrap h2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(33, 37, 41, 1);
}

.wrap p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: rgba(126, 126, 126, 1);
}

.skillsbtn {
    background-color: #fff !important;
    padding: 4px 10px !important;
    font-size: 12px !important;
    margin: 0px 7px 0px 0px;
    font-weight: 400 !important;
    line-height: 18px !important;
    /* color: #00425A !important; */
    border: 1px solid #BAD7E9 !important;
    gap: 10px !important;
    border-radius: 19px !important;
    color: rgba(0, 66, 90, 1) !important;
}

.skillDesc {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: 400 !important;
    color: rgba(126, 126, 126, 1);
}

.display-5 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.4rem;
}

.mbr-bold {
    font-weight: 700;
}

.readMore {
    margin: 0 auto;
}

.dropbtn {
    background-color: transparent;
    color: rgba(0, 66, 90, 1);
    padding: 13px 32px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid rgba(186, 215, 233, 1);
    margin-bottom: 10px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.blueCard {
    margin-bottom: 10px;
    background-color: #BAD7E9;
    border-radius: 20px;
    padding: 15px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background-image: url(../../../src/assets/images/borderLayer.png);
}

.boxblue {
    // background-color: rgb(186, 215, 233);
}

.primary-txt {
    color: #00425A !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    border: 1px solid #a7c1d1;
    border-radius: 20px;
    padding: 5px 10px;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: transparent;
    border: 1px solid rgba(186, 215, 233, 1);
    color: rgba(0, 66, 90, 1);
}

.desc-color {
    color: #979797;
}

.icon-bottom-border {
    width: 26px;
    border: 1px solid #00425A;
    margin: 0px auto;
}

.accompli-card {
    width: 154px !important;
    height: auto !important;
    text-align: center !important;
    background: linear-gradient(206.87deg, rgba(186, 215, 233, 0.2) 0%, rgba(45, 199, 255, 0.2) 105.71%);
    border: 1px solid #D8E9F3;
    border-radius: 20px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-right: 20px;
}

.accompli-icon {
    width: 75px;
    height: 75px;
    margin: 0px auto;
}

.accompli-title {
    color: #212529 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: center;
    margin-top: 10px !important;
}

.accompli-date {
    color: #212529 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-align: center;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.slider-indicator-icon {
    height: 10px !important;
    width: 67px !important;
    margin: 15px auto;
}

.appreciation-inner-card {
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 15px 0px #0000000F;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px 40px;
}

.appreciation-title {
    color: #00425A !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-align: center !important;
    margin-top: 10px !important;
}

.appreciation-post {
    color: #979797 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-align: center !important;
}

.appreciation-desc {
    color: #212529 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-align: center !important;
    margin-top: 10px !important;
}

.your-profile-txt {
    color: #DB3535;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0px !important;
}

.your-profile-description {
    color: #212529 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    margin-bottom: 0.5rem !important;
}

.harry-Card {
    background: linear-gradient(149.83deg, #B5F8D3 -1.77%, #DFF5F1 63.11%, #E1E8F3 96.4%);
    border-radius: 20px;
    padding: 24px;
    margin-bottom: 30px;

    .hey-txt {
        color: #00425A !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 24px;
        margin-bottom: 0rem !important;
    }

    .harry-txt {
        color: #00425A !important;
        font-size: 20px !important;
        font-weight: 600 !important;
        line-height: 30px;
        margin-bottom: 0.8rem !important;
    }

    .harry-description {
        color: #212529 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 24px;
        margin-bottom: 3rem !important;
    }

    .explore-btn {
        background: #00425A;
        border: 1px solid #00425A;
        padding: 12px 32px;
        border-radius: 10px;
        color: #FFFFFF !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 18px;
        margin-bottom: 0rem !important;
    }
}

.certification-title {
    color: #212529 !important;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 24px !important;
}

.certification-description {
    color: #7E7E7E !important;
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 21px !important;
}

.certification-date-level {
    color: #7E7E7E !important;
    font-size: 12px;
    font-weight: 400 !important;
    line-height: 18px !important;
}

@media only screen and (max-width: 900px) {
    .img-resposive {
        width: 100%;
        height: 151px;
    }

    .user-profile {
        width: 90px;
        height: 90px;
    }

    .topbannerGrid {
        .cardBody {
            bottom: 0%;
            padding: 0px 10px;
        }
    }

    .bottom-right {
        position: absolute;
        bottom: 5px;
        right: 5px;
    }

    .imageRightText {
        left: 10px;
        bottom: -50%;

        .userTitle {
            margin-left: 110px;

            span {
                display: block;
            }
        }

        .postionTitle {
            font-size: 14px;
            white-space: nowrap;

            img {
                margin-right: 5px;
            }
        }

        .px-3 {
            padding-left: 2px !important;
            padding-right: 2px !important;
        }
    }

    .pl-0 {
        padding-left: 0px !important;
    }

    .mt-20 {
        margin-top: 20px !important;
    }

    .btnList {
        overflow-x: auto;
        white-space: nowrap;
        scrollbar-width: none;
    }

    .accomplishment-list {
        overflow-x: auto;
        white-space: nowrap;
        padding-bottom: 10px;
        scrollbar-width: none;
    }

    .completeProfileCard {
        margin-bottom: 20px;
    }

    .accompli-title {
        white-space: break-spaces;
    }

    .mobile-display {
        display: block;
    }

    .desktop-display {
        display: none;
    }

    .eeppage .mobile-company-list {
        display: flex;
    }

    .eeppage .desktop-company-list {
        display: none;
    }

    .eeppage .horizontal-scroll-container {
        display: flex;
        overflow-x: auto;
        gap: 16px;
        padding: 8px;
        scroll-snap-type: x mandatory;
    }

    .blueCard {
        margin-bottom: 10px;
        background-color: #BAD7E9;
        border-radius: 20px;
        width: auto;
        height: auto;
        display: flex;
    }

    .sidebarheading {
        font-size: 16px !important;
        line-height: 20px !important;
    }

    .profilebtn {
        font-size: 12px !important;
        padding: 5px !important;
    }

    .profilebtnActive {
        font-size: 12px !important;
        padding: 5px !important;
    }

    .aboutheading {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    .content-section {
        width: 200px;
    }

    .accompli-card {
        width: 154px !important;
        border-radius: 20px;
        padding: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        align-items: center;
    }

    .accompli-date {
        text-align: center;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        width: 200px;
    }

    .appreciation-inner-card {
        padding: 20px 10px;
    }
}