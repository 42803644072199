.my-team-compensation {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .top-grid {
        display: flex;
        justify-content: space-between;

        .top-btn-List {
            display: flex;
            gap: 10px;
            margin-bottom: 20px;
        }

        .send-download-btn {
            background-color: #004259;
            padding: 8px 16px;
            border-radius: 10px;
            color: #F9F9F9;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            font-family: 'poppins';
        }
    }


    .active-btn {
        background-color: #BAD7E9;
        padding: 8px 16px;
        border-radius: 20px;
        border: none;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
        .top-btn-count{
            margin-left: 0.5rem;
            background-color: var(--primary);
            color: #ffffff;
            /* width: 1rem; */
            padding: 0 0.3rem;
            border-radius: 10px;
        }
    }

    .inActive-btn {
        background-color: transparent;
        padding: 8px 16px;
        border-radius: 20px;
        border: none;
        color: #979797;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
        .top-btn-count{
            margin-left: 0.5rem;
            background-color: var(--primary);
            color: #ffffff;
            /* width: 1rem; */
            padding: 0 0.3rem;
            border-radius: 10px;
        }
    }

    .tabs-outer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tabs {
            @include heading;
            font-size: 1.1rem;
            display: flex;
            flex-direction: row;
            background-color: #ffffff;
            border-radius: 2rem;
            width: max-content;

            .tab {
                border: none;
                background-color: #ffffff;
                border-radius: 2rem;
                cursor: pointer;
                padding: 0.5rem 2rem;
            }

            .tab.active {
                background-color: var(--primary);
                color: var(--graybg);
                font-weight: 500;
                border-radius: 2rem;
            }
        }
    }

    .table-card {
        background-color: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 10px;
        margin-top: 20px;

        .table-item {
            align-items: center;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 20px;
            padding-right: 20px;
        }

        .table-header-txt {
            color: #00425A;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'poppins';
        }

        .table-user-name {
            color: #00425A;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'poppins';
        }

        .table-user-id {
            color: #595959;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            font-family: 'poppins';
        }

        .item-border {
            height: 1px;
            width: 100%;
            background-color: #E6E6E6;
        }

        .ta-center {
            text-align: center;
        }

        .marginLeft-9 {
            margin-left: -9px;
        }

        .ml-10 {
            margin-left: 10px;
        }

        .ml-30 {
            margin-left: 30px;
        }

        .send-download-btn {
            background-color: #BAD7E9;
            padding: 8px 16px;
            border-radius: 10px;
            border: none;
            color: #00425A;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'poppins';
        }
    }


    .compensation-letter {
        .tabs-outer{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
            .tab{
                padding: 0.5rem 2rem;
                color: gray;
                border-radius: 2rem;
                span{
                    font-size: 0.7rem;
                    padding: 0 0.37rem;
                    border: 1px solid var(--primary);
                    border-radius: 1rem;
                }
            }
            .active-tab{
                color: var(--primary);
                background-color: var(--secondary);
                font-weight: 500;
            }
        }
        .year-dropdown{
            display: flex;
            justify-content: flex-end;
            margin-top: 1rem;
        }
        .compensation-letter-card {
            background-color: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 10px;
            padding: 24px;
            margin-top: 20px;

            .user-name {
                color: #00425A;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .letter-description {
                color: #00425A;
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                font-family: 'poppins';
                margin-top: 15px;
            }

            .w-100 {
                width: 100%;
            }

            .table-header {
                background-color: #00425A;
                border-radius: 10px;
                height: 50px;
                display: grid;
                grid-template-columns: 40% 15% 15% 15% 15%;
                margin-top: 15px;
                align-items: center;
                padding: 0px 20px;

                .header-txt {
                    color: #FFFFFF;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    font-family: 'poppins';
                }
            }

            .table-item {
                align-items: center;
                padding-top: 8px;
                padding-bottom: 8px;
                padding-left: 20px;
                padding-right: 20px;
                display: grid;
                grid-template-columns: 40% 15% 15% 15% 15%;
            }

            .table-list-value {
                color: #979797;
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .table-list-total {
                color: #000000;
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .item-border {
                height: 1px;
                width: 100%;
                background-color: #E6E6E6;
            }

            .item-total-border {
                height: 1px;
                width: 100%;
                background-color: #000000;
            }

            .ta-center {
                text-align: center;
            }
        }

        .download-letter-btn-grid {
            width: 100%;
            text-align: end;
            margin-top: 15px;
            
            .download-letter-btn {
                background-color: #00425A;
                padding: 8px 16px;
                border-radius: 10px;
                border: none;
                color: #F9F9F9;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                font-family: 'poppins';
            }
        }
    }
}