@import "../utils/utils.scss";

.promotions-form {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    

    .alllabeltext {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(39, 39, 39, 1);
    }
    .comm-left-text {
        color: #00425A;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        margin-top: 8px;
    }

    .boxpadding {
        padding: 24px;
    }

    .borderpadding {
        padding: 0px 24px;
    }

    .title-grid {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }
    .title-grid1{
        text-align: right;
    }
    .title-grid1 .view-stakeholder-feedback-btn{
        margin-left: 10px;
    }
    .mrleft-1{
        margin-right: 5px;
    }

    .head-delete {
        // font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 46.94px;
        text-align: left;
        float: left;
        color: #DB3535;

        display: flex;
        align-items: center;
        margin-right: 1rem;

        .title {
            color: #004259;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            text-transform: capitalize;
        }

        .btnpadding {
            padding: 0px 5px;
        }


    }

    .page-title {
        color: #00425A;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        font-family: 'poppins';
        align-self: center;
    }

    .view-stakeholder-feedback-btn {
        width: fit-content;
        background-color: var(--graybg);
        padding: 6px 15px;
        border-radius: 5px;
        border: 0.5px solid #979797;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .user-form-card {
        background-color: #ffffff;
        border-radius: 20px;
        // border: 1px solid #979797;

        .user-card {
            background-color: #F4F9FB;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            padding: 20px;
            border: 1px solid #c9e2eb99;
            border-bottom: 0px;

            .right-border {
                border-right: 1px solid #E6E6E6;
            }

            .ta-center {
                text-align: center;
            }

            .pl-0 {
                padding-left: 0px !important;
            }

            .pl-35 {
                padding-left: 35px !important;
            }

            .profile-name {
                color: #00425A;
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
                font-family: 'poppins';
                margin: 4px 0px 2px;

                img {
                    margin-left: 4px;
                    height: 8.73px;
                    width: 16px;
                }
            }

            .profile-emp-no {
                color: #00425A;
                font-size: 12px;
                line-height: 15px;
                font-weight: 400;
                font-family: 'poppins';
            }
            
            .profile-field-label {
                color: #979797;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .profile-field-value {
                color: #00425A;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                font-family: 'poppins';
            }
        }

        .form-card {
            background-color: #FFFFFF;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;


        }

        .save-submit-btn-div {
            display: flex;
            justify-content: end;
            margin-top: 20px;
            padding-right: 24px;

            .save-draft {
                border: 1px solid #004259;
                border-radius: 10px;
                padding: 13px 20px;
                background-color: #FFFFFF;
                color: #004259;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
                text-transform: capitalize;
            }

            .submit-list {
                background-color: #004259;
                border-radius: 10px;
                padding: 13px 20px;
                color: #FFFFFF;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
                text-transform: capitalize;
                margin-left: 15px;
            }
        }
    }

    .horizontal-border {
        height: 1px;
        width: 100%;
        background-color: #000000;
        opacity: 10%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .comm-left-text {
        color: rgba(0, 66, 90, 1);
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        margin-top: 0px;
    }

    .jc-end {
        justify-content: end;
    }

    .plan-cancel-btn {
        height: 40px;
        border: 1px solid #004259;
        border-radius: 8px;
        padding: 8px 20px;
        background-color: #FFFFFF;
        color: #004259;
        font-size: 14px;
        font-weight: 400;
    }

    .plan-save-btn {
        height: 40px;
        border: 1px solid #004259;
        border-radius: 8px;
        padding: 8px 20px;
        background-color: #004259;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        margin-left: 15px;
    }

    .role-border-div {
        display: block;
        overflow: hidden;
    }

    .uploadDoc {
        color: #004259;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        text-transform: capitalize;
        margin-top: 10px;
        cursor: pointer;
    }

    .deleteIconRight {
        right: 0px;
        top: 35px;
        position: absolute;
    }

    .deleteSelectBox {
        position: relative;
    }

    .selectIcondelete {
        display: flex;
        align-items: center;
    }

    .deleteSelectBox svg {
        color: red;
        cursor: pointer;
    }

    .pl-15 {
        padding-left: 15px;
    }
    
}

//----- Forms Stakeholders Right Drawer Start -----//
.from-stakeholders-drawer {
    padding: 30px 30px 30px 14px;
    width: 500px !important;
    margin-left: 0px !important;

    .user-grid {
        margin-left: 10px;
        align-content: center;
    }

    .right-user-name {
        color: #00425A;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        font-family: 'poppins';
    }

    .user-id {
        color: #979797;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        font-family: 'poppins';
    }

    .ta-right {
        text-align: right;
    }

    .title-stakeholders {
        color: #00425A;
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        font-family: 'poppins';
    }

    .top-btn-List {
        display: flex;
        gap: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .active-btn {
        background-color: #BAD7E9;
        padding: 8px 16px;
        border-radius: 20px;
        border: none;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .inActive-btn {
        background-color: transparent;
        padding: 8px 16px;
        border-radius: 20px;
        border: none;
        color: #979797;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .is-expanded-container {
        display: grid;
        grid-template-columns: 4% 96%;
    }
    .is-comments-container {
        width: 100%;
        background-color: #F4F1F1;
        border-radius: 12px;
        margin-top: 10px;
        border: 1px solid #D9D9D9;
    }
    .opacityBox {
      opacity: 60%;
    }

    .plus-minus-icon {
        height: 20px;
        width: 20px;
        margin-top: 10px;
        cursor: pointer;
    }
    .dotBlue-icon {
        height: 20px;
        width: 20px;
        margin-top: 0px;
        cursor: pointer;
    }
    .dot-icon {
        height: auto;
        width: auto;
        margin-right: 6px;
    }
    .rating-icon {
        height: auto;
        width: 100%;
        // margin-top: 25px;
        // cursor: pointer;
    }

    .grayCard {
        background-color: #F9F9F9;
        padding: 25px 30px;
        border-radius: 15px;
        // margin-top: 22px;
        margin-bottom: 15px;
    }

    .user-expanded-card {
        width: '100%';
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 4px 20px 3px;
    }
    .user-comments-card {
        padding: 17px;
        // opacity: 60%;

         span{
            padding: 0px;
        }
        .emp-name-checkbox svg{
            // display:none;
        }
    }

    .user-unfilled-expanded-card {
        width: '100%';
        background-color: #FFFFFF;
        border-radius: 20px;
        padding-top: 10px;
    }

    .d-flex {
        display: flex !important;
    }

    .user-box {
        height: 30px;
        width: 30px;
        background-color: #025573;
        border-radius: 15px;
        align-content: center;
        text-align: center;
        padding-top: 3px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
    }

    .user-name {
        color: #004259;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
        margin-left: 10px;
        align-content: center;
    }
    .hr-name {
        color: #7E7E7E;
        font-size: 14px;
        font-weight: 400;
        font-family: "poppins";
        line-height: 21px;
        margin-left: 0px;
        align-content: center;
    }
    .hr-desc {
        color: #7E7E7E;
        font-size: 16px;
        font-weight: 400;
        font-family: "poppins";
        line-height: 24px;
        margin-left: 0px;
        align-content: center;
    }    

    .personalDeatilTitle {
        color: #004259;
        font-size: 16px;
        font-weight: 600;
        font-family: "poppins";
        line-height: 24px;
        margin-bottom: 10px;
    }

    .personalDeatilleftTitle {
        color: #7E7E7E;
        font-size: 14px;
        font-weight: 400;
        font-family: "poppins";
        line-height: 21px;
        margin-bottom: 10px;
    }

    .personalDeatilRightTitle {
        color: #00425A;
        font-size: 14px;
        font-weight: 500;
        font-family: "poppins";
        line-height: 21px;
        margin-bottom: 10px;
    }

    .support-promotion {
        color: #00AA5A;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
        margin-left: 5px;
        margin-right: 5px;
    }
    .hrname {
        color: #004259;
        font-size: 14px;
        font-weight: 500;
        font-family: "poppins";
        line-height: 21px;
        margin-right: 5px;
        background-color: #EDFAFF;
        border-radius: 6px;
        padding: 3px 10px;
    }

    .do-not-support-promotion {
        color: #DB3535;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .description-grid {
        margin-top: 0px;
    }

    .description-title {
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
        margin-top: 10px;
    }

    .description {
        color: #7E7E7E;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
        margin-top: 5px;
    }

    .border-horizontal {
        height: 1px;
        background-color: #D9D9D9;
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .border-devider {
        height: 1px;
        background-color: #D9D9D9;
        margin-top: 25px;
        margin-bottom: 15px;
    }
}


.promotion-feedback-right-drawer {
    padding: 30px 30px 30px 14px;
    width: 500px !important;
    margin-left: 0px !important;
    .header-div {
        display: flex !important;
            border: 1px solid rgba(189, 215, 234, 1);
            width: max-content;
            /* line-height: 29px; */
            padding: 5px 15px;
            border-radius: 10px;

        .header-title {
            color: rgba(0, 66, 90, 1);
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 0px;
            line-height: 21px;
        }
        img{
            margin-left: 10px;
            width: 11px;
            margin-top: 1px;
        }
    }
    .user-grid {
        margin-left: 10px;
        align-content: center;
    }

    .feedback-user-name {
        color: #00425A;
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        font-family: 'poppins';
    }

    .feedback-user-id {
        color: #979797;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        font-family: 'poppins';
    }

    .ta-right {
        text-align: right;
    }

    .title-promotion-feedback {
        color: #00425A;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        font-family: 'poppins';
    }

    .field-label {
        color: #272727;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        font-family: 'poppins';
        opacity: 70%;
        margin-top: 20px;
    }

    .upload-document {
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        margin-top: 20px;
        cursor: pointer;
    }

    .file-size-label {
        color: #7E7E7E;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        font-family: 'poppins';
        margin-top: 5px;
    }

    .button-grid {
        display: flex;
        justify-content: end;
        margin-top: 20px;
        gap: 15px;

        .cancel-btn {
            background-color: #FFFFFF;
            border: 1px solid #004259;
            padding: 13px 20px;
            border-radius: 8px;
            color: #004259;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            font-family: 'poppins';
        }

        .send-request-btn {
            background-color: #004259;
            padding: 13px 20px;
            border-radius: 8px;
            color: #FFFFFF;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            font-family: 'poppins';
        }
    }
}

.share-drawer {

    .search-bar {
        width: 280px;
        background-color: #F9F9F9;
        border: 1px solid #D9D9D9;
        border-radius: 10px;
    }

    .role-border-div {
        border: 1px solid #BDD7EA;
        padding: 6px 10px;
        border-radius: 7px;
        width: fit-content;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .ai-center {
        align-items: center;
    }

    .role-type {
        color: #00425A;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        font-family: 'poppins';
    }

    .role-close-Icon {
        height: 12px;
        width: 12px;
        cursor: pointer;
        margin-left: 15px;
    }

    .user-name {
        color: #00425A;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        font-family: 'poppins';
        margin-top: 5px;
    }

    .border-horizontal {
        height: 1px;
        background-color: #D9D9D9;
        margin-top: 10px;
    }

    .send-btn-grid {
        display: flex;
        justify-content: end;
        margin-top: 20px;

        .send-btn {
            background-color: #00425A;
            border: none;
            padding: 8px 20px;
            border-radius: 10px;
            width: fit-content;
            color: #F9F9F9;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            font-family: 'poppins';
        }
    }
}

//----- Forms Stakeholders Right Drawer End -----//

.promotions-form-view {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .comm-left-text {
        color: #00425A;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        margin-top: 8px;
    }

    .grayTitle {
        color: #7E7E7E;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .blueTitle {
        color: #004259;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        .notsupported{
            color: #DB3535;
        }
        .supported{
            color: #00AA5A;
        }
    }

    .doticontext {
        align-items: baseline;
        display: flex;
    }

    .doticontext img {
        margin-right: 10px;
    }

    .boxpadding {
        padding: 24px 0px;
        margin: 15px 0px 0px;
        background-color: #fff;
        border-radius: 20px;
    }

    .borderpadding {
        padding: 0px 24px;
    }

    .title-grid {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }
    .title-grid1 {
        text-align: right;

        .view-stakeholder-feedback-btn{
            margin-left: 10px;
        }
    }

    .head-delete {
        // font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 46.94px;
        text-align: left;
        float: left;
        margin-bottom: 10px;

        display: flex;
        align-items: center;
        margin-right: 1rem;

        .title {
            color: #004259;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            text-transform: capitalize;
        }

        .btnpadding {
            padding: 0px 5px;
        }


    }

    .page-title {
        color: #00425A;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        font-family: 'poppins';
        align-self: center;
    }

    .view-stakeholder-feedback-btn {
        width: fit-content;
        background-color: var(--graybg);
        padding: 6px 15px;
        border-radius: 5px;
        border: 0.5px solid #979797;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .user-form-card {
        // background-color: #ffffff;
        border-radius: 20px;
        // border: 1px solid #979797;

        .user-card {
            border-radius: 20px;
            padding: 20px;
            border: 1px solid #CEE0E7;
            background-color: #F4F9FB;

            .right-border {
                border-right: 1px solid #E6E6E6;
            }

            .ta-center {
                text-align: center;
            }

            .pl-0 {
                padding-left: 0px !important;
            }

            .pl-35 {
                padding-left: 35px !important;
            }

            .profile-name {
                color: #00425A;
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
                font-family: 'poppins';

                img {
                    margin-left: 4px;
                    height: 8.73px;
                    width: 16px;
                }
            }

            .profile-emp-no {
                color: #00425A;
                font-size: 12px;
                line-height: 15px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .profile-field-label {
                color: #979797;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .profile-field-value {
                color: #00425A;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                font-family: 'poppins';
            }
        }

        .form-card {
            background-color: #FFFFFF;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;


        }

        .save-submit-btn-div {
            display: flex;
            justify-content: end;
            margin-top: 20px;
            padding-right: 24px;

            .save-draft {
                border: 1px solid #004259;
                border-radius: 10px;
                padding: 13px 20px;
                background-color: #FFFFFF;
                color: #004259;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
                text-transform: capitalize;
            }

            .submit-list {
                background-color: #004259;
                border-radius: 10px;
                padding: 13px 20px;
                color: #FFFFFF;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
                text-transform: capitalize;
                margin-left: 15px;
            }
        }
         .save-submit-btn-div-right {
            display: flex;
            justify-content: end;
            margin-top: 20px;
            padding-right: 0px;
            width: 100%;

            .save-draft {
                border: 1px solid #004259;
                border-radius: 10px;
                padding: 13px 20px;
                background-color: #FFFFFF;
                color: #004259;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
                text-transform: capitalize;
            }

            .submit-list {
                background-color: #004259;
                border-radius: 10px;
                padding: 13px 20px;
                color: #FFFFFF;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
                text-transform: capitalize;
                margin-left: 15px;
            }
        }
    }

    .horizontal-border {
        height: 1px;
        width: 100%;
        background-color: #000000;
        opacity: 10%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .comm-left-text {
        color: #979797;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 8px;
    }

    .jc-end {
        justify-content: end;
    }

    .plan-cancel-btn {
        height: 40px;
        border: 1px solid #004259;
        border-radius: 8px;
        padding: 8px 20px;
        background-color: #FFFFFF;
        color: #004259;
        font-size: 14px;
        font-weight: 400;
    }

    .plan-save-btn {
        height: 40px;
        border: 1px solid #004259;
        border-radius: 8px;
        padding: 8px 20px;
        background-color: #004259;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        margin-left: 15px;
    }

    .role-border-div {
        display: block;
        overflow: hidden;

        // .customRadiocolor svg{
        //     fill: #004259 !important;
        // }
        .customRadiocolor path{
            fill:#004259 !important;
        }
    }


    .uploadDoc {
        color: #004259;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        text-transform: capitalize;
        margin-top: 10px;
        cursor: pointer;
    }

    .deleteIconRight {
        right: 0px;
        top: 35px;
        position: absolute;
    }

    .deleteSelectBox {
        position: relative;
    }

    .selectIcondelete {
        display: flex;
        align-items: center;
    }

    .deleteSelectBox svg {
        color: red;
        cursor: pointer;
    }

    .pl-15 {
        padding-left: 20px;
    }
    .pr-15 {
        padding-right: 20px;
    }

}