@import '../../utils/utils.scss';

.changed-report{
    .tablee{
        background-color: #ffffff;
        margin: 1rem 0px;
        border-radius: 10px;
        .feedback-emp-list-table{
            width: 100%;
            background-color: #ffffff;
            th, td{
                padding: 0.5rem;
            }
            tr{
                border-bottom: 1px solid #e2e2e2;
            }
            th {
                font-size: 0.9rem;
                color: var(--primary);
                font-weight: 500;
                background-color: #ffffff;
            }
            th.e-name{
                min-width: 7rem;
            }
            .table-head-emp-list{
                height: 4rem;
            }
            .mid-cols{
                text-align: left;
            }
            .mid-cols-small{
                max-width: 8rem;
            }
            .mid-cols-medium{
                width: 7rem;
            }
            .last-col-large{
                width: 9rem;
            }
            .last-col{
                width: 9rem;
                text-align: start;
                
            }
            .last-col.head{
                padding-right: 1.8rem;
            }
            td{
               @include normalText;
               padding: 0.5rem;
               .emp-i{
                .name{
                    color: var(--primary);
                }
                .emp-id{
                    @include smallText;
                }
               }
               .revised{
                color: #69C88E;
               }
               .not-revised{
                color: red;
               }
               .empDetaila{
                .empPic{
                    display: none;
                }
               }
               .the-reason{
                max-height: 5rem;
                overflow-y: scroll;
               }
            }
        }
    }
}
/* width */
.the-reason::-webkit-scrollbar {
    width: 0.32rem;
  }
  
  /* Track */
  .the-reason::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .the-reason::-webkit-scrollbar-thumb {
    background: #c7c7c7;
  }
  
  /* Handle on hover */
  .the-reason::-webkit-scrollbar-thumb:hover {
    background: #a9a9a9;
  }