@import "../utils/utils.scss";

.manager-feedbacks-member-form {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .feedback-title-grid {
        display: flex;
        justify-content: space-between;
    }
    
    .page-title {
        color: #00425A;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        font-family: 'poppins';
        align-self: center;
    }

    .user-form-card {
        background-color: #F4F9FB;
        border: 1px solid #CEE0E7;
        border-radius: 20px;
        padding: 20px;

        .right-border {
            border-right: 1px solid #E6E6E6;
            margin-top: 10px;
        }

        .ta-center {
            text-align: center;
        }

        .pl-0 {
            padding-left: 0px !important;
        }

        .pl-35 {
            padding-left: 35px !important;
        }

        .profile-name {
            color: #00425A;
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            font-family: 'poppins';
            margin: 4px 0px 2px;
            img {
                margin-left: 4px;
                height: 8.73px;
                width: 16px;
            }
        }
        
        .profile-emp-no {
            color: #00425A;
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
            font-family: 'poppins';
        }

        .profile-field-label {
            color: #979797;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            font-family: 'poppins';
        }

        .profile-field-value {
            color: #00425A;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            font-family: 'poppins';
        }
    }

    .top-btn-List {
        display: flex;
        gap: 10px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .active-btn {
        background-color: #BAD7E9;
        padding: 8px 16px;
        border-radius: 20px;
        border: none;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .inActive-btn {
        background-color: transparent;
        padding: 8px 16px;
        border-radius: 20px;
        border: none;
        color: #979797;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .top-btn-count {
        padding: 4px 8px;
        background-color: #00425A;
        border-radius: 15px;
        color: #FFFFFF;
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        font-family: 'poppins';
        margin-left: 10px;
    }

    .is-expanded-container {
        display: grid;
        grid-template-columns: 4% 96%;
    }

    .user-expanded-card {
        width: '100%';
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 20px;
    }

    .d-flex-jc-sb {
        display: flex;
        justify-content: space-between;
    }

    .d-flex {
        display: flex !important;
    }

    .user-box {
        height: 30px;
        width: 30px;
        background-color: #025573;
        border-radius: 15px;
        align-content: center;
        text-align: center;
        padding-top: 3px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        position: relative;
    }

    .user-name {
        color: #004259;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
        margin-left: 10px;
        align-content: center;
    }

    .user-accepted {
        color: #00AA5A;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .user-denied {
        color: #DB3535;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .feedback-request {
        color: #7E7E7E;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
    }

    .user-date {
        color: #7E7E7E;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
    }

    .plus-minus-icon {
        height: 20px;
        width: 20px;
        margin-top: 25px;
        cursor: pointer;
    }
    .plus-minus-icon1 {
        position: absolute;
    top: 0px;
    margin-top: 0px;
    width: 30px;
    left: 0px;
    height: 30px;
    background-repeat: no-repeat;
    }

    .description-grid {
        margin-top: 5px;
        margin-left: 40px;
        margin-right: 100px;
    }

    .description {
        color: #7E7E7E;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
    }

    .border-horizontal {
        height: 1px;
        background-color: #D9D9D9;
        margin-top: 10px;
    }

    .user-mt-15 {
        margin-top: 15px;
    }

    .reply-btn {
        width: 64px;
        height: 32px;
        background-color: #FFFFFF;
        border: 0.5px solid #979797;
        border-radius: 5px;
        margin-top: 15px;
        color: #00425A;
        font-size: 16px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 24px;
    }

    .cancel-send-btn-grid {
        display: flex;
        margin-top: 15px;

        .cancel-btn {
            width: 120px;
            height: 45px;
            background-color: #FFFFFF;
            border: 1px solid #004259;
            border-radius: 8px;
            color: #004259;
            font-size: 14px;
            font-weight: 500;
            font-family: 'poppins';
            line-height: 21px;
        }

        .send-reply-btn {
            width: 120px;
            height: 45px;
            background-color: #004259;
            border: 1px solid #004259;
            border-radius: 8px;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 500;
            font-family: 'poppins';
            line-height: 21px;
            margin-left: 15px;
        }
    }
}