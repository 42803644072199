@import "../../../utils/utils.scss";

.host-session{
    .schedule-form-outer-block{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem; 
        .schedule-ip-wrap{
            padding-left: 0.5rem;
            display: flex;
            align-items: center;
            background-color: var(--graybg);
        }
        .session-name-ip{
            display: flex;
            flex-direction: column;
        }
    }
    .search-popup-req-feed{
        position: absolute;
        z-index: 111;
        width: 100%;  
        padding: 0.7rem;
        box-shadow: 0px 0px 6px 1px #e1e1e1;
        background-color: #ffffff;
        max-height: 20rem;
        overflow-y: scroll;
        .search-emp-outer{
            display: flex;
            justify-content: space-between;
            margin: 0.5rem 0px;
            .empDetaila{
                display: flex;
                .empPic{
                    .userImg{
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;                                
                    }
                }
                .userInfo{
                    margin-left: 1rem;
                    .name {
                        @include subHeadingDark;
                        font-size: 0.81rem
                    }
                    .designation{
                        @include smallText;
                    }
                }            
            }
            .search-add-emp-btn{
                padding: 0px 1.5rem;
                background-color: var(--secondary);
                color: var(--primary);
                border-radius: 10px;
                border: none;
                font-size: 0.81rem;
            }
            .search-added-emp-btn{
               @include smallText;
            }
        }
        
       }
       .search-wrapper{
        .search-added-emp-btn{
            display: flex;
            justify-content: center;
            .empDetaila{
                display: flex;
                .empPic{
                    .userImg{
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;                                
                    }
                }
                .userInfo{
                    margin-left: 1rem;
                    .name {
                        @include subHeadingDark;
                        font-size: 0.9rem
                    }
                    .designation{
                        @include smallText;
                    }
                }
                
            }
            svg{
                margin-left: 1rem;
                cursor: pointer;
            }
        }
        .view-all-search{
            @include subHeadingDark;
            font-size: 0.9rem;
            cursor: pointer;
            text-align: center;
           }
       }
       
}