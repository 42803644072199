.performance-rate{

    .ratings-annual{
        color: var(--primary);
        display: flex;
        .header-annualcycle{
           width: 50%;
           span:first-child{
            font-weight: 500;
           }        
        }
        .rating-outer{
           width: 25%;
           span:first-child{
            font-weight: 500;
           }        
        }
    }

    .detail-outer{
        background-color: var(--graybg);
        border-radius: 10px;
        padding: 0.5rem 1rem;
        .que-peer{
            margin: 0.5rem 0px;
            font-size: 0.87rem;
            line-height: 1rem;
            color: var(--primary);
        }
        .question-outer{
            padding: 1rem 0px;
            .que{
                margin: 0.5rem 0px;
                font-size: 0.87rem;
                line-height: 1rem;
                color: var(--primary);
            }
            .peer-feedback-section{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                .peer-feedback-btn-outer{
                    .show-peer-feedback-button{
                        width: 7rem;
                        height: 2.1rem;
                        background-color: #ffffff;
                        border: 1px solid var(--primary);
                        border-radius: 5px;
                        font-size: 0.7rem;
                        padding: 0.5rem;
                        color: var(--primary);
                        &:hover{
                            font-size: 0.77rem;
                            padding: 0.465rem;
                        }
                        &:active{
                            font-size: 0.7rem;
                            padding: 0.5rem;
                        }
                    }
                }
            }
            .emp-response-outer{
                margin-top: 1rem;
                width: 100%;
                display: flex;
                justify-content: space-between;
                .emp-details{
                    width: 25%;
                    .user-container{
                        display: flex;
                        justify-content: flex-start;
                        .icon-container{
                            .icon-size{
                                width: 45px;
                                overflow: hidden;
                                height: 45px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                        .title-container{
                            margin-left: 0.5rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            .upper-title{
                                font-size: 0.87rem;
                                line-height: 1rem;
                                color: var(--primary);
                            }
                            .lower-title{
                                font-size: 0.7rem;
                                color: var(--tertiary);
                            }
                        }
                    }
                }
                .emp-response{
                    width: 75%;
                    display: flex;
                    flex-direction: column;
                    font-size: 0.75rem;
                    color: var(--primary);
                    .text-responses{                        
                        background-color: #ffffff;
                        padding: 0.5rem;
                        border-radius: 10px;
                    }
                    .selections{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        .selected-value{
                            margin-top: 0.5rem;
                            margin-right: 0.25rem;
                            padding: 0.25rem 0.5rem;
                            background-color: var(--secondary);
                            border-radius: 10px;
                        }
                    }
                }
                
            }
        }
        .question-outer.border-b{
          border-bottom: 1px solid #cdc6c6;
        }
    }
    .peer-feedback-in-sidebar{
        .heading{
            font-size: 1.1rem;
            font-weight: 600;
            color: var(--primary);
        }
    }
    .pdf-content{
        position: absolute;
        left: -9999px;
        top: -9999px;
    padding: 1rem;
    background: white;
    color: black;
    max-width: 600px;
    margin: auto;
    .text-divider {
        width: 100%;
        text-align: center;
        margin: 20px 0;
        font-size: 16px;
        font-weight: bold;
        color: red;
      }
    .ratings-annual{
        color: var(--primary);
        font-size: 0.65rem;
        display: flex;
        .header-annualcycle{
            width: 50%;
            span:first-child{
             font-weight: 500;
            }        
         }
        .rating-outer{
           width: 25%;
           span:first-child{
            font-weight: 500;
           }
        }
    }
        .annual-cycle-heading{
            font-size: 1rem;
            font-weight: 600;
            text-align: center;
            color: var(--primary);
        }
        .emp-name-heading{
            font-size: 0.75rem;
            font-weight: 600;
            text-align: center;
            color: var(--primary);
        }
        .accordian-heading{
            font-size: 0.65rem;
            font-weight: 600;
        }
        .detail-outer{
            background-color: var(--graybg);
            border-radius: 10px;
            padding: 0.5rem 0.5rem;
            .que-peer{
                margin: 0.5rem 0px;
                font-size: 0.57rem;
                color: var(--primary);
            }
            .question-outer{
                padding: 0.25rem 0px;
                .que{
                    margin: 0.5rem 0px;
                    font-size: 0.57rem;
                    color: var(--primary);
                }
                .peer-feedback-section{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    .peer-feedback-btn-outer{
                        .show-peer-feedback-button{
                            width: 7rem;
                            height: 2.1rem;
                            background-color: #ffffff;
                            border: 1px solid var(--primary);
                            border-radius: 5px;
                            font-size: 0.7rem;
                            padding: 0.5rem;
                            color: var(--primary);
                            &:hover{
                                font-size: 0.77rem;
                                padding: 0.465rem;
                            }
                            &:active{
                                font-size: 0.7rem;
                                padding: 0.5rem;
                            }
                        }
                    }
                }
                .emp-response-outer{
                    margin-top: 0.25rem;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    .emp-details{
                        width: 25%;
                        .user-container{
                            display: flex;
                            justify-content: flex-start;
                            .icon-container{
                                .icon-size{
                                    width: 45px;
                                    overflow: hidden;
                                    height: 45px;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                            .title-container{
                                margin-left: 0.5rem;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                .upper-title{
                                    font-size: 0.57rem;
                                    color: var(--primary);
                                }
                                .lower-title{
                                    font-size: 0.47rem;
                                    color: var(--tertiary);
                                }
                            }
                        }
                    }
                    .emp-response{
                        width: 75%;
                        display: flex;
                        flex-direction: column;
                        font-size: 0.57rem;
                        color: var(--primary);
                        .text-responses{                        
                            background-color: #ffffff;
                            padding: 0.57rem;
                            border-radius: 10px;
                        }
                        .selections{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            .selected-value{
                                margin-top: 0.57rem;
                                margin-right: 0.25rem;
                                padding: 0.25rem 0.25rem;
                                background-color: var(--secondary);
                                border-radius: 10px;
                            }
                        }
                    }
                    
                }
            }
            .question-outer.border-b{
              border-bottom: 1px solid #cdc6c6;
            }
        }
        .peer-feedback-in-sidebar{
            .heading{
                font-size: 0.55rem;
                font-weight: 600;
                color: var(--primary);
            }
        }
    }
    .not-fount{
        .text{
            color: var(--primary);
            text-align: center;
            margin-top: 2rem;
            font-size: 1.5rem;
            font-weight: 600;
        }
        img{
            width: 100%;
        }

    }
}

.performance-history{
    .header-box{
        background-color: var(--primary);
        color: #ffffff;
        padding: 1rem;
        border-radius: 0.5rem;
        display: flex;
        justify-content: space-between;
        span:first-child{
            width: 30%;
        }
        span:last-child{
            margin-right: 4rem;
        }
    }
}