.form-row-page-pass-change{
    padding: 40px 30px !important;
    background-color: var(--graybg);
    height: calc(100vh - 58px);
    .card-div{
        background-color: #ffffff;
        width: 70%;
        padding: 4rem;
        margin: auto;
        @media(max-width:768px){
            width: 100%;
        }
    }

    .popup-pass-change-text1{
        @include subHeadingDark;
        font-size: 0.9rem;
        text-align: center;
        font-weight: 500;
        margin-bottom: 0.7rem;
    }
    .popup-pass-change-text{
        @include subHeadingDark;
        font-size: 0.9rem;
        padding: 0px 1rem;
    }
    .user-change-password-page{
        .main-btn {
            .MuiStack-root {
                margin-left: 0;
                margin-right: 0;
            }
        
            .valuesBtn {
                line-height: 24px;
                height: 2.5rem;
                    
                width: 100%;
            }
    
        
            .next {
                @include subHeadingLight;
                border: none;
                color: #fff;
                background: var(--primary);
            }
        }
    
        .accorDetails.personalInfo {
            background: #fff;
            border-radius: 20px;
            position: relative;
            .eyeIcon{
                border: none;
                background-color: #ffffff;
                position: absolute;
                right: 0.75rem;
                top: 32%;
                cursor: pointer;
                padding: 0px 0.25rem;
                border-radius: 1rem;
                &:hover{
                    background-color: var(--secondary);
                }
            }
            .accordian-title {
                color: #401C73;
                font-size: 16px;
                font-weight: 600;
                line-height: 36px;
                letter-spacing: 0em;
            }
        }
    
        .accordian-data {
            .d-flex {
                border-bottom: 1px solid #000;
                padding: 10px 0;
            }
            .title {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #7E7E7E;
                margin: 0;
            }
            .subTitle {
                margin: 0;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0em;
                color: #401C73;
            }
        }
    }
    
}