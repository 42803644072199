.peer-feedback-container-in-sidebar {
    p{
        margin: 0;
        padding: 0;
    }
    color: var(--primary);
    .main-heading{
        font-size: 1.15rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    .section-main{
        margin-bottom: 1rem;
        .section-label{
            font-size: 0.9rem;
            font-weight: 500;
            margin-bottom: 1rem;
        }  
        .question-label{
            font-size: 0.77rem;
            font-weight: 500;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }
        .feedback-text{
            font-size: 0.73rem;
            font-weight: 400;
            margin-bottom: 1rem;
            .emp-response{
                // width: 75%;
                display: flex;
                flex-direction: column;
                // font-size: 0.75rem;
                color: var(--primary);
                .text-responses{                        
                    background-color: #ffffff;
                    color: gray;
                    padding: 0.5rem;
                    border-radius: 10px;
                }
                .selections{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .selected-value{
                        margin-top: 0.5rem;
                        margin-right: 0.25rem;
                        padding: 0.25rem 0.5rem;
                        background-color: var(--secondary);
                        border-radius: 10px;
                    }
                }
            }
        }
        .sub-section-main{
            .feedback-item{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .icon-size{
                    width: 45px;
                    div{
                        border-radius: 25px;
                    }
                    img{
                        width: 45px;
                        border-radius: 25px;
                    }
                }
                .feedback-header{
                    height: 45px;
                    width: 25%;
                    margin-left: 0.5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: strat;
                    .employee-info{
                        font-size: 0.84rem;
                        font-weight: 500;
                    }
                    .employee-department{
                        font-size: 0.7rem;
                        font-weight: 500;
                        color: gray;
                    }
                }
                .feedback-content{
                    width: 75%;
                    .feedback-text{
                        .text-responses{
                        background-color: #ffffff;
                        }
                    }
                }
            }
        }
    }
      
}

