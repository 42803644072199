@import "../utils/utils.scss";

.sidedrawer-notification {
  z-index: 10;
  background: #ffffff;
  overflow-y: scroll;
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 30%;
  animation: slideIn 500ms linear;

  .close-drawer-icon {
    float: right;
    cursor: pointer;
    &:hover {
      padding: 0px 2px;
      border-radius: 5px;
      background-color: #f0f0f0;
    }
  }

  border-left: 2px solid gray;
  box-shadow: 0px 0px 208px 10px;

  .drawer-children-main {
    margin: 1rem 0px;
    @include smallText;

    div {
      .scrl-div {
        position: absolute;
        width: 91%;
        height: 100%;
        padding-bottom: 5rem;

        .heading {
          @include subHeadingDark;
          font-size: 0.9rem;
          margin-top: 0.5rem;
          margin-bottom: 0.15rem;
        }

        .big-heading {
          @include subHeadingDark;
          font-size: 0.9rem;
          margin-top: 0.5rem;
          margin-bottom: 0.15rem;
        }

        .situatin {
          background-color: #d9ead3;
        }

        .behavior {
          background-color: #d9d2e9;
        }

        .impact {
          background-color: #f4cccc;
        }

        .color-info {
          display: flex;
          justify-content: space-evenly;

          p {
            display: flex;
            align-items: center;
            margin-bottom: 0;
          }

          .color-block {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 1rem;
          }

          .situatin {
            background-color: #d9ead3;
          }

          .behavior {
            background-color: #d9d2e9;
          }

          .impact {
            background-color: #f4cccc;
          }
        }
      }
      .main-heading {
        @include heading;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
      }
      .filter-div {
        display: flex ;
        justify-content:space-between;

        .only-show-unread{
          font-size: 16px ;
          margin-right: 8px;
        }
        .mark-as-read {
          font-size: 0.9rem;
          cursor: pointer;
          color: #1976d2;
        }
      }
      .notification-div {
        border: 1px solid #ccc;
        padding: 5px;
        margin: 5px;
        cursor: pointer;
        border-radius: 5px;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        .dot {
          height: 8px;
          justify-self: flex-end;
          padding-inline: 4px;
        }
        .title {
          @include heading;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: 0em;
        }
        .message {
          font-size: 0.9rem;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: var(--tertiary);
          margin: 0;
        }
        .date {
          font-size: 0.9rem;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: var(--tertiary);
          margin: 0;
        }
      }
      .empty-msg{
        font-size: 0.9rem;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--tertiary);
        margin: 0;
        margin-top: 20px;
      }
    }
  }

  .drawer-table {
    border: 1px solid gray;

    th {
      background-color: #dcdcdc;
      color: #000000;
      font-weight: 500;
    }

    tr,
    th,
    td {
      border: 1px solid gray;
      padding: 0.25rem;
    }
  }
}

.d-sidedrawer-notification {
  background: #ffffff;
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 50%;
  animation: slideOut 500ms linear;
}

@keyframes slideIn {
  0% {
    transform: translateX(400px);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translateX(0px);
    animation-timing-function: ease-in;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0px);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translateX(100%);
    animation-timing-function: ease-in;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* width */
.sidedrawer-notification::-webkit-scrollbar {
  width: 0.32rem;
}

/* Track */
.sidedrawer-notification::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sidedrawer-notification::-webkit-scrollbar-thumb {
  background: #c7c7c7;
}

/* Handle on hover */
.sidedrawer-notification::-webkit-scrollbar-thumb:hover {
  background: #d31818;
}
