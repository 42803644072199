@import '../utils/utils.scss';

.functional-leader{
    height: calc(100vh - 58px);
    padding: 0px 2rem;
    background-color: var(--graybg);
    .search-wrapper{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0px;
        .r-f-tabs{
            display: flex;
            align-items: center;
            .tab{
                border: none;
                width: fit-content;
                text-align: center;
                @include subHeadingDark;
                margin-right: 1rem;
                padding: 0.5rem 2rem;
                padding-left: 1rem;
                border-radius: 48px;
                cursor: pointer;
                position: relative;
                span{
                    padding: 0.25rem;
                    font-size: 0.65rem;
                    border-radius: 1rem;
                    background-color: var(--primary);
                    color: #ffffff;
                    position: absolute;
                    right: 5px;
                    width: 21px;
                    text-align: center;
                }
            }
            .tab.active{
                background-color: var(--secondary);
            }
        }
        .second-div{
            display: flex;
            align-items: center;
            .view-reports{
                @include subHeadingDark;
                font-weight: 500;
                text-decoration: underline;
                cursor: pointer;
            }
            .approve-all-btn{
                @include subHeadingDark;
                font-weight: 400;
                border: none;
                border-radius: 10px;
                background-color: #69C88E;
                color: #ffffff;
                margin-left: 0.5rem;
                padding: 1.05rem 1.25rem;
                width: 7rem;
            }
            .filter-tab{
                @include subHeadingDark;
                position: relative;
                margin-left: 0.5rem;
                border: 1px solid var(--primary);
                border-radius: 10px;
                padding: 1.05rem 1.25rem;
                width: 7rem;
                cursor: pointer;
                img{
                    margin-right: 0.5rem;
                }
                .red-dot{
                    width: 12px;
                    height: 12px;
                    background-color: red;
                    border-radius: 6px;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            }
        }
    }
    .functional-leader-main{
        .tablee{
            background-color: #ffffff;
            .feedback-emp-list-table{
                width: 100%;
                background-color: #ffffff;
                th, td{
                    padding: 0.5rem;
                }
                tr{
                    border-bottom: 1px solid #e2e2e2;
                }
                th {
                    font-size: 0.9rem;
                    color: var(--primary);
                    padding: 0.75rem 0px;
                    font-weight: 500;
                    background-color: #ffffff;
                    
                }
                th.emp-name {
                    display: flex;
                    align-items: center;
                    padding-left: 0.5rem;
                    svg{
                        font-size: 1.1rem;
                    }
                    .sessionName{
                        margin-left: 0.5rem;
                    }
                    
                }
                .table-head-emp-list{
                    height: 4rem;
                }
                .mid-cols{
                    width: 25%;
                    text-align: center;
                }
                
                td{
                    font-size: 0.75rem;
                    padding: 0.5rem;
                    .title{
                        display: flex;
                        align-items: center;
                        svg{
                            font-size: 1rem;
                        }
                        .empInfo{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: left;
                            margin-left: 0.5rem;
                            .name{
                                @include subHeadingDark;
                            }
                            .sub{
                                width: 100%;
                                @include smallText;
                            }
                            
                        }                        
                    }
                    .btns{
                        display: flex;
                        justify-content: space-between;
                        font-size: 0.75rem;
                        .send-btn{
                            cursor: pointer;
                            background-color: var(--secondary);
                            color: var(--primary);
                            border: none;
                            padding: 5px 10px;
                            border-radius: 10px;
                        }
                    }
                
                    .action{
                        display:flex ;
                        align-items: center;
                        button{
                            font-family: 'poppins';
                            padding: 0.25rem;
                            svg{
                                color: var(--primary);
                                font-size: 1.25rem;
                            }
                        }
                        
                    }
                }
            }
        } 
    }
}