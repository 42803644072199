.access-denied{
    width: 100%;
    text-align: center;
    .heading{
        margin-top: 5rem;
        font-size: 2.5rem;
        color: var(--primary);
        font-weight: 600;
    }
    .text{
        font-size: 1.5rem;
        color: var(--primary);
        margin-top: 2rem;
        margin-bottom: 2.5rem;
    }
    .go-back-btn{
        border: none;
        background-color: var(--primary);
        color: #ffffff;
        font-size: 1.25rem;
        padding: 0.5rem 2.5rem;
    }
}