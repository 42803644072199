.selfAssessment.container{
    max-width: 1550px;
}
.selfAssessment-page{
    padding: 40px 30px !important;
    background-color: #F9F9F9;
    .banner {
        width: 100%;
        border-radius: 25px;
        padding-bottom: 40px;
        height: 150px;
    }
    .profile-details {
        padding: 18px 30px 0px 30px;
        background-color: #fff;
        border-radius: 15px;
        position: relative;
        top: -113px;
        left: 20px;
        text-align: center;
        .user {
            border-radius: 300px;
            width: 100%;
        }
        .userName {
            font-size: 26px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 0em;
            color: var(--primary);
            margin: 0;
        }
        .category {
            font-size: 17px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            color: var(--tertiary);
            margin: 0;
        }
        .links {
            text-align: left;
            margin: 0;
            padding-top: 60px;
            .head {
                font-size: 17px;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: 0em;
                color: var(--primary);
                border-bottom: 1px solid var(--primary);
                width: fit-content;
                margin: 0;
                padding-bottom: 10px;
                margin-bottom: 20px;
            }
            .link {
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: 0em;
                margin: 0;
                color: #000;
                opacity: 30%;
                padding-bottom: 30px;
            }
        }
        img{
            width: 60% !important;
        }
    }

    .accordian-data {
        .d-flex {
            border-bottom: 1px solid #000;
            padding: 10px 0;
        }
        .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--tertiary);
            margin: 0;
        }
        .subTitle {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            color: var(--primary);
        }
    }

    .buttonDiv {
        p {
            margin: 0;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            color: var(--primary);
        }
        p.active {
            color: #fff;
            background: var(--primary);
            padding: 15px 30px;
            border-radius: 10px;
        }
    }

    .accorDetails.personalInfo {
        background: #fff;
        border-radius: 20px;
        padding: 30px 40px;
        margin: 0 1rem;
        margin-bottom: 30px;
        .accordian-title {
            color: var(--primary);
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 0em;
        }
    }

    .feedback {
        background: #fff;
        border-radius: 20px;
        padding: 30px 30px;
        margin: 0 1rem;
        .heading {
            padding-bottom: 20px;
            .title {
                font-size: 32px;
                font-weight: 600;
                line-height: 48px;
                letter-spacing: 0em;
                color: #000000;
                margin: 0;
            }
            .membCount {
                color: #000;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0em;
                margin: 0;
            }
            .member {
                font-family: Poppins;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                margin: 0;
                color: #000;
            }
        }
        .userData {
            border: 1px solid #00000038;
            border-radius: 10px;
            padding: 18px;
            margin-bottom: 15px;
            .text {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                color: #252525;
                margin: 0;
            }
            .userImg {
                width: 46px;
            }
            .name {
                color: #000;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0em;
                margin: 0;
            }
            .member {
                margin: 0;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                color: #595959;
            }
            button.btn {
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0em;
                border-radius: 10px;
                width: 130px;
            }
            button.btn.green {
                background: #69C88E;
            }
            button.btn.red {
                background: #DB3535;
            }

        }
    }

    .feedbackAssessment {
        border-radius: 20px;
        background-color: #fff;
        padding: 20px 10px;
        .userImg {
            width: 75px;
        }
        .bor-b {
            border-bottom: 1px solid #00000026;
            padding-bottom: 20px;
        }
        .title {
            font-size: 32px;
            font-weight: 600;
            line-height: 48px;
            letter-spacing: 0em;
            color: #000;
            margin: 0;
        }
        .id {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            margin: 0;
            color: #595959;
        }
        .viewBtn {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            color: #000;
            margin: 0;
            padding: 13px 15px;
            border-color: #000;
            outline: none;
            background: #fff;
            border-radius: 10px;
        }
     
    }

    
}
.selfAssessment{
    .input-chat-section{
        .MuiBox-root{
        .messageInput{
            position: relative;
           input{
            
            width: 100%;
            background-color: #F9F9F9;
            border: 1px solid #F9F9F9;
            padding: 1rem;
            border-radius: 1rem;
             }
             button{
                position: absolute;
                top: 0;
                right: 0;
                svg{
                    color: #000000;
                    font-size: 2rem;
                    margin-top: 0.41rem;
                }
             }
           }
        }
    }
    .trendingRating-o {
        background-color: #ffffff;
        padding: 1rem;
    }
    .trendingRating {
        .title {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 0em;
            margin: 0;
            color: #000;
        }
        .rateOuter {
            padding-top: 25px;
            padding-bottom: 0;
            @media(max-width:500px){
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
            }
            @media(min-width:500px){
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-gap: 10px;
            }
        }
        .rate {
            border-radius: 10px;
            background: #F9F9F9;
            position: relative;
            padding: 15px 12px;
            text-align: center;
            width: 100%;
            cursor: pointer;
            
            .rateTitle {
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                color: var(--tertiary);
                margin: 0;
            }
            .checked {
                opacity: 0;
                position: absolute;
                right: 10px;
                top: 5px;
                width: 12px;
            }
        }
        .rate.active {
            border: 1px solid #000;
            .checked {
                opacity: 1;
            }
        }

        .rate.deactive {
            .rateTitle{
                color: #cfcfcf;
                background-color: #f5f5f5;
                cursor: no-drop;
            }
        }

        .contentDiv {
            .contentHead {
                padding: 1rem;
                .title {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                    letter-spacing: 0em;
                    color: #000000;
                    margin: 0;
                    .red {
                        color: #DB3535;
                    }
                }

            }
            .values {
                padding-bottom: 30px;
                grid-template-columns: repeat(6,minmax(0,1fr));
                .valueTitle {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 30px;
                    letter-spacing: 0em;
                    color: var(--tertiary);
                    margin: 0;
                    text-align: center;
                    padding: 20px 20px;
                    border-radius: 10px;
                    position: relative;
                    .checked {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        opacity: 0;
                    }
                }
                .valueTitle.active {
                    border: 1px solid #000;
                    .checked {
                        opacity: 1;
                    }
                }
                .value1 {
                    background: #FCF8E8;
                }
                .value2 {
                    background: #FFE6E6;
                }
                .value3 {
                    background: #F6FBF4;
                }
                .value4 {
                    background: #EAF6F6;
                }
                .value5 {
                    background: #DAEAF1;
                }
                .value6 {
                    background: #F9F9F9;
                }
                .textarea {
                    width: 100%;
                    background: #F9F9F9;
                    border: none;
                    border-radius: 10px;
                    height: 65px;
                    padding: 10px 20px;
                    outline: none;
                }
                .btn {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0em;
                    border-radius: 10px;
                    padding: 10px 50px;
                }
                .save {
                    border: 1px solid #000;
                    background: #fff;
                }
                .next {
                    background: #000;
                    color: #fff;
                }
                .MuiStack-root.css-13xfuc4-MuiStack-root {
                    margin: 1rem 0rem;
                }
                
            }
        }
        
    .values {
        .MuiStack-root {
            margin-left: 0;
            margin-right: 0;
        }
        .valuesBtn {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            width: 163px;            
            height: 50px;
            border-radius: 10px;
        }
        .draft {
            color: #000;
            background: #fff;
        }
        .next {
            color: #fff;
            background: #000;
        }
    }
    }
}
.selfAssessment {
    padding: 28px 38px;
    background: #F9F9F9;
    border-radius: 0;
    .headingPage {
        background: #fff;
        padding: 0 50px 0 50px;
        margin-bottom: 30px;
        .userName {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            color: #000;
            margin: 0;
        }
        .alert {
            margin: 0;
        }
        .setting {
            margin: 0;
        }
    }
    .contentDiv {
        padding: 1rem;
        background: #fff;
        border-radius: 20px;
        .title {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 0em;
            color: #000;
        }
        .values {
            .MuiStack-root {
                margin-left: 0;
                margin-right: 0;
            }
            .valuesBtn {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0em;
                width: 163px;            
                height: 50px;
                border-radius: 10px;
            }
            .draft {
                color: #000;
                background: #fff;
            }
            .next {
                color: #fff;
                background: #000;
            }
        }
    }
}