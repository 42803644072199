@import "../utils/utils.scss";

.report-performance {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .chart-report-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px 25px;
    }

    .card-100per {
        height: 100%;
    }

    .feedback-comparison-grid {
        .feedback-comparison {
            color: #00425A;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            font-family: 'poppins';
        }

        .ta-center {
            text-align: center;
        }

        .chart-icon {
            margin: 10px auto;
            width: 100%;
        }

        .bottom-d-flex {
            display: flex;
        }

        .self-box {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: #2D99FE;
        }

        .other-box {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: #CD77DB;
        }

        .company-avg-box {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: #7BC784;
        }

        .box-label {
            color: #00425A;
            font-size: 14px;
            line-height: 15px;
            font-weight: 400;
            margin-top: 5px;
            margin-left: 5px;
            margin-right: 30px;
        }
    }

    .scoring-behaviours-grid {
        .scoring-behaviours {
            color: #00425A;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            font-family: 'poppins';
        }

        .item-mt-10 {
            margin-top: 10px;
        }

        .scoring-beha-container {
            display: grid;
            grid-template-columns: 10% 90%;
            grid-column-gap: 10px;

            .item-count {
                background-color: #0ED678;
                border-radius: 10px;
                height: 30px;
                width: 30px;
                align-content: center;
                text-align: center;
                color: #FFFFFF;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
            }

            .item-bt-border {
                border-bottom: 1px solid #EAF3F8;

                .item-title {
                    color: #00425A;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                }

                .own-answer-avg {
                    color: #979797;
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 400;
                }

                .blue-dark-color {
                    color: #00425A;
                    margin-right: 5px;
                }

            }

            .item-mt-6 {
                margin-top: 6px;
            }

            .item-mb-10 {
                margin-bottom: 10px;
            }
        }
    }

    .summary-of-competencies-grid {
        .summary-of-competencies {
            color: #00425A;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            font-family: 'poppins';
        }

        .competencies-container {
            overflow-x: auto;
        }

        .competencies-container::-webkit-scrollbar {
            height: 6px;
        }

        .competencies-container::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 10px;
        }

        .competencies-container::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
        }

        .competencies-scroll {
            flex-wrap: nowrap;
            gap: 16px;
        }

        .competencies-card {
            width: 210px;
            background-color: #ffffff;
            border-radius: 14px;
            padding: 20px;
            margin-top: 10px;
            cursor: pointer;
        }

        .competencies-title {
            color: #00425A;
            font-size: 16px;
            line-height: 18px;
            font-weight: 500;
            font-family: 'poppins';
        }

        .competencies-description {
            color: #979797;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            font-family: 'poppins';
            margin-top: 5px;
        }

        .jc-sb {
            justify-content: space-between;
            margin-top: 10px;
        }

        .slider-label {
            color: #00425A;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            font-family: 'poppins';
        }

        .slider-count {
            color: #00425A;
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            font-family: 'poppins';
        }
    }

    .summary-feedback-grid {
        .summary-feedback {
            color: #00425A;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            font-family: 'poppins';
        }

        .strengths {
            color: #00425A;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            font-family: 'poppins';
            margin-top: 10px;
        }

        .item-strengths-bt-border {
            border-bottom: 1px solid #EAF3F8;
            margin-top: 10px;
            margin-bottom: 10px;

            .item-title {
                color: #00425A;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .item-description {
                color: #979797;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                font-family: 'poppins';
                margin-top: 5px;
                margin-bottom: 10px;
            }
        }
    }
}