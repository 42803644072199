@import "../utils/utils.scss";

.hrSuccessionPlanPage {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1rem 2rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .d-flex {
        display: flex;
    }

    .jc-sb {
        justify-content: space-between;
    }

    .jc-end {
        justify-content: flex-end;
    }

    .schedule-session-btn {
        background-color: #004259;
        border-radius: 8px;
        height: 43px;
        padding: 10px 20px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .top-tabs {
        display: flex;
        align-items: center;

        .active {
            background: #BDD7EA;
            height: 48px;
            border: none;
            border-radius: 40px;
            padding: 12px 16px;
            margin-right: 30px;
            color: #004259;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
        }

        .deactive {
            background: transparent;
            height: 48px;
            border: none;
            border-radius: 40px;
            padding: 12px 16px;
            margin-right: 30px;
            color: #979797;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
        }

        .tab-count {
            background-color: #004259;
            padding: 5px 6px;
            border-radius: 50%;
            color: #FFFFFF;
            font-size: 10px;
            font-weight: 500;
            margin-left: 5px;
        }

    }


    .scheduled-session-card {
        background: #fff;
        border-radius: 20px;
        padding: 30px 0px;
        padding-top: 10px;
        margin-top: 20px;

        .scheduled-session-table {
            width: 100%;
            background-color: #ffffff;

            .send-btn{
                background-color: var(--secondary);
                color: var(--primary);
                border: none;
                padding: 5px 10px;
                border-radius: 10px;
                min-width: 4.3rem;
                svg{
                    margin-right: 0.25rem;
                }
            }

            th,
            td {
                padding: 0.5rem;
                text-align: left;
            }

            tr {
                border-bottom: 1px solid #e2e2e2;
                padding-left: 20px;
                padding-right: 20px;
            }

            th {
                font-size: 0.9rem;
                color: var(--secondary);
                font-weight: 500;
            }

            .table-heading {
                height: auto;
            }

            .table-head-label {
                color: #004259;
                font-size: 14px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .action-w-120 {
                width: 120px;
            }

            .col-w-150 {
                width: 150px;
            }

            .table-value {
                color: #7E7E7E;
                font-size: 14px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .table-live-value {
                color: #DB3535;
                font-size: 14px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .live-bullet-point {
                font-size: 30px;
                vertical-align: middle;
                line-height: 5px;
            }

            .scheduled-time {
                font-size: 12px;
            }

            .post-count {
                background-color: #004259;
                padding: 4px 6px;
                border-radius: 50%;
                color: #FFFFFF;
                font-size: 10px;
                font-weight: 500;
                margin-left: 5px;
            }

            .start-btn {
                background: #BAD7E9;
                height: 40px;
                padding: 8px 25px;
                border-radius: 10px;
                border: none;
                color: #004259;
                font-size: 16px;
                font-weight: 400;
                cursor: pointer;
                text-transform: capitalize;
            }

            .c-pointer {
                cursor: pointer;
            }

            .icon-mr-5 {
                margin-right: 5px;
            }
        }
    }

    .left-right-arrow {
        height: 20px;
        width: 12px;
        align-self: center;
    }

    .ml-10 {
        margin-left: 10px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .pl-20 {
        padding-left: 20px;
    }

    .pr-20 {
        padding-right: 20px;
    }

    .pr-105 {
        padding-right: 105px;
    }

    .ta-end {
        text-align: end;
    }

    .pagination-border {
        height: 32px;
        width: 32px;
        border: 0.5px solid #979797;
        border-radius: 5px;
        margin-left: 10px;
        padding-top: 3px;
        text-align: center;
        align-content: center;

        .pagination-count {
            color: #004259;
            font-size: 16px;
            font-weight: 400;
        }
    }

    .succession-analytics-grid {

        .heading-div {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .analytics-heading {
                color: #004259;
                font-size: 20px;
                line-height: 30px;
                font-weight: 600;
                font-family: 'poppins';
                align-content: center;
            }
        }

        .succession-analytics-card {
            background: #fff;
            border-radius: 20px;
            // padding: 24px;
            padding-bottom: 24px;
            margin-top: 20px;

            .p-24 {
                padding: 24px;
            }

            .head-grid {
                margin-top: 5px;
                margin-bottom: 10px;
                padding: 0px 24px;
            }

            .analytics-field-label {
                color: #272727;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                font-family: 'poppins';
                text-align: left;
                opacity: 70%;
            }

            .d-flex {
                display: flex !important;
            }

            .ai-center {
                align-items: center;
            }

            .dropdown-roles-container {
                display: grid;
                grid-template-columns: 4% 96%;
            }

            .role-container {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                margin-top: 10px;
            }

            .role-border-div {
                border: 1px solid #BDD7EA;
                padding: 6px 10px;
                border-radius: 7px;
                width: fit-content;
            }

            .role-type {
                color: #00425A;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            .role-close-Icon {
                height: 12px;
                width: 12px;
                cursor: pointer;
                margin-left: 15px;
            }

            .list-title {
                color: #00425A;
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
                font-family: 'poppins';
            }

            .list-description {
                color: #7E7E7E;
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .analytics-item {
                height: 60px;
                padding: 5px 24px;
                align-content: center;
            }

            .item-b-border {
                border: 0.5px solid #000000;
                opacity: 10%;
            }

            .item-title {
                color: #00425A;
                font-size: 18px;
                line-height: 27px;
                font-weight: 600;
                font-family: 'poppins';
                height: 45px;
                align-content: center;
                text-align: end;
            }

            .count-grid {
                height: 45px;
                width: 135px;
                border-radius: 12px;
                text-align: center;
                align-content: center;
            }

            .green-bg {
                background-color: #94E49C;
            }

            .dark-pink-bg {
                background-color: #EF7474;
            }

            .dark-orange-bg {
                background-color: #FFB865;
            }

            .yellow-bg {
                background-color: #FAEF8D;
            }

            .item-count {
                color: #000000;
                font-size: 18px;
                line-height: 27px;
                font-weight: 600;
                font-family: 'poppins';
            }
        }
    }
}