@import '../utils/utils.scss';

.common-dialog {
    padding: 1.5rem;

    .feedback-dialog-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 40px;

        .checkIcon {
            width: 5rem;
        }

        .content {
            @include heading(0.9rem auto);
            font-family: Poppins;
            text-align: center;
        }

        .button {
            margin-top: 0.9rem;
            border-radius: 7px;
            background-color: var(--primary);
            color: var(--secondary);
            padding: 0.4rem 2rem;
            font-size: var(--subHeaderFontSize);
            font-weight: 600;
            border: none;

            &:hover {
                background-color: #2a2a2a;
            }

            &:active {
                background-color: #000000;
            }
        }

        .multi-btn {
            display: flex;
            justify-content: center;

            button:nth-child(2) {
                border: 1px solid var(--primary);
                color: var(--primary);
                margin-left: 1rem;
                background-color: #ffffff;
            }
        }
    }

    .check-approved-popup-main {
        font-family: poppins;
        margin: 0px 1rem;
        margin-top: 1rem;
        width: 25rem;

        svg {
            font-size: 1.5rem;
            position: absolute;
            right: 5px;
            top: 5px;
            color: #696464;
            cursor: pointer;

            &:hover {
                background-color: #eeeeee;
            }
        }

        .messsage {
            @include subHeadingDark;
            font-weight: 500;
            margin: 1rem 0px
        }

        .table-heading {
            @include subHeadingDark;
        }

        table {
            width: 100%;
            margin: 1rem 0px;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 6px #ececec;

            tr {
                border-bottom: 1px solid rgb(195, 195, 195);
            }

            th,
            td {
                padding: 0.5rem;
            }

            thead {
                th {
                    font-size: 0.9rem;
                    font-weight: 500;
                }
            }

            tbody {
                td {
                    font-size: 0.81rem;
                }
            }
        }

        .btn-outer {
            width: 100%;
            display: flex;
            justify-content: center;

            .approve-btn {
                @include subHeadingDark;
                font-weight: 400;
                border: none;
                border-radius: 10px;
                background-color: #69C88E;
                color: #ffffff;
                margin: 0.5rem auto;
                padding: 0.7rem 1.25rem;
                width: 7rem;
            }

            .approve-done-btn {
                @include subHeadingDark;
                font-weight: 400;
                border: none;
                border-radius: 10px;
                background-color: var(--primary);
                color: #ffffff;
                margin: 0.5rem auto;
                padding: 0.7rem 1.25rem;
                width: 7rem;
            }
        }

        .button-outer-view-bottom {
            margin-top: 1rem;
            width: 100%;
            display: flex;
            justify-content: center;

            .approve-cancel-btn {
                @include subHeadingDark;
                font-weight: 400;
                border: none;
                border-radius: 10px;
                background-color: #ffffff;
                color: var(--primary);
                margin: 0.5rem;
                padding: 0.7rem 1.25rem;
                width: 7rem;
                border: 1px solid var(--primary);
            }

            .approve-done-btn {
                @include subHeadingDark;
                font-weight: 400;
                border: none;
                border-radius: 10px;
                background-color: var(--primary);
                color: #ffffff;
                margin: 0.5rem;
                padding: 0.7rem 1.25rem;
                width: 7rem;
            }
            .reject-btn {
                background-color: #DB3535;
                border: 1px solid #DB3535;
            }
        }

    }

    .delegate-modal {
        gap: 10px;

        .user-border-div {
            border: 1px solid #BDD7EA;
            padding: 6px 10px;
            border-radius: 7px;
            width: fit-content;
            margin-top: 10px;
        }

        .user-d-flex-ai-center {
            display: flex;
            align-items: center;
        }
    
        .user-close-Icon {
            height: 12px;
            width: 12px;
            cursor: pointer;
            margin-left: 15px;
        }

        .border-user-name {
            color: #00425A;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        .delegate-user-name {
            color: #00425A;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-top: 10px;
        }

        .horizontal-border {
            height: 1px;
            width: 100%;
            background-color: #000000;
            opacity: 10%;
            margin-top: 10px;
            margin-bottom: 15px;
        }

        .delegate-div {
            display: flex;
            justify-content: flex-end;
        }

        .delegate-btn {
            height: 40px;
            border: 1px solid #004259;
            border-radius: 8px;
            padding: 8px 20px;
            background-color: #004259;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 400;
        }
    }
}