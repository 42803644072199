@import "../utils/utils.scss";

// .hr-promotions {
//     width: 100%;
//     height: calc(100vh - 58px);
//     padding: 1.5rem;
//     background-color: var(--graybg);
//     overflow-y: auto;

//     .title-grid {
//         display: flex;
//         justify-content: space-between;
//         margin-bottom: 0px;
//     }

//     .page-title {
//         color: #00425A;
//         font-size: 20px;
//         line-height: 24px;
//         font-weight: 600;
//         font-family: 'poppins';
//         align-self: center;
//     }

//     .view-scheduled-sessions-btn {
//         width: fit-content;
//         background-color: #004259;
//         padding: 12px 15px;
//         border-radius: 8px;
//         border: none;
//         color: #FFFFFF;
//         font-size: 14px;
//         line-height: 21px;
//         font-weight: 500;
//         font-family: 'poppins';
//         text-transform: capitalize;
//     }

//     .validation-icon {
//         height: 20px;
//         width: 20px;
//     }

//     .icon-hw-15 {
//         height: 15px;
//         width: 15px;
//         cursor: pointer;
//     }

//     .validated-grid {
//         display: flex;
//         gap: 10px;

//         .validated-txt {
//             color: #00425A;
//             font-size: 16px;
//             line-height: 24px;
//             font-weight: 400;
//             font-family: 'poppins';
//         }
//     }

//     .c-pointer {
//         cursor: pointer;
//     }

//     .gap-2 {
//         gap: 2px;
//     }

    .table-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding-top: 12px;
        padding-bottom: 20px;
        margin-bottom: 20px;

        .nominate-promo-table {
            background-color: #ffffff;

            .emp-list-table {
                width: 100%;
                background-color: #ffffff;

                th,
                td {
                    padding: 0.5rem;
                }

                tr {
                    border-bottom: 1px solid #e2e2e2;
                }

                tr:last-child {
                    border-bottom: 0px solid #e2e2e2;
                }

                th {
                    font-size: 0.9rem;
                    color: var(--primary);
                    font-weight: 500;
                    background-color: #ffffff;

                }

                .table-head-emp-list {
                    height: 4rem;
                }

                .w-130 {
                    width: 130px !important;
                }

                .ta-end {
                    text-align: end;
                }

                .pl-20 {
                    padding-left: 20px !important;
                }

                .pr-20 {
                    padding-right: 20px !important;
                }

                .emp-name-checkbox {
                    color: #00425A;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: 'poppins';
                }

                .emp-table-value {
                    color: #979797;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: 'poppins';
                }
            }
        }
    }
// }