@import "../utils/utils.scss";

.my-leave {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .title-grid {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .page-title {
        color: #00425A;
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        font-family: 'poppins';
        align-self: center;
    }

    .right-top-grid {
        display: flex;
        gap: 10px;
    }

    .leave-glance-btn {
        width: auto;
        background-color: #004259;
        padding: 12px 15px;
        border-radius: 10px;
        border: none;
        color: #FFFFFF;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .leave-glance-btn:hover {
        width: auto;
        background-color: #004259;
        padding: 12px 15px;
        border-radius: 10px;
        border: none;
        color: #FFFFFF;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .my-team-btn {
        width: auto;
        background-color: #BAD7E9;
        padding: 12px 15px;
        border-radius: 10px;
        border: none;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .my-team-btn:hover {
        width: auto;
        background-color: #BAD7E9;
        padding: 12px 15px;
        border-radius: 10px;
        border: none;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .down-arrow {
        color: #00425A;
        font-size: 20px;
        cursor: pointer;
        margin-left: 10px;
    }

    .top-btn-List {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
    }

    .active-btn {
        background-color: #BAD7E9;
        padding: 8px 16px;
        border-radius: 20px;
        border: none;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .inActive-btn {
        background-color: transparent;
        padding: 8px 16px;
        border-radius: 20px;
        border: none;
        color: #979797;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .c-pointer {
        cursor: pointer;
    }

    .top-slider-card {
        border-radius: 20px;
        margin-bottom: 20px;

        .top-slider-container {
            overflow-x: auto;
            scrollbar-width: none;
            /* Firefox */
        }

        .top-slider-container::-webkit-scrollbar {
            height: 6px;
            display: none;
            /* Chrome, Safari, Edge, and Opera */
        }

        .top-slider-container::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 10px;
        }

        .top-slider-container::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
        }

        .top-slider-scroll {
            flex-wrap: nowrap;
            gap: 16px;

            .top-slider-item-div {
                cursor: pointer;
            }
        }

        .bullet-icon {
            height: 8px;
            width: 57px;
            display: flex;
            margin: 20px auto;
            margin-bottom: 0px;
        }

        .top-slider-item {
            height: 100%;
            width: 231px;
            min-width: 231px;
            background-color: #FFFFFF;
            box-shadow: 0px 0px 10px 0px #0000001A;
            border-radius: 14px;
            padding: 16px;

            .sub-item {
                display: grid;
                grid-template-columns: 75% 25%;

                .sub-item-title {
                    color: #004259;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    font-family: 'poppins';
                    cursor: pointer;
                    white-space: nowrap;
                }

                .leaves-grid {
                    display: grid;
                    grid-template-columns: 55% 20% 25%;
                }

                .sub-title {
                    color: #7E7E7E;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    font-family: 'poppins';
                }

                .i-button {
                    height: 12px;
                    width: 12px;
                    cursor: pointer;
                    margin-left: 10px;
                    margin-bottom: 3px;
                }

                .calendar-icon {
                    height: 32px;
                    width: 32px;
                    border: 1px solid #D4E9F6;
                    border-radius: 50%;
                    padding: 4px;
                    display: flex;
                    justify-self: end;
                }
            }
        }
    }

    .my-leave-history {
        .table-title {
            color: #00425A;
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            font-family: 'poppins';
            margin-bottom: 10px;
        }

        .table-card {
            background-color: #ffffff;
            border-radius: 20px;
            padding-top: 12px;
            padding-bottom: 20px;
            margin-bottom: 20px;

            .leave-history-table {
                background-color: #ffffff;
                overflow-x: auto;

                .history-list-table {
                    width: 100%;
                    background-color: #ffffff;
                    white-space: nowrap;

                    th,
                    td {
                        padding: 0.5rem;
                    }

                    tr {
                        border-bottom: 1px solid #e2e2e2;
                    }

                    tr:last-child {
                        border-bottom: 0px solid #e2e2e2;
                    }

                    th {
                        font-size: 0.9rem;
                        color: var(--primary);
                        font-weight: 500;
                        background-color: #ffffff;
                    }

                    .table-head-history-list {
                        height: 4rem;
                    }

                    .w-180 {
                        width: 180px !important;
                    }

                    .w-500 {
                        width: 500px !important;
                    }

                    .ta-end {
                        text-align: end;
                    }

                    .ta-center {
                        text-align: center !important;
                    }

                    .pl-20 {
                        padding-left: 20px !important;
                    }

                    .pr-20 {
                        padding-right: 20px !important;
                    }

                    .leave-history-table-value {
                        color: #979797;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 400;
                        font-family: 'poppins';
                    }

                    .manager-table-value {
                        color: #7E7E7E;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 400;
                        font-family: 'poppins';
                    }

                    .status-table-value {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 400;
                        font-family: 'poppins';
                    }

                    .action-btn {
                        width: 141px;
                        background-color: #004259;
                        padding: 8px 18px;
                        border-radius: 10px;
                        border: none;
                        color: #FFFFFF;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 400;
                        font-family: 'poppins';
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}

.leave-drawer {

    .top-drawer {
        display: grid;
        grid-template-columns: 75% 25%;

        .leave-cal-grid {
            display: flex;
            gap: 10px;

            .leave-calendar-icon {
                height: 36px;
                width: 36px;
                border: 1px solid #D4E9F6;
                border-radius: 50%;
                padding: 4px;
            }

            .leaves-title {
                color: #000000;
                font-size: 24px;
                line-height: 36px;
                font-weight: 600;
                font-family: 'poppins';
            }

            .taken-out-of {
                color: #979797;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                font-family: 'poppins';
            }
        }

        .apply-btn {
            background-color: #004259;
            border: none;
            padding: 8px 20px;
            border-radius: 8px;
            width: fit-content;
            height: 40px;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            font-family: 'poppins';
            justify-self: end;
        }
    }

    .border-horizontal {
        height: 1px;
        background-color: #D9D9D9;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .bullet-points {
        color: #000000;
        font-size: 20px;
        margin-left: 5px;
        margin-right: 10px;
    }

    .leave-sub-title {
        color: #000000;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
    }

    .leave-description {
        color: #7E7E7E;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .ml-25 {
        margin-left: 25px;
    }

    .entitlement-card-border {
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        margin-bottom: 15px;

        .entitlement-container {
            display: grid;
            grid-template-columns: 49% 2% 49%;

            .card-title {
                height: 40px;
                background-color: #EDFAFF;
                color: #7E7E7E;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                font-family: 'poppins';
                padding-top: 8px;
                padding-left: 15px;
            }

            .left-top-border-radius {
                border-top-left-radius: 8px;
            }

            .right-top-border-radius {
                border-top-right-radius: 8px;
                margin-left: -8px;
            }

            .card-description {
                color: #000000;
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                font-family: 'poppins';
                padding: 8px 15px;
            }

            .ml-8 {
                margin-left: -8px;
            }

            .vertical-border {
                width: 1px;
                height: 100%;
                background-color: #D9D9D9;
            }
        }
    }

    .close-btn-grid {
        display: flex;
        justify-content: start;
        margin-top: 15px;

        .close-btn {
            background-color: #FFFFFF;
            border: 1px solid #004259;
            padding: 8px 20px;
            border-radius: 10px;
            width: fit-content;
            color: #004259;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            font-family: 'poppins';
        }
    }
}


.apply-modal {
    padding: 20px;

    .modal-heading {
        color: #272727;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
    }

    .taken-out-of {
        color: #979797;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
        margin-top: 10px;
    }

    .modal-border-horizontal {
        height: 1px;
        background-color: #D9D9D9;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .field-label {
        color: #272727;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        font-family: 'poppins';
        margin-bottom: 4px;
    }

    .leave-days {
        color: #7E7E7E;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        font-family: 'poppins';
    }

    .mt-15 {
        margin-top: 15px;
    }

    .action-grid {
        margin-top: 20px;
        text-align: center;

        .cancel-btn {
            background-color: #FFFFFF;
            border: 1px solid #004259;
            padding: 8px 20px;
            border-radius: 10px;
            width: 120px;
            color: #004259;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            font-family: 'poppins';
            text-transform: capitalize;
        }

        .submit-btn {
            background-color: #004259;
            border: 1px solid #004259;
            padding: 8px 20px;
            border-radius: 10px;
            width: 120px;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            font-family: 'poppins';
            text-transform: capitalize;
            margin-left: 10px;
        }
    }
}

.withdraw-leave-modal {
    padding: 30px 20px;
    text-align: center;

    .modal-heading {
        color: #272727;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
    }

    .family-care-leave-grid {
        background-color: #F4F4F4;
        border-radius: 8px;
        padding: 8px 16px;
        width: fit-content;
        margin: 10px auto;

        .family-care-leave {
            color: #000000;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            font-family: 'poppins';
        }

        .date-days {
            color: #979797;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            font-family: 'poppins';
        }
    }

    .action-withdraw-leave-grid {
        margin-top: 20px;
        text-align: center;

        .cancel-btn {
            background-color: #FFFFFF;
            border: 1px solid #004259;
            padding: 8px 20px;
            border-radius: 10px;
            width: 100px;
            color: #004259;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            font-family: 'poppins';
            text-transform: capitalize;
        }

        .yes-btn {
            background-color: #004259;
            border: 1px solid #004259;
            padding: 8px 20px;
            border-radius: 10px;
            width: 100px;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            font-family: 'poppins';
            text-transform: capitalize;
            margin-left: 10px;
        }
    }
}

.leave-glance-modal {
    padding: 20px;

    .modal-heading {
        color: #272727;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
    }

    .heading-border-horizontal {
        height: 1px;
        background-color: #D9D9D9;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .leave-table-border {
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        overflow: hidden;
        border-collapse: separate;

        .table-head {
            background-color: #EDFAFF;

            .head-title {
                color: #7E7E7E;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                font-family: 'poppins';
                white-space: nowrap;
                padding-top: 8px !important;
                padding-bottom: 8px !important;
            }
        }

        .table-cell {
            border-right: 1px solid #D9D9D9;
        }

        .table-value {
            color: #000000;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'poppins';
            padding-top: 5px !important;
            padding-bottom: 5px !important;
        }

        .leave-type {
            white-space: nowrap;
        }
    }

    .action-leave-glance-grid {
        margin-top: 15px;
        text-align: center;

        .close-btn {
            background-color: #FFFFFF;
            border: 1px solid #004259;
            padding: 8px 20px;
            border-radius: 10px;
            width: 100px;
            color: #004259;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            font-family: 'poppins';
            text-transform: capitalize;
        }
    }
}