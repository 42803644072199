@import '../utils/utils.scss';

.selfAssesmentAllinone{
    .feedback-main {
        display: flex;
        // padding: 40px 30px !important;
        background-color: #F9F9F9;
        // height: calc(100vh - 58px);

        .invisible-info-section{
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            overflow-y: auto;
            background-color: #ffffff;
            border-radius: 0.625rem;
            width: 20rem;
            // margin: 1rem 0px;
            // margin-right: 1rem;
            padding: 0.5rem;
            // animation: slideIn 0.5s ease-in-out; // Added animation
            animation: growWidth 0.5s ease-out; /* Animation duration and timing */
            transform-origin: left; /* Ensure growth starts from the left */
            svg{
                font-size: 1.5rem;
                color: gray;
                float: inline-end;
                cursor: pointer;
            }
            .info-content {             
                // width: min-content;   
                font-size: var(--smallTextFontSize);
                color: var(--tertiary);
                .se-image-container{
                    display: flex;
                    justify-content: center;
                }
        
                table{
                    margin: 1rem 0px;
                    width: 100%;
                    border-collapse: collapse;
                }
                th{
                    background: #FFD24C;
                    padding: 0.25rem 0.5rem;
                    border: 0.5px solid #aeaeae; 
                }
                td{
                    background: #F5F7F7;
                    padding: 0.25rem 0.5rem;
                    vertical-align: text-top;
                    border: 0.5px solid #aeaeae; 
                }  
                p {
                    margin: 0.25rem 0px;
                }
                a {
                    color: #4299e1;
                
                    text-decoration: none;
                }
                .title {
                    color: #1a4d2e;        
                    font-size: 1rem;
                    font-weight: 600;
                    margin: 1rem 0px;
                }
                img {
                    width: 5rem;
                    max-width: 100%;
                    height: auto;
                } 
            }
        }

        .form-builder-portal-form-header{
            color: var(--primary);
            font-size: 1.5rem;
            margin-top: 1rem;
            font-weight: 500;
            text-align: center;
        }
    
        .feedbackAssessment {
            width: 100%;
            // overflow-y: auto;
            border-radius: 20px;
            background-color: #fff;
            // padding: 30px 20px 20px 40px;
            padding: 1rem;
            .assesment-re-btn-in-mannager-feedback-container{
                display: flex;
                justify-content: flex-end;
                .assesment-re-btn-in-mannager-feedback{
                    font-size: 1rem;
                    width: 10rem;
                    background-color: #ffffff;
                    color: var(--primary);
                    border: 1px solid var(--primary);
                    border-radius: 10px;
                    padding: 0.25rem 0.5rem;
                    // margin-right: 1rem;
                }
            }
            .MuiStack-root {
                margin-left: 0;
                margin-right: 0;
            }
            .userImg {
                width: 75px;
                border-radius: 50%;
            }
    
            .bor-b {
                border-bottom: 1px solid #00000026;
                padding-bottom: 20px;
            }
            .sticky-row-manager-feedback {
                position: sticky;
                z-index: 8;
                top: 58px;
                background-color: #ffffff;
            }

            .feedback-at-other-pages {
                top: -32px;
                z-index: 111;
            }
    
            .title {
                font-size: 2rem;
                font-weight: 600;
                line-height: 48px;
                letter-spacing: 0em;
                color: var(--primary);
                margin: 0;
            }
    
            .id {
                @include smallText;
            }

            .btns-tops{
                display: flex;
                .view-old-feedback-in{
                    button{
                        @include subHeadingDark;
                        font-weight: 600;
                        padding: 13px 15px;
                        border-color: var(--primary);
                        background: #fff;
                        border-radius: 10px;
                    }    
                }
                .viewBtn {
                    @include subHeadingDark;
                    margin-left: 0.5rem;
                    font-weight: 500;
                    padding: 9px 12px;
                    border-color: var(--primary);
                    background: #fff;
                    border-radius: 10px;
                }
            }           
    
            .trendingRating {
                .title {
                    font-family: Poppins;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                    letter-spacing: 0em;
                    margin: 0;
                    color: #000;
                }
    
                .rateOuter {
                    padding-top: 25px;
                    padding-bottom: 0;
                    @media(max-width:500px){
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 10px;
                    }
                    @media(min-width:500px){
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        grid-gap: 10px;
                    }
                }
    
                .rate {
                    border-radius: 10px;
                    background: #F9F9F9;
                    position: relative;
                    padding: 15px 12px;
                    text-align: center;
                    width: 100%;
                    cursor: pointer;
    
                    .rateTitle {
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: 0em;
                        color: var(--tertiary);
                        margin: 0;
                    }
    
                    .checked {
                        opacity: 0;
                        position: absolute;
                        right: 10px;
                        top: 5px;
                        width: 12px;
                    }
                    
                }
    
                .rate.active {
                    background-color: var(--primary);         
                    .rateTitle {
                        color: var(--secondary);
                    }
                    
                }
    
                .contentDiv {
                    .contentHead {
                        padding-right: 20px;
                        padding-bottom: 20px;
                        padding-top: 20px;
    
                        .title {
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 30px;
                            letter-spacing: 0em;
                            color: #000000;
                            margin: 0;
    
                            .red {
                                color: #DB3535;
                            }
                        }
    
                    }
    
                    .values {
                        padding-bottom: 30px;
                        grid-template-columns: repeat(6, minmax(0, 1fr));
    
                        .valueTitle {
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 30px;
                            letter-spacing: 0em;
                            color: var(--tertiary);
                            margin: 0;
                            text-align: center;
                            padding: 20px 20px;
                            border-radius: 10px;
                            position: relative;
    
                            .checked {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                opacity: 0;
                            }
                        }
    
                        .valueTitle.active {
                            background-color: var(--primary);       
    
                            .rateTitle {
                                color: var(--secondary);
                            }
            
                        }
    
                        .value1 {
                            background: #FCF8E8;
                        }
    
                        .value2 {
                            background: #FFE6E6;
                        }
    
                        .value3 {
                            background: #F6FBF4;
                        }
    
                        .value4 {
                            background: #EAF6F6;
                        }
    
                        .value5 {
                            background: #DAEAF1;
                        }
    
                        .value6 {
                            background: #F9F9F9;
                        }
    
                        .textarea {
                            width: 100%;
                            background: #F9F9F9;
                            border: none;
                            border-radius: 10px;
                            height: 65px;
                            padding: 10px 20px;
                            outline: none;
                        }
    
                        .btn {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0em;
                            border-radius: 10px;
                            padding: 10px 50px;
                        }
    
                        .save {
                            border: 1px solid #000;
                            background: #fff;
                        }
    
                        .next {
                            background: #000;
                            color: #fff;
                        }
    
                        .MuiStack-root.css-13xfuc4-MuiStack-root {
                            margin: 1rem 0rem;
                        }
    
                    }
                }
    
    
            }
    
            .accDetail-questions{
                display: flex;
                justify-content: space-between;
                .icon-btns{
                    min-width: 19%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                   p{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--primary);
                    font-size: 0.87rem;
                    margin-right: 1rem;
                    cursor: pointer;
                   
                   }
                   .peer-btn{
                border: 1px solid var(--primary);
                padding: 2px 1rem;
                border-radius: 0.5rem;
                &:hover{
                    color: skyblue;
                    border: 1px solid skyblue;
                }
    
                }
                   .i-btn{
                    p{
                    background-color: var(--primary);
                    color: #ffffff;
                    border-radius: 1rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    text-align: center;
                    font-weight: 600;
                    &:hover{
                        background-color: skyblue;
                    }
                   }}
                }
            }
            .rateOuter {
                padding-top: 25px;
                padding-bottom: 0;
                @media(max-width:500px){
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 10px;
                }
                @media(min-width:500px){
                    display: grid;
                    grid-template-columns: repeat(6, 1fr);
                    grid-gap: 10px;
                }
            }
            .rate {
                border-radius: 10px;
                background: #F9F9F9;
                position: relative;
                padding: 15px 12px;
                text-align: center;
                width: 100%;
                height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                cursor: pointer;
                
                .rateTitle {
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0em;
                    color: var(--tertiary);
                    margin: 0;
                }
                .checked {
                    opacity: 0;
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    width: 12px;
                }
            }
            .rate.active {
                background-color: var(--primary);           
    
                .rateTitle {
                    color: var(--secondary);
                }
            }
    
            .rate.deactive {
                .rateTitle{
                    color: #cfcfcf;
                    background-color: #f5f5f5;
                    cursor: no-drop;
                }
            }
            .dropdown-heading{
                @include normalText;
                display: flex;
                justify-content: space-between;
                .i-btn{
                    p{
                    background-color: var(--primary);
                    color: #ffffff;
                    border-radius: 1rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    text-align: center;
                    font-weight: 600;
                    cursor: pointer;
                    &:hover{
                        background-color: skyblue;
                    }
                   }}
    
            }
            .accDetal-xFeedback{
                .q-title{
                    @include normalText;
                    margin-top: 0.7rem;
                    margin-left: 0.5rem;
                }
                .q-text{
                    color: var(--tertiary);
                    font-size: 0.82rem;
                    padding: 0.7rem;
                    background-color: #f9f9f9;
                    border-radius: 7px;
                    margin-top: 0.7rem;
                    .readmore-text{
                        font-size: 0.82rem;
                        margin-left: 0.5rem;
                        color: blue;
                        cursor: pointer;
                    }
                }
                
            }
            .q-title-values{
                @include normalText;
                margin-top: 1rem;
                margin-left: 0.5rem;
            }
            .peer-title{
                font-size: 1rem;
                    margin-top: 0.7rem;
                    color: gray;
            }
    
            .accDetal-peerFeedback{
                .p-title{
                    font-size: 1rem;
                    margin-top: 0.7rem;
                    color: gray;
                }
                .p-text{
                    font-size: 1rem;
                    padding: 0.7rem;
                    border-radius: 7px;
                    display: list-item;          /* This has to be "list-item"                                               */
                    list-style-type: square;     /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
                    list-style-position: inside; /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-position */
                }
            }
    
            
        }
        .rightArrow {
            cursor: pointer;
        }
        .main-btn {
            .MuiStack-root {
                margin-left: 0;
                margin-right: 0;
            }
        
            .valuesBtn {
                line-height: 24px;
                width: 163px;
                height: 50px;
                
                border-radius: 10px;
            }
        
            .draft {
                @include subHeadingDark;
                background: #fff;
                border-color: var(--primary);

                display: flex;
                justify-content: center;
                align-items: center;
            }
        
            .next {
                @include subHeadingLight;
                border: none;
                color: #fff;
                background: var(--primary);
            }
        }
        .acc-wrapper{
            .acc-heading{
                margin-top: 1rem;
                font-size: 1.25rem;
                font-weight: 600;
            }
        }
    }
    
    .user-profile-page{
        .feedback{
            .heading {            
                .membCount {
                    @include heading;
                    font-weight: 600;
                    line-height: 30px;
                }
                .member {
                    @include smallText;
                    line-height: 18px;
                }
            }
           
            .userData {
                border: 1px solid #00000038;
                border-radius: 10px;
                padding: 18px;
                margin-bottom: 15px;
               
                .userImg {
                    width: 46px;
                    border-radius: 50%;
                }
                .name {
                    @include subHeadingDark;
                    font-weight: 600;
                    line-height: 24px;
                }
                .member {
                    @include smallText;
                    font-weight: 400;
                    line-height: 18px;
                }
                button.btn {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    letter-spacing: 0em;
                    border-radius: 10px;
                    width: 130px;
                }
                button.btn.green {
                    background: #69C88E;
                }
                button.btn.red {
                    background: #DB3535;
                }
    
            }
        }
    }
    .tab-element{
        margin: 30px 0px;
    }
    .tile-element{
        margin: 30px 0px;
    }
    .info {
        .se-image-container{
            display: flex;
            justify-content: center;
        }

        table{
            margin: 1rem 0px;
            width: 100%;
            border-collapse: collapse;
        }
        th{
            background: #FFD24C;
            padding: 0.25rem 0.5rem;
            border: 0.5px solid #aeaeae; 
        }
        td{
            background: #F5F7F7;
            padding: 0.25rem 0.5rem;
            vertical-align: text-top;
            border: 0.5px solid #aeaeae; 
        }  
        p {
            margin: 0.25rem 0px;
        }
        a {
            color: #4299e1;
        
            text-decoration: none;
        }
        .title {
            color: #1a4d2e;        
            font-size: 1rem;
            font-weight: 600;
            margin: 1rem 0px;
        }
        img {
            width: 5rem;
            max-width: 100%;
            height: auto;
        } 
    }
    .disabledInput {
        .MuiTextField-root {
            pointer-events: none
        }
        textarea {
            opacity: 50%;
        }
        .rateOuter {
            pointer-events: none;
        }

        .Self-Appraisal-desibled {
            background: none !important;
            .rate  {
                background: none;
            }
        }

        .MuiFormControl-root {
            pointer-events: none
        }

        .MuiFormGroup-root {
            pointer-events: none
        }
    }

    .peer-btn{
        
        &:hover{
            color: skyblue;
            border: 1px solid skyblue;
        }
        height: fit-content;
        border: 1px solid var(--primary);
        padding: 2px 1rem;
        border-radius: 0.5rem;
        cursor: pointer;
        text-wrap: nowrap;
        margin: 0;

    }
    .peer-info-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-left: 2rem;
    }

    .like-text-field {
        word-wrap: break-word;
        height: auto !important;
        border-radius: 10px !important;
    }

    .sidedrawer-peer-info {
        .card-title {
            font-size: 1.25rem !important;
        }
        p {
            margin-bottom: 0px !important
        }
    }

    .upload-document-container {
        margin: 30px auto;
        box-sizing: border-box;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid #dad2d2;
        box-shadow: none;
        width: 100%;
        font-size: 1.25rem;
        font-weight: 600;

        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 0px 16px;

        .upload-doc-button {
            font-size: var(--subHeaderFontSize);
            color: var(--primary);
            margin: 0px;
            padding: 0px;
            background: #fff;
            border: 1px solid var(--primary);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            line-height: 24px;
            border-radius: 10px;

            width: 107px;
        }

        .accordian-heading {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .item-distributor {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .doc-distibute {
            display: flex;

            width: 80%;
            overflow: auto;

            .documents {
                border: 1px solid var(--primary);
                border-radius: 10px;
                width: 130px;
                margin-right: 25px;


                display: flex;
                justify-content: space-between;
                padding: 10px;
                
                &:hover{
                    background-color: rgb(227, 251, 255);
                }

                .documents-name {
                    cursor: pointer;

                    width: 80px;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .delete {
                    cursor: pointer;
                }
            }
        }
    }
}
.selfAssesmentAllinone11{
    .feedback-main11 {
        background-color: #F9F9F9;
    
        .feedbackAssessment {
            border-radius: 20px;
            background-color: #fff;
            .assesment-re-btn-in-mannager-feedback-container{
                display: flex;
                justify-content: flex-end;
                .assesment-re-btn-in-mannager-feedback{
                    font-size: 1rem;
                    width: 10rem;
                    background-color: #ffffff;
                    color: var(--primary);
                    border: 1px solid var(--primary);
                    border-radius: 10px;
                    padding: 0.25rem 0.5rem;
                    margin-right: 1rem;
                }
            }
            .MuiStack-root {
                margin-left: 0;
                margin-right: 0;
            }
            .userImg {
                width: 75px;
                border-radius: 50%;
            }
    
            .bor-b {
                border-bottom: 1px solid #00000026;
                padding-bottom: 20px;
            }

            .sticky-row-manager-feedback {
                position: sticky;
                z-index: 8;
                top: 58px;
                background-color: #ffffff;
            }

            .feedback-at-other-pages {
                top: -32px;
                z-index: 111;
            }
    
            .title {
                font-size: 2rem;
                font-weight: 600;
                line-height: 48px;
                letter-spacing: 0em;
                color: var(--primary);
                margin: 0;
            }
    
            .id {
                @include smallText;
            }
    
            .viewBtn {
                @include subHeadingDark;
                font-weight: 600;
                padding: 13px 15px;
                border-color: var(--primary);
                background: #fff;
                border-radius: 10px;
            }
    
            .trendingRating {
                .title {
                    font-family: Poppins;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                    letter-spacing: 0em;
                    margin: 0;
                    color: #000;
                }
    
                .rateOuter {
                    padding-top: 25px;
                    padding-bottom: 0;
                    @media(max-width:500px){
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 10px;
                    }
                    @media(min-width:500px){
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        grid-gap: 10px;
                    }
                }
    
                .rate {
                    border-radius: 10px;
                    background: #F9F9F9;
                    position: relative;
                    padding: 15px 12px;
                    text-align: center;
                    width: 100%;
                    cursor: pointer;
    
                    .rateTitle {
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: 0em;
                        color: var(--tertiary);
                        margin: 0;
                    }
    
                    .checked {
                        opacity: 0;
                        position: absolute;
                        right: 10px;
                        top: 5px;
                        width: 12px;
                    }
                    
                }
    
                .rate.active {
                    background-color: var(--primary);       
    
                    .rateTitle {
                        color: var(--secondary);
                    }
                    
                }
    
                .contentDiv {
                    .contentHead {
                        padding-right: 20px;
                        padding-bottom: 20px;
                        padding-top: 20px;
    
                        .title {
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 30px;
                            letter-spacing: 0em;
                            color: #000000;
                            margin: 0;
    
                            .red {
                                color: #DB3535;
                            }
                        }
    
                    }
    
                    .values {
                        padding-bottom: 30px;
                        grid-template-columns: repeat(6, minmax(0, 1fr));
    
                        .valueTitle {
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 30px;
                            letter-spacing: 0em;
                            color: var(--tertiary);
                            margin: 0;
                            text-align: center;
                            padding: 20px 20px;
                            border-radius: 10px;
                            position: relative;
    
                            .checked {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                opacity: 0;
                            }
                        }
    
                        .valueTitle.active {
                            background-color: var(--primary);       
    
                            .rateTitle {
                                color: var(--secondary);
                            }
                        }
    
                        .value1 {
                            background: #FCF8E8;
                        }
    
                        .value2 {
                            background: #FFE6E6;
                        }
    
                        .value3 {
                            background: #F6FBF4;
                        }
    
                        .value4 {
                            background: #EAF6F6;
                        }
    
                        .value5 {
                            background: #DAEAF1;
                        }
    
                        .value6 {
                            background: #F9F9F9;
                        }
    
                        .textarea {
                            width: 100%;
                            background: #F9F9F9;
                            border: none;
                            border-radius: 10px;
                            height: 65px;
                            padding: 10px 20px;
                            outline: none;
                        }
    
                        .btn {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0em;
                            border-radius: 10px;
                            padding: 10px 50px;
                        }
    
                        .save {
                            border: 1px solid #000;
                            background: #fff;
                        }
    
                        .next {
                            background: #000;
                            color: #fff;
                        }
    
                        .MuiStack-root.css-13xfuc4-MuiStack-root {
                            margin: 1rem 0rem;
                        }
    
                    }
                }
    
    
            }
    
            .accDetail-questions{
                display: flex;
                justify-content: space-between;
                .icon-btns{
                    min-width: 19%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                   p{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--primary);
                    font-size: 0.87rem;
                    margin-right: 1rem;
                    cursor: pointer;
                   
                   }
                   .peer-btn{
                border: 1px solid var(--primary);
                padding: 2px 1rem;
                border-radius: 0.5rem;
                &:hover{
                    color: skyblue;
                    border: 1px solid skyblue;
                }
    
                }
                   .i-btn{
                    p{
                    background-color: var(--primary);
                    color: #ffffff;
                    border-radius: 1rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    text-align: center;
                    font-weight: 600;
                    &:hover{
                        background-color: skyblue;
                    }
                   }}
                }
            }
            .rateOuter {
                padding-top: 25px;
                padding-bottom: 0;
                @media(max-width:500px){
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 10px;
                }
                @media(min-width:500px){
                    display: grid;
                    grid-template-columns: repeat(6, 1fr);
                    grid-gap: 10px;
                }
            }
            .rate {
                border-radius: 10px;
                background: #F9F9F9;
                position: relative;
                padding: 15px 12px;
                text-align: center;
                width: 100%;
                height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                cursor: pointer;
                
                .rateTitle {
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0em;
                    color: var(--tertiary);
                    margin: 0;
                }
                .checked {
                    opacity: 0;
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    width: 12px;
                }
            }
            .rate.active {
                background-color: var(--primary);           
    
                .rateTitle {
                    color: var(--secondary);
                }    
            }
    
            .rate.deactive {
                .rateTitle{
                    color: #cfcfcf;
                    background-color: #f5f5f5;
                    cursor: no-drop;
                }
            }
            .dropdown-heading{
                @include normalText;
                display: flex;
                justify-content: space-between;
                .i-btn{
                    p{
                    background-color: var(--primary);
                    color: #ffffff;
                    border-radius: 1rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    text-align: center;
                    font-weight: 600;
                    cursor: pointer;
                    &:hover{
                        background-color: skyblue;
                    }
                   }}
    
            }
            .accDetal-xFeedback{
                .q-title{
                    @include normalText;
                    margin-top: 0.7rem;
                    margin-left: 0.5rem;
                }
                .q-text{
                    color: var(--tertiary);
                    font-size: 0.82rem;
                    padding: 0.7rem;
                    background-color: #f9f9f9;
                    border-radius: 7px;
                    margin-top: 0.7rem;
                    .readmore-text{
                        font-size: 0.82rem;
                        margin-left: 0.5rem;
                        color: blue;
                        cursor: pointer;
                    }
                }
                
            }
            .q-title-values{
                @include normalText;
                margin-top: 1rem;
                margin-left: 0.5rem;
            }
            .peer-title{
                font-size: 1rem;
                    margin-top: 0.7rem;
                    color: gray;
            }
    
            .accDetal-peerFeedback{
                .p-title{
                    font-size: 1rem;
                    margin-top: 0.7rem;
                    color: gray;
                }
                .p-text{
                    font-size: 1rem;
                    padding: 0.7rem;
                    border-radius: 7px;
                    display: list-item;          /* This has to be "list-item"                                               */
                    list-style-type: square;     /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
                    list-style-position: inside; /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-position */
                }
            }
    
            
        }
        .rightArrow {
            cursor: pointer;
        }
        .main-btn {
            .MuiStack-root {
                margin-left: 0;
                margin-right: 0;
            }
        
            .valuesBtn {
                line-height: 24px;
                width: 163px;
                height: 50px;
                
                border-radius: 10px;
            }
        
            .draft {
                @include subHeadingDark;
                background: #fff;
                border-color: var(--primary);

                display: flex;
                justify-content: center;
                align-items: center;
            }
        
            .next {
                @include subHeadingLight;
                border: none;
                color: #fff;
                background: var(--primary);
            }
        }
        .acc-wrapper{
            .acc-heading{
                margin-top: 1rem;
                font-size: 1.25rem;
                font-weight: 600;
            }
        }
    }
    
    .user-profile-page{
        .feedback{
            .heading {            
                .membCount {
                    @include heading;
                    font-weight: 600;
                    line-height: 30px;
                }
                .member {
                    @include smallText;
                    line-height: 18px;
                }
            }
           
            .userData {
                border: 1px solid #00000038;
                border-radius: 10px;
                padding: 18px;
                margin-bottom: 15px;
               
                .userImg {
                    width: 46px;
                    border-radius: 50%;
                }
                .name {
                    @include subHeadingDark;
                    font-weight: 600;
                    line-height: 24px;
                }
                .member {
                    @include smallText;
                    font-weight: 400;
                    line-height: 18px;
                }
                button.btn {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    letter-spacing: 0em;
                    border-radius: 10px;
                    width: 130px;
                }
                button.btn.green {
                    background: #69C88E;
                }
                button.btn.red {
                    background: #DB3535;
                }
    
            }
        }
    }
    .tab-element{
        margin: 30px 0px;
    }
    .tile-element{
        margin: 30px 0px;
    }
    .info {
        .se-image-container{
            display: flex;
            justify-content: center;
        }

        table{
            margin: 1rem 0px;
            width: 100%;
            border-collapse: collapse;
        }
        th{
            background: #FFD24C;
            padding: 0.25rem 0.5rem;
            border: 0.5px solid #aeaeae; 
        }
        td{
            background: #F5F7F7;
            padding: 0.25rem 0.5rem;
            vertical-align: text-top;
            border: 0.5px solid #aeaeae; 
        }  
        p {
            margin: 0.25rem 0px;
        }
        a {
            color: #4299e1;
        
            text-decoration: none;
        }
        .title {
            color: #1a4d2e;        
            font-size: 1rem;
            font-weight: 600;
            margin: 1rem 0px;
        }
        img {
            width: 5rem;
            max-width: 100%;
            height: auto;
        } 
    }
    .disabledInput {
        .MuiTextField-root {
            pointer-events: none
        }
        textarea {
            opacity: 50%;
        }
        .rateOuter {
            pointer-events: none;
        }

        .MuiFormControl-root {
            pointer-events: none
        }

        .MuiFormGroup-root {
            pointer-events: none
        }
    }

    .peer-btn{
        
        &:hover{
            color: skyblue;
            border: 1px solid skyblue;
        }
        height: fit-content;
        border: 1px solid var(--primary);
        padding: 2px 1rem;
        border-radius: 0.5rem;
        cursor: pointer;
        text-wrap: nowrap;
        margin: 0;

    }
    .peer-info-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-left: 2rem;
    }

    .like-text-field {
        word-wrap: break-word;
        height: auto !important;
        border-radius: 10px !important;
    }

    .sidedrawer-peer-info {
        .card-title {
            font-size: 1.25rem !important;
        }
        p {
            margin-bottom: 0px !important
        }
    }

    .upload-document-container {
        margin: 30px 0px;
        box-sizing: border-box;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid #dad2d2;
        box-shadow: none;
        width: 100%;
        font-size: 1.25rem;
        font-weight: 600;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 0px 16px;

        .upload-doc-button {
            font-size: var(--subHeaderFontSize);
            color: var(--primary);
            margin: 0px;
            padding: 0px;
            background: #fff;
            border: 1px solid var(--primary);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            line-height: 24px;
            border-radius: 10px;

            width: 107px;
        }

        .accordian-heading {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .item-distributor {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .doc-distibute {
            display: flex;

            width: 80%;
            overflow: auto;

            .documents {
                border: 1px solid var(--primary);
                border-radius: 10px;
                width: 130px;
                margin-right: 25px;


                display: flex;
                justify-content: space-between;
                padding: 10px;

                .documents-name {
                    cursor: pointer;

                    width: 80px;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .delete {
                    cursor: pointer;
                }
            }
        }
    }
}
.selfAssesmentAllinone111{
    .feedback-main111 {
        background-color: #F9F9F9;
    
        .feedbackAssessment1 {
            border-radius: 20px;
            background-color: #fff;
            .assesment-re-btn-in-mannager-feedback-container{
                display: flex;
                justify-content: flex-end;
                .assesment-re-btn-in-mannager-feedback{
                    font-size: 1rem;
                    width: 10rem;
                    background-color: #ffffff;
                    color: var(--primary);
                    border: 1px solid var(--primary);
                    border-radius: 10px;
                    padding: 0.25rem 0.5rem;
                    margin-right: 1rem;
                }
            }
            .MuiStack-root {
                margin-left: 0;
                margin-right: 0;
            }
            .userImg {
                width: 75px;
                border-radius: 50%;
            }
    
            .bor-b {
                border-bottom: 1px solid #00000026;
                padding-bottom: 20px;
            }

            .sticky-row-manager-feedback {
                position: sticky;
                z-index: 8;
                top: 58px;
                background-color: #ffffff;
            }

            .feedback-at-other-pages {
                top: -32px;
                z-index: 111;
            }
    
            .title {
                font-size: 2rem;
                font-weight: 600;
                line-height: 48px;
                letter-spacing: 0em;
                color: var(--primary);
                margin: 0;
            }
    
            .id {
                @include smallText;
            }
    
            .viewBtn {
                @include subHeadingDark;
                font-weight: 600;
                padding: 13px 15px;
                border-color: var(--primary);
                background: #fff;
                border-radius: 10px;
            }
    
            .trendingRating {
                .title {
                    font-family: Poppins;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                    letter-spacing: 0em;
                    margin: 0;
                    color: #000;
                }
    
                .rateOuter {
                    padding-top: 25px;
                    padding-bottom: 0;
                    @media(max-width:500px){
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 10px;
                    }
                    @media(min-width:500px){
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        grid-gap: 10px;
                    }
                }
    
                .rate {
                    border-radius: 10px;
                    background: #F9F9F9;
                    position: relative;
                    padding: 15px 12px;
                    text-align: center;
                    width: 100%;
                    cursor: pointer;
    
                    .rateTitle {
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: 0em;
                        color: var(--tertiary);
                        margin: 0;
                    }
    
                    .checked {
                        opacity: 0;
                        position: absolute;
                        right: 10px;
                        top: 5px;
                        width: 12px;
                    }
                    
                }
    
                .rate.active {
                    background-color: var(--primary);       
    
                    .rateTitle {
                        color: var(--secondary);
                    }
                    
                }
    
                .contentDiv {
                    .contentHead {
                        padding-right: 20px;
                        padding-bottom: 20px;
                        padding-top: 20px;
    
                        .title {
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 30px;
                            letter-spacing: 0em;
                            color: #000000;
                            margin: 0;
    
                            .red {
                                color: #DB3535;
                            }
                        }
    
                    }
    
                    .values {
                        padding-bottom: 30px;
                        grid-template-columns: repeat(6, minmax(0, 1fr));
    
                        .valueTitle {
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 30px;
                            letter-spacing: 0em;
                            color: var(--tertiary);
                            margin: 0;
                            text-align: center;
                            padding: 20px 20px;
                            border-radius: 10px;
                            position: relative;
    
                            .checked {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                opacity: 0;
                            }
                        }
    
                        .valueTitle.active {
                            background-color: var(--primary);       
    
                            .rateTitle {
                                color: var(--secondary);
                            }
                        }
    
                        .value1 {
                            background: #FCF8E8;
                        }
    
                        .value2 {
                            background: #FFE6E6;
                        }
    
                        .value3 {
                            background: #F6FBF4;
                        }
    
                        .value4 {
                            background: #EAF6F6;
                        }
    
                        .value5 {
                            background: #DAEAF1;
                        }
    
                        .value6 {
                            background: #F9F9F9;
                        }
    
                        .textarea {
                            width: 100%;
                            background: #F9F9F9;
                            border: none;
                            border-radius: 10px;
                            height: 65px;
                            padding: 10px 20px;
                            outline: none;
                        }
    
                        .btn {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0em;
                            border-radius: 10px;
                            padding: 10px 50px;
                        }
    
                        .save {
                            border: 1px solid #000;
                            background: #fff;
                        }
    
                        .next {
                            background: #000;
                            color: #fff;
                        }
    
                        .MuiStack-root.css-13xfuc4-MuiStack-root {
                            margin: 1rem 0rem;
                        }
    
                    }
                }
    
    
            }
    
            .accDetail-questions{
                display: flex;
                justify-content: space-between;
                .icon-btns{
                    min-width: 19%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                   p{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--primary);
                    font-size: 0.87rem;
                    margin-right: 1rem;
                    cursor: pointer;
                   
                   }
                   .peer-btn{
                border: 1px solid var(--primary);
                padding: 2px 1rem;
                border-radius: 0.5rem;
                &:hover{
                    color: skyblue;
                    border: 1px solid skyblue;
                }
    
                }
                   .i-btn{
                    p{
                    background-color: var(--primary);
                    color: #ffffff;
                    border-radius: 1rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    text-align: center;
                    font-weight: 600;
                    &:hover{
                        background-color: skyblue;
                    }
                   }}
                }
            }
            .rateOuter {
                padding-top: 25px;
                padding-bottom: 0;
                @media(max-width:500px){
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 10px;
                }
                @media(min-width:500px){
                    display: grid;
                    grid-template-columns: repeat(6, 1fr);
                    grid-gap: 10px;
                }
            }
            .rate {
                border-radius: 10px;
                background: #F9F9F9;
                position: relative;
                padding: 15px 12px;
                text-align: center;
                width: 100%;
                height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                cursor: pointer;
                
                .rateTitle {
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0em;
                    color: var(--tertiary);
                    margin: 0;
                }
                .checked {
                    opacity: 0;
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    width: 12px;
                }
            }
            .rate.active {
                background-color: var(--primary);           
    
                .rateTitle {
                    color: var(--secondary);
                }
            }
    
            .rate.deactive {
                .rateTitle{
                    color: #cfcfcf;
                    background-color: #f5f5f5;
                    cursor: no-drop;
                }
            }
            .dropdown-heading{
                @include normalText;
                display: flex;
                justify-content: space-between;
                .i-btn{
                    p{
                    background-color: var(--primary);
                    color: #ffffff;
                    border-radius: 1rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    text-align: center;
                    font-weight: 600;
                    cursor: pointer;
                    &:hover{
                        background-color: skyblue;
                    }
                   }}
    
            }
            .accDetal-xFeedback{
                .q-title{
                    @include normalText;
                    margin-top: 0.7rem;
                    margin-left: 0.5rem;
                }
                .q-text{
                    color: var(--tertiary);
                    font-size: 0.82rem;
                    padding: 0.7rem;
                    background-color: #f9f9f9;
                    border-radius: 7px;
                    margin-top: 0.7rem;
                    .readmore-text{
                        font-size: 0.82rem;
                        margin-left: 0.5rem;
                        color: blue;
                        cursor: pointer;
                    }
                }
                
            }
            .emps-response{
                display: flex;
                justify-content: space-between;
                .q-title-values{
                    width: 30%;
                }
                .disabledInput{
                    width: 66%;
                    .accDetal-xFeedback{
                        width: 100%;
                    }
                    .rateOuter{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        
                        .rate{
                            border-radius: 10px;
                            position: relative;
                            padding: 5px 10px;
                            text-align: center;
                            width: max-content;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .rate.not-selected{
                            display: none;
                        }
                    }
                }
                .accDetal-xFeedback{
                    width: 66%;
                }
                .man-selection-div{
                    width: 66%;
                    .accDetal-xFeedback{
                        width: 100%;
                    }
                }
                .rateOuter{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    
                    .rate{
                        border-radius: 10px;
                        position: relative;
                        padding: 5px 10px;
                        text-align: center;
                        width: max-content;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .rate.not-selected{
                        display: none;
                    }
                }
            }
            .q-title-values{
                @include normalText;
                margin-top: 1rem;
                margin-left: 0.5rem;
            }
            .peer-title{
                font-size: 1rem;
                    margin-top: 0.7rem;
                    color: gray;
            }
    
            .accDetal-peerFeedback{
                .p-title{
                    font-size: 1rem;
                    margin-top: 0.7rem;
                    color: gray;
                }
                .p-text{
                    font-size: 1rem;
                    padding: 0.7rem;
                    border-radius: 7px;
                    display: list-item;          /* This has to be "list-item"                                               */
                    list-style-type: square;     /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
                    list-style-position: inside; /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-position */
                }
            }
    
            
        }
        .rightArrow {
            cursor: pointer;
        }
        .main-btn {
            .MuiStack-root {
                margin-left: 0;
                margin-right: 0;
            }
        
            .valuesBtn {
                line-height: 24px;
                width: 163px;
                height: 50px;
                
                border-radius: 10px;
            }
        
            .draft {
                @include subHeadingDark;
                background: #fff;
                border-color: var(--primary);

                display: flex;
                justify-content: center;
                align-items: center;
            }
        
            .next {
                @include subHeadingLight;
                border: none;
                color: #fff;
                background: var(--primary);
            }
        }
        .acc-wrapper{
            .acc-heading{
                margin-top: 1rem;
                font-size: 1.25rem;
                font-weight: 600;
            }
        }
    }
    
    .user-profile-page{
        .feedback{
            .heading {            
                .membCount {
                    @include heading;
                    font-weight: 600;
                    line-height: 30px;
                }
                .member {
                    @include smallText;
                    line-height: 18px;
                }
            }
           
            .userData {
                border: 1px solid #00000038;
                border-radius: 10px;
                padding: 18px;
                margin-bottom: 15px;
               
                .userImg {
                    width: 46px;
                    border-radius: 50%;
                }
                .name {
                    @include subHeadingDark;
                    font-weight: 600;
                    line-height: 24px;
                }
                .member {
                    @include smallText;
                    font-weight: 400;
                    line-height: 18px;
                }
                button.btn {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    letter-spacing: 0em;
                    border-radius: 10px;
                    width: 130px;
                }
                button.btn.green {
                    background: #69C88E;
                }
                button.btn.red {
                    background: #DB3535;
                }
    
            }
        }
    }
    .tab-element{
        margin: 30px 0px;
    }
    .tile-element{
        margin: 30px 0px;
    }
    .info {

        .se-image-container{
            display: flex;
            justify-content: center;
        }
        
        table{
            margin: 1rem 0px;
            width: 100%;
            border-collapse: collapse;
        }
        th{
            background: #FFD24C;
            padding: 0.25rem 0.5rem;
            border: 0.5px solid #aeaeae; 
        }
        td{
            background: #F5F7F7;
            padding: 0.25rem 0.5rem;
            vertical-align: text-top;
            border: 0.5px solid #aeaeae; 
        }  
        p {
            margin: 0.25rem 0px;
        }
        a {
            color: #4299e1;
        
            text-decoration: none;
        }
        .title {
            color: #1a4d2e;        
            font-size: 1rem;
            font-weight: 600;
            margin: 1rem 0px;
        }
        img {
            width: 5rem;
            max-width: 100%;
            height: auto;
        } 
    }
    .disabledInput {
        .MuiTextField-root {
            pointer-events: none
        }
        textarea {
            opacity: 50%;
        }
        .rateOuter {
            pointer-events: none;
        }

        .MuiFormControl-root {
            pointer-events: none
        }

        .MuiFormGroup-root {
            pointer-events: none
        }
    }

    .peer-btn{
        
        &:hover{
            color: skyblue;
            border: 1px solid skyblue;
        }
        height: fit-content;
        border: 1px solid var(--primary);
        padding: 2px 1rem;
        border-radius: 0.5rem;
        cursor: pointer;
        text-wrap: nowrap;
        margin: 0;

    }
    .peer-info-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-left: 2rem;
    }

    .like-text-field {
        word-wrap: break-word;
        height: auto !important;
        border-radius: 10px !important;
    }

    .sidedrawer-peer-info {
        .card-title {
            font-size: 1.25rem !important;
        }
        p {
            margin-bottom: 0px !important
        }
    }

    .upload-document-container {
        margin: 30px 0px;
        box-sizing: border-box;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid #dad2d2;
        box-shadow: none;
        width: 100%;
        font-size: 1.25rem;
        font-weight: 600;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 0px 16px;

        .upload-doc-button {
            font-size: var(--subHeaderFontSize);
            color: var(--primary);
            margin: 0px;
            padding: 0px;
            background: #fff;
            border: 1px solid var(--primary);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            line-height: 24px;
            border-radius: 10px;

            width: 107px;
        }

        .accordian-heading {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .item-distributor {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .doc-distibute {
            display: flex;

            width: 80%;
            overflow: auto;

            .documents {
                border: 1px solid var(--primary);
                border-radius: 10px;
                width: 130px;
                margin-right: 25px;


                display: flex;
                justify-content: space-between;
                padding: 10px;

                .documents-name {
                    cursor: pointer;

                    width: 80px;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .delete {
                    cursor: pointer;
                }
            }
        }
    }
}

 /* width */
 .feedbackAssessment::-webkit-scrollbar {
    width: 0.32rem;
  }
  
  /* Track */
  .feedbackAssessment::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .feedbackAssessment::-webkit-scrollbar-thumb {
    background: #c7c7c7;
  }
  
  /* Handle on hover */
  .feedbackAssessment::-webkit-scrollbar-thumb:hover {
    background: #a9a9a9;
  }
 /* width */
 .invisible-info-section::-webkit-scrollbar {
    width: 0.32rem;
  }
  
  /* Track */
  .invisible-info-section::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .invisible-info-section::-webkit-scrollbar-thumb {
    background: #c7c7c7;
  }
  
  /* Handle on hover */
  .invisible-info-section::-webkit-scrollbar-thumb:hover {
    background: #a9a9a9;
  }
  @keyframes growWidth {
    0% {
        width: 0px; /* Start with 10px width */
    }
    100% {
        width: 20rem; /* End with 100px width */
    }
}
.invisible-info-popup{
    padding: 0.5rem;
    box-shadow: 0px 0px 12px 1px #d2d2d2;
    margin-bottom: 1rem;
    width: 50%;
    border-radius: 0.5rem;
    position: relative;
    &::before{
        content: ' ';
        display: block;
        position: absolute;
        left: 15px;
        // top: 15px;
        bottom: -8px;
        width: 14px;
        height: 14px;
        // border-color: #d2d2d2;
        // border-width: 1px;
        border: none;
        // border-style: none none solid solid;
        background-color: #fff;
        box-shadow: -2px 2px 3.5px #d2d2d2;
        -webkit-box-shadow: -2px 2px 3.5px #d2d2d2;
        -moz-box-shadow: -2px 2px 3.5px #d2d2d2;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
    }
}
