@import "../utils/utils.scss";

.approver-view-form {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .title-grid {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    .page-title {
        color: #00425A;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        font-family: 'poppins';
        align-self: center;
    }

    .gap-2 {
        gap: 2px;
    }

    .mt-15 {
        margin-top: 15px;
    }

    .do-not-support-txt {
        padding: 6px 8px;
        color: #DB3535;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
    }

    .support-txt {
        padding: 6px 8px;
        color: #00AA5A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
    }

    .supports-btn {
        width: fit-content;
        background-color: transparent;
        padding: 6px 15px;
        color: #7E7E7E;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .view-stakeholder-feedback-btn {
        width: fit-content;
        background-color: var(--graybg);
        padding: 6px 15px;
        border-radius: 10px;
        border: 0.5px solid #979797;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .user-form-card {
        border-radius: 20px;

        .user-card {
            border-radius: 20px;
            padding: 20px;
            border: 1px solid #CEE0E7;
            background-color: #F4F9FB;

            .right-border {
                border-right: 1px solid #E6E6E6;
            }

            .ta-center {
                text-align: center;
            }

            .pl-0 {
                padding-left: 0px !important;
            }

            .pl-35 {
                padding-left: 35px !important;
            }

            .profile-name {
                color: #00425A;
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
                font-family: 'poppins';

                img {
                    margin-left: 4px;
                    height: 8.73px;
                    width: 16px;
                }
            }

            .profile-emp-no {
                color: #00425A;
                font-size: 12px;
                line-height: 15px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .profile-field-label {
                color: #979797;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .profile-field-value {
                color: #00425A;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                font-family: 'poppins';
            }
        }

        .form-card {
            background-color: #FFFFFF;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;


        }

        .save-submit-btn-div {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
            padding-right: 24px;

            .save-draft {
                border: 1px solid #004259;
                border-radius: 10px;
                padding: 13px 20px;
                background-color: #FFFFFF;
                color: #004259;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
                text-transform: capitalize;
            }

            .submit-list {
                background-color: #004259;
                border-radius: 10px;
                padding: 13px 20px;
                color: #FFFFFF;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
                text-transform: capitalize;
                margin-left: 15px;
            }
        }
    }

    .boxpadding {
        padding: 24px 0px;
        margin: 15px 0px 0px;
        background-color: #fff;
        border-radius: 20px;
    }

    .grayTitle {
        color: #7E7E7E;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .blueTitle {
        color: #004259;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        font-family: 'poppins';
    }

    .click-here {
        color: #025573;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        font-family: 'poppins';
        cursor: pointer;
    }

    .check-before-valid {
        color: #7E7E7E;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        font-family: 'poppins';
    }

    .click-here-icon {
        height: 15px;
        width: 15px;
        margin-right: 5px;
        margin-bottom: 4px;
    }

    .validate-candidate-btn {
        width: fit-content;
        background-color: #004259;
        padding: 6px 15px;
        border-radius: 8px;
        border: 0.5px solid #004259;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .validate-candidate-btn:hover {
        width: fit-content;
        background-color: #004259;
        padding: 6px 15px;
        border-radius: 8px;
        border: 0.5px solid #004259;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .do-not-support-promotion-btn {
        width: fit-content;
        background-color: #DB3535;
        padding: 6px 15px;
        border-radius: 8px;
        border: 0.5px solid #DB3535;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .do-not-support-promotion-btn:hover {
        width: fit-content;
        background-color: #DB3535;
        padding: 6px 15px;
        border-radius: 8px;
        border: 0.5px solid #DB3535;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .ta-end {
        text-align: end;
    }

    .doticontext {
        align-items: baseline;
        display: flex;
    }

    .doticontext img {
        margin-right: 10px;
    }

    .borderpadding {
        padding: 0px 24px;
    }

    .head-delete {
        // font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 46.94px;
        text-align: left;
        float: left;
        margin-bottom: 10px;

        display: flex;
        align-items: center;
        margin-right: 1rem;

        .title {
            color: #004259;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            text-transform: capitalize;
        }

        .btnpadding {
            padding: 0px 5px;
        }


    }

    .horizontal-border {
        height: 1px;
        width: 100%;
        background-color: #000000;
        opacity: 10%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .comm-left-text {
        color: #979797;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 8px;
    }

    .jc-end {
        justify-content: end;
    }

    .plan-cancel-btn {
        height: 40px;
        border: 1px solid #004259;
        border-radius: 8px;
        padding: 8px 20px;
        background-color: #FFFFFF;
        color: #004259;
        font-size: 14px;
        font-weight: 400;
    }

    .plan-save-btn {
        height: 40px;
        border: 1px solid #004259;
        border-radius: 8px;
        padding: 8px 20px;
        background-color: #004259;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        margin-left: 15px;
    }

    .role-border-div {
        display: block;
        overflow: hidden;
    }

    .uploadDoc {
        color: #004259;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        text-transform: capitalize;
        margin-top: 10px;
        cursor: pointer;
    }

    .deleteIconRight {
        right: 0px;
        top: 35px;
        position: absolute;
    }

    .deleteSelectBox {
        position: relative;
    }

    .selectIcondelete {
        display: flex;
        align-items: center;
    }

    .deleteSelectBox svg {
        color: red;
        cursor: pointer;
    }

    .pl-15 {
        padding-left: 15px;
    }
}

//----- Sending Comment Right Drawer Start -----//
.sending-comment-drawer {
    padding: 20px;
    width: 500px !important;
    margin-left: 0px !important;

    .sending-comment-for {
        color: #004259;
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        font-family: 'poppins';
    }

    .ta-right {
        text-align: right;
    }

    .your-message {
        color: #272727;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: 'poppins';
        margin-top: 20px;
    }

    .refresh-icon {
        height: 15px;
        width: 15px;
        cursor: pointer;
    }

    .btn-grid {
        display: flex;
        gap: 15px;
        justify-content: end;
        margin-top: 20px;

        .cancel-btn {
            border: 1px solid #004259;
            border-radius: 8px;
            padding: 8px 15px;
            background-color: #FFFFFF;
            color: #004259;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            font-family: 'poppins';
            text-transform: capitalize;
        }

        .send-comment-btn {
            background-color: #004259;
            border-radius: 8px;
            padding: 8px 15px;
            color: #FFFFFF;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            font-family: 'poppins';
            text-transform: capitalize;
        }
    }

    .comments-history {
        color: #004259;
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        font-family: 'poppins';
        margin-top: 24px;
        margin-bottom: 10px;
    }

    .comments-history-card {
        background-color: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 12px;
        padding: 20px;

        .history-list-container {
            display: grid;
            grid-template-columns: 4% 96%;
            margin-bottom: 10px;
        }

        .bottom-border-grid {
            border-bottom: 1px solid #D9D9D9;
        }

        .bullet-point {
            color: #D9D9D9;
            font-size: 30px;
            line-height: 22px;
            font-weight: 500;
            font-family: 'poppins';
        }

        .history-date-time {
            color: #7E7E7E;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            font-family: 'poppins';
        }

        .history-title {
            background-color: #EDFAFF;
            padding: 3px 10px;
            border-radius: 6px;
            color: #004259;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            font-family: 'poppins';
            margin-left: 5px;
        }

        .history-description {
            color: #004259;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            font-family: 'poppins';
            margin-top: 5px;
            margin-bottom: 8px;
        }
    }
}

//----- Sending Comment Right Drawer End -----//

//----- Check List Candidate Valid Right Drawer Start -----//
.check-list-candidate-valid-drawer {
    padding: 20px;
    width: 500px !important;
    margin-left: 0px !important;

    .candidate-validation {
        color: #004259;
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        font-family: 'poppins';
    }

    .ta-right {
        text-align: right;
    }

    .close-icon {
        height: 15px;
        width: 15px;
        cursor: pointer;
    }

    .check-list-candidate-container {
        display: grid;
        grid-template-columns: 5% 95%;
        border-bottom: 1px solid #D9D9D9;
        margin-top: 20px;
    }

    .list-count {
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
    }

    .list-title {
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
    }

    .list-description {
        color: #7E7E7E;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        font-family: 'poppins';
        margin-top: 5px;
        margin-bottom: 8px;
    }
}
//----- Check List Candidate Valid Right Drawer Start -----//

//----- View From Right Drawer Start -----//
.show-panelist-vote-drawer {
    padding: 30px 30px 30px 14px;
    width: 500px !important;
    margin-left: 0px !important;

    .user-grid {
        margin-left: 10px;
        align-content: center;
    }

    .user-profile-icon {
        height: 50px;
        width: 50px;
        border: 2px solid #CBE6F0;
        border-radius: 50%;
    }

    .right-user-name {
        color: #00425A;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        font-family: 'poppins';
    }

    .user-id {
        color: #979797;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        font-family: 'poppins';
    }

    .ta-right {
        text-align: right;
    }

    .title-from-panelists {
        color: #004259;
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        font-family: 'poppins';
    }

    .horizontal-border {
        height: 1px;
        width: 100%;
        background-color: #D9D9D9;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .is-expanded-container {
        display: grid;
        grid-template-columns: 4% 96%;
    }

    .plus-minus-icon {
        height: 20px;
        width: 20px;
        margin-top: 10px;
        cursor: pointer;
    }

    .rating-icon {
        height: auto;
        width: 100%;
        // margin-top: 25px;
        // cursor: pointer;
    }

    .grayCard {
        background-color: #F9F9F9;
        padding: 25px 30px;
        border-radius: 15px;
        // margin-top: 22px;
        margin-bottom: 15px;
    }

    .user-expanded-card {
        width: '100%';
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 4px 20px 3px;
    }

    .user-unfilled-expanded-card {
        width: '100%';
        background-color: #FFFFFF;
        border-radius: 20px;
        padding-top: 10px;
    }

    .d-flex {
        display: flex !important;
    }

    .user-box {
        height: 30px;
        width: 30px;
        background-color: #025573;
        border-radius: 15px;
        align-content: center;
        text-align: center;
        padding-top: 3px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
    }

    .user-name {
        color: #004259;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
        margin-left: 10px;
        align-content: center;
    }

    .personalDeatilTitle {
        color: #004259;
        font-size: 16px;
        font-weight: 600;
        font-family: "poppins";
        line-height: 24px;
        margin-bottom: 10px;
    }

    .personalDeatilleftTitle {
        color: #7E7E7E;
        font-size: 14px;
        font-weight: 400;
        font-family: "poppins";
        line-height: 21px;
        margin-bottom: 10px;
    }

    .personalDeatilRightTitle {
        color: #00425A;
        font-size: 14px;
        font-weight: 500;
        font-family: "poppins";
        line-height: 21px;
        margin-bottom: 10px;
    }

    .support-promotion {
        color: #00AA5A;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .do-not-support-promotion {
        color: #DB3535;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .description-grid {
        margin-top: 0px;
    }

    .description-title {
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
        margin-top: 10px;
    }

    .description {
        color: #7E7E7E;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins';
        line-height: 21px;
        margin-top: 5px;
    }

    .border-horizontal {
        height: 1px;
        background-color: #D9D9D9;
        margin-top: 10px;
        margin-bottom: 5px;
    }
}
//----- View From Right Drawer End -----//


//----- Promotion Feedback Right Drawer Start -----//
// .promotion-feedback-right-drawer {
//     padding: 30px 30px 30px 14px;
//     width: 500px !important;
//     margin-left: 0px !important;
//     .header-div {
//         display: flex !important;
//             border: 1px solid rgba(189, 215, 234, 1);
//             width: max-content;
//             /* line-height: 29px; */
//             padding: 5px 15px;
//             border-radius: 10px;

//         .header-title {
//             color: rgba(0, 66, 90, 1);
//             font-size: 14px;
//             font-weight: 400;
//             margin-bottom: 0px;
//             line-height: 21px;
//         }
//         img{
//             margin-left: 10px;
//             width: 11px;
//             margin-top: 1px;
//         }
//     }
//     .user-grid {
//         margin-left: 10px;
//         align-content: center;
//     }

//     .feedback-user-name {
//         color: #00425A;
//         font-size: 24px;
//         line-height: 30px;
//         font-weight: 700;
//         font-family: 'poppins';
//     }

//     .feedback-user-id {
//         color: #979797;
//         font-size: 14px;
//         line-height: 21px;
//         font-weight: 500;
//         font-family: 'poppins';
//     }

//     .ta-right {
//         text-align: right;
//     }

//     .title-promotion-feedback {
//         color: #00425A;
//         font-size: 20px;
//         line-height: 24px;
//         font-weight: 600;
//         font-family: 'poppins';
//     }

//     .field-label {
//         color: #272727;
//         font-size: 12px;
//         line-height: 15px;
//         font-weight: 400;
//         font-family: 'poppins';
//         opacity: 70%;
//         margin-top: 20px;
//     }

//     .upload-document {
//         color: #00425A;
//         font-size: 16px;
//         line-height: 24px;
//         font-weight: 400;
//         font-family: 'poppins';
//         margin-top: 20px;
//         cursor: pointer;
//     }

//     .file-size-label {
//         color: #7E7E7E;
//         font-size: 12px;
//         line-height: 15px;
//         font-weight: 400;
//         font-family: 'poppins';
//         margin-top: 5px;
//     }

//     .button-grid {
//         display: flex;
//         justify-content: end;
//         margin-top: 20px;
//         gap: 15px;

//         .cancel-btn {
//             background-color: #FFFFFF;
//             border: 1px solid #004259;
//             padding: 13px 20px;
//             border-radius: 8px;
//             color: #004259;
//             font-size: 14px;
//             line-height: 18px;
//             font-weight: 500;
//             font-family: 'poppins';
//         }

//         .send-request-btn {
//             background-color: #004259;
//             padding: 13px 20px;
//             border-radius: 8px;
//             color: #FFFFFF;
//             font-size: 14px;
//             line-height: 18px;
//             font-weight: 500;
//             font-family: 'poppins';
//         }
//     }
// }
//----- Promotion Feedback Right Drawer End -----//