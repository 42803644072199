@import "../utils/utils.scss";

.team-leave-history {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .title-grid {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .page-title {
        color: #00425A;
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        font-family: 'poppins';
        align-self: center;
    }

    .right-top-grid {
        display: flex;
        gap: 10px;
    }

    .filter-btn {
        width: auto;
        background-color: transparent;
        border: 1px solid #00425A;
        padding: 8px 16px;
        border-radius: 10px;
        color: #00425A;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .filter-btn:hover {
        width: auto;
        background-color: transparent;
        border: 1px solid #00425A;
        padding: 8px 16px;
        border-radius: 10px;
        color: #00425A;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        font-family: 'poppins';
        text-transform: capitalize;
    }

    .c-pointer {
        cursor: pointer;
    }


    .team-leave-history-table-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding-top: 12px;
        padding-bottom: 20px;
        margin-bottom: 20px;

        .team-leave-history-table {
            background-color: #ffffff;

            .team-leave-history-list-table {
                width: 100%;
                background-color: #ffffff;

                th,
                td {
                    padding: 0.5rem;
                }

                tr {
                    border-bottom: 1px solid #e2e2e2;
                }

                tr:last-child {
                    border-bottom: 0px solid #e2e2e2;
                }

                th {
                    font-size: 0.9rem;
                    color: var(--primary);
                    font-weight: 500;
                    background-color: #ffffff;
                }

                .table-head-team-leave-history-list {
                    height: 4rem;
                }

                .ta-end {
                    text-align: end;
                }

                .ta-center {
                    text-align: center !important;
                }

                .pl-20 {
                    padding-left: 20px !important;
                }

                .pr-10 {
                    padding-right: 10px !important;
                }

                .pr-20 {
                    padding-right: 20px !important;
                }

                .leave-table-request-value {
                    color: #7E7E7E;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: 'poppins';
                }

                .leave-table-value {
                    color: #979797;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: 'poppins';
                }

                .status-table-value {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    font-family: 'poppins';
                }

                .action-btn {
                    background-color: transparent;
                    padding: 8px 18px;
                    border: none;

                    .menu-icon {
                        height: 15px;
                        width: 15px;
                    }
                }
            }
        }
    }

    .table-pagination {
        width: 100%;
        display: flex;
        justify-content: end;
        margin-top: 10px;

        .d-flex {
            display: flex;
        }

        .left-right-arrow {
            height: 20px;
            width: 12px;
            align-self: center;
        }

        .ml-10 {
            margin-left: 10px;
        }

        .pagination-border {
            height: 32px;
            width: 32px;
            border: 0.5px solid #979797;
            border-radius: 5px;
            margin-left: 10px;
            padding-top: 3px;
            text-align: center;
            align-content: center;

            .pagination-count {
                color: #004259;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}

.declining-leave-for-modal {
    padding: 20px;

    .modal-heading {
        color: #272727;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'poppins';
        margin-bottom: 15px;
    }


    .top-profile {
        background-color: #FFFFFF;
        border-radius: 14px;
        padding: 16px 19px;

        .profile-grid {
            display: flex;
            gap: 10px;

            .user-profile-icon {
                height: 57px;
                width: 57px;
                border: 4px solid #CBE6F0;
                border-radius: 50%;
            }

            .user-name {
                color: #000000;
                font-size: 24px;
                line-height: 36px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .user-id {
                color: #979797;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'poppins';
            }
        }

        .profile-field-label {
            color: #7E7E7E;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'poppins';
            margin-top: 10px;
            margin-bottom: 2px;
        }

        .profile-field-value {
            color: #000000;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'poppins';
        }
    }

    .field-label {
        color: #272727;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        font-family: 'poppins';
        margin-bottom: 4px;
    }

    .leave-note {
        color: #7E7E7E;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        font-family: 'poppins';
        margin-top: 5px;
    }

    .mt-15 {
        margin-top: 15px;
    }

    .action-grid {
        margin-top: 20px;
        text-align: center;

        .cancel-btn {
            background-color: #FFFFFF;
            border: 1px solid #004259;
            padding: 8px 20px;
            border-radius: 10px;
            width: 120px;
            color: #004259;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            font-family: 'poppins';
            text-transform: capitalize;
        }

        .decline-btn {
            background-color: #004259;
            border: 1px solid #004259;
            padding: 8px 20px;
            border-radius: 10px;
            width: 120px;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            font-family: 'poppins';
            text-transform: capitalize;
            margin-left: 10px;
        }
    }
}