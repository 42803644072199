@import "../utils/utils.scss";

.employee-engagement {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .top-btn-List {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
    }

    .active-btn {
        background-color: #BAD7E9;
        padding: 8px 16px;
        border-radius: 40px;
        border: none;
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .inActive-btn {
        background-color: #FFFFFF;
        padding: 8px 16px;
        border-radius: 40px;
        border: none;
        color: #979797;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: 'poppins';
        text-decoration: none;
        cursor: pointer;
    }

    .my-team-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px 24px;
        margin-bottom: 20px;

        .user-profile {
            height: 48px;
            width: 48px;
            border-radius: 50%;
            border: 1px solid #D9D9D9;
        }

        .user-name {
            color: #004259;
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
            font-family: 'poppins';
            margin-left: 10px;
        }

        .user-post {
            color: #7E7E7E;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            font-family: 'poppins';
            margin-left: 10px;
        }

        .user-date {
            color: #979797;
            font-size: 14px;
            line-height: 27px;
            font-weight: 400;
            font-family: 'poppins';
            text-align: end;
        }

        .horizontal-border {
            border: 1px solid #D9D9D9;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .banner-title {
            color: #272727;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'poppins';
        }

        .banner-img {
            width: 100%;
            height: 240px;
            background-color: #E4F6FF;
            border-radius: 14px;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .link-count {
            color: #979797;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            font-family: 'poppins';
        }

        .c-pointer {
            cursor: pointer;
        }

        .like-share-icon {
            width: 18px;
            height: 18px;
        }

        .like-share-lbl {
            color: #979797;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            font-family: 'poppins';
            margin-left: 5px;
        }

        .blue-drak {
            color: #025573;
        }

        .comment-grid {
            align-items: center;
            margin-top: 10px;
        }

    }


    .choose-send-kudos-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding: 0px 24px;
        margin-bottom: 20px;

        .choose-item {
            text-align: center;
            margin-bottom: 15px;
            padding: 5px;
        }

        .choose-item-heading {
            color: #004259;
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
            font-family: 'poppins';
            margin-top: 15px;
            margin-bottom: 5px;
        }

        .choose-item-title {
            color: #004259;
            font-size: 10px;
            line-height: 15px;
            font-weight: 500;
            font-family: 'poppins';
            margin-top: 2px;
        }

        .choose-user {
            height: 48px;
            width: 48px;
            border-radius: 50%;
            border: 1px solid #E5F6FC;
        }
    }

    .suggested-members-card {
        background-color: #ffffff;
        border-radius: 20px;
        padding: 0px 24px;
        margin-bottom: 20px;
        
        .members-item-heading {
            color: #004259;
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
            font-family: 'poppins';
            margin-top: 15px;
            margin-bottom: 5px;
        }

        .members-item {
            margin-bottom: 15px;
            padding: 5px;
        }

        .members-profile-grid {
            display: flex;
            gap: 10px;
        }

        .members-profile {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            border: 1px solid #D9D9D9;
        }

        .members-name {
            color: #004259;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            font-family: 'poppins';
        }

        .members-post {
            color: #7E7E7E;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            font-family: 'poppins';
        }
    }
}