.comments-card-layout {
    background-color: var(--white);
    padding: 1rem;
    padding-right: 0px;
    border-radius: 14px;

    .comment-header-outer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 1.5rem;
        margin-bottom: 1rem;

        .comment-header-inner {
            display: flex;
            align-items: flex-start;

            .comments-label {
                color: var(--primary);
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 0px;
            }

            .comment-count {
                background-color: var(--secondary);
                border-radius: 50%;
                color: var(--primary);
                font-size: 12px;
                font-weight: 400;
                margin-left: 5px;
                margin-bottom: 0px;
                height: 20px;
                width: 20px;
                text-align: center;
                line-height: 20px;
            }
        }

        .filter-icon {
            width: 2rem;
            height: 2rem;
            cursor: pointer;
        }
    }


    .no-comment {
        color: var(--primary);
        text-align: center;
        font-style: oblique;
    }

    .all-comments-wrapper {
        max-height: 36rem;
        overflow-y: auto;

        .comment-user-card {
            border: 1px solid #dadada;
            border-radius: 14px;
            padding: 16px;
            margin-top: 8px;
            margin-right: 1rem;

            .user-profile {
                height: 41px;
                width: 41px;
                // border: 1px solid #BDD7EA;
                border-radius: 50%;

                .empPic {
                    .userImg {
                        width: 38px;
                        height: 38px;
                        border-radius: 50%;
                    }
                }
            }

            .user-div {
                margin-left: 8px;
            }

            .userName {
                color: var(--primary);
                font-size: 0.92rem;
                font-weight: 500;
                margin-bottom: 0px;
            }

            .userPost {
                color: var(--grayDark);
                font-size: 0.81rem;
                font-weight: 400;
                margin-bottom: 0px;
            }

            .user-comment {
                color: var(--tertiary);
                font-size: 0.86rem;
                font-weight: 400;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .comment-date {
                color: var(--grayDark);
                font-size: 0.75rem;
                font-weight: 400;
                margin-bottom: 0px;
            }

            .expandAnyGoal-Icon-div {
                height: 100px;
                width: 100%;
                position: relative;
                margin-bottom: 10px;

                .background-Icon {
                    position: absolute;
                    top: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 1;
                }

                .expandAnyGoal-Icon {
                    position: absolute;
                    top: 0px;
                    left: 48%;
                    transform: translateX(-55%);
                    z-index: 3;
                }
            }

            .expandAnyGoal {
                color: var(--tertiary);
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 0px;
                text-align: center;
            }
        }

        .recently-added-comment {
            opacity: 0.6;
            position: relative;
        }
    }

    .comment-card-bg {
        background-color: #EDFAFF;
    }

    .comment-border {
        height: 1px;
        background-color: var(--grayDark);
        opacity: 50%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .comment-field-div {
        display: flex;
        align-items: center;
        background-color: var(--graybg);
        border-radius: 10px;
    }

    .comment-filter-sidedrawer-outer {

        .sidedrawer {
            width: 25rem;

            .filter-sidedrawer-inner {
                .filter-header {
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: var(--primary);
                }

                .search-popup-req-feed {
                    position: absolute;
                    z-index: 111;
                    width: 100%;
                    padding: 0.7rem;
                    box-shadow: 0px 0px 6px 1px #e1e1e1;
                    background-color: #ffffff;
                    max-height: 20rem;
                    overflow-y: scroll;

                    .search-emp-outer {
                        display: flex;
                        justify-content: space-between;
                        margin: 0.5rem 0px;

                        .empDetaila {
                            display: flex;

                            .empPic {
                                .userImg {
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                }
                            }

                            .userInfo {
                                margin-left: 1rem;

                                .name {
                                    @include subHeadingDark;
                                    font-size: 0.81rem
                                }

                                .designation {
                                    @include smallText;
                                }
                            }
                        }

                        .search-add-emp-btn {
                            padding: 0px 1.5rem;
                            background-color: var(--secondary);
                            color: var(--primary);
                            border-radius: 10px;
                            border: none;
                            font-size: 0.81rem;
                        }

                        .search-added-emp-btn {
                            @include smallText;
                        }
                    }
                    .view-all-search{
                        @include subHeadingDark;
                        font-size: 0.9rem;
                        cursor: pointer;
                        text-align: center;
                       }
                       .no-result{
                        @include subHeadingDark;
                        font-size: 0.9rem;
                        text-align: center;
                       }
                }

                .search-wrapper {
                    .search-added-emp-btn {
                        display: flex;
                        justify-content: center;

                        .empDetaila {
                            display: flex;

                            .empPic {
                                .userImg {
                                    width: 45px;
                                    height: 45px;
                                    border-radius: 50%;
                                }
                            }

                            .userInfo {
                                margin-left: 1rem;

                                .name {
                                    @include subHeadingDark;
                                    font-size: 0.9rem
                                }

                                .designation {
                                    @include smallText;
                                }
                            }

                        }

                        svg {
                            margin-left: 1rem;
                            cursor: pointer;
                        }
                    }

                }
                .filter-body{
                    .searched-selected-emp-outer{
                        display: flex;
                        flex-wrap: wrap;
                        .searched-selected-emp-inner{
                            margin: 0.25rem;
                            padding: 0.25rem;
                            border: 1px solid var(--grayDark);
                            border-radius: 5px;
                            svg{
                                cursor: pointer;
                            }
                        }
                        
                    }
                    .date-filter-to-text{
                        width: 100%;
                        display: flex; /* Ensure it behaves like an inline element */
                            align-items: center; /* Align text to the middle */
                            justify-content: center; /* Center the content */
                            margin: 1rem 0px;
                            margin-top: 1.2rem;
                            font-size: 1rem;
                            font-weight: 500;
                        .horizontal-line-text{
                            display: inline-block; /* Ensure it behaves like an inline element */
                            width: 5rem; /* Set the width to 10 rem */
                            height: 1px; /* Set the height to create a line */
                            background-color: black; /* Set the color of the line */
                            margin: 0 0.5rem; /* Optional: Add some margin for spacing */
                        }

                    }
                    .date-filter-btn-outer{
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 2rem;
                        font-size:0.91rem;
                        font-weight: 500;
                        .reset-btn{                
                            padding: 0.5rem 1rem;                
                            color: var(--primary);
                            background-color: #ffffff;
                            border: 1px solid var(--primary);
                            border-radius: 5px;
                            margin-right: 1rem;
                            &:hover{
                                background-color: rgb(244, 252, 254);
                                color: var(--primary);
                            }
                            &:active{
                                background-color: #ffffff;
                                color: var(--primary);
                            }

                        }
                        .apply-btn{
                            padding: 0.5rem 1rem;
                            color: #ffffff;
                            background-color: var(--primary);
                            border-radius: 5px;
                            &:hover{
                                background-color: #014f6c;
                                color: #ffffff;
                            }
                            &:active{
                                background-color: var(--primary);
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }

    }
}

/* width */
.all-comments-wrapper::-webkit-scrollbar {
    width: 0.41rem;
}

/* Track */
.all-comments-wrapper::-webkit-scrollbar-track {
    background: rgb(234, 234, 234);
}

/* Handle */
.all-comments-wrapper::-webkit-scrollbar-thumb {
    background: rgb(193, 193, 193);
}

/* Handle on hover */
.all-comments-wrapper::-webkit-scrollbar-thumb:hover {
    background: rgb(186, 186, 186);
}