@import '../utils/utils.scss';

.give-peer-feedback-request-one{
    height: calc(100vh - 58px);
    padding: 0px 2rem;
    background-color: var(--graybg);
    .no-result{
        @include heading;
        text-align: center;
        width: 100%;
        padding-top: 2rem;
    }
    .search-wrapper{
        display: flex;
        justify-content: space-between;
        .r-f-tabs{
            display: flex;
            align-items: center;
            .tab{
                border: none;
                background-color: var(--graybg);
                width: fit-content;
                text-align: center;
                @include subHeadingDark;
                margin-right: 1rem;
                padding: 0.5rem 2rem;
                padding-left: 1rem;
                border-radius: 48px;
                cursor: pointer;
                position: relative;
                span{
                    padding: 0.25rem;
                    font-size: 0.65rem;
                    border-radius: 1rem;
                    background-color: var(--primary);
                    color: #ffffff;
                    position: absolute;
                    right: 5px;
                    width: 21px;
                    text-align: center;
                }
            }
            .tab.active{
                background-color: var(--secondary);
            }
        }
        .second-div{
            display: flex;
            align-items: center;
            .filter-tab{
                @include subHeadingDark;
                position: relative;
                margin-left: 0.5rem;
                border: 1px solid var(--primary);
                border-radius: 10px;
                padding: 1.05rem 1.25rem;
                width: 7rem;
                cursor: pointer;
                img{
                    margin-right: 0.5rem;
                }
                .red-dot{
                    width: 12px;
                    height: 12px;
                    background-color: red;
                    border-radius: 6px;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            }
        }
    }
    .feedback-one{
        background: #fff;
        border-radius: 20px;
        padding: 0.5rem;
        .tablee{
            background-color: #ffffff;
            .feedback-emp-list-table{
                width: 100%;
                background-color: #ffffff;
                th, td{
                    padding: 0.5rem;
                }
                tr{
                    border-bottom: 1px solid #e2e2e2;
                }
                th {
                    font-size: 0.9rem;
                    color: var(--primary);
                    font-weight: 500;
                    background-color: #ffffff;
                    
                }
                .table-head-emp-list{
                    height: 4rem;
                }
                .mid-cols{
                    width: 20%;
                    text-align: left;
                }
                .last-col{
                    width: 9rem;
                    text-align: start;
                    
                }
                .last-col.head{
                    padding-right: 1.8rem;
                }
                td{
                    .empDetaila{
                        display: flex;
                        .empPic{
                            .userImg{
                                width: 45px;
                                height: 45px;
                                border-radius: 50%;                                
                            }
                        }
                        .userInfo{
                            margin-left: 1rem;
                            .name {
                                @include subHeadingDark;
                                font-size: 0.9rem
                            }
                            .designation{
                                @include smallText;
                            }
                        }
                        
                    }
                    .btns{
                        font-size: 0.75rem;
                        text-align: left;
                        
                    }
                    .btnss{
                        .send-btn{
                            font-size: 0.75rem;
                            background-color: var(--secondary);
                            color: var(--primary);
                            border: none;
                            padding: 5px 10px;
                            border-radius: 10px;
                        }
                    }
                    .feedback-table-status{
                           @include smallText;
                    }
                    .btns.green {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: #69C88E;
                        font-weight: 500;
                    }
                    .btns.grey {
                        color: var(--tertiary);
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        font-weight: 500;
                    }
                    .btns.yellow {
                        padding: 2px 5px;
                        font-size: var(--smallTextFontSize);
                        color: #fdaa34;
                        font-weight: 500;
                    }
                    .action{
                        button{
                            font-family: 'poppins';
                            padding: 0.25rem;
                            svg{
                                color: var(--primary);
                                font-size: 1.25rem;
                            }
                        }
                        
                    }
                    .status-contain{
                        display: flex;
                        align-items: center;
                        .checkIcon{
                            width: 1.25rem;
                            margin-right: 0.5rem;
                        }
                    }
                }
            }
        }    
    }   
}
