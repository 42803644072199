@import "../../utils/utils.scss";

.hr-succession-plan-page {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1rem 2rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .succession-heading {
        color: #00425A;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
    }

    .succession-sub-heading {
        color: #00425A;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .ml-20 {
        margin-left: 20px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .goals-form-page-container {
        background-color: var(--white);
        border-radius: 14px;
        padding: 20px;

        .field-row {
            display: flex;
            justify-content: space-between;

            .text-field {
                width: 100%;

                .field-label {
                    color: #272727;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.52px;
                    text-align: left;
                    height: 15px;
                    gap: 0px;
                    opacity: 70%;
                }

                .field-description-label {
                    color: #7E7E7E;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19.36px;
                    text-align: left;
                    margin-top: 5px;
                }
            }
        }
    }


    .critical-roles-container {
        display: grid;
        grid-template-columns: 4% 96%;
    }

    .minusPlusIcon {
        height: 20px;
        width: 20px;
        cursor: pointer;
    }

    .dotIcon {
        height: 10px;
        width: 10px;
    }

    .headIcon {
        height: 15px;
        width: 15px;
        margin-left: 5px;
        margin-top: 5px;
    }

    .vertical-border {
        width: 1px;
        height: 100%;
        background-color: #000000;
        opacity: 10%;
        margin-left: 10px;
    }

    .ceo-border {
        height: 42px;
        width: 60px;
        border-radius: 10px;
        border: 1px solid #00425A;
        background-color: #F7FBFD;
        text-align: center;
        align-content: center;
        cursor: pointer;
    }

    .d-flex {
        display: flex;
    }

    .jc-end {
        justify-content: flex-end;
    }

    .jc-sb {
        justify-content: space-between;
    }

    .ac-center {
        align-content: center;
    }

    .ai-center {
        align-items: center;
    }

    .mt-5 {
        margin-top: 5px !important;
    }

    .mt-8 {
        margin-top: 8px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .ml-10 {
        margin-left: 10px;
    }

    .ceo-view-text {
        color: #7E7E7E;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .ceo-edit-text {
        color: #00425A;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .add-position-text {
        color: #00425A;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        align-content: center;
        margin-left: 10px;
        cursor: pointer;
    }

    .delete-position {
        color: #DB3535;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        align-content: center;
        margin-right: 20px;
        cursor: pointer;
    }

    .edit-border {
        width: 60px;
        height: 25px;
        border-radius: 5px;
        border: 1px solid #979797;
        text-align: center;
        align-content: center;
        cursor: pointer;
    }

    .horizontal-border {
        height: 1px;
        width: 100%;
        background-color: #000000;
        opacity: 10%;
    }

    .mtb-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mtb-5 {
        margin-top: 15px;
        margin-bottom: 5px;
    }

    .comm-left-text {
        color: #979797;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 8px;
    }

    .role-border-div {
        border: 1px solid #BDD7EA;
        padding: 6px 10px;
        border-radius: 7px;
        width: fit-content;
    }

    .role-border-color-card-div {
        border: 1px solid #BDD7EA;
        padding: 6px 10px;
        padding-left: 0px;
        border-radius: 7px;
        width: fit-content;
    }

    .color-card {
        margin-right: 5px;
    }

    .role-type {
        color: #00425A;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }

    .role-close-Icon {
        height: 12px;
        width: 12px;
        cursor: pointer;
        margin-left: 15px;
    }

    .plan-cancel-btn {
        height: 40px;
        border: 1px solid #004259;
        border-radius: 8px;
        padding: 8px 20px;
        background-color: #FFFFFF;
        color: #004259;
        font-size: 14px;
        font-weight: 400;
    }

    .plan-save-btn {
        height: 40px;
        border: 1px solid #004259;
        border-radius: 8px;
        padding: 8px 20px;
        background-color: #004259;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        margin-left: 15px;
    }

    .role-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .mitigation-plan-field-label {
        color: #272727;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: left;
        height: 15px;
        gap: 0px;
        opacity: 70%;
    }
}

.modal-pb-10 {
    padding-bottom: 10px;
}

.profile-edit {
    padding: 20px;

    .modal-heading {
        color: #00425A;
        font-size: 20px;
        font-weight: 600;
    }

    .modal-reason-position-heading {
        color: #004259;
        font-size: 14px;
        font-weight: 600;
    }

    .modal-save-btn-grid {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }

    .modal-save-btn {
        height: 40px;
        border: 1px solid #004259;
        border-radius: 8px;
        padding: 8px 20px;
        background-color: #004259;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
    }

    .modal-close-Icon {
        height: 12px;
        width: 12px;
        cursor: pointer;
        text-align: end;
    }

    .you-moved {
        color: #7E7E7E;
        font-size: 14px;
        font-weight: 400;
        margin-right: 5px;
        align-content: center;
    }

    .user-name {
        color: #00425A;
        font-size: 14px;
        font-weight: 400;
        margin-left: 5px;
        align-content: center;
    }

    .date-end-grid {
        text-align: end;
    }

    .modal-date-description {
        color: #7E7E7E;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
    }

    .modal-ceo-grid {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .modal-ceo {
        color: #979797;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        padding: 3px 8px;
        align-content: center;
    }

    .modal-right-arrow {
        margin-left: 5px;
        margin-right: 5px;
    }

    .profile-card {
        border: 1px solid;
        border-image-source: linear-gradient(180deg, #CEE0E7 0%, #EBF8FD 100%);
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 20px;

        .right-border {
            border-right: 1px solid #E6E6E6;
        }

        .ta-center {
            text-align: center;
        }

        .profile-name {
            color: #00425A;
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
        }

        .profile-emp-no {
            color: #00425A;
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
        }

        .profile-field-label {
            color: #979797;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
        }

        .profile-field-value {
            color: #00425A;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
        }
    }

    .rate-competency-card {
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 20px;
        margin-top: 20px;
    }

    .rate-competency-count-grid {
        background: #EDFAFF;
        padding: 6px 8px;
        border-radius: 8px;
        width: fit-content;
        margin-left: 10px;

        .count {
            color: #272727;
            font-size: 14px;
            font-weight: 400;
        }

        .count-msg {
            color: #7E7E7E;
            font-size: 14px;
            font-weight: 400;
        }

        .ml-10 {
            margin-left: 10px;
        }
    }

    .select-field-label {
        color: #979797;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        margin-top: 10px;
    }

    .select-field-value {
        color: #00425A;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        margin-top: 10px;
    }

    .hr-rate-competency {
        color: #00425A;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        align-content: center;
    }

    .field-description-label {
        color: #7E7E7E;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-top: 5px;
    }

    .rate-competency-grid {
        display: grid;
        grid-template-columns: 18.8% 18.8% 18.8% 18.8% 18.8%;
        grid-column-gap: 10px;
    }

    .profile-horizontal-border {
        height: 1px;
        width: 100%;
        background-color: #D9D9D9;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .text-field-area {
        background-color: #FFFFFF;
        color: #7E7E7E;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
    }
}


.adding-role-edit {
    padding: 20px;

    .adding-role-card {
        border: 1px solid;
        border-image-source: linear-gradient(180deg, #CEE0E7 0%, #EBF8FD 100%);
        background-color: #FFFFFF;
        border-radius: 20px;
        padding: 20px;
    }

    .search-field-label {
        color: #272727;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        opacity: 70%;
        margin-bottom: 4px;
    }

    .role-value {
        color: #00425A;
        font-size: 24px;
        line-height: 36px;
        font-weight: 500;
    }

    .title-field-label {
        color: #00425A;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        margin-top: 20px;
    }

    .adding-role-container {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: 10px;
    }

    .adding-role-border {
        border: 1px solid #BDD7EA;
        padding: 6px 10px;
        border-radius: 7px;
        width: fit-content;
    }

    .adding-role-div {
        display: flex;
        align-items: center;
    }

    .adding-role-type {
        color: #00425A;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }

    .adding-role-close {
        height: 12px;
        width: 12px;
        cursor: pointer;
        margin-left: 15px;
    }

    .adding-text-field {
        color: #7E7E7E;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
    }

    .adding-text-area {
        background-color: #FFFFFF;
        color: #7E7E7E;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
    }

    .adding-bottom-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .adding-cancel-btn {
        height: 40px;
        border: 1px solid #004259;
        border-radius: 8px;
        padding: 8px 20px;
        background-color: #FFFFFF;
        color: #004259;
        font-size: 14px;
        font-weight: 400;
    }

    .adding-save-btn {
        height: 40px;
        border: 1px solid #004259;
        border-radius: 8px;
        padding: 8px 20px;
        background-color: #004259;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        margin-left: 15px;
    }

    .adding-mt-10 {
        margin-top: 10px;
    }

    .adding-mt-20 {
        margin-top: 20px;
    }
}