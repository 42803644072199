.my-team-page-main-container{
    height: calc(100vh - 58px);
    background-color: var(--graybg);
    padding: 1rem;
    .tabs-outer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tabs{
            @include heading;
            font-size: 1.1rem;
            display: flex;
            flex-direction: row;
            background-color: #ffffff;
            border-radius: 2rem;
            width: max-content;
            .tab{
                border: none;
                background-color: #ffffff;
                border-radius: 2rem;
                cursor: pointer;
                padding: 0.5rem 2rem;
            }
            .tab.active{
                background-color: var(--primary);
                color: var(--graybg);
                font-weight: 500;
                border-radius: 2rem;
            }
        }
       
        
    }

    .table-container-sample {
        position: relative;
        width: 100%;
        height: 32rem;
        overflow-x: auto;
        margin-top: 1rem;
        table {
            border-collapse: collapse;
            width: 100%;
            table-layout: fixed;
            border: none;
            thead{
                position: sticky;
                top: 0;
                z-index: 2;
            }
            th, td {
                background-color: #ffffff;
                padding: 12px 8px;
                text-align: left;
                width:10rem;
                border: none;
                word-break: break-word;
            }
            tr{
                border-bottom: 1px solid rgb(206, 206, 206);
            }
            th {
                .col-head-outer{
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    img{
                        margin-right: 0.5rem;
                    }
                    .table-heading{
                        margin-left: 0rem;
                        color: #00425A;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                    }
                    .sort-icon{
                        margin-left: 0.5rem;
                        color: var(--primary);
                        font-size: 0.9rem;
                        cursor: pointer;
                    }
                }
            }
            th.sticky, td.sticky {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                background-color: #f4fdff;
                z-index: 1;
                width: 7rem;
            }
            th.sticky2, td.sticky2 {
                position: -webkit-sticky;
                position: sticky;
                left: 7rem;
                background-color: #f4fdff;
                z-index: 1;
                width: 9rem;
            }
            th.sticky-right, td.sticky-right {
                position: -webkit-sticky;
                position: sticky;
                right: 0;
                background-color: #f4fdff;
                z-index: 1;
                width: 6rem;
            }
            th.sticky + th.sticky, td.sticky + td.sticky {
                left: 50px; /* Adjust width of first sticky column */
            }
            td{
                color: #979797;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                line-height: normal;
            }
            th.gray-block, td.gray-block{
                background-color: #f2f2f2;
            }
            td.table-value-emp-name {
                    color: #00425A;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    .all-flags{
                        display: flex;
                        justify-content: flex-start;
                    }
                    
                }
            td.table-value.mid-align {
                    text-align: center;
                }
            td.table-value-btns {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                // padding: 1rem;
                    .edit-btn {
                        width: 2.7rem;
                        font-size: 0.75rem;
                        color: var(--primary);
                        background-color: #ffffff;
                        border: 1px solid gray;
                        border-radius: 5px;
                        margin-bottom: 0.5rem;
                    }
                    .view-btn {
                        width: 2.7rem;
                        font-size: 0.75rem;
                        color: var(--primary);
                        background-color: #ffffff;
                        border: 1px solid gray;
                        border-radius: 5px;
                    }
                }
            
        }
       
    }

    .table-bg {
        border-radius: 20px;
        background-color: #fff;
        padding-top: 20px;
        margin-top: 15px;
        margin-bottom: 15px;

        .no-data{
            color: var(--primary);
            margin-top: 1rem;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 500;
        }

        .table-top-layout {
            display: flex;
            justify-content: space-between;
            margin: 0px 1rem;

            .table-top-column {
                display: flex;
            justify-content: space-between;

            .search-bar-div{
                position: relative;
                .search-bar {
                    background-color: #F9F9F9;
                    border-radius: 10px;                
                    }
                    .close-icon{
                        color: gray !important;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                    }
                }

                .table-filter-dropdown {

                    .filter-tab {
                        @include subHeadingDark;
                        background-color: #BAD7E9;
                        position: relative;
                        margin-left: 1rem;
                        border: 1px solid var(--primary);
                        border-radius: 10px;
                        padding: 1.05rem 1.25rem;
                        width: 7.9rem;
                        cursor: pointer;

                        img {
                            margin-right: 0.7rem;
                        }

                        .red-dot {
                            width: 12px;
                            height: 12px;
                            background-color: red;
                            border-radius: 6px;
                            position: absolute;
                            top: 5px;
                            right: 5px;
                        }
                    }
                }

                .check-box {
                    color: #00425A;
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: 10px;
                    margin-left: 10px;
                }

                .w-100 {
                    width: 100%;
                }

                .mt-12 {
                    margin-top: 12px;
                }

                .mr-inherit {
                    margin-right: inherit;
                }
            }

            .expand-all {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-right: inherit;
            }
        }

    }
}