@import "../utils/utils.scss";

.hr-view-sessions {
    width: 100%;
    height: calc(100vh - 58px);
    padding: 1.5rem;
    background-color: var(--graybg);
    overflow-y: auto;

    .scheduled-session-card {
        background: #fff;
        border-radius: 20px;
        padding: 30px 0px;
        padding-top: 10px;

        .scheduled-session-table {
            width: 100%;
            background-color: #ffffff;

            .send-btn {
                background-color: var(--secondary);
                color: var(--primary);
                border: none;
                padding: 5px 10px;
                border-radius: 10px;
                width: 100%;

                svg {
                    margin-right: 0.25rem;
                }
            }

            th,
            td {
                padding: 0.5rem;
                text-align: left;
            }

            tr {
                border-bottom: 1px solid #e2e2e2;
                padding-left: 20px;
                padding-right: 20px;
            }

            th {
                font-size: 0.9rem;
                color: var(--secondary);
                font-weight: 500;
            }

            .table-heading {
                height: auto;
            }

            .table-head-label {
                color: #004259;
                font-size: 14px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .action-w-120 {
                width: 120px;
            }

            .col-w-150 {
                width: 150px;
            }

            .table-value {
                color: #7E7E7E;
                font-size: 14px;
                font-weight: 400;
                font-family: 'poppins';
            }

            .ta-end {
                text-align: end;
            }

            .action-btn-div {
                cursor: pointer;
                text-align: left;
            }

            .table-live-value {
                color: #DB3535;
                font-size: 14px;
                font-weight: 500;
                font-family: 'poppins';
            }

            .live-bullet-point {
                font-size: 30px;
                vertical-align: middle;
                line-height: 5px;
            }

            .scheduled-time {
                font-size: 12px;
            }

            .post-count {
                background-color: #004259;
                padding: 4px 6px;
                border-radius: 50%;
                color: #FFFFFF;
                font-size: 10px;
                font-weight: 500;
                margin-left: 5px;
            }

            .start-btn {
                background: #BAD7E9;
                height: 40px;
                padding: 8px 25px;
                border-radius: 10px;
                border: none;
                color: #004259;
                font-size: 16px;
                font-weight: 400;
                cursor: pointer;
                text-transform: capitalize;
            }

            .c-pointer {
                cursor: pointer;
            }

            .icon-mr-5 {
                margin-right: 5px;
            }
        }
    }

    .d-flex {
        display: flex !important;
    }

    .jc-end {
        justify-content: flex-end;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .left-right-arrow {
        height: 20px;
        width: 12px;
        align-self: center;
    }

    .ml-10 {
        margin-left: 10px;
    }

    .pagination-border {
        height: 32px;
        width: 32px;
        border: 0.5px solid #979797;
        border-radius: 5px;
        margin-left: 10px;
        padding-top: 3px;
        text-align: center;
        align-content: center;

        .pagination-count {
            color: #004259;
            font-size: 16px;
            font-weight: 400;
        }
    }
}